import moment from 'moment';

export class SecurityPriceAlert {
  public id: string;
  public assetType: string;
  public condition: string;
  public insertTimestamp: string;
  public price: string;
  public priceType: string;
  public securityMarketId: number;
  public status: string;
  public ticker: string;
  public currentPrice?: number;
  public createdLocalDate?: Date;

  constructor(data: Partial<SecurityPriceAlert>) {
    data.createdLocalDate = moment.utc(data.insertTimestamp, 'YYYY-MM-DD hh:mm:ss a').toDate();
    Object.assign(this, data);
  }
}

export class WatchlistItem {
  public id: string;
  public insertTimestamp: string;
  public securityMarketid: string;
  public ticker: string;
  public lastPrice?: number;
  public netchange?: number;
  public low?: number;
  public high?: number;
  public createdLocalDate?: Date;

  constructor(data: Partial<WatchlistItem>) {
    data.createdLocalDate = moment.utc(data.insertTimestamp, 'YYYY-MM-DD hh:mm:ss a').toDate();
    Object.assign(this, data);
  }
}

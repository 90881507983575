import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { PopupSettings } from '@progress/kendo-angular-dropdowns/common/models/popup-settings';

@Component({
  selector: 'pinnakl-dropdown',
  templateUrl: './pinnakl-dropdown.component.html'
})
export class PinnaklDropdownComponent implements AfterViewInit {
  private dropdownValueSet = false;
  private dropdownValue: any;
  private _dropdownSource: any[];
  comboBoxInstance: ComboBoxComponent;
  @Input() popupSettings?: PopupSettings;
  @Input() controlName: string;
  @Input() disabled: boolean;
  @Input() clearButton = true;
  @Input()
  dropdownOptions: {
    allowCustom: boolean;
    isAsync: boolean;
    modelNormalizer: (textObservable: Observable<string>) => Observable<any>;
    modelProperty: string;
    objectModel: boolean;
    viewProperty: string;
  };
  dropdownCollection: any[];
  dropdownLoading: boolean;
  defaultValue: any = null;
  @Input() form: UntypedFormGroup;
  @Input() setDefaultSingleValue: boolean;
  @Output() dropdownFetchData = new EventEmitter<void>();
  @Output() onDropdownValueChange = new EventEmitter<number>();
  @Output() onFilterValueChange = new EventEmitter<string>();

  get dropdownSource(): any[] {
    return this._dropdownSource;
  }

  @Input()
  set dropdownSource(dropdownSource: any[]) {
    this.dropdownCollection = this._dropdownSource = dropdownSource;
    if (!this.dropdownOptions || !this.dropdownOptions.isAsync) {
      return;
    }
    this.dropdownLoading = false;
    if (this.comboBoxInstance) {
      this.comboBoxInstance.toggle(true);
    }
  }

  ngAfterViewInit(): void {
    // If there is only one value in list then this value should be set as default
    setTimeout(() => {
      // in AfterViewInit hook value from @Input is initialized (moved from setter)
      // Fix: In new deal modal for field org value selected but reactive form not initialized
      if (this.dropdownSource?.length === 1 && this.dropdownOptions && this.setDefaultSingleValue) {
        if (this.dropdownOptions.objectModel || typeof this.dropdownSource[0] !== 'object') {
          this.defaultValue = this.dropdownSource[0];
          this.form.patchValue({ [this.controlName]: this.dropdownSource[0] });
        } else {
          this.defaultValue = this.dropdownSource[0][this.dropdownOptions.modelProperty];
          this.form.patchValue({
            [this.controlName]: this.dropdownSource[0][this.dropdownOptions.modelProperty]
          });
        }
      } else {
        this.defaultValue = null;
      }
    });
  }

  dropdownFetchDataEmit(): void {
    this.dropdownFetchData.emit();
  }

  dropdownBlurred(comboBoxInstance: ComboBoxComponent): void {
    if (!this.comboBoxInstance) {
      this.comboBoxInstance = comboBoxInstance;
    }

    const formControlValue = this.form.controls[this.controlName].value;

    if (!this.dropdownValueSet || (formControlValue !== null && formControlValue !== undefined)) {
      return;
    }

    this.form.patchValue({ [this.controlName]: this.dropdownValue });
    this.dropdownValue = null;
    this.dropdownValueSet = false;
  }

  dropdownValueChanged(changedDropdownModelValue: any): void {
    this.onDropdownValueChange.emit(changedDropdownModelValue);
    if (this.comboBoxInstance && changedDropdownModelValue == null) {
      this.comboBoxInstance.toggle(true);
    }
  }

  filterDropdown(text: string): void {
    this.dropdownCollection = this._dropdownSource.filter(item => {
      const itemText: string =
        this.dropdownOptions && this.dropdownOptions.viewProperty
          ? (item[this.dropdownOptions.viewProperty]?.toString() ?? '')
          : item.toString();
      return itemText.toLowerCase().includes(text.toLowerCase());
    });
    this.onFilterValueChange.emit(text);
  }

  // TODO: REMOVE IF DROPDOWN WORK PROPERLY AFTER REALTIME TESTING
  dropdownFocused(comboBoxInstance: ComboBoxComponent): void {
    return;
    // if (this.disabled) {
    //   return;
    // }
    // if (!this.comboBoxInstance) {
    //   this.comboBoxInstance = comboBoxInstance;
    // }
    // this.dropdownValue = this.form.controls[this.controlName].value;
    // // this.dropdownValueSet = true;
    // // this.form.patchValue({ [this.controlName]: null });
    // if (!this.comboBoxInstance.isOpen) {
    //   comboBoxInstance.open.emit();
    // }
  }

  dropdownOpen(event: Event, comboBoxInstance: ComboBoxComponent): void {
    if (!this.comboBoxInstance) {
      this.comboBoxInstance = comboBoxInstance;
    }
    if (!this.dropdownOptions || !this.dropdownOptions.isAsync) {
      this.comboBoxInstance.toggle(true);
      return;
    }
    if (event) {
      event.preventDefault();
    }
    this.dropdownLoading = true;
    this.dropdownFetchData.emit();
  }

  defaultModelNormalizer(textObservable: Observable<string>): Observable<string> {
    return textObservable.pipe(map((text: string) => text));
  }
}

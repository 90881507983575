import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Folder } from '@pinnakl/poems/folders/domain';
import { ItemStatus } from '@pinnakl/shared/types';
import {
  createFolder,
  deleteAllowedFolder,
  deleteFolder,
  folderCreated,
  folderCreateFailure,
  folderDataLoaded,
  folderDeleteAllowed,
  folderDeleteAllowedFailure,
  folderDeleted,
  folderDeleteFailure,
  foldersLoaded,
  folderUpdated,
  folderUpdateFailure,
  loadFolders,
  selectFolder,
  updateFolder
} from './folders.actions';
import { FoldersState } from './folders.models';

export const foldersAdapter: EntityAdapter<Folder> = createEntityAdapter<Folder>();

export const initialState: FoldersState = foldersAdapter.getInitialState({
  foldersLoaded: false,
  foldersLoading: false,
  selectedFolderId: null,
  customFolderData: null,
  createFolderStatus: null,
  updateFolderStatus: null,
  deleteFolderStatus: null,
  deleteAllowedFolderStatus: null
});

const loadFoldersConfig = (
  loaded: boolean,
  loading: boolean
): { foldersLoaded: boolean; foldersLoading: boolean } => ({
  foldersLoaded: loaded,
  foldersLoading: loading
});

export const foldersReducer = createReducer(
  initialState,
  on(folderDataLoaded, (state, { customFolderData }) => ({
    ...state,
    customFolderData
  })),
  on(loadFolders, state => ({
    ...state,
    ...loadFoldersConfig(false, true)
  })),
  on(foldersLoaded, (state, { folders }) =>
    foldersAdapter.setAll(folders, {
      ...state,
      ...loadFoldersConfig(true, false)
    })
  ),
  on(createFolder, state => ({
    ...state,
    createFolderStatus: null
  })),
  on(folderCreated, (state, { folder }) =>
    foldersAdapter.addOne(folder, {
      ...state,
      createFolderStatus: {
        status: ItemStatus.Success,
        data: { folder }
      }
    })
  ),
  on(folderCreateFailure, (state, { error }) => ({
    ...state,
    createFolderStatus: {
      status: ItemStatus.Failure,
      error: { error }
    }
  })),
  on(updateFolder, state => ({
    ...state,
    updateFolderStatus: null
  })),
  on(folderUpdated, (state, { folder }) =>
    foldersAdapter.setOne(folder, {
      ...state,
      updateFolderStatus: {
        status: ItemStatus.Success,
        data: { folder }
      }
    })
  ),
  on(folderUpdateFailure, (state, { error }) => ({
    ...state,
    updateFolderStatus: {
      status: ItemStatus.Failure,
      error: { error }
    }
  })),
  on(deleteFolder, state => ({
    ...state,
    deleteFolderStatus: null
  })),
  on(folderDeleted, (state, { id }) =>
    foldersAdapter.removeOne(id, {
      ...state,
      deleteFolderStatus: {
        status: ItemStatus.Success,
        data: { id }
      }
    })
  ),
  on(folderDeleteFailure, (state, { error, id }) => ({
    ...state,
    deleteFolderStatus: {
      status: ItemStatus.Failure,
      error: { error, id }
    }
  })),

  on(deleteAllowedFolder, state => ({
    ...state,
    deleteAllowedFolderStatus: null
  })),
  on(folderDeleteAllowed, (state, { id }) => ({
    ...state,
    deleteAllowedFolderStatus: { id, status: ItemStatus.Success }
  })),
  on(folderDeleteAllowedFailure, (state, { id, error }) => ({
    ...state,
    deleteAllowedFolderStatus: { id, status: ItemStatus.Failure, error }
  })),
  on(selectFolder, (state, { id }) => ({ ...state, selectedFolderId: id }))
);

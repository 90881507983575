import { Injectable } from '@angular/core';
import { EnvNames } from './environment-names.enum';
import { PinnaklEnvironment } from './environment.model';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  private _environment?: PinnaklEnvironment;

  get isDevEnv(): boolean {
    return this._environment?.envName === EnvNames.dev;
  }

  get isProdEnv(): boolean {
    return this._environment?.envName === EnvNames.prod;
  }

  get environment(): PinnaklEnvironment | undefined {
    return this._environment;
  }

  registerEnvironment(config: PinnaklEnvironment): void {
    this._environment = config;
  }

  get<T = any>(key: keyof PinnaklEnvironment): T {
    if (!this.environment) {
      throw new Error('Environment was not defined.');
    }
    if (this.environment[key] == null) {
      console.error('Requested property does not not exist in environment configuration.', {
        key,
        configuration: [EnvNames.dev, EnvNames.staging].includes(this.environment.envName)
          ? this.environment
          : 'private'
      });
    }
    return this.environment[key] as T;
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadRebalanceConfig } from './rebalance-config-state.actions';
import { PmsRebalanceConfigState } from './rebalance-config-state.model';
import { rebalanceConfigQuery } from './rebalance-config-state.selectors';

@Injectable({
  providedIn: 'root'
})
export class PmsRebalanceConfigStateFacadeService {
  private stagedRowsForRebalance = new Set<string>();
  rebalanceConfig$ = this.store.select(rebalanceConfigQuery.getSelectedRebalanceConfig);
  rebalanceConfigLoading$ = this.store.select(rebalanceConfigQuery.getRebalanceConfigLoading);

  get stagedRebalanceRows(): string[] {
    return [...this.stagedRowsForRebalance];
  }

  constructor(private readonly store: Store<PmsRebalanceConfigState>) {}

  loadPmsRebalanceConfig(): void {
    this.store.dispatch(loadRebalanceConfig());
  }

  addStagedRowsForRebalance(rowId: string): void {
    this.stagedRowsForRebalance.add(rowId);
  }

  resetStagedRowsForRebalance(positionsIds?: string[]): void {
    if (positionsIds) {
      positionsIds.forEach(id => this.stagedRowsForRebalance.delete(id));
    } else {
      this.stagedRowsForRebalance.clear();
    }
  }
}

import {
  createFeatureSelector,
  createSelector,
  DefaultProjectorFn,
  MemoizedSelector
} from '@ngrx/store';
import { PriceEventData } from '@pinnakl/poems/streams/price/domain';
import { PriceEventsStreamState } from './price-events-stream.models';
import { priceEventsSelectors } from './price-events-stream.reducer';

export const featureSelectorName = 'price-events-stream';

const { selectAll, selectEntities } = priceEventsSelectors;

const getPriceEventsStateSelector =
  createFeatureSelector<PriceEventsStreamState>(featureSelectorName);

const getStreamLoading = createSelector(getPriceEventsStateSelector, ({ loading }) => loading);
const getStreamError = createSelector(getPriceEventsStateSelector, ({ error }) => error);
const getAllEventsData = createSelector(getPriceEventsStateSelector, selectAll);
const getAllEventsEntities = createSelector(getPriceEventsStateSelector, selectEntities);

const getPriceEventsBySecurityMarketId = (
  id: string
): MemoizedSelector<
  object,
  PriceEventData | undefined,
  DefaultProjectorFn<PriceEventData | undefined>
> => createSelector(getAllEventsEntities, entities => entities[id]);

const getConnectedStatus = createSelector(
  getPriceEventsStateSelector,
  ({ connected }) => connected
);
const getErrorStatus = createSelector(
  getPriceEventsStateSelector,
  ({ connectionError }) => connectionError
);
const getLastMessageTime = createSelector(
  getPriceEventsStateSelector,
  ({ timestamp }) => timestamp
);

export const priceEventsStreamQuery = {
  getStreamLoading,
  getAllEventsData,
  getAllEventsEntities,
  getPriceEventsBySecurityMarketId,
  getStreamError,
  getConnectedStatus,
  getErrorStatus,
  getLastMessageTime
};

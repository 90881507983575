import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, NgControl } from '@angular/forms';
import {
  HandleFormControlCreation,
  InjectNgControl,
  ListenFormControlStatusChangesComponent
} from '@pinnakl/shared/util-helpers';
import { Subscription } from 'rxjs';
import { InputNumberPublicApiComponent } from './input-number-public-api.component';

@Component({
  template: '',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlInjectedInputNumberComponent
  extends InputNumberPublicApiComponent
  implements ListenFormControlStatusChangesComponent, OnInit, OnChanges
{
  formControl?: FormControl;
  public ngControl: NgControl;
  public statusChangesSubscription?: Subscription;
  public readonly destroyRef = inject(DestroyRef);

  @Input() controlWasChanged?: FormGroup;

  constructor(public readonly cdr: ChangeDetectorRef) {
    super();
    this.ngControl = InjectNgControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['controlWasChanged'] && !changes['controlWasChanged']?.firstChange) {
      this.resetControl();
    }
  }

  ngOnInit(): void {
    this.resetControl();
  }

  private resetControl(): void {
    HandleFormControlCreation(this);
  }
}

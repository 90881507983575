import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FoldersEffects } from './folders.effects';
import { foldersReducer, initialState } from './folders.reducer';
import { featureSelectorName } from './folders.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureSelectorName, foldersReducer, {
      initialState
    }),
    EffectsModule.forFeature([FoldersEffects])
  ]
})
export class FoldersDataAccessModule {}

<div class="container-fluid row">
  <div class="col-12 comparison-chart mt-2">
    <highcharts-chart
      [Highcharts]="Highcharts"
      [constructorType]="chartConstructor"
      [options]="chartOptions"
      [runOutsideAngular]="true"
      style="width: 100%; height: 400px; display: block"
    ></highcharts-chart>
  </div>
</div>

import { createAction, props } from '@ngrx/store';
import { Folder } from '@pinnakl/poems/folders/domain';
import { CustomFolderData } from './folders.models';

export enum FoldersActions {
  LoadFolderData = '[Folders] Load folder data',
  FolderDataLoaded = '[Folders] Folder data loaded',

  LoadFolders = '[Folders] Load folders',
  FolderLoaded = '[Folders] Folders loaded',

  CreateFolder = '[Folders] Create folder',
  FolderCreated = '[Folders] Folder created',
  FolderCreateFailure = '[Folders] Folder create failure',

  UpdateFolder = '[Folders] Update folder',
  FolderUpdated = '[Folders] Folder updated',
  FolderUpdateFailure = '[Folders] Folder update failure',

  DeleteFolder = '[Folders] Delete folder',
  FolderDeleted = '[Folders] Folder deleted',
  FolderDeleteFailure = '[Folders] Folder delete failure',

  DeleteAllowedFolder = '[Folders] Delete allowed folder',
  FolderDeleteAllowed = '[Folders] Folder delete allowed',
  FolderDeleteAllowedFailure = '[Folders] Folder delete allowed failure',

  SelectFolder = '[Folders] Select folder'
}

export const loadFolderData = createAction(FoldersActions.LoadFolderData);
export const folderDataLoaded = createAction(
  FoldersActions.FolderDataLoaded,
  props<{
    customFolderData: CustomFolderData;
  }>()
);

export const loadFolders = createAction(FoldersActions.LoadFolders);
export const foldersLoaded = createAction(
  FoldersActions.FolderLoaded,
  props<{ folders: Folder[] }>()
);

export const createFolder = createAction(
  FoldersActions.CreateFolder,
  props<{ folder: Omit<Folder, 'id'> }>()
);
export const folderCreated = createAction(
  FoldersActions.FolderCreated,
  props<{ folder: Folder }>()
);
export const folderCreateFailure = createAction(
  FoldersActions.FolderCreateFailure,
  props<{ error: any }>()
);

export const updateFolder = createAction(
  FoldersActions.UpdateFolder,
  props<{ folder: Partial<Folder> }>()
);
export const folderUpdated = createAction(
  FoldersActions.FolderUpdated,
  props<{ folder: Folder }>()
);
export const folderUpdateFailure = createAction(
  FoldersActions.FolderUpdateFailure,
  props<{ error: any }>()
);

export const deleteFolder = createAction(FoldersActions.DeleteFolder, props<{ id: string }>());
export const folderDeleted = createAction(FoldersActions.FolderDeleted, props<{ id: string }>());
export const folderDeleteFailure = createAction(
  FoldersActions.FolderDeleteFailure,
  props<{
    id: string;
    error: any;
  }>()
);

export const deleteAllowedFolder = createAction(
  FoldersActions.DeleteAllowedFolder,
  props<{ id: string }>()
);
export const folderDeleteAllowed = createAction(
  FoldersActions.FolderDeleteAllowed,
  props<{ id: string }>()
);
export const folderDeleteAllowedFailure = createAction(
  FoldersActions.FolderDeleteAllowedFailure,
  props<{ id: string; error?: any }>()
);

export const selectFolder = createAction(
  FoldersActions.SelectFolder,
  props<{ id: string | null }>()
);

import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { Aum } from '@pinnakl/poems/aum/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { Observable } from 'rxjs';

@Injectable()
export class AumApiService {
  private readonly aumEndpoint = `${V3_ENDPOINT}aums`;

  constructor(private readonly wsp: WebServiceProvider) {}

  getAums(): Observable<Aum[]> {
    return this.wsp.get<Aum[]>({
      endpoint: this.aumEndpoint
    });
  }
}

import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { loadPositionsTimeSeries, positionsTimeSeriesLoaded } from './timeseries-positions.actions';
import {
  SecurityPositionsTimeItems,
  TimeseriesPositionsState
} from './timeseries-positions.models';

export const timeseriesPositionsAdapter: EntityAdapter<SecurityPositionsTimeItems> =
  createEntityAdapter<SecurityPositionsTimeItems>({
    selectId: ({ securityMarketId }) => securityMarketId
  });

export const initialState: TimeseriesPositionsState = timeseriesPositionsAdapter.getInitialState({
  loading: false
});

export const timeseriesPositionsReducer = createReducer(
  initialState,
  on(loadPositionsTimeSeries, state => ({ ...state, loading: true })),
  on(positionsTimeSeriesLoaded, (state, { positionsTimeItems }) =>
    timeseriesPositionsAdapter.addOne(positionsTimeItems, {
      ...state,
      loading: false
    })
  )
);

import { createAction, props } from '@ngrx/store';
import { ISecurity } from '@pinnakl/securities/domain';

export enum SecuritiesActions {
  LoadSecurities = '[Securities] Load securities',
  SecuritiesLoaded = '[Securities] Securities loaded'
}

export const loadSecurities = createAction(SecuritiesActions.LoadSecurities);
export const securitiesLoaded = createAction(
  SecuritiesActions.SecuritiesLoaded,
  props<{ securities: ISecurity[] }>()
);

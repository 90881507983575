import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Align } from '@progress/kendo-angular-popup';
import { Collision } from '@progress/kendo-angular-popup/models/collision.interface';
import { PopupAnimation } from '@progress/kendo-angular-popup/models/popup-animation.interface';

@Component({
  selector: 'confirm-action',
  templateUrl: './confirm-action.component.html'
})
export class ConfirmActionComponent implements OnChanges {
  @Input() animate: boolean | PopupAnimation = false;
  @Input() containerClass = 'confirm-delete';
  @Input() messageClass: string;
  @Input() confirmationMessage: string;
  @Input() showConfirmation: any;
  @Input() anchor: any;
  @Input() anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  @Input() popupAlign: Align = { horizontal: 'right', vertical: 'top' };
  @Input() collision: Collision = { horizontal: 'fit', vertical: 'fit' };
  @Output() onCancelled: EventEmitter<void> = new EventEmitter<void>();
  @Output() onConfirmed: EventEmitter<void> = new EventEmitter<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['anchor'] && changes['anchor'].currentValue !== changes['anchor'].previousValue) {
      this.markForCheck();
    }
  }

  cancelAction(): void {
    this.onCancelled.emit();
  }

  actionConfirmed(): void {
    this.onConfirmed.emit();
  }

  markForCheck() {
    this.cdr.markForCheck();
  }
}

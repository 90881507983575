import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AumApiService } from './aum-api.service';
import { AumFacadeService } from './aum-facade.service';
import { AumEffects } from './aum.effects';
import { aumsReducer, initialState } from './aum.reducer';
import { featureSelectorName } from './aum.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureSelectorName, aumsReducer, {
      initialState
    }),
    EffectsModule.forFeature([AumEffects])
  ],
  providers: [AumApiService, AumFacadeService]
})
export class AumDataAccessModule {}

<div
  [@filterVisibleChanged]="filterVisible"
  class="oms-grid-filter config-wrapper z-1"
>
  <div class="row pnkl-page-header prospecting-header-height">
    <div class="col-md-12 full-width-padding-fix">
      <div class="row">
        <div class="col-md-12"><h3 class="text-color-secondary">Filter</h3></div>
      </div>
    </div>
  </div>
  <form
    (ngSubmit)="onSubmit()"
    [formGroup]="form"
    class="config-body"
  >
    <div class="row crm-prospecting-filter-body reporting-filter-height overflow-auto">
      <div class="col-md-12">
        <div>
          <div
            [class.presets-config]="shouldFilterHaveDateRangePresets"
            class="col-md-12"
          >
            <div
              *ngFor="let parameter of parameters"
              class="row mt-3"
            >
              <div class="col-md-12">
                <pinnakl-input
                  [controlName]="parameter.name"
                  [form]="form"
                  [inputClass]="parameter.type === 'date' ? 'datepicker-dark' : undefined"
                  [label]="parameter.caption"
                  [type]="parameter.type"
                >
                </pinnakl-input>
              </div>
            </div>
          </div>
          <ng-container *ngIf="shouldFilterHaveDateRangePresets">
            <div class="radioGroup">
              <span class="group-label">Previous:</span>
              <form [formGroup]="omsDateFiltersForm">
                <ng-container *ngFor="let item of previousFiltersControls">
                  <span class="toggle-radio-btn">
                    <input
                      [id]="item.id"
                      [value]="item.value"
                      formControlName="filterDate"
                      type="radio"
                    />
                    <label [for]="item.id">{{ item.name }}</label>
                  </span>
                </ng-container>
              </form>
            </div>
            <div class="radioGroup">
              <span class="group-label">To Date:</span>
              <form [formGroup]="toDateFiltersForm">
                <ng-container *ngFor="let item of toDateFiltersControls">
                  <span class="toggle-radio-btn">
                    <input
                      [id]="item.id"
                      [value]="item.value"
                      formControlName="filterDate"
                      type="radio"
                    />
                    <label [for]="item.id">{{ item.name }}</label>
                  </span>
                </ng-container>
              </form>
            </div>
          </ng-container>
        </div>
        <div
          *ngIf="columns && columns.length > 0"
          [darkMode]="true"
          class="row grid-filter-columns"
          pnklCustomScroll
        >
          <div class="col-md-12">
            <div class="row mt-3">
              <div class="col-md-4 check-holder check-holder-empty mb-4">
                <span *ngIf="!suppressSelection">
                  <input
                    formControlName="selectAll"
                    id="selectAll"
                    type="checkbox"
                  />
                  <label for="selectAll"></label>
                  <label class="ml-3">SELECT ALL</label>
                </span>
              </div>
            </div>
            <div
              *ngFor="let column of columns; let i = index"
              class="row m-t-minus-2"
            >
              <div
                *ngIf="!suppressSelection"
                class="col-md-1 check-holder check-holder-empty mt-4"
              >
                <input
                  [formControlName]="column.name + 'include'"
                  [id]="'checkbox' + i"
                  type="checkbox"
                />
                <label [for]="'checkbox' + i"></label>
              </div>
              <div class="col-md-8 flex-pull-right">
                <pinnakl-input
                  (dropdownFetchData)="getDropdownValues(column)"
                  [controlName]="column.name"
                  [dropdownOptions]="column.type === 'text' ? { isAsync: true } : undefined"
                  [dropdownSource]="column.dropdownCollection"
                  [form]="form"
                  [inputClass]="
                    column.type === 'date'
                      ? 'datepicker-dark popup-left-margin-adjust'
                      : column.type === 'text'
                        ? 'pnkl-select-dark'
                        : undefined
                  "
                  [labelClass]="
                    !suppressSelection
                      ? 'grid-filter-label text-uppercase'
                      : 'grid-filter-label text-uppercase left-minus-10'
                  "
                  [label]="column.caption"
                  [type]="column.type !== 'text' ? column.type : 'multiselect'"
                >
                </pinnakl-input>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="shouldFilterHaveDateRangePresets">
          <pinnakl-modal
            (closeModal)="toggleModal()"
            [hideDialog]="isSavePresetsHide"
            [width]="400"
            className="filter-presets-modal"
          >
            <filter-presets
              (closeModal)="closeModalAndRefreshData()"
              *ngIf="!isSavePresetsHide"
              [presetValues]="form.value"
            ></filter-presets>
          </pinnakl-modal>
        </div>
        <form
          *ngIf="shouldFilterHaveDateRangePresets && selectPresetForm && presets?.length"
          [formGroup]="selectPresetForm"
          class="presets-list"
        >
          <div class="presets-header">Filter Presets</div>
          <div class="presets">
            <div
              *ngFor="let preset of presets"
              class="toggle-radio-btn"
            >
              <input
                [id]="preset.id"
                [value]="preset"
                formControlName="preset"
                type="radio"
              />
              <label [for]="preset.id"
                >{{ preset.configValue.presetname }}
                <div
                  #presetAnchor
                  (click)="showPresetConfirmation($event, preset)"
                  class="remove-radio-btn"
                ></div>
              </label>
              <confirm-action
                (onCancelled)="preset.showDelete = false"
                (onConfirmed)="deletePreset(preset)"
                [anchor]="presetAnchor"
                [showConfirmation]="preset.showDelete"
                confirmationMessage="Are you sure you want to delete the preset?"
              >
              </confirm-action>
            </div>
          </div>
        </form>
        <div class="row mt-3 filter-actions">
          <div class="col-md-default-width">
            <button
              (click)="resetAllFilters()"
              class="btn reset-background waves-effect text-uppercase"
              type="button"
            >
              Reset
            </button>
          </div>
          <div class="col-md-default-width flex-pull-right">
            <button
              (click)="toggleModal()"
              *ngIf="shouldFilterHaveDateRangePresets"
              [disabled]="!isDatesValid"
              class="btn presets-btn"
              type="button"
            >
              Save Preset
            </button>
            <button
              class="btn btn-success waves-effect text-uppercase"
              type="submit"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

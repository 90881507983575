import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GridColumnsState } from './grid-columns.models';
import { gridColumnsAdapter } from './grid-columns.reducer';

export const featureSelectorName = 'pms-grid-columns';

const getGridColumnsStateSelector = createFeatureSelector<GridColumnsState>(featureSelectorName);

const { selectAll } = gridColumnsAdapter.getSelectors();

const getGridColumns = createSelector(getGridColumnsStateSelector, selectAll);

export const gridColumnsQuery = {
  getGridColumns
};

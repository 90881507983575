import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { ClosePriceOverrideBase } from '@pinnakl/poems/pricing/domain';
import { V4_ENDPOINT } from '@pinnakl/shared/constants';
import { Observable } from 'rxjs';

@Injectable()
export class ClosePriceOverrideApiService {
  private readonly closePriceOverrideEndpoint = `${V4_ENDPOINT}pricing/prev-close-price-override`;

  constructor(private readonly wsp: WebServiceProvider) {}

  createClosePriceOverride(closePriceOverride: ClosePriceOverrideBase): Observable<number> {
    return this.wsp.post<number>({
      endpoint: this.closePriceOverrideEndpoint,
      body: closePriceOverride
    });
  }
}

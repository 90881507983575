import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { RealtimePriceOverride, RealtimePriceOverrideBase } from '@pinnakl/poems/pricing/domain';
import { isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { filter, Observable, take } from 'rxjs';
import {
  createOverride,
  deleteOverride,
  loadAllRealtimePriceOverrides,
  loadOverrideBySecurityMarketId
} from './realtime-price-override.actions';
import { RealtimePriceOverrideState } from './realtime-price-override.models';
import { RealtimePriceOverrideQuery } from './realtime-price-override.selectors';

@Injectable()
export class RealtimePriceOverrideFacadeService {
  private readonly overrideStatusesMap = new Map<
    string,
    Observable<RealtimePriceOverride | null | undefined>
  >();
  overridesList$ = this.store$.pipe(select(RealtimePriceOverrideQuery.getAllOverridesList));
  overridesMap$ = this.store$.pipe(select(RealtimePriceOverrideQuery.getAllOverridesMap));

  constructor(private readonly store$: Store<RealtimePriceOverrideState>) {}

  loadAllRealtimePriceOverrides(): void {
    this.store$.dispatch(loadAllRealtimePriceOverrides());
  }

  loadRealtimePriceOverrideBySecurityMarketId(securityMarketId: string, forceFetch = false): void {
    if (forceFetch || !this.overrideStatusesMap.has(securityMarketId)) {
      this.store$.dispatch(loadOverrideBySecurityMarketId({ securityMarketId }));
    }
  }

  getRealtimePriceOverrideBySecurityMarketId(
    id: string
  ): Observable<RealtimePriceOverride | null | undefined> {
    let statusStream = this.overrideStatusesMap.get(id);

    if (statusStream) {
      return statusStream;
    }

    statusStream = this.store$.pipe(
      select(RealtimePriceOverrideQuery.getRealtimePriceOverrideBySecurityMarketId(id))
    );
    this.overrideStatusesMap.set(id, statusStream);

    return statusStream;
  }

  realtimePriceOverrideLoaded(securityMarketId: string): void {
    this.overrideStatusesMap.set(
      securityMarketId,
      this.store$.pipe(
        select(
          RealtimePriceOverrideQuery.getRealtimePriceOverrideBySecurityMarketId(securityMarketId)
        ),
        filter(isNeitherNullNorUndefined)
      )
    );
  }

  createRealtimePriceOverride(overrideStatus: RealtimePriceOverrideBase): void {
    this.store$.dispatch(createOverride({ realtimePriceOverride: overrideStatus }));
  }

  deleteRealtimePriceOverride(securityMarketId: string): void {
    this.store$
      .select(
        RealtimePriceOverrideQuery.getRealtimePriceOverrideBySecurityMarketId(securityMarketId)
      )
      .pipe(take(1))
      .subscribe(
        override => override?.id && this.store$.dispatch(deleteOverride({ securityMarketId }))
      );
  }
}

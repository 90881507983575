<section class="flex-label-position-{{ labelPosition }}">
  <section *ngIf="labelToggle">
    <label>{{ labelToggle }}</label
    ><span
      *ngIf="required"
      class="color-red pl-0-25"
      >*</span
    >
  </section>

  <div class="btn-toggle">
    <button
      (click)="toggleValue(option.value)"
      *ngFor="let option of toggleSource"
      [disabled]="disabled"
      [ngClass]="{ selected: selected === option[toggleOptions.valueProperty] }"
      class="item-toggle border-none bg-none"
    >
      {{ option[toggleOptions.viewProperty] }}
    </button>
  </div>
</section>

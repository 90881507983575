import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SecurityDetailsModalState } from './security-details-modal.models';

export const featureSelectorName = 'security-details-modal-state';

const getSecurityDetailsStateSelector =
  createFeatureSelector<SecurityDetailsModalState>(featureSelectorName);

const getSecurityDetailsModalSelectedAccounts = createSelector(
  getSecurityDetailsStateSelector,
  ({ selectedAccounts }) => selectedAccounts
);

const getSecurityDetailsModalSelectedFolderId = createSelector(
  getSecurityDetailsStateSelector,
  ({ selectedFolderId }) => selectedFolderId
);

const getSecurityDetailsModalFolderComments = createSelector(
  getSecurityDetailsStateSelector,
  ({ folderComments }) => folderComments
);

const getSecurityDetailsModalActiveTicker = createSelector(
  getSecurityDetailsStateSelector,
  ({ activeTicker }) => activeTicker
);

const getSecurityDetailsModalActiveSecurityMarketId = createSelector(
  getSecurityDetailsStateSelector,
  ({ activeSecurityMarketId }) => activeSecurityMarketId
);

const getSecurityDetailsModalActiveUnderlyingSecurityId = createSelector(
  getSecurityDetailsStateSelector,
  ({ activeUnderlyingSecurityId }) => activeUnderlyingSecurityId
);

const getSecurityDetailsModalTimeseriesLoading = createSelector(
  getSecurityDetailsStateSelector,
  ({ timeseriesLoading }) => timeseriesLoading
);
const getSecurityDetailsModalTimeseriesPrices = createSelector(
  getSecurityDetailsStateSelector,
  ({ timeseriesPrices }) => timeseriesPrices
);
const getSecurityDetailsModalTimeseriesPositions = createSelector(
  getSecurityDetailsStateSelector,
  ({ timeseriesPositions }) => timeseriesPositions
);

const getSecurityExposureSummaryInfo = createSelector(
  getSecurityDetailsStateSelector,
  ({ exposureSummaryInfo }) => exposureSummaryInfo
);

const getSecurityMarkOverrideData = createSelector(
  getSecurityDetailsStateSelector,
  ({ markOverrideData }) => markOverrideData
);

const getSecurityObject = createSelector(
  getSecurityDetailsStateSelector,
  ({ security }) => security
);

const getMarkOriginalOverriddenValue = createSelector(
  getSecurityDetailsStateSelector,
  ({ markOriginalOverriddenValue }) => markOriginalOverriddenValue
);

export const securityDetailsModalStateQuery = {
  getSecurityObject,
  getMarkOriginalOverriddenValue,
  getSecurityMarkOverrideData,
  getSecurityExposureSummaryInfo,
  getSecurityDetailsModalActiveTicker,
  getSecurityDetailsModalFolderComments,
  getSecurityDetailsModalSelectedAccounts,
  getSecurityDetailsModalTimeseriesPrices,
  getSecurityDetailsModalSelectedFolderId,
  getSecurityDetailsModalTimeseriesLoading,
  getSecurityDetailsModalTimeseriesPositions,
  getSecurityDetailsModalActiveSecurityMarketId,
  getSecurityDetailsModalActiveUnderlyingSecurityId
};

import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';

@Component({
  selector: 'rounded-checkbox',
  templateUrl: './rounded-checkbox.component.html',
  styleUrls: ['./rounded-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RoundedCheckboxComponent
    }
  ]
})
export class RoundedCheckboxComponent implements ControlValueAccessor {
  @Input() formGroup: UntypedFormGroup;
  @Input() formControl: UntypedFormControl;
  @Input() disabled = false;
  @Input() value;
  @Input() label: string;
  @Input() uniqId: string;

  @Output() inputModelChange = new EventEmitter<string>();
  touched = false;

  onChange = quantity => {};

  onTouched = () => {};

  writeValue(value): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}

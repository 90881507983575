import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialogFactoryService, MatPinnaklModalComponent } from '@pinnakl/shared-ui';
import { VdrUser } from '@pinnakl/vdr/domain';
import { ChangePasswordFormComponent } from '../change-password/change-password-form/change-password-form.component';
import { ChangePasswordModalComponent } from '../modals/change-password-modal/change-password-modal.component';
import { RegisterNewUserModalComponent } from '../modals/register-new-user-modal/register-new-user-modal.component';

@Injectable()
export class AuthPopupService {
  constructor(private matDialogFactoryService: MatDialogFactoryService) {}

  openChangePasswordModal(): MatDialogRef<MatPinnaklModalComponent<ChangePasswordFormComponent>> {
    const dialog = this.matDialogFactoryService.open(
      {
        headerText: 'Change password',
        component: ChangePasswordModalComponent,
        dragDisable: true
      },
      {
        width: '620',
        panelClass: ['modal-dialog-radius-12', 'modal-dialog-full-height'],
        disableClose: true
      }
    );
    return dialog.dialogRef;
  }

  openPortalRegistrationModal(
    vdrUser: VdrUser
  ): MatDialogRef<MatPinnaklModalComponent<ChangePasswordFormComponent>> {
    const dialog = this.matDialogFactoryService.open(
      {
        headerText: 'Register New User',
        component: RegisterNewUserModalComponent,
        dragDisable: true,
        componentData: {
          vdrUser
        }
      },
      {
        width: '620',
        panelClass: ['modal-dialog-radius-12', 'modal-dialog-full-height'],
        disableClose: true
      }
    );
    return dialog.dialogRef;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TradeWorkflowSpecEffects } from './trade-workflow-spec.effects';
import { initialState, tradeWorkflowSpecReducer } from './trade-workflow-spec.reducer';
import { featureSelectorName } from './trade-workflow-spec.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureSelectorName, tradeWorkflowSpecReducer, {
      initialState
    }),
    EffectsModule.forFeature([TradeWorkflowSpecEffects])
  ]
})
export class TradeWorkflowSpecDataAccessModule {}

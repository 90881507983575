import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, DecimalPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PoemsCommonDataFacadeService } from '@pinnakl/poems/data-access';
import {
  PrimeButtonComponent,
  PrimeInputNumberComponent,
  PrimeSingleDropdownComponent
} from '@pinnakl/shared/ui/prime';
import { isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'pinnakl-previous-close-price',
  templateUrl: 'previous-close-price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./previous-close-price.component.scss'],
  imports: [
    DecimalPipe,
    NgIf,
    PrimeButtonComponent,
    PrimeInputNumberComponent,
    PrimeSingleDropdownComponent,
    ReactiveFormsModule,
    AsyncPipe,
    InlineSVGModule
  ],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.2s ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [style({ opacity: 1 }), animate('0.2s ease-in', style({ opacity: 0 }))])
    ])
  ]
})
export class PreviousClosePriceComponent {
  @Input({ required: true }) price!: number;
  @Input({ required: true }) editPreviousCloseForm!: FormGroup<{
    editPreviousCloseValue: FormControl<string | null>;
    editPreviousCloseCurrency: FormControl<string | null>;
  }>;

  @Output() saveNewPreviousCloseValue = new EventEmitter<{
    value: number;
    currency: string;
  } | null>();
  @Output() isEditMode = new EventEmitter<boolean>();

  isEditMode$ = new BehaviorSubject(false);

  previousCloseCurrencies$: Observable<string[]>;

  constructor(private readonly commonDataFacadeService: PoemsCommonDataFacadeService) {
    this.previousCloseCurrencies$ = this.commonDataFacadeService.currencies$.pipe(
      filter(isNeitherNullNorUndefined),
      map(currencies => currencies.map(({ currency }) => currency))
    );
  }

  toggleEditMode(): void {
    const isEditMode = !this.isEditMode$.value;
    this.isEditMode$.next(isEditMode);
    this.isEditMode.emit(isEditMode);
  }

  savePreviousCloseValue(): void {
    const { editPreviousCloseValue, editPreviousCloseCurrency } = this.editPreviousCloseForm.value;

    editPreviousCloseCurrency &&
      this.saveNewPreviousCloseValue.emit({
        value: editPreviousCloseValue ? parseFloat(editPreviousCloseValue) : 0,
        currency: editPreviousCloseCurrency
      });
  }
}

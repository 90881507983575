import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map, tap } from 'rxjs';
import { PositionsGeneralApiService } from './positions-general-api.service';
import { PositionsGeneralFacadeService } from './positions-general-facade.service';
import { loadPositionsBase, positionsBaseLoaded } from './positions-general.actions';

@Injectable()
export class PositionsGeneralEffects {
  loadPositionsBaseBySecurityMarketId$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPositionsBase),
      concatMap(({ securityMarketId }) =>
        this.api.getPositionBaseInfoBySecurityMarketId(securityMarketId).pipe(
          map(positionBase => ({
            securityMarketId,
            positionBase
          }))
        )
      ),
      tap(({ securityMarketId }) =>
        setTimeout(() => this.facade.positionBaseInfoLoaded(securityMarketId), 0)
      ),
      map(positionBaseInfo => positionsBaseLoaded(positionBaseInfo))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: PositionsGeneralApiService,
    private readonly facade: PositionsGeneralFacadeService
  ) {}
}

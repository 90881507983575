import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PmsPosition } from '@pinnakl/pms/domain';
import {
  loadPositions,
  loadPositionsFailure,
  saveKeysMap,
  setCalculatedAllPositions,
  setCalculatedCashBalance,
  setCalculatedFilteredByAccountSummaryData,
  setCalculatedNavCurrentMap,
  setPinnedData,
  setPositions,
  updateManyPositions,
  updatePosition
} from './positions.actions';
import { PositionsState } from './positions.models';

export const positionsAdapter: EntityAdapter<PmsPosition> = createEntityAdapter<PmsPosition>({
  sortComparer: false
});

export const initialState: PositionsState = positionsAdapter.getInitialState({
  loading: false,
  keysMap: null,
  navCurrentMap: null,
  calculatedCashBalance: null,
  calculatedFilteredByAccountSummaryData: null,
  calculatedAllPositions: [],
  calculatedGridData: [],
  pinnedData: []
});

export const positionsReducer = createReducer(
  initialState,
  on(loadPositions, state => ({ ...state, loading: true })),
  on(loadPositionsFailure, (state, { error }) => ({ ...state, error, loading: false })),
  on(setPositions, (state, { positions }) => {
    return positionsAdapter.setAll(positions, {
      ...state,
      loading: false
    });
  }),
  on(updatePosition, (state, { id, changes }) => {
    return positionsAdapter.updateOne(
      {
        id,
        changes
      },
      state
    );
  }),
  on(setCalculatedAllPositions, (state, { positions }) => {
    return {
      ...state,
      calculatedAllPositions: positions
    };
  }),
  on(setCalculatedCashBalance, (state, { cashBalance }) => {
    return {
      ...state,
      calculatedCashBalance: cashBalance
    };
  }),
  on(setCalculatedFilteredByAccountSummaryData, (state, { summaryData }) => {
    return {
      ...state,
      calculatedFilteredByAccountSummaryData: summaryData
    };
  }),
  on(updateManyPositions, (state, { changes }) => {
    return positionsAdapter.updateMany(changes, state);
  }),
  on(setPinnedData, (state, { pinnedData }) => ({ ...state, pinnedData })),
  on(saveKeysMap, (state, { keysMap }) => ({ ...state, keysMap })),
  on(setCalculatedNavCurrentMap, (state, { navCurrentMap }) => ({ ...state, navCurrentMap }))
);

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountsState } from './accounts.models';
import { accountAdapter } from './accounts.reducer';

export const featureSelectorName = 'pms-accounts';
const getAccountStateSelector = createFeatureSelector<AccountsState>(featureSelectorName);

const { selectAll } = accountAdapter.getSelectors();

const getAccounts = createSelector(getAccountStateSelector, selectAll);
const isLoading = createSelector(getAccountStateSelector, ({ loading }) => loading);
const isLoaded = createSelector(getAccountStateSelector, ({ ids }) => !!ids.length);

export const accountQuery = {
  getAccounts,
  isLoaded,
  isLoading
};

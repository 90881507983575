import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PositionService } from '@pinnakl/poems';
import { Account } from '@pinnakl/shared/types';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { GroupDescriptor, SortDescriptor, groupBy, orderBy } from '@progress/kendo-data-query';
import moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'trade-history',
  templateUrl: './trade-history.component.html',
  styleUrls: ['./trade-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TradeHistoryComponent implements OnChanges {
  private accountId: number = null;
  @ViewChild(GridComponent) grid: GridComponent;
  @Input() secId: number;
  @Input() underlyingSecId: number;
  tradeHistory: Array<any>;
  sort: SortDescriptor[] = [];
  groups: GroupDescriptor[] = [];
  gridView: GridDataResult;
  historyForm: UntypedFormGroup;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly spinner: PinnaklSpinnerService,
    private readonly positionService: PositionService,
    private readonly cdr: ChangeDetectorRef,
    private readonly toastr: PinnaklUIToastMessage
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.secId.firstChange) {
      this.watchSelectedAccount();
    }
  }

  getTradeHistory(isUnderlyingEnabled = false): void {
    if (this.accountId) {
      this.loadTradeHistoryByAccount(
        this.secId,
        this.underlyingSecId,
        isUnderlyingEnabled,
        this.accountId
      );
    } else {
      this.loadTradeHistory(this.secId, this.underlyingSecId, isUnderlyingEnabled);
    }
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.renderGrid();
  }

  groupChange(groups: GroupDescriptor[]): void {
    this.groups = groups;
    this.renderGridAfterGrouping();
  }

  private watchSelectedAccount(): void {
    this.positionService.selectedPositionPopupAccount$
      .pipe(untilDestroyed(this))
      .subscribe((account: Account) => {
        this.accountId = account?.id;
        this.getTradeHistory();
        this.initHistoryForm();
      });
  }

  private loadTradeHistoryByAccount(
    secId: number,
    underlyingSecId: number,
    isUnderlyingEnabled: boolean,
    accId: number
  ): void {
    this.spinner.spin();
    this.positionService
      .getTradeAllocationsBySecurity(secId, underlyingSecId, isUnderlyingEnabled, accId)
      .then(tradeHistory => this.handleSuccessOfTradeHistoryRequest(tradeHistory))
      .catch((err: HttpErrorResponse) => this.handleErrorOfTradeHistoryRequest(err));
  }

  private loadTradeHistory(
    id: string | number = this.secId,
    underlyingSecId: number,
    isUnderlyingEnabled = false
  ): void {
    this.spinner.spin();
    this.positionService
      .getTradeHistory(id, underlyingSecId, isUnderlyingEnabled)
      .then(tradeHistory => this.handleSuccessOfTradeHistoryRequest(tradeHistory))
      .catch((err: HttpErrorResponse) => this.handleErrorOfTradeHistoryRequest(err));
  }

  private handleSuccessOfTradeHistoryRequest(tradeHistory: any): void {
    tradeHistory.sort((a, b) => +new Date(b.tradedate) - +new Date(a.tradedate));
    this.spinner.stop();
    tradeHistory = this.getParsedTradeHistory(tradeHistory);
    this.tradeHistory = tradeHistory;
    this.renderGrid();
  }

  private handleErrorOfTradeHistoryRequest(err: HttpErrorResponse): void {
    this.spinner.stop();
    this.toastr.error(err?.error?.message);
    console.error(err, 'Error while loadTradeHistory');
  }

  private getParsedTradeHistory(tradeHistory: any[]): any[] {
    return tradeHistory.map(tradeHistoryJson => {
      tradeHistoryJson.commission = parseFloat(tradeHistoryJson.commission);
      tradeHistoryJson.netmoneylocal = parseFloat(tradeHistoryJson.netmoneylocal);
      tradeHistoryJson.price = parseFloat(tradeHistoryJson.price);
      tradeHistoryJson.quantity = parseFloat(tradeHistoryJson.quantity);
      tradeHistoryJson.tradedate = moment(
        tradeHistoryJson.tradedate,
        'MM/DD/YYYY hh:mm:ss a'
      ).format('MM/DD/YYYY');
      tradeHistoryJson.trantype = this.getTranTypeLong(tradeHistoryJson.trantype);
      tradeHistoryJson.brokername =
        tradeHistoryJson?.executingbrokername ?? tradeHistoryJson?.brokername ?? '';
      tradeHistoryJson.commissionpershare = tradeHistoryJson?.commissionpershare
        ? tradeHistoryJson.commissionpershare
        : tradeHistoryJson?.commpershare;
      return tradeHistoryJson;
    });
  }

  private renderGrid(): void {
    this.gridView = {
      data: orderBy(this.tradeHistory, this.sort),
      total: this.tradeHistory.length
    };
    this.grid.autoFitColumns();
    this.cdr.detectChanges();
  }

  private renderGridAfterGrouping(): void {
    this.gridView = {
      data: groupBy(this.tradeHistory, this.groups),
      total: this.tradeHistory.length
    };
    this.grid.autoFitColumns();
    this.cdr.detectChanges();
  }

  private initHistoryForm(): void {
    this.historyForm = this.fb.group({
      underlying: false
    });
    this.watchUnderlyingControl();
  }

  private watchUnderlyingControl(): void {
    this.historyForm
      .get('underlying')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((isUnderlyingEnabled: boolean) => {
        this.getTradeHistory(isUnderlyingEnabled);
      });
  }

  private getTranTypeLong(tranType: string): string {
    return {
      b: 'Buy',
      bc: 'Cover',
      s: 'Sell',
      ss: 'Short'
    }[tranType.toLowerCase()];
  }
}

<mat-sidenav-container
  (backdropClick)="config.backdropClick($event)"
  class="sidenav-container"
>
  <mat-sidenav
    #drawer
    [mode]="config.mode"
    [position]="config.position"
  >
    <div class="top-center right absolute">
      <i
        (click)="close()"
        class="icon-pinnakl-left-open cursor-pointer"
      ></i>
    </div>
    <ng-template #templateRef></ng-template>
  </mat-sidenav>
</mat-sidenav-container>

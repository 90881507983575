import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { FolderComments } from '@pinnakl/poems/folders/domain';
import { ItemStatus } from '@pinnakl/shared/types';
import {
  createFolderComment,
  deleteFolderComment,
  folderCommentCreated,
  folderCommentDeleted,
  folderCommentsLoaded,
  folderCommentUpdated,
  updateFolderComment
} from './folder-comments.actions';
import { FolderCommentsState } from './folder-comments.models';

export const folderCommentsAdapter: EntityAdapter<FolderComments> =
  createEntityAdapter<FolderComments>();

export const initialState: FolderCommentsState = folderCommentsAdapter.getInitialState({
  selectedFolderId: null,
  createFolderCommentStatus: null,
  updateFolderCommentStatus: null,
  deleteFolderCommentStatus: null
});

export const folderCommentsReducer = createReducer(
  initialState,
  on(folderCommentsLoaded, (state, { folderComments }) =>
    folderCommentsAdapter.setOne(folderComments, state)
  ),

  on(updateFolderComment, state => ({ ...state, updateFolderCommentStatus: null })),
  on(folderCommentUpdated, (state, { folderComment }) =>
    folderCommentsAdapter.setOne(
      {
        id: folderComment.folderId.toString(),
        comments:
          state.entities[folderComment.folderId]?.comments.map(comment =>
            comment.id === folderComment.id ? folderComment : comment
          ) ?? []
      },
      {
        ...state,
        updateFolderCommentStatus: {
          status: ItemStatus.Success,
          data: {
            comment: folderComment
          }
        }
      }
    )
  ),

  on(createFolderComment, state => ({ ...state, createFolderCommentStatus: null })),
  on(folderCommentCreated, (state, { folderComment }) =>
    folderCommentsAdapter.setOne(
      {
        id: folderComment.folderId.toString(),
        comments: [...(state.entities[folderComment.folderId]?.comments ?? []), folderComment]
      },
      {
        ...state,
        createFolderCommentStatus: {
          status: ItemStatus.Success,
          data: {
            comment: folderComment
          }
        }
      }
    )
  ),

  on(deleteFolderComment, state => ({ ...state, deleteFolderCommentStatus: null })),
  on(folderCommentDeleted, (state, { id, folderId }) =>
    folderCommentsAdapter.setOne(
      {
        id: folderId,
        comments: (state.entities[folderId]?.comments ?? []).filter(
          comment => comment.id.toString() !== id
        )
      },
      {
        ...state,
        deleteFolderCommentStatus: {
          status: ItemStatus.Success,
          data: {
            id
          }
        }
      }
    )
  )
);

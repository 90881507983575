import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PmsColumnsApiService } from './columns-api.service';
import { PmsColumnsFacadeService } from './columns-facade.service';
import { PmsColumnsEffects } from './columns.effects';
import { columnsReducer, initialState } from './columns.reducer';
import { featureSelectorName } from './columns.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureSelectorName, columnsReducer, {
      initialState
    }),
    EffectsModule.forFeature([PmsColumnsEffects])
  ],
  providers: [PmsColumnsFacadeService, PmsColumnsApiService]
})
export class PmsColumnsDataAccessModule {}

import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CVAOnChangeCallback } from '../../../';

@Component({
  selector: 'pinnakl-toggle',
  templateUrl: './pinnakl-toggle.component.html',
  styleUrls: ['./pinnakl-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PinnaklToggleComponent),
      multi: true
    }
  ]
})
export class PinnaklToggleComponent {
  @Input() toggleSource: any[];
  @Input() toggleOptions: {
    valueProperty?: string;
    viewProperty?: string;
  };
  @Input() labelToggle: string;
  @Input() required = false;
  @Input() labelPosition: 'top' | 'left' | 'right' = 'top';
  @Input() disabled = false;
  selected: string;

  toggleValue(value) {
    this.onChange(value);
    this.selected = value;
  }

  registerOnChange(fn: CVAOnChangeCallback<any>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    this.selected = obj;
  }

  private onChange: CVAOnChangeCallback<any> = () => {};
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomScrollDirective } from './custom-scroll.directive';
import { RouterUrlLinkWithHrefDirective } from './router-url-link.directive';

const directives = [RouterUrlLinkWithHrefDirective];

@NgModule({
  imports: [CommonModule, CustomScrollDirective],
  declarations: [...directives],
  exports: [...directives, CustomScrollDirective]
})
export class DirectivesModule {}

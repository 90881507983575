import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PmsRebalanceConfigApiService } from './rebalance-config-api.service';
import { PmsRebalanceConfigStateEffects } from './rebalance-config-state.effects';
import { initialState, rebalanceConfigStateReducer } from './rebalance-config-state.reducer';
import { featureSelectorName } from './rebalance-config-state.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureSelectorName, rebalanceConfigStateReducer, {
      initialState
    }),
    EffectsModule.forFeature([PmsRebalanceConfigStateEffects])
  ],
  providers: [PmsRebalanceConfigApiService]
})
export class PmsRebalanceConfigDataAccessModule {}

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { ModalCloseButtonComponent } from './mat-pinnakl-modal/components/modal-close-button/modal-close-button.component';
import { MatPinnaklModalComponent } from './mat-pinnakl-modal/mat-pinnakl-modal.component';
import { PinnaklModalComponent } from './pinnakl-modal.component';

@NgModule({
  imports: [CommonModule, DialogModule, WindowModule, MatDialogModule, DragDropModule],
  declarations: [PinnaklModalComponent, MatPinnaklModalComponent, ModalCloseButtonComponent],
  exports: [PinnaklModalComponent, MatDialogModule, ModalCloseButtonComponent],
  providers: []
})
export class PinnaklModalModule {}

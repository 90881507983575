import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CurrencyModel, FxRate } from '@pinnakl/poems/domain';
import { getLoadableEntities$, isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { filter } from 'rxjs';
import { take } from 'rxjs/operators';
import { loadCurrencies, loadFxRates, setFxRates } from './common-data.actions';
import { CommonDataState } from './common-data.model';
import { CommonDataQuery } from './common-data.selectors';

@Injectable()
export class PoemsCommonDataFacadeService {
  fxRates$ = this.store.select(CommonDataQuery.getFxRates);
  currencies$ = getLoadableEntities$<CurrencyModel[]>(
    this.store.select(CommonDataQuery.getCurrencies).pipe(filter(isNeitherNullNorUndefined)),
    this.store.select(CommonDataQuery.getCurrenciesLoaded),
    this.store.select(CommonDataQuery.getCurrenciesLoading),
    this.loadCurrencies.bind(this)
  );

  constructor(private readonly store: Store<CommonDataState>) {}

  loadFxRates(forceRefetch = false): void {
    this.fxRates$
      .pipe(take(1))
      .subscribe(rates => (rates === null || forceRefetch) && this.store.dispatch(loadFxRates()));
  }

  setFxRates(fxRates: FxRate[]): void {
    this.store.dispatch(setFxRates({ fxRates }));
  }

  loadCurrencies(): void {
    this.store.dispatch(loadCurrencies());
  }
}

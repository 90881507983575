import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountCode } from '@pinnakl/pms/domain';
import { PmsPresetsState, defaultWidgetsOrder } from './presets.models';

export const featureSelectorName = 'pms-presets';

const getPresetsStateSelector = createFeatureSelector<PmsPresetsState>(featureSelectorName);

const pmsPresets = createSelector(getPresetsStateSelector, state => state);

const pmsWidgetsOrderConfigId = createSelector(
  getPresetsStateSelector,
  ({ pmsWidgetsOrder }) => pmsWidgetsOrder?.id ?? null
);

const pmsPresetsLoaded = createSelector(getPresetsStateSelector, ({ loaded }) => loaded);
const pmsWidgetsOrderConfigParsedValue = createSelector(
  getPresetsStateSelector,
  ({ pmsWidgetsOrder, loaded }) =>
    loaded ? (pmsWidgetsOrder?.parsedConfigValue ?? defaultWidgetsOrder) : defaultWidgetsOrder
);

const pmsSavedColumnsConfigs = createSelector(
  getPresetsStateSelector,
  ({ pmsGridColumns }) => pmsGridColumns
);

const pmsColumnsConfigsNames = createSelector(getPresetsStateSelector, ({ pmsGridColumns }) =>
  pmsGridColumns.map(({ id, parsedConfigValue: { name } }) => ({
    id,
    name
  }))
);
const pmsSelectedSavedColumnsConfigId = createSelector(
  getPresetsStateSelector,
  ({ pmsGridSelectedColumnsPreset }) => pmsGridSelectedColumnsPreset?.parsedConfigValue?.id ?? null
);
const pmsSelectedSavedColumnsConfigParsedValueColumns = createSelector(
  getPresetsStateSelector,
  pmsSelectedSavedColumnsConfigId,
  ({ pmsGridColumns }, selectedConfigId) =>
    selectedConfigId
      ? (pmsGridColumns.find(({ id }) => id === selectedConfigId)?.parsedConfigValue?.columns ??
        null)
      : null
);
const pmsSelectedSavedColumnsConfig = createSelector(
  getPresetsStateSelector,
  pmsSelectedSavedColumnsConfigId,
  ({ pmsGridColumns }, selectedConfigId) =>
    selectedConfigId ? (pmsGridColumns.find(({ id }) => id === selectedConfigId) ?? null) : null
);
const pmsSelectedSavedColumnsConfigParsedValueFilters = createSelector(
  getPresetsStateSelector,
  pmsSelectedSavedColumnsConfigId,
  ({ pmsGridColumns }, selectedConfigId) => {
    const selectedConfigFilters = pmsGridColumns.find(({ id }) => id === selectedConfigId)
      ?.parsedConfigValue?.filters;

    if (selectedConfigFilters) {
      const { [AccountCode]: _, ...filters } = selectedConfigFilters;
      return filters;
    }

    return null;
  }
);

const pmsSelectedSavedColumnsConfigParsedValueExclusive = createSelector(
  getPresetsStateSelector,
  pmsSelectedSavedColumnsConfigId,
  ({ pmsGridColumns }, selectedConfigId) => {
    return !!pmsGridColumns.find(({ id }) => id === selectedConfigId)?.parsedConfigValue
      ?.excludeSecurities;
  }
);

const pmsSelectedSavedColumnsConfigParsedValueAccounts = createSelector(
  getPresetsStateSelector,
  pmsSelectedSavedColumnsConfigId,
  ({ pmsGridColumns }, selectedConfigId) => {
    const selectedConfigFilters = pmsGridColumns.find(({ id }) => id === selectedConfigId)
      ?.parsedConfigValue?.filters;

    if (selectedConfigFilters) {
      const { [AccountCode]: accounts } = selectedConfigFilters;
      return accounts;
    }

    return null;
  }
);

const pmsSelectedSavedColumnsConfigName = createSelector(
  getPresetsStateSelector,
  pmsSelectedSavedColumnsConfigId,
  ({ pmsGridColumns }, selectedConfigId) =>
    selectedConfigId
      ? (pmsGridColumns.find(({ id }) => id === selectedConfigId)?.parsedConfigValue?.name ?? null)
      : null
);

const pmsOtherSettingsConfigParsedValue = createSelector(
  getPresetsStateSelector,
  ({ pmsOtherSettings }) => pmsOtherSettings?.parsedConfigValue
);
const pmsOtherSettingsConfig = createSelector(
  getPresetsStateSelector,
  ({ pmsOtherSettings }) => pmsOtherSettings
);

export const pmsPresetsQuery = {
  pmsPresets,
  pmsPresetsLoaded,
  pmsOtherSettingsConfigParsedValue,
  pmsOtherSettingsConfig,
  pmsWidgetsOrderConfigId,
  pmsWidgetsOrderConfigParsedValue,
  pmsColumnsConfigsNames,
  pmsSelectedSavedColumnsConfigId,
  pmsSavedColumnsConfigs,
  pmsSelectedSavedColumnsConfig,
  pmsSelectedSavedColumnsConfigParsedValueColumns,
  pmsSelectedSavedColumnsConfigParsedValueAccounts,
  pmsSelectedSavedColumnsConfigParsedValueFilters,
  pmsSelectedSavedColumnsConfigParsedValueExclusive,
  pmsSelectedSavedColumnsConfigName
};

import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { PmsAccountCashCombinedItem, PmsPosition, PmsSummaryWidgetData } from '@pinnakl/pms/domain';
import { PositionsKeysMap } from './positions.models';

export enum PmsPositionsActions {
  LoadPositions = '[Pms Positions] Load positions',
  ReloadPositions = '[Pms Positions] Reload positions',
  loadPositionsFailure = '[Pms Positions] Load positions error',
  SetPositions = '[Pms Positions] Set positions',
  UpdatePosition = '[Pms Positions] Update positions',
  UpdateManyPositions = '[Pms Positions] Update many positions',
  SetCalculatedAllPositions = '[Pms Positions] Set calculated all positions',
  SetCalculatedCashBalance = '[Pms Positions] Set calculated cash balance',
  SetCalculatedFilteredByAccountSummaryData = '[Pms Positions] Set calculated filtered by account summary data',
  SaveKeysMap = '[Pms Positions] Save positions keys map',
  BuildPinnedData = '[Pms Positions] Build pinned data',
  SetPinnedData = '[Pms Positions] Set pinned data',
  SetCalculatedNavCurrentMap = '[Pms Positions] Set calculated nav current map'
}

export const loadPositions = createAction(PmsPositionsActions.LoadPositions);
export const reloadPositions = createAction(PmsPositionsActions.ReloadPositions);

export const loadPositionsFailure = createAction(
  PmsPositionsActions.loadPositionsFailure,
  props<{ error: any }>()
);

export const setPositions = createAction(
  PmsPositionsActions.SetPositions,
  props<{ positions: PmsPosition[] }>()
);

export const setCalculatedAllPositions = createAction(
  PmsPositionsActions.SetCalculatedAllPositions,
  props<{ positions: PmsPosition[] }>()
);
export const setCalculatedFilteredByAccountSummaryData = createAction(
  PmsPositionsActions.SetCalculatedFilteredByAccountSummaryData,
  props<{ summaryData: PmsSummaryWidgetData }>()
);
export const setCalculatedCashBalance = createAction(
  PmsPositionsActions.SetCalculatedCashBalance,
  props<{ cashBalance: PmsAccountCashCombinedItem[] }>()
);
export const updatePosition = createAction(
  PmsPositionsActions.UpdatePosition,
  props<{ id: string; changes: Partial<PmsPosition> }>()
);

export const updateManyPositions = createAction(
  PmsPositionsActions.UpdateManyPositions,
  props<{ changes: Update<PmsPosition>[] }>()
);

export const saveKeysMap = createAction(
  PmsPositionsActions.SaveKeysMap,
  props<{ keysMap: PositionsKeysMap }>()
);

export const buildPinnedData = createAction(
  PmsPositionsActions.BuildPinnedData,
  props<{ positions: PmsPosition[] }>()
);

export const setPinnedData = createAction(
  PmsPositionsActions.SetPinnedData,
  props<{ pinnedData: PmsPosition[] }>()
);
export const setCalculatedNavCurrentMap = createAction(
  PmsPositionsActions.SetCalculatedNavCurrentMap,
  props<{ navCurrentMap: Record<string, number> }>()
);

import { EnvNames, PinnaklEnvironment } from '@pinnakl/core/environment';
import { AppNames } from '@pinnakl/shared/types';
import {
  authConfig,
  authUrls,
  coreApiServerUrls,
  datastream1Urls,
  datastream2Urls,
  fileServiceUrls,
  firebaseConfigs,
  frontEndErrorServiceUrls,
  grpcApiServerUrls,
  requestTimeoutPeriods,
  tradingServiceUrls
} from './pinnakl-environment-options';

const envName: EnvNames = EnvNames.dev;

export const environment: PinnaklEnvironment = {
  envName: envName,
  appName: AppNames.PLATFORM_WEB,
  fileServiceUrl: fileServiceUrls[envName],
  grpcApiServerUrl: grpcApiServerUrls[envName],
  firebaseConfig: firebaseConfigs[EnvNames.staging], // TODO Why this is staging??
  frontEndErrorServiceUrl: frontEndErrorServiceUrls[envName],
  includeTesting: false,
  production: false,
  requestTimeoutPeriod: requestTimeoutPeriods[envName],
  coreApiServerUrl: coreApiServerUrls[envName],
  datastream1Url: datastream1Urls[envName],
  datastream2Url: datastream2Urls[envName],
  tradingServiceUrl: tradingServiceUrls[envName],
  authConfig: authConfig(envName),
  authUrl: authUrls[envName]
};

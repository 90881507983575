import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { ListenFormControlStatusChangesComponent } from './listen-form-control-status-changes-component.model';

export const ClearSubscription = (componentThis: ListenFormControlStatusChangesComponent): void => {
  if (componentThis?.formControl && componentThis?.statusChangesSubscription?.unsubscribe) {
    componentThis.statusChangesSubscription?.unsubscribe();
  }
};
export const ResetFormControl = (componentThis: ListenFormControlStatusChangesComponent): void => {
  if (componentThis?.ngControl?.control) {
    componentThis.formControl = componentThis.ngControl.control as FormControl;
  }
};
export const SubscribeToStatusChanges = (
  componentThis: ListenFormControlStatusChangesComponent
): void => {
  if (componentThis?.formControl?.statusChanges) {
    componentThis.statusChangesSubscription = componentThis.formControl.statusChanges
      .pipe(takeUntilDestroyed(componentThis.destroyRef))
      .subscribe(() => componentThis.cdr.markForCheck());
  }
};

/**
 * The order of functions execution is important
 * @param componentThis (ListenFormControlStatusChangesComponent)
 */
export const HandleFormControlCreation = (
  componentThis: ListenFormControlStatusChangesComponent
): void => {
  ClearSubscription(componentThis);
  ResetFormControl(componentThis);
  SubscribeToStatusChanges(componentThis);
};

import { createAction, props } from '@ngrx/store';
import { FolderComment, FolderComments } from '@pinnakl/poems/folders/domain';

export enum FolderCommentsActions {
  LoadFolderComments = '[Folder comments] Load folder comments',
  FolderCommentsLoaded = '[Folder comments] Folder comments loaded',

  CreateFolderComment = '[Folder comments] Create folder comment',
  FolderCommentCreated = '[Folder comments] Folder comment created',

  UpdateFolderComment = '[Folder comments] Update folder comment',
  FolderCommentUpdated = '[Folder comments] Folder comment updated',

  DeleteFolderComment = '[Folder comments] Delete folder comment',
  FolderCommentDeleted = '[Folder comments] Folder comment deleted'
}

export const loadFolderComments = createAction(
  FolderCommentsActions.LoadFolderComments,
  props<{ id: string }>()
);
export const folderCommentsLoaded = createAction(
  FolderCommentsActions.FolderCommentsLoaded,
  props<{ folderComments: FolderComments }>()
);

export const createFolderComment = createAction(
  FolderCommentsActions.CreateFolderComment,
  props<{ folderComment: Partial<FolderComment> }>()
);
export const folderCommentCreated = createAction(
  FolderCommentsActions.FolderCommentCreated,
  props<{ folderComment: FolderComment }>()
);

export const updateFolderComment = createAction(
  FolderCommentsActions.UpdateFolderComment,
  props<{ folderComment: Partial<FolderComment> }>()
);
export const folderCommentUpdated = createAction(
  FolderCommentsActions.FolderCommentUpdated,
  props<{ folderComment: FolderComment }>()
);

export const deleteFolderComment = createAction(
  FolderCommentsActions.DeleteFolderComment,
  props<{ folderId: string; id: string }>()
);
export const folderCommentDeleted = createAction(
  FolderCommentsActions.FolderCommentDeleted,
  props<{ folderId: string; id: string }>()
);

<div
  (click)="itemClickHandler(dataItem)"
  class="flex-container"
>
  <div class="flex-item">
    <div
      [ngStyle]="{ background: addAlpha(dataItem?.color) }"
      class="security-icon-container"
    >
      <span [ngStyle]="{ color: dataItem?.color }">{{ getCurrentSymbol(dataItem.ticker) }}</span>
    </div>
  </div>
  <div class="flex-item">
    <div class="security-item-info">
      <div
        *ngIf="dataItem?.assetType !== 'option'"
        class="first-name"
        innerHTML="{{ dataItem.description | highlight: searchText }}"
      ></div>
      <div
        class="second-name"
        innerHTML="{{ dataItem.ticker | highlight: searchText }}"
      ></div>
    </div>
  </div>
  <div class="flex-item">
    <div class="security-item-assettype">{{ dataItem?.assetType }}</div>
  </div>
  <div class="flex-item">
    <div class="security-item-cusip">
      <ng-container *ngIf="dataItem?.assetType === 'equity'; else otherAssetType">
        <span>
          <ng-container *ngIf="dataItem?.sedol; else showCusip">
            {{ dataItem?.sedol }}
          </ng-container>
          <ng-template #showCusip>
            {{ dataItem?.cusip }}
          </ng-template>
        </span>
      </ng-container>
      <ng-template #otherAssetType>
        <span>{{ dataItem?.cusip }}</span>
      </ng-template>
    </div>
  </div>
  <div class="flex-item flex-column">
    <ng-container *ngIf="dataItem?.longPosition !== 0">
      <div class="security-item-amount-container">
        <i class="custom-icon-top-chart"></i>
        <span class="amount">{{ dataItem?.longPosition | number: '1.0' }} </span>
      </div>
    </ng-container>
    <ng-container *ngIf="dataItem?.shortPosition !== 0">
      <div class="security-item-amount-container">
        <i class="custom-icon-low-chart"></i>
        <span class="amount">{{ dataItem?.shortPosition | number: '1.0' }} </span>
      </div>
    </ng-container>
  </div>
</div>

import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PmsGridColDef } from '@pinnakl/pms/domain';
import { resetColumns, setGridColumns } from './grid-columns.actions';
import { GridColumnsState } from './grid-columns.models';

export const gridColumnsAdapter: EntityAdapter<PmsGridColDef> = createEntityAdapter<PmsGridColDef>({
  selectId: ({ colId }) => colId,
  sortComparer: false
});

export const initialState: GridColumnsState = gridColumnsAdapter.getInitialState();

export const gridColumnsReducer = createReducer(
  initialState,
  on(setGridColumns, (state, { colDefs }) => gridColumnsAdapter.setAll(colDefs, state)),
  on(resetColumns, () => initialState)
);

export interface LastPrice {
  clientId: number;
  priceDate: string;
  bid: number;
  mid: number;
  ask: number;
  securityId: number;
  securityMarketId: number;
  source: number;
  evalTime: string;
  entryMethod: string;
  include: boolean;
  closeLocal: number;
  currencyCode: string;
}

export enum RealtimePriceOverrideTypes {
  DAY = 'DAY',
  GTC = 'GTC'
}

export interface RealtimePriceOverrideBase {
  securityMarketId: number;
  currentPrice: number;
  overridePrice: number;
  currencyCode: string;
  overrideExpiration: RealtimePriceOverrideTypes;
}

export interface ClosePriceOverrideBase {
  securityMarketId: number;
  price: number;
  currency: string;
}

export interface RealtimePriceOverride extends RealtimePriceOverrideBase {
  id: number;
}

export interface RealtimePriceOverrideFromApi {
  id: number;
  price: number;
  securityMarketId: number;
  overrideExpiration: RealtimePriceOverrideTypes;
}

export interface BenchmarkIndex {
  benchmarkIndex: string | 'Account P&L';
  timestamp: string;
  price: number;
  intradayReturn: number;
}

export interface AccountBenchmarkIndex {
  accountId: number;
  timestamp: string;
  pl: number;
}

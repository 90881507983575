import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PositionsGeneralState } from './positions-general.models';
import { PositionsBaseAdapterSelectors } from './positions-general.reducer';

const getPositionsGeneralFeatureSelector =
  createFeatureSelector<PositionsGeneralState>('positions-general');

const getBasePositionsSelector = createSelector(
  getPositionsGeneralFeatureSelector,
  ({ positionsBases }) => positionsBases
);

const getAllBasePositions = createSelector(
  getBasePositionsSelector,
  PositionsBaseAdapterSelectors.selectAll
);
const basePositionsEntities = createSelector(
  getBasePositionsSelector,
  PositionsBaseAdapterSelectors.selectEntities
);
const getBasePositionsById = securityMarketId =>
  createSelector(basePositionsEntities, entities => entities[securityMarketId]);
const getBasePositionsLoading = createSelector(getBasePositionsSelector, ({ loading }) => loading);

export const PositionsGeneralQuery = {
  getAllBasePositions,
  getBasePositionsById,
  getBasePositionsLoading
};

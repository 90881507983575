import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { PmsDirection, PmsPositionApi } from '@pinnakl/pms/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { GlobalNumberRound } from '@pinnakl/shared/util-helpers';
import uniqueId from 'lodash/uniqueId';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PmsPositionsApiService {
  private readonly endpoint = `${V3_ENDPOINT}pms_positions`;

  constructor(private readonly wsp: WebServiceProvider) {}

  loadPositions(): Observable<PmsPositionApi[]> {
    return this.wsp.get<PmsPositionApi[]>({ endpoint: this.endpoint }).pipe(
      map(positions =>
        positions.map(p => ({
          ...p,
          id: uniqueId('pms_position'),
          mark: p.mark || p.priceSodLocal,
          mtd_pnl: 0,
          ytd_pnl: 0,
          fxRateSod: p.fxRateSod ? GlobalNumberRound(p.fxRateSod, 4) : p.fxRateSod,
          delta: p.delta ?? 1,
          undPrc: p.undPrc ?? 0,
          deltaadjexposure: p.deltaAdjExposure ?? 0,
          deltaadjposition: 0,
          deltaadjmvpct: 0,
          direction:
            p.direction?.toUpperCase() == PmsDirection.LONG ? PmsDirection.LONG : PmsDirection.SHORT // TODO: remove this when backend is fixed
        }))
      )
    );
  }
}

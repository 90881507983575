import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs';
import { TimeseriesPricesApiService } from './timeseries-prices-api.service';
import { loadPricesTimeSeries, pricesTimeSeriesLoaded } from './timeseries-prices.actions';

@Injectable()
export class TimeseriesPricesEffects {
  loadPricesTimeseries$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPricesTimeSeries),
      concatMap(({ securityMarketId }) =>
        this.api.getTimeItemsBySecurityMarketId(securityMarketId).pipe(
          map(prices => ({
            securityMarketId,
            prices
          }))
        )
      ),
      map(pricesTimeItems => pricesTimeSeriesLoaded({ pricesTimeItems }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: TimeseriesPricesApiService
  ) {}
}

<ng-container *ngFor="let dataItem of items; index as index">
  <pnkl-security-item
    #securityItem
    (itemSelected)="itemSelected($event)"
    (keydown)="arrowKeysHandler($any(index))"
    [dataItem]="dataItem"
    [searchText]="searchText"
    [tabindex]="index"
  >
  </pnkl-security-item>
  <hr class="border-only" />
</ng-container>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PricingGrpcService, PricingItem } from '@pinnakl/grpc';
import { EVENT_STREAM_BUFFER_TIMEOUT } from '@pinnakl/pms/domain';
import { PriceEventData } from '@pinnakl/poems/streams/price/domain';
import { of, take } from 'rxjs';
import { bufferTime, catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { PriceEventsStreamFacadeService } from './price-events-stream-facade.service';
import {
  priceEventsReceived,
  subscribeToStream,
  subscribeToStreamFailure,
  unsubscribeFromStream
} from './price-events-stream.actions';

const priceItemToPriceEvent = (item: PricingItem): PriceEventData => ({
  clientId: item.clientid,
  value: item.value,
  securityMarketId: item.securitymarketid,
  priceType: 'mid',
  currency: item.currency,
  source: item.source,
  evalTime: new Date(item.evaltime).toDateString()
});

@Injectable()
export class PriceEventsStreamEffects {
  subscribeToStream$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subscribeToStream),
      switchMap(({ token }) =>
        this.eventsService.subscribeToStream(token, {
          onEstablish: disconnect => this.facade.emitConnectionEstablished(disconnect),
          onError: isFixed => this.facade.emitConnectionError(isFixed)
        })
      ),
      bufferTime(EVENT_STREAM_BUFFER_TIMEOUT),
      filter(events => !!events.length),
      map(events => priceEventsReceived({ events: events.map(priceItemToPriceEvent) })),
      catchError(error => of(subscribeToStreamFailure({ error })))
    )
  );

  unsubscribeToStream$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unsubscribeFromStream),
      take(1),
      tap(() => this.eventsService.unsubscribeFromStream())
      // catchError(error => of(subscribeToStreamFailure({ error })))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly eventsService: PricingGrpcService,
    private readonly facade: PriceEventsStreamFacadeService
  ) {}
}

import { createAction, props } from '@ngrx/store';
import { CurrencyModel, FxRate } from '@pinnakl/poems/domain';

export enum CommonDataActionTypes {
  LoadFxRates = '[POEMS | Common data] Load FxRates',
  FxRatesLoaded = '[POEMS | Common data] FxRates loaded',
  SetFxRates = '[POEMS | Common data] Set fxRates',
  LoadFxRatesFailed = '[POEMS | Common data] Load FxRates failed',
  LoadCurrencies = '[POEMS | Common data] Load Currencies',
  CurrenciesLoaded = '[POEMS | Common data] Currencies loaded',
  LoadCurrenciesFailed = '[POEMS | Common data] Load Currencies failed'
}

export const loadFxRates = createAction(CommonDataActionTypes.LoadFxRates);
export const fxRatesLoaded = createAction(
  CommonDataActionTypes.FxRatesLoaded,
  props<{ fxRates: FxRate[] }>()
);
export const setFxRates = createAction(
  CommonDataActionTypes.SetFxRates,
  props<{ fxRates: FxRate[] }>()
);
export const loadFxRatesFailure = createAction(
  CommonDataActionTypes.FxRatesLoaded,
  props<{ error: any }>()
);

export const loadCurrencies = createAction(CommonDataActionTypes.LoadCurrencies);
export const currenciesLoaded = createAction(
  CommonDataActionTypes.CurrenciesLoaded,
  props<{ currencies: CurrencyModel[] }>()
);
export const loadCurrenciesFailure = createAction(
  CommonDataActionTypes.LoadCurrenciesFailed,
  props<{ error: any }>()
);

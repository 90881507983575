import { Dictionary, Update } from '@ngrx/entity';
import { Aum } from '@pinnakl/poems/aum/domain';
import { RealtimePriceOverride } from '@pinnakl/poems/pricing/domain';
import { AccountCash } from '@pinnakl/poems/streams/portfolio/data-access';
import { ColumnState, SetFilterModelValue } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { PmsColumn, PmsFxRate, PmsPosition } from './pms-config.model';
import { CustomFiltersConfig, PmsAllFiltersConfig } from './pms-presets.model';
import { PmsRebalanceConfig } from './rebalance.model';

export enum PmsWidgetsItems {
  Grid = 'grid',
  Summary = 'summary',
  Balance = 'balance',
  Chart = 'chart'
}

export interface PmsWidgetOrderItem {
  key: PmsWidgetsItems;
}

export type PmsWidgetsOrder = PmsWidgetOrderItem[];

export enum PmsHeaderSidePanels {
  Alerts = 'alerts',
  Config = 'config'
}

export enum PmsGridSidePanels {
  Columns = 'columns',
  Filters = 'filters'
}

export interface ActionItem {
  image?: string;
  icon?: string;
  panel?: PmsHeaderSidePanels;
  tooltip?: string;
  activeClass?: boolean;
  activeClassObservable?: Observable<boolean>;
  id?: string;
}

export interface PmsSidePanelAction extends ActionItem {
  tooltip: string;
  icon: string;
  action: (panel: PmsHeaderSidePanels | null) => void;
}

export interface PmsRebalanceAction extends ActionItem {
  disabled?: Observable<boolean>;
  action: (event: Event) => void;
  svgIcon?: string;
}

export type AccountCodeFilter = 'accountcode';
export const AccountCode = 'accountcode';

export enum ColumnsWithFilters {
  AssetType = 'assettype',
  SubAccountCode = 'subaccountcode',
  LocalCurrency = 'localcurrency'
}

export enum ColumnsWithFiltersLabels {
  AssetType = 'Asset type',
  SubAccountCode = 'Sub account code',
  LocalCurrency = 'Local currency'
}

export interface PmsColumnFilter {
  columnId: string;
  selectedValues: SetFilterModelValue | null;
}

export const ColumnsWithFiltersArray = [
  ColumnsWithFilters.AssetType,
  ColumnsWithFilters.LocalCurrency,
  ColumnsWithFilters.SubAccountCode
];

export const EmptyColumnsWithFiltersMap: CustomFiltersConfig = {
  [ColumnsWithFilters.AssetType]: [],
  [ColumnsWithFilters.SubAccountCode]: [],
  [ColumnsWithFilters.LocalCurrency]: []
};

export interface PmsFiltersValue {
  label: ColumnsWithFiltersLabels;
  values?: string[];
}

export interface PmsFiltersValues {
  [ColumnsWithFilters.AssetType]: PmsFiltersValue;
  [ColumnsWithFilters.SubAccountCode]: PmsFiltersValue;
  [ColumnsWithFilters.LocalCurrency]: PmsFiltersValue;
}

export const DefaultFiltersConfig = {
  [ColumnsWithFilters.AssetType]: {
    label: ColumnsWithFiltersLabels.AssetType
  },
  [ColumnsWithFilters.LocalCurrency]: {
    label: ColumnsWithFiltersLabels.LocalCurrency
  },
  [ColumnsWithFilters.SubAccountCode]: {
    label: ColumnsWithFiltersLabels.SubAccountCode
  }
};

export interface PmsSummaryWidgetData {
  filteredAum: number;
  mvusd: number;
  mvusdpct: number;
  lmvusd: number;
  lmvusdpct: number;
  smvusd: number;
  smvusdpct: number;
  totaldaygainloss: number;
  pnltotalpct: number;
  longPnl: number;
  longPnlPct: number;
  shortPnl: number;
  shortPnlPct: number;
  longDeltaAdjExposure: number;
  shortDeltaAdjExposure: number;
}

export interface PmsAccountCashCombinedItem {
  accountCode: string;
  accountId: number;
  totalAmountUSD: number;
  navCurrent: number;
  items: {
    currency: string;
    amountCurrent: number;
    calculatedPct: number;
    calculatedAmountUsd: number;
  }[];
}

export interface PmsCalculationsWorkerDataRequest {
  positionsArray: PmsPosition[];
  columns: { columnsFromGrid: ColumnState[]; columnsList: PmsColumn[] };
  generalData: {
    aums: Aum[];
    fxRates: PmsFxRate[];
    accountCash: AccountCash[] | undefined;
    priceOverridesMap: Dictionary<RealtimePriceOverride>;
  };
  filters: { userFilters: PmsAllFiltersConfig; searchString: string };
  rebalance: {
    rebalanceConfig: PmsRebalanceConfig | null;
  };
}

export interface PmsCalculationsWorkerDataResponse {
  navCurrentMap: Record<string, number>;
  allCalculatedPositions: PmsPosition[];
  widgetsData: {
    summaryData: PmsSummaryWidgetData;
    cashBalance: PmsAccountCashCombinedItem[];
  };
  grid: {
    calculatedPositions: PmsPosition[];
    pinnedRows: PmsPosition[];
  };
  rebalance: {
    rebalanceUpdates: Update<PmsPosition>[];
  };
}

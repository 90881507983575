import { Injectable } from '@angular/core';
import { EventSourceHandlers } from '@pinnakl/shared/types';
import { filter, map, Observable } from 'rxjs';
import { SubscriptionsManager } from './subscriptions-manager';

export enum EventMessageType {
  CRM_INCLUDED_CONTACT = 6,
  CRM_TRACKING_LINK_CONTACT = 7,
  OBJECTSTORE_ACTIONS = 11
}

export enum EventActionType {
  ALL = 'all',
  GET = 'get',
  PUT = 'put',
  POST = 'post',
  DELETE = 'delete'
}

export enum EventEndpoint {
  NOTIFICATIONS = 'notifications',
  TASKS_STATUS = 'tasks_status',
  TRADES = 'trades',
  ORDER = 'order',
  ORDERS = 'orders',
  PLACEMENTS = 'placements',
  TRADE_ALLOCATION_OMS = 'trade_allocation_oms',
  STOCK_LOAN_REQUESTS = 'stock_loan_requests',
  STOCK_LOAN_QUOTES = 'stock_loan_quotes',
  VDR_CHAT_MESSAGES = 'crm_vdr_chat_message',
  EMAIL_CAMPAIGN_INCLUDED_CONTACT = 'email_campaign_included_contact',
  EMAIL_CAMPAIGN_TRACKING_LINK = 'email_campaign_tracking_link'
}

interface StreamResult {
  MessageType: EventMessageType;
  Message: {
    ActionType: EventActionType;
    Endpoint: string;
    Payload: Record<string, any>;
    Timestamp: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class ServerSentEventsStreamService {
  constructor(private readonly _subscriptionsManager: SubscriptionsManager) {}

  subscribeToEvents<T>(
    baseUrl: string,
    topics: string[] = [],
    endpoint = 'Core',
    handlers?: EventSourceHandlers
  ): Observable<T> {
    return this._subscriptionsManager.registerSubscription<T>(
      `${this.RESOURCE_URL(baseUrl, endpoint)}`,
      `?${Array.isArray(topics) && topics.length ? `&topic=${topics.join(',')}` : ''}`,
      handlers
    );
  }

  // We need to unsubscribe in that places where we dispatch events from stream data
  unsubscribeFromEvents(baseUrl: string, topics: string[] = [], endpoint = 'Core'): void {
    return this._subscriptionsManager.unRegisterSubscription(
      `${this.RESOURCE_URL(baseUrl, endpoint)}`,
      `?${Array.isArray(topics) && topics.length ? `&topic=${topics.join(',')}` : ''}`
    );
  }

  /**
   * Function to subscribe to stream with events (server sent events)
   * @param baseUrl Url for stream (usually is taken from env file)
   * @param endpoint Url part after server url
   * @param endpoints Array of endpoints which should be accepted and passed through
   * @param actions Array of action types which should be accepted and passed through
   * @param handlers Object with handlers for establish/error/message
   */
  subToManyObjectStoreActions(
    baseUrl: string,
    endpoints?: string[],
    actions: string[] = [EventActionType.ALL],
    endpoint = 'Core',
    handlers?: EventSourceHandlers
  ): Observable<any> {
    return this._subscriptionsManager
      .registerSubscription<StreamResult>(
        `${this.RESOURCE_URL(baseUrl, endpoint)}`,
        `?topic=OBJECTSTORE_ACTIONS`,
        handlers
      )
      .pipe(
        filter(item => item.MessageType === EventMessageType.OBJECTSTORE_ACTIONS),
        filter(item =>
          actions && actions[0] !== EventActionType.ALL
            ? actions.includes(item.Message.ActionType)
            : true
        ),
        filter(item => (endpoints ? endpoints.includes(item.Message.Endpoint) : true)),
        map(({ Message }) => Message)
      );
  }

  // We need to unsubscribe in that places where we are dispatching events from stream data
  unsubToManyObjectStoreActions(baseUrl: string, endpoint = 'Core'): void {
    return this._subscriptionsManager.unRegisterSubscription(
      `${this.RESOURCE_URL(baseUrl, endpoint)}`,
      `?topic=OBJECTSTORE_ACTIONS`
    );
  }

  private RESOURCE_URL = (baseUrl: string, endpoint: string) => `${baseUrl}${endpoint}/`;
}

<div
  [formGroup]="$any(form)"
  [ngClass]="containerClass"
  [ngSwitch]="type.toLowerCase()"
>
  <label
    *ngIf="!(type === 'boolean') && !hideLabel && !(inputOptions && inputOptions.floatingLabel)"
    [ngClass]="labelClass"
    class="mb-0"
  >
    {{ label }}
    <span
      *ngIf="required"
      class="color-red pl-0-25"
      >*</span
    >
  </label>
  <div *ngSwitchCase="'date'">
    <pinnakl-date-input
      [bottomView]="bottomView"
      [dateOptions]="dateOptions"
      [disableInput]="disabled"
      [formControlName]="controlName"
      [inputClass]="inputClass"
      [topView]="topView"
      [type]="$any(type)"
    >
    </pinnakl-date-input>
  </div>
  <div *ngSwitchCase="'file'">
    <file-input [formControlName]="controlName"></file-input>
  </div>
  <div *ngSwitchCase="'time'">
    <pinnakl-time-input
      [disableInput]="disabled ? true : undefined"
      [formControlName]="controlName"
      [format]="format"
      [inputClass]="inputClass"
    >
    </pinnakl-time-input>
  </div>
  <div
    *ngSwitchCase="'multiselect'"
    [ngClass]="inputClass"
  >
    <pnkl-multiselect-dropdown
      (dropdownFetchData)="dropdownFetchDataEmit()"
      (onDropdownValueChange)="onDropdownValueChange.emit($event)"
      [disabled]="disabled"
      [dropdownOptions]="$any(dropdownOptions)"
      [dropdownSource]="dropdownSource"
      [formControlName]="controlName"
      [popupSettings]="popupSettings"
    ></pnkl-multiselect-dropdown>
  </div>
  <div
    *ngSwitchCase="'dropdown'"
    [ngClass]="inputClass"
  >
    <pinnakl-dropdown
      (dropdownFetchData)="dropdownFetchDataEmit()"
      (onDropdownValueChange)="dropdownValueChanged($event)"
      (onFilterValueChange)="filterValueChange($event)"
      [clearButton]="
        dropdownOptions?.clearButton === true || dropdownOptions?.clearButton === false
          ? dropdownOptions.clearButton
          : true
      "
      [controlName]="controlName"
      [disabled]="disabled"
      [dropdownOptions]="$any(dropdownOptions)"
      [dropdownSource]="dropdownSource"
      [form]="$any(form)"
      [ngClass]="inputClass"
      [popupSettings]="popupSettings"
      [setDefaultSingleValue]="setDefaultSingleValue"
    >
    </pinnakl-dropdown>
  </div>
  <div *ngSwitchCase="'numeric'">
    <input
      [attr.disabled]="disabled ? true : undefined"
      [decimals]="decimals"
      [formControlName]="controlName"
      [ngClass]="inputClass"
      [textalign]="textalign"
      class="pnkl-input"
      pnklNumFormat
    />
  </div>
  <div *ngSwitchCase="'place'">
    <pinnakl-place-autocomplete
      (placeDetailsReceived)="
        placeOptions?.sendPlaceDetails ? placeDetailsReceived.emit($event) : null
      "
      [formControlName]="controlName"
      [placeType]="placeOptions ? placeOptions.placeType : undefined"
      [sendPlaceDetails]="placeOptions ? placeOptions.sendPlaceDetails : false"
      [suggestionType]="placeOptions ? placeOptions.suggestionType : undefined"
    >
    </pinnakl-place-autocomplete>
  </div>
  <div *ngSwitchCase="'richtextarea'">
    <pinnakl-editor
      [disableEditor]="disabled"
      [formControlName]="controlName"
      [textEditorHeight]="textEditorHeight"
    ></pinnakl-editor>
  </div>
  <div *ngSwitchCase="'search'">
    <pinnakl-search-input
      [disableInput]="disabled ? true : undefined"
      [formControlName]="controlName"
      [inputClass]="inputClass"
    >
    </pinnakl-search-input>
  </div>
  <div *ngSwitchCase="'text'">
    <pinnakl-input-floating-label
      (onKeyDownText)="keyDown($event)"
      [autoComplete]="autoComplete"
      [disabledInput]="disabled ? true : undefined"
      [formControlName]="controlName"
      [inputClass]="inputClass"
      [inputOptions]="inputOptions"
      [label]="label"
      [placeholder]="placeholder"
      class="pnkl-input"
      type="text"
    >
    </pinnakl-input-floating-label>
  </div>
  <div *ngSwitchCase="'textarea'">
    <pinnakl-text-area
      (keyup.enter)="onPressEnter($event)"
      [disableInput]="disabled ? true : undefined"
      [formControlName]="controlName"
      [inputClass]="inputClass"
      class="pnkl-input"
    ></pinnakl-text-area>
  </div>
  <div *ngSwitchCase="'boolean'">
    <span [attr.class]="setCheckboxStyle()">
      <span
        *ngIf="checkBoxStyle === 'toggle' && label"
        [ngClass]="labelClass"
        class="switch-label"
        >{{ label }}</span
      >
      <label
        *ngIf="checkBoxStyle === 'toggle'"
        [attr.class]="toggleStyle.switch"
        [for]="controlName + randomNumber"
      >
        <input
          (change)="onChangeToggle.emit($event)"
          [attr.checked]="value"
          [attr.disabled]="disabled ? true : undefined"
          [formControlName]="controlName"
          [id]="controlName + randomNumber"
          type="checkbox"
        />
        <span [attr.class]="toggleStyle.holder"></span>
      </label>
      <input
        *ngIf="checkBoxStyle === 'input-check'"
        [attr.checked]="value"
        [formControlName]="controlName"
        [id]="controlName"
        type="checkbox"
      />
      <label
        *ngIf="checkBoxStyle === 'input-check'"
        [for]="controlName"
        >{{ label }}</label
      >
    </span>
  </div>
  <div *ngSwitchCase="'password'">
    <pinnakl-input-floating-label
      [autoComplete]="autoComplete"
      [disabledInput]="disabled ? true : undefined"
      [formControlName]="controlName"
      [inputClass]="inputClass"
      [inputOptions]="inputOptions"
      [label]="label"
      type="password"
    >
    </pinnakl-input-floating-label>
  </div>
  <div *ngSwitchCase="'email'">
    <pinnakl-input-floating-label
      [disabledInput]="disabled ? true : undefined"
      [formControlName]="controlName"
      [inputClass]="inputClass"
      [inputOptions]="inputOptions"
      [label]="label"
      type="email"
    >
    </pinnakl-input-floating-label>
  </div>
  <div *ngSwitchCase="'number'">
    <pinnakl-input-floating-label
      [disabledInput]="disabled ? true : undefined"
      [formControlName]="controlName"
      [inputClass]="inputClass"
      [inputOptions]="inputOptions"
      [label]="label"
      [required]="required"
      type="number"
    >
    </pinnakl-input-floating-label>
  </div>
  <div *ngSwitchCase="'month-picker'">
    <pinnakl-date-input
      [bottomView]="bottomView"
      [dateOptions]="dateOptions"
      [disableInput]="disabled"
      [formControlName]="controlName"
      [inputClass]="inputClass"
      [topView]="topView"
      [type]="$any(type)"
    >
    </pinnakl-date-input>
  </div>

  <div *ngSwitchCase="'date-time'">
    <pinnakl-date-time-input
      [disableInput]="disabled"
      [formControlName]="controlName"
      [inputClass]="inputClass"
      [type]="$any(type)"
    >
    </pinnakl-date-time-input>
  </div>
  <div *ngSwitchCase="'toggle'">
    <pinnakl-toggle
      [disabled]="disabled"
      [formControlName]="controlName"
      [labelPosition]="labelPosition"
      [labelToggle]="labelToggle"
      [toggleOptions]="toggleOptions"
      [toggleSource]="toggleSource"
    ></pinnakl-toggle>
  </div>
  <div class="bottom-section">
    <pnkl-validation
      *ngIf="showValidation"
      [controlName]="controlName"
      [errosMsg]="errosMsg"
      [form]="form"
      [validationClass]="validationClass"
    ></pnkl-validation>
    <div
      *ngIf="maxLength"
      class="textarea-limit"
    >
      <progress-circle
        [current]="currentLength"
        [diameter]="24"
        [limit]="maxLength"
      ></progress-circle>
    </div>
  </div>
</div>

<form
  (ngSubmit)="register()"
  [formGroup]="form"
  class="register-form"
>
  <div class="row mb-1">
    <div class="col-md-6">
      <prime-input-container>
        <ng-template #labelContent>
          <prime-label-content
            formControlName="email"
            label="Email"
          ></prime-label-content>
        </ng-template>
        <ng-template #inputContent>
          <prime-text-input formControlName="email"> </prime-text-input>
        </ng-template>
        <ng-template #validationContent>
          <prime-input-validation
            [customErrors]="{
              pattern: 'Email is not valid.'
            }"
            class="mt-1"
            formControlName="email"
          ></prime-input-validation>
        </ng-template>
      </prime-input-container>
    </div>
    <div class="col-md-6">
      <prime-input-container>
        <ng-template #labelContent>
          <prime-label-content
            formControlName="phoneNumber"
            label="Phone number"
          ></prime-label-content>
        </ng-template>
        <ng-template #inputContent>
          <prime-mask-input
            formControlName="phoneNumber"
            mask="+9-999-999-9999"
            styleClass="w-100"
          >
          </prime-mask-input>
        </ng-template>
        <ng-template #validationContent>
          <prime-input-validation
            [customErrors]="{
              pattern: 'Phone is not valid.'
            }"
            class="mt-1"
            formControlName="phoneNumber"
          ></prime-input-validation>
        </ng-template>
      </prime-input-container>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <prime-input-container>
        <ng-template #labelContent>
          <prime-label-content
            formControlName="firstName"
            label="First name"
          ></prime-label-content>
        </ng-template>
        <ng-template #inputContent>
          <prime-text-input formControlName="firstName"> </prime-text-input>
        </ng-template>
        <ng-template #validationContent>
          <prime-input-validation
            class="mt-1"
            formControlName="firstName"
          ></prime-input-validation>
        </ng-template>
      </prime-input-container>
    </div>
    <div class="col-md-6">
      <prime-input-container>
        <ng-template #labelContent>
          <prime-label-content
            formControlName="lastName"
            label="Last name"
          ></prime-label-content>
        </ng-template>
        <ng-template #inputContent>
          <prime-text-input formControlName="lastName"> </prime-text-input>
        </ng-template>
        <ng-template #validationContent>
          <prime-input-validation
            class="mt-1"
            formControlName="lastName"
          ></prime-input-validation>
        </ng-template>
      </prime-input-container>
    </div>
  </div>

  <div class="multi-factor">
    <h4 class="title">Multi-factor authentication</h4>
    <div class="multi-factor-controls">
      <div class="multi-factor-header">ONE TIME PASSWORD</div>
      <div>
        <div class="radioGroup">
          <span class="toggle-radio-btn">
            <input
              [value]="multiFactorTypes.email.value"
              formControlName="otpChannel"
              id="multiFactorEmail"
              type="radio"
            />
            <label for="multiFactorEmail">{{ multiFactorTypes.email.label }}</label>
          </span>
          <span class="toggle-radio-btn">
            <input
              [value]="multiFactorTypes.mobile.value"
              formControlName="otpChannel"
              id="multiFactorMobile"
              type="radio"
            />
            <label for="multiFactorMobile">{{ multiFactorTypes.mobile.label }}</label>
          </span>
        </div>
      </div>
    </div>
  </div>

  <change-password-form
    [hideSubmit]="true"
    [isSubmitted]="isSubmitted"
    formControlName="password"
  ></change-password-form>
  <div class="actions">
    <button
      class="btn submit"
      type="submit"
    >
      Save profile
    </button>
  </div>
</form>

import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  editPortfolioLatestPrice,
  portfolioLatestPricesLoaded
} from './portfolio-latest-prices.actions';
import { LatestPrice, PortfolioLatestPricesState } from './portfolio-latest-prices.models';

export const portfolioLatestPricesAdapter = createEntityAdapter<LatestPrice>({
  selectId: ({ securityMarketId }) => securityMarketId
});

export const initialState: PortfolioLatestPricesState =
  portfolioLatestPricesAdapter.getInitialState({});

export const portfolioLatestPricesReducer = createReducer(
  initialState,
  on(portfolioLatestPricesLoaded, (state, { prices }) =>
    portfolioLatestPricesAdapter.setMany(prices, state)
  ),
  on(editPortfolioLatestPrice, (state, { securityMarketId, newLatestPrice }) =>
    portfolioLatestPricesAdapter.updateOne(
      { id: securityMarketId, changes: { priceLocal: newLatestPrice } },
      state
    )
  )
);

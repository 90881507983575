import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'pinnakl-local-spinner',
  templateUrl: 'local-spinner.component.html',
  styleUrls: ['local-spinner.component.scss'],
  imports: [NgClass, NgStyle],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocalSpinnerComponent {
  @Input() classes = '';
  @Input() size = '100px';
  @Input() backgroundColor = 'rgba(255, 255, 255, 0.2)';
  @Input() otherNgStyles: Record<string, string> = {};

  getComputedStyle(): Record<string, string> {
    return {
      backgroundSize: this.size,
      backgroundColor: this.backgroundColor,
      ...this.otherNgStyles
    };
  }
}

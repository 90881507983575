import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AumState } from './aum.models';
import { aumsAdapter } from './aum.reducer';

export const featureSelectorName = 'aums';

const getAumStateSelector = createFeatureSelector<AumState>(featureSelectorName);

const { selectAll } = aumsAdapter.getSelectors();

const getAums = createSelector(getAumStateSelector, selectAll);
const getAumsLoaded = createSelector(getAumStateSelector, ({ loaded }) => loaded);
const getAumsLoading = createSelector(getAumStateSelector, ({ loading }) => loading);

export const aumsQuery = {
  getAums,
  getAumsLoaded,
  getAumsLoading
};

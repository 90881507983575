import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PmsRebalanceConfig } from '@pinnakl/pms/domain';
import {
  loadRebalanceConfig,
  loadRebalanceConfigFailure,
  setRebalanceConfig
} from './rebalance-config-state.actions';
import { PmsRebalanceConfigState } from './rebalance-config-state.model';

export const rebalanceConfigAdapter: EntityAdapter<PmsRebalanceConfig> =
  createEntityAdapter<PmsRebalanceConfig>({
    sortComparer: false
  });

export const initialState: PmsRebalanceConfigState = rebalanceConfigAdapter.getInitialState({
  loading: false,
  selectedRebalanceConfigId: null,
  targetPortfolio: null
});

export const rebalanceConfigStateReducer = createReducer(
  initialState,
  on(loadRebalanceConfig, state => ({ ...state, loading: true })),
  on(loadRebalanceConfigFailure, (state, { error }) => ({ ...state, error, loading: false })),
  on(setRebalanceConfig, (state, { rebalanceConfig }) => {
    return rebalanceConfigAdapter.setAll(rebalanceConfig, {
      ...state,
      selectedRebalanceConfigId: rebalanceConfig?.[0]?.id ?? null,
      loading: false
    });
  })
);

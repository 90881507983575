import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { RealtimePriceOverride } from '@pinnakl/poems/pricing/domain';
import {
  allRealtimePriceOverridesLoaded,
  loadAllRealtimePriceOverrides,
  loadOverrideBySecurityMarketId,
  overrideBySecurityMarketIdLoaded,
  overrideCreated,
  overrideDeleted
} from './realtime-price-override.actions';
import { RealtimePriceOverrideState } from './realtime-price-override.models';

const adapter = createEntityAdapter<RealtimePriceOverride>({
  selectId: ({ securityMarketId }) => securityMarketId
});

export const initialState: RealtimePriceOverrideState = adapter.getInitialState({
  loading: false
});

export const realtimePriceOverrideReducer = createReducer(
  initialState,
  on(loadAllRealtimePriceOverrides, state => ({
    ...state,
    loading: true
  })),
  on(allRealtimePriceOverridesLoaded, (state, { overrides }) =>
    adapter.setAll(overrides, {
      ...state,
      loading: false
    })
  ),
  on(loadOverrideBySecurityMarketId, state => ({
    ...state,
    loading: true
  })),
  on(overrideBySecurityMarketIdLoaded, (state, { realtimePriceOverride }) =>
    adapter.setOne(realtimePriceOverride, {
      ...state,
      loading: false
    })
  ),
  on(overrideCreated, (state, { realtimePriceOverride }) =>
    adapter.setOne(realtimePriceOverride, state)
  ),
  on(overrideDeleted, (state, { securityMarketId }) => adapter.removeOne(securityMarketId, state))
);

export const realtimePriceOverrideSelectors = adapter.getSelectors();

<p-inputNumber
  (onBlur)="onBlur.emit($event)"
  (onClear)="onClear.emit($event)"
  (onFocus)="onFocus.emit($event)"
  (onInput)="onInput.emit($event)"
  (onKeyDown)="onKeyDown.emit($event)"
  [allowEmpty]="allowEmpty"
  [ariaLabel]="ariaLabel"
  [ariaRequired]="ariaRequired"
  [autocomplete]="autocomplete"
  [buttonLayout]="buttonLayout"
  [currencyDisplay]="currencyDisplay"
  [currency]="currency"
  [decrementButtonClass]="decrementButtonClass"
  [decrementButtonIcon]="decrementButtonIcon"
  [formControl]="$any(formControl)"
  [format]="format"
  [incrementButtonClass]="incrementButtonClass"
  [incrementButtonIcon]="incrementButtonIcon"
  [inputId]="inputId"
  [inputStyleClass]="inputStyleClass"
  [inputStyle]="inputStyle"
  [localeMatcher]="localeMatcher"
  [locale]="locale"
  [maxFractionDigits]="maxFractionDigits"
  [max]="max"
  [maxlength]="maxlength"
  [minFractionDigits]="minFractionDigits"
  [min]="min"
  [mode]="mode"
  [name]="name"
  [ngClass]="containerClass"
  [placeholder]="placeholder"
  [prefix]="prefix"
  [readonly]="readonly"
  [required]="required"
  [showButtons]="showButtons"
  [showClear]="showClear"
  [size]="size"
  [step]="step"
  [styleClass]="styleClass"
  [style]="style"
  [suffix]="suffix"
  [tabindex]="tabindex"
  [title]="title"
  [useGrouping]="useGrouping"
  class="w-100"
></p-inputNumber>

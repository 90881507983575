import { createReducer, on } from '@ngrx/store';
import { setLoadedAndLoadingFields } from '@pinnakl/shared/util-helpers';
import {
  loadTradeWorkflowSpec,
  tradeWorkflowSpecLoaded,
  tradeWorkflowSpecUpdated
} from './trade-workflow-spec.actions';
import { TradeWorkflowSpecState } from './trade-workflow-spec.models';

export const initialState: TradeWorkflowSpecState = {
  spec: undefined,
  loaded: false,
  loading: false
};

export const tradeWorkflowSpecReducer = createReducer(
  initialState,
  on(loadTradeWorkflowSpec, state => ({
    ...state,
    ...setLoadedAndLoadingFields(false, true)
  })),
  on(tradeWorkflowSpecLoaded, (state, { spec }) => ({
    ...state,
    spec,
    ...setLoadedAndLoadingFields(true, false)
  })),
  on(tradeWorkflowSpecUpdated, (state, { spec }) => ({
    ...state,
    spec: {
      ...state.spec,
      ...spec
    }
  }))
);

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PmsColumnsState } from './columns.models';
import { columnsAdapter } from './columns.reducer';

export const featureSelectorName = 'pms-columns';

const getColumnsStateSelector = createFeatureSelector<PmsColumnsState>(featureSelectorName);

const { selectAll } = columnsAdapter.getSelectors();
const getColumns = createSelector(getColumnsStateSelector, selectAll);
const getColumnsLoading = createSelector(getColumnsStateSelector, state => state.loading);

export const columnsQuery = {
  getColumns,
  getColumnsLoading
};

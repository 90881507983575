import { EMPTY_FILTER_FIELD, PresetModules } from '@pinnakl/shared/types';
import { buildBasePreset } from '@pinnakl/shared/util-helpers';
import { PmsPosition } from './pms-config.model';
import { PmsAllFiltersConfig } from './pms-presets.model';
import {
  AccountCode,
  ColumnsWithFilters,
  ColumnsWithFiltersArray,
  EmptyColumnsWithFiltersMap
} from './pms-widgets.model';

export const buildPmsBasePreset = buildBasePreset(PresetModules.Pms);

export const buildPossibleColumnsFiltersValues = (
  positions: PmsPosition[]
): PmsAllFiltersConfig => {
  const valuesSets = positions.reduce(
    (accum, position) => {
      for (const key of [...ColumnsWithFiltersArray, AccountCode]) {
        accum[key].add(
          position[key] === '' ? EMPTY_FILTER_FIELD : (position[key] ?? EMPTY_FILTER_FIELD)
        );
      }
      return accum;
    },
    {
      [AccountCode]: new Set<string>(),
      [ColumnsWithFilters.AssetType]: new Set<string>(),
      [ColumnsWithFilters.SubAccountCode]: new Set<string>(),
      [ColumnsWithFilters.LocalCurrency]: new Set<string>()
    }
  );
  return Object.entries(valuesSets).reduce(
    (accum, [key, valuesSet]) => ({
      ...accum,
      [key]: Array.from(valuesSet)
    }),
    { ...EmptyColumnsWithFiltersMap, accountcode: [] }
  );
};

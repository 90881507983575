import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentFills, ComponentSizes } from '@pinnakl/shared/types';
import { CheckIcon } from 'primeng/icons/check';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { Nullable } from 'primeng/ts-helpers';
import { NoopValueAccessorDirective } from '../../directives';
import { ControlInjectedMultiSelectComponent } from './control-injected-multi-select.component';

export type MultiSelectSize = ComponentSizes;
export type MultiSelectFill = ComponentFills;
const DefaultMultiSelectSize = 'md';

const imports = [CommonModule, CheckIcon, MultiSelectModule, ReactiveFormsModule];

@Component({
  imports: [imports],
  selector: 'prime-multi-select',
  templateUrl: './prime-multi-select.component.html',
  styleUrls: ['./prime-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  hostDirectives: [NoopValueAccessorDirective]
})
export class PrimeMultiSelectComponent extends ControlInjectedMultiSelectComponent {
  // custom `fill` input for theming, variant is not being utilized
  @Input() fill: MultiSelectFill = 'outlined';
  @Input() size: MultiSelectSize;
  @Input() rounded: boolean;
  @Input() selectAllHeader: boolean;
  @Input() containerClass!: string;
  @ViewChild(MultiSelect, { static: false }) multiSelect?: MultiSelect;

  @ViewChild('headerCheckbox', { static: false }) headerCheckboxViewChild: Nullable<ElementRef>;
  @ContentChild('headerTemplate', { static: false }) headerTemplate: TemplateRef<null> | null =
    null;
  @ContentChild('filterTemplate', { static: false }) filterTemplate: TemplateRef<null> | null =
    null;
  @ContentChild('selectedItemsTemplate', { static: false })
  selectedItemsTemplate: TemplateRef<null> | null = null;
  @ContentChild('itemTemplate', { static: false })
  itemTemplate: TemplateRef<null> | null = null;
  @ContentChild('groupItemsTemplate', { static: false })
  groupItemsTemplate: TemplateRef<null> | null = null;
  @ContentChild('removetokeniconTemplate', { static: false })
  removetokeniconTemplate: TemplateRef<null> | null = null;
  @ContentChild('dropdowniconTemplate', { static: false })
  dropdowniconTemplate: TemplateRef<null> | null = null;
  @ContentChild('validationContent', { static: false })
  validationContent: TemplateRef<null> | null = null;

  @ContentChild('inputIconTemplate', { static: false })
  inputIconTemplate: TemplateRef<null> | null = null;

  constructor() {
    super();
    // be aware with using "body" as appendTo, it may break clickOutside functionality
    this.appendTo = this.appendTo === null ? this.appendTo : 'body';
    this.size = DefaultMultiSelectSize;
    this.rounded = false;
    this.selectAllHeader = false;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChartsModule, StockChartModule } from '@progress/kendo-angular-charts';
import { ComboBoxModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { AgGridModule } from 'ag-grid-angular';
import { HighchartsChartModule } from 'highcharts-angular';
import { PnklComparisonChartComponent } from './pnkl-comparison-chart';
import { PnklLineStockchartDateComponent } from './pnkl-line-stockchart-date';
import { PnklLineStockchartDateOptionsComponent } from './pnkl-line-stockchart-date-options';
import { PnklSimpleComparisonChartComponent } from './pnkl-simple-comparison-chart';
import { PnklStackbarChartDateComponent } from './pnkl-stackbar-chart-date';
import { PnklTreeMapComponent } from './pnkl-tree-map';

const COMPONENTS = [
  PnklComparisonChartComponent,
  PnklSimpleComparisonChartComponent,
  PnklLineStockchartDateComponent,
  PnklLineStockchartDateOptionsComponent,
  PnklStackbarChartDateComponent,
  PnklTreeMapComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    ComboBoxModule,
    CommonModule,
    FormsModule,
    HighchartsChartModule,
    MultiSelectModule,
    ReactiveFormsModule,
    StockChartModule,
    AgGridModule,
    ChartsModule
  ],
  exports: [...COMPONENTS]
})
export class PnklChartsModule {}

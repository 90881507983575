import { CommonDateFormats, DateInfo, Holiday } from '@pinnakl/shared/types';
import moment from 'moment/moment';

export const DateHelpers = {
  getPreviousBusinessDay: (initialDate?: Date): Date => {
    const dayInMs = 24 * 60 * 60 * 1000;
    let lastWorkDay = new Date((initialDate ? initialDate.getTime() : Date.now()) - dayInMs);
    while ([0, 6].includes(lastWorkDay.getDay())) {
      lastWorkDay = new Date(lastWorkDay.getTime() - dayInMs);
    }
    return lastWorkDay;
  },
  getCurrentFirstDayOf: (value: 'month' | 'quarter' | 'year'): Date => {
    return moment(new Date()).startOf(value).toDate();
  },
  compareDatesWithoutTime: (date1: Date, date2: Date): number => {
    if (!date1 || !date2) {
      throw 'You need provide proper date values';
    }
    const dateA = new Date(date1.getTime());
    dateA.setHours(0, 0, 0, 0);
    const dateB = new Date(date2.getTime());
    dateB.setHours(0, 0, 0, 0);
    return dateA.getTime() - dateB.getTime();
  },
  compareDatesWithTime: (date1: Date, date2: Date): number => {
    if (!date1 || !date2) {
      throw 'You need provide proper date values';
    }
    return date1.getTime() - date2.getTime();
  },
  isDateInBetween: (currentDate: Date, startDate: Date, endDate: Date): boolean => {
    return (
      (!startDate || DateHelpers.compareDatesWithoutTime(currentDate, startDate) >= 0) &&
      (!endDate || DateHelpers.compareDatesWithoutTime(endDate, currentDate) >= 0)
    );
  },
  areDatesEqual: (date1: Date, date2: Date): boolean => {
    if (date1) {
      if (date2) {
        return DateHelpers.compareDatesWithoutTime(date1, date2) === 0;
      } else {
        return false;
      }
    } else {
      return !date2;
    }
  },
  /*
   * Return true if date1 is greater or equal than date2
   * */
  compareDates: (date1: Date, date2: Date, strict = false, skipTime = true): boolean => {
    const comparatorFunction = skipTime
      ? DateHelpers.compareDatesWithoutTime
      : DateHelpers.compareDatesWithTime;
    if (strict) {
      return comparatorFunction(date1, date2) > 0;
    }
    return comparatorFunction(date1, date2) >= 0;
  },
  addWeekdays: (date: Date, days: number): Date => {
    let dateMoment = moment(date);
    while (days !== 0) {
      dateMoment = days > 0 ? dateMoment.add(1, 'days') : dateMoment.subtract(1, 'days');
      if (dateMoment.isoWeekday() !== 6 && dateMoment.isoWeekday() !== 7) {
        const increment = days > 0 ? -1 : 1;
        days += increment;
      }
    }
    return dateMoment.toDate();
  },
  formatDateInUTC: (date: number | Date | null | undefined, format = 'MM/DD/YYYY'): string => {
    return moment.utc(date).format(format);
  },
  formatDate: (date: number | Date | null | undefined, format = 'MM/DD/YYYY'): string => {
    return moment(date).format(format);
  },
  addBusinessDays: (date: Date, days: number, holidays: Holiday[]): Date => {
    let dateMoment = moment(date); // use a clone

    const momentHolidaysArray = holidays.map(({ holidayDate }) =>
      moment(holidayDate).format('MM/DD/YYYY')
    );

    while (days !== 0) {
      dateMoment = days > 0 ? dateMoment.add(1, 'days') : dateMoment.subtract(1, 'days');
      if (
        dateMoment.isoWeekday() !== 6 &&
        dateMoment.isoWeekday() !== 7 &&
        momentHolidaysArray.indexOf(dateMoment.format('MM/DD/YYYY')) === -1
      ) {
        const increment = days > 0 ? -1 : 1;
        days += increment;
      }
    }
    return dateMoment.toDate();
  },
  getDate: (value: string, format: string): Date | null => {
    const dateMoment = moment(value, format);
    return dateMoment.isValid() ? dateMoment.toDate() : null;
  },
  getMomentDate(value: moment.MomentInput, format?: string): moment.Moment {
    return moment(value, format);
  },
  getUtcDate: (value: string, format: string): Date => {
    return moment.utc(value, format).toDate();
  },
  getDateTime: (value: string, format: string): number | null => {
    return DateHelpers.getDate(value, format)?.getDate() ?? null;
  },
  hasDST: (date = new Date()): boolean => {
    const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(january, july) === date.getTimezoneOffset();
  },
  endTimeMarket: (): Date => {
    const todayDateTime = new Date();
    const today = `${
      todayDateTime.getMonth() + 1
    }/${todayDateTime.getDate()}/${todayDateTime.getFullYear()}`;

    return DateHelpers.hasDST()
      ? new Date(`${today} 9:01:00 PM Z`)
      : new Date(`${today} 8:01:00 PM Z`);
  },
  startTimeMarket: (): Date => {
    const todayDateTime = new Date();
    const today = `${
      todayDateTime.getMonth() + 1
    }/${todayDateTime.getDate()}/${todayDateTime.getFullYear()}`;

    return DateHelpers.hasDST()
      ? new Date(`${today} 2:31:00 PM Z`)
      : new Date(`${today} 1:31:00 PM Z`);
  },
  isMarketOpen: (): boolean => {
    const currDateTime = new Date();
    return (
      currDateTime > DateHelpers.startTimeMarket() &&
      currDateTime < DateHelpers.endTimeMarket() &&
      ![0, 6].includes(currDateTime.getDay())
    );
  },
  isTodayMarketDay: (): boolean => {
    const currDateTime = new Date();
    return ![0, 6].includes(currDateTime.getDay());
  },
  isBeforeMarket: (): boolean => {
    const currDateTime = new Date();
    return currDateTime < DateHelpers.startTimeMarket() && ![0, 6].includes(currDateTime.getDay());
  },
  getOnlyDateStringFromDate: (
    date: Date | number,
    dateFormat: CommonDateFormats | string = CommonDateFormats.SHORT
  ): string => {
    return moment(date).format(dateFormat).slice(0, dateFormat.length);
  },
  getDateStringByFormat: (date: Date | number, dateFormat: string): string => {
    return moment(date).format(dateFormat);
  },
  getFirstDateOfMonth: (date: Date): Date => {
    return moment(date).startOf('month').toDate();
  },
  getLastDateOfMonth: (date: Date): Date => {
    return moment(date).endOf('month').toDate();
  },
  getFirstDateOfYear: (date: Date): Date => {
    return moment(date).startOf('year').toDate();
  },
  getLastDateOfYear: (date: Date): Date => {
    return moment(date).endOf('year').toDate();
  },
  getFullDateInfo: <T extends string | number>(
    date: Date,
    convertToNumbers = false,
    format: {
      year: string;
      month: string;
      day: string;
    } = {
      year: CommonDateFormats.YEAR,
      month: CommonDateFormats.MONTH_NUMBER,
      day: CommonDateFormats.DAY
    }
  ): DateInfo<T> => {
    const year = moment(date).format(format.year);
    const month = moment(date).format(format.month);
    const day = moment(date).format(format.day);
    return {
      year: convertToNumbers ? +year : year,
      month: convertToNumbers ? +month : month,
      day: convertToNumbers ? +day : day
    } as DateInfo<T>;
  },
  getDateWithOffsetShift: (date: number | Date): Date => {
    const dateMoment = moment(date);
    return dateMoment.add(moment().utcOffset(), 'm').toDate();
  },
  getDateWithoutOffsetShift: (date: number | Date): Date => {
    const dateMoment = moment(date);
    return dateMoment.subtract(moment().utcOffset(), 'm').toDate();
  }
};

import { createAction, props } from '@ngrx/store';
import {
  TradeWorkflowSpec,
  TradeWorkflowSpecUpdateParams
} from '@pinnakl/poems/trade-workflow-spec/domain';

export enum TradeWorkflowSpecActionTypes {
  LoadTradeWorkflowSpec = '[Trade Workflow Spec] Load TradeWorkflowSpec',
  TradeWorkflowSpecLoaded = '[Trade Workflow Spec] TradeWorkflowSpec loaded',
  UpdateTradeWorkflowSpec = '[Trade Workflow Spec] Update TradeWorkflowSpec',
  TradeWorkflowSpecUpdated = '[Trade Workflow Spec] TradeWorkflowSpec updated'
}

export const loadTradeWorkflowSpec = createAction(
  TradeWorkflowSpecActionTypes.LoadTradeWorkflowSpec
);

export const tradeWorkflowSpecLoaded = createAction(
  TradeWorkflowSpecActionTypes.TradeWorkflowSpecLoaded,
  props<{ spec: TradeWorkflowSpec }>()
);

export const updateTradeWorkflowSpec = createAction(
  TradeWorkflowSpecActionTypes.UpdateTradeWorkflowSpec,
  props<{ spec: TradeWorkflowSpecUpdateParams }>()
);

export const tradeWorkflowSpecUpdated = createAction(
  TradeWorkflowSpecActionTypes.TradeWorkflowSpecUpdated,
  props<{ spec: TradeWorkflowSpec }>()
);

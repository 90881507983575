export class Place {
  constructor(
    public city: string,
    public country: string,
    public postalCode: string,
    public state: string,
    public street: string,
    public street1: string,
    public latitude: number | undefined = undefined,
    public longitude: number | undefined = undefined,
  ) {}
}

import { Injectable } from '@angular/core';
import { AccountService, SecurityItem, SecurityService, WideSearchComponent } from '@pinnakl/poems';
import { PinnaklSpinnerService } from '@pinnakl/shared/util-providers';

import { cloneDeep } from 'lodash';
import moment from 'moment';
import { EMPTY, forkJoin, from, Subscription } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { DialogService } from 'primeng/dynamicdialog';
import { PositionPopupComponent } from './positions/positions-ui/position-popup';

@Injectable()
export class NavBarService {
  private positionSummarySubscription: Subscription;

  constructor(
    private readonly dialogService: DialogService,
    private readonly accountService: AccountService,
    private readonly securityService: SecurityService,
    private readonly spinner: PinnaklSpinnerService
  ) {}

  searchModalOpen(closeCb?: (event: SecurityItem) => void): void {
    this.dialogService
      .open(WideSearchComponent, {
        width: '632px',
        height: '611px',
        contentStyle: { 'border-radius': '8px', padding: '16px' },
        showHeader: false
      })
      .onClose.pipe(filter(v => !!v?.event))
      .subscribe(result =>
        closeCb ? closeCb(result.event) : this.openPositionSummaryDialog(result.event)
      );
  }

  private openPositionSummaryDialog(item: SecurityItem): void {
    this.spinner.spin();
    if (this.positionSummarySubscription) {
      this.positionSummarySubscription.unsubscribe();
    }

    const accounts$ = from(this.accountService.getAccounts());

    const security$ = from(this.securityService.getSecurityBySecMarkedId(item.securityMarketId));

    this.positionSummarySubscription = forkJoin([accounts$, security$])
      .pipe(
        catchError(() => {
          this.spinner.stop();
          return EMPTY;
        })
      )
      .subscribe(result => {
        const [accounts, security] = result;

        this.dialogService
          .open(PositionPopupComponent, {
            contentStyle: { 'border-radius': '8px', padding: '16px' },
            showHeader: false,
            data: {
              accounts: cloneDeep(accounts),
              ticker: security.ticker,
              securityMarketId: item.securityMarketId,
              underlyingSecId: security.underlyingsecid,
              posDate: moment().format('MM/DD/YYYY')
            },
            width: '1100px'
          })
          .onClose.subscribe(type => {
            if (type === 'wide-modal') {
              this.searchModalOpen();
            }
          });
        this.spinner.stop();
      });
  }
}

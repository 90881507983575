import { Injectable } from '@angular/core';
import { AuthService } from '@pinnakl/auth/providers';
import { EventSourceHandlers } from '@pinnakl/shared/types';
import { PageSubscriptionsHandler } from '@pinnakl/shared/util-helpers';
import { Observable, Subject, Subscriber } from 'rxjs';
import { take } from 'rxjs/operators';

const DEFAULT_MAX_RECONNECT_COUNT = 100;
type MessageKey =
  | 'rtprice'
  | 'greeks'
  | 'rtportfoliostatus'
  | 'objectstore_actions'
  | 'order_quotes';
const MessageKeys: MessageKey[] = [
  'rtprice',
  'greeks',
  'rtportfoliostatus',
  'objectstore_actions',
  'order_quotes'
];

const MessagesTimeout: Record<MessageKey, number> = {
  rtportfoliostatus: 30,
  rtprice: 30,
  greeks: 30,
  objectstore_actions: 30,
  order_quotes: 30
};
const MessagesMaxReconnectCount: Record<MessageKey, number> = {
  rtportfoliostatus: 4,
  rtprice: 4,
  greeks: 4,
  objectstore_actions: 4,
  order_quotes: 4
};

const getKeyByUrl = (url: string): MessageKey | undefined => {
  for (const key of MessageKeys) {
    if (url.toLowerCase().includes(key)) {
      return key;
    }
  }
  return undefined;
};

@Injectable({
  providedIn: 'root'
})
export class EventSourceService {
  private readonly _lastMessageTime: Record<MessageKey | string, number> = {};
  private readonly _lastMessageTimers: Record<MessageKey | string, number | undefined> = {};
  private readonly _errorsMap = {};
  private readonly _eventSourcesMap = new Map<string, EventSource>();
  private readonly _unsubSourcesMap = new Map<string, boolean>();
  private readonly _keepAliveMessageType = 'keep-alive';
  private readonly _serverMessageMessageType = 'server-message';

  constructor(
    private readonly _pageSubscriptionsHandler: PageSubscriptionsHandler,
    private readonly authService: AuthService
  ) {}

  create<T>(url: string, handlers?: EventSourceHandlers): Subject<T> {
    const pageSubscriptionsEstablishedSub =
      this._pageSubscriptionsHandler?.pageSubscriptionsEstablishedSub;
    const pageSubscriptionsErroredSub = this._pageSubscriptionsHandler?.pageSubscriptionsErroredSub;
    const eventSubject = new Subject<T>();
    new Observable<T>(observer => {
      this.initializeErrorCounter(url);
      this.establishSubscription<T>(
        url,
        ev => {
          if (handlers?.onEstablish) {
            handlers.onEstablish();
          }
          pageSubscriptionsEstablishedSub?.next(true);
          this._eventSourcesMap.set(url, ev);
          this._errorsMap[url] = 0;
          const key = getKeyByUrl(url);
          if (key) {
            this._lastMessageTime[key] = Date.now();
            const time = MessagesTimeout[key];
            if (time) {
              this._lastMessageTimers[key] = window.setInterval(() => {
                if (this._unsubSourcesMap.get(url)) {
                  clearInterval(this._lastMessageTimers[key]);
                  this._lastMessageTimers[key] = undefined;
                  console.log(`Stop checking messages time delay due to unsubscribe. Url: ${url}`);
                  return;
                } else {
                  const lastMessageTime = this._lastMessageTime[key];
                  if (lastMessageTime && Math.abs(Date.now() - lastMessageTime) / 1000 > time) {
                    this.stopEventSource(url, handlers);
                    pageSubscriptionsErroredSub?.next(true);
                    console.error(`Too long time between messages for ${url}`);
                    observer.error(`Too long time between messages for ${url}`);
                    clearInterval(this._lastMessageTimers[key]);
                    this._lastMessageTimers[key] = undefined;
                  }
                }
              }, time * 1000);
            }
          }
        },
        observer,
        pageSubscriptionsErroredSub,
        handlers
      );
      return this.stopEventSource.bind(this, url, handlers);
    }).subscribe(eventSubject);

    return eventSubject;
  }

  stopEventSource(url: string, handlers?: EventSourceHandlers): void {
    this._unsubSourcesMap.set(url, true);
    const ev = this._eventSourcesMap.get(url);
    if (ev) {
      ev.close();
      this._eventSourcesMap.delete(url);
    }
    if (handlers?.onEstablish) {
      handlers.onEstablish(true);
    }
  }

  private establishSubscription<T>(
    url: string,
    onEstablish: (ev: EventSource) => void,
    observer: Subscriber<T>,
    errorSub?: Subject<boolean>,
    handlers?: EventSourceHandlers
  ): void {
    const token = this.getUserToken();
    const connectUrl = url.endsWith('?') ? url + `usertoken=${token}` : url + `&usertoken=${token}`;
    let eventSource: EventSource | null = new EventSource(connectUrl);

    const key = getKeyByUrl(connectUrl);
    if (key) {
      eventSource.addEventListener(this._keepAliveMessageType, (event: MessageEvent) => {
        try {
          const key = getKeyByUrl(url);
          if (key) {
            this._lastMessageTime[key] = Date.now();
          }
        } catch (e) {
          console.log('Something went wrong with keep alive event', event);
        }
      });

      eventSource.addEventListener(this._serverMessageMessageType, (event: MessageEvent) => {
        try {
          const key = getKeyByUrl(url);
          if (key) {
            this._lastMessageTime[key] = Date.now();
          }
          handlers?.onMessage?.();
          const data: T = JSON.parse(event.data);
          observer.next(data);
        } catch (e) {
          console.log('Something went wrong with parsing data', event);
        }
      });
    } else {
      eventSource.addEventListener('message', (event: MessageEvent) => {
        try {
          const data: T = JSON.parse(event.data);
          observer.next(data);
        } catch (e) {
          console.log('Something went wrong with parsing data', event);
        }
      });
    }

    eventSource.onopen = () => eventSource && onEstablish(eventSource);

    eventSource.onerror = err => {
      eventSource?.close();
      eventSource = null;
      this.onEventSourceError<T>(err, url, onEstablish, observer, errorSub, handlers);
    };
  }

  private initializeErrorCounter(url: string): void {
    this._errorsMap[url] = 0;
    this._unsubSourcesMap.set(url, false);
  }

  private onEventSourceError<T>(
    err: any,
    url: string,
    onEstablish: (ev: EventSource) => void,
    observer: Subscriber<T>,
    errorSub?: Subject<boolean>,
    handlers?: EventSourceHandlers
  ): void {
    const token = this.getUserToken();
    // https://stackoverflow.com/questions/59823336/how-to-get-status-code-when-using-eventsource-in-chrome
    // There is no way to get the error code to handle 401
    // So that we need to double-check token in local storage on each connection error
    if (token) {
      this._errorsMap[url] += 1;
      console.error(`Subscription to ${url} can't be established`, err);
      console.error(`Error count: ${this._errorsMap[url]}`);

      let streamMaxReconnectCount: number | undefined = undefined;
      const key = getKeyByUrl(url);
      if (key) {
        streamMaxReconnectCount = MessagesMaxReconnectCount[key];
      }
      if (this._errorsMap[url] <= (streamMaxReconnectCount ?? DEFAULT_MAX_RECONNECT_COUNT)) {
        if (handlers?.onEstablish && handlers?.onError) {
          handlers.onEstablish(true);
          handlers.onError(true);
        }
        console.error(
          `Subscription ${url} can't be established. Retry count ${this._errorsMap[url]}`
        );
        if (this._unsubSourcesMap.get(url)) {
          console.log(`Unsubscribed for ${url}. Stop reconnection`);
          return;
        } else {
          setTimeout(() => {
            this.establishSubscription(url, onEstablish, observer, errorSub, handlers);
          }, 4000);
        }
      } else {
        if (handlers?.onError) {
          handlers.onError();
        }
        errorSub?.next(true);
        console.error(`Subscription ${url} can't be established`);
        observer.error(`Subscription ${url} can't be established`);
      }
    } else {
      this.authService.localLogout();
    }
  }

  private getUserToken(): string | null {
    let token: string | null = null;
    this.authService
      .getAccessToken()
      .pipe(take(1))
      .subscribe(accessToken => (token = accessToken));
    return token;
  }
}

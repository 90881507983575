<p-button
  (onBlur)="onBlur.emit($event)"
  (onClick)="onClick.emit($event)"
  (onFocus)="onFocus.emit($event)"
  [autofocus]="autofocus"
  [badgeClass]="badgeClass"
  [badge]="badge"
  [disabled]="disabled"
  [iconPos]="iconPos"
  [icon]="icon"
  [label]="label"
  [link]="link"
  [loadingIcon]="loadingIcon"
  [loading]="loading"
  [outlined]="outlined"
  [plain]="plain"
  [raised]="raised"
  [rounded]="rounded"
  [severity]="severity"
  [styleClass]="classes$ | async"
  [style]="style"
  [tabindex]="tabindex"
  [text]="text"
  [type]="type"
  pAutoFocus
  pRipple
>
  <ng-container *ngIf="iconTemplate">
    <ng-template pTemplate="icon">
      <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="contentTemplate">
    <ng-template pTemplate="content">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </ng-template>
  </ng-container>
</p-button>

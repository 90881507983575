import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import moment from 'moment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BenchmarkIndexIntradayPricesApiService {
  private readonly indexIntradayPerformanceEndpoint = `${V3_ENDPOINT}index_intraday_performance`;
  private readonly accountIntradayPerformanceEndpoint = `${V3_ENDPOINT}account_intraday_performance`;

  constructor(private readonly wsp: WebServiceProvider) {}

  public loadIntradayPerformance<T>(
    type: 'index' | 'account',
    getLastFiveMinutes = false
  ): Observable<T[]> {
    let endpoint = '';
    switch (type) {
      case 'index':
        endpoint = this.indexIntradayPerformanceEndpoint;
        break;
      case 'account':
        endpoint = this.accountIntradayPerformanceEndpoint;
        break;
    }

    const optionsParams = getLastFiveMinutes
      ? {
          filters: [
            {
              key: 'startTime',
              type: 'EQ',
              value: [moment().subtract(6, 'minutes').utc().format('YYYY-MM-DD HH:mm:ss')]
            },
            {
              key: 'endTime',
              type: 'EQ',
              value: [moment().utc().format('YYYY-MM-DD HH:mm:ss')]
            }
          ]
        }
      : {};

    return this.wsp.get<T[]>({ endpoint, optionsParams });
  }
}

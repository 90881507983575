import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { InputMaskModule } from 'primeng/inputmask';
import { NoopValueAccessorDirective } from '../../directives';
import { ControlInjectedInputMaskComponent } from './control-injected-input-mask.component';

@UntilDestroy()
@Component({
  selector: 'prime-mask-input',
  templateUrl: './prime-input-mask.component.html',
  styleUrls: ['./prime-input-mask.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, InputMaskModule, ReactiveFormsModule],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  hostDirectives: [NoopValueAccessorDirective]
})
export class PrimeInputMaskComponent extends ControlInjectedInputMaskComponent {
  constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }
}

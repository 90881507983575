<div class="reconnect-window">
  <pinnakl-modal
    [hideDialog]="!reconnectWindowService.visible"
    [showCloseButton]="false"
    [width]="300"
    title="Reconnecting"
  >
    <div>
      Connection to the server was lost. Trying to re-establish connection..
      <div class="text-align-center">
        <img
          class="mt-3"
          src="/assets/images/spinner.gif"
        />
      </div>
    </div>
  </pinnakl-modal>
</div>

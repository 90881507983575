import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map, switchMap } from 'rxjs';
import { FolderCommentsApiService } from './folder-comments-api.service';
import {
  createFolderComment,
  deleteFolderComment,
  folderCommentCreated,
  folderCommentDeleted,
  folderCommentUpdated,
  folderCommentsLoaded,
  loadFolderComments,
  updateFolderComment
} from './folder-comments.actions';

@Injectable()
export class FolderCommentsEffects {
  loadFolderComments$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFolderComments),
      switchMap(({ id }) =>
        this.api
          .getFolderCommentsByFolderId(id)
          .pipe(map(comments => folderCommentsLoaded({ folderComments: { id, comments } })))
      )
    )
  );

  createFolderComment$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFolderComment),
      concatMap(({ folderComment }) => this.api.postFolderComment(folderComment)),
      map(folderComment => folderCommentCreated({ folderComment }))
    )
  );

  updateFolderComment$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFolderComment),
      concatMap(({ folderComment }) => this.api.putFolderComment(folderComment)),
      map(folderComment => folderCommentUpdated({ folderComment }))
    )
  );

  deleteFolderComment$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFolderComment),
      concatMap(({ id, folderId }) =>
        this.api.deleteFolderComment(id).pipe(map(() => folderCommentDeleted({ id, folderId })))
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: FolderCommentsApiService
  ) {}
}

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Security, SecuritySearchResult, SecurityV2 } from '@pinnakl/shared/types';
import _ from 'lodash';
import { BehaviorSubject, defer, iif, of, switchMap } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { addAlpha, getCurrentSymbol } from '../security-items';
import { SecurityService } from '../services';

type SelectorSecurity = SecuritySearchResult & { searchString: string; color: string };
const addParamIfExist = (initialString: string, param?: string): string =>
  param ? initialString + `${param} - ` : initialString;

@UntilDestroy()
@Component({
  selector: 'security-selector',
  templateUrl: 'security-selector.component.html',
  styleUrls: ['security-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecuritySelectorComponent implements OnInit {
  // We need select template variable to access tetx input in kendo combo box
  @ViewChild('select', { static: false }) select: any;

  addAlpha = addAlpha;
  getCurrentSymbol = getCurrentSymbol;

  @Input() assetType = '';
  @Input() isLocal = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() valueField = 'id';
  @Input() label = 'SECURITY';
  @Input() includeCheckForExpiration = false;
  @Input() control?: FormControl<SecurityV2 | Security>;
  selectorSecurities: SelectorSecurity[] = [];
  searchText$ = new BehaviorSubject('');
  isLoading$ = new BehaviorSubject(false);
  colors = ['#ba68c8', '#ffb74d', '#4dd0e1', '#673ab7'];
  selectorControl = new FormControl<SecuritySearchResult>(undefined);
  dropdownCollection$ = new BehaviorSubject<SelectorSecurity[]>([]);

  @Input() set prefetchedSecurities(securities: SecuritySearchResult[]) {
    this.dropdownCollection$.next(this.setParamsToData(securities));
  }

  constructor(private readonly securityService: SecurityService) {}

  @Input() additionalSecuritiesFilter: (sec: SecuritySearchResult) => boolean = () => true;

  ngOnInit(): void {
    this.handleDropdownSearching();
    this.handleControlInitialValue();
    this.handleSetValueOutside();
    this.handleSecuritySelectorControlChanges();
  }

  filterDropdown(text: string): void {
    this.searchText$.next(text);
    if (this.selectorSecurities?.length) {
      this.dropdownCollection$.next(
        this.selectorSecurities.filter(security =>
          security.searchString.toLowerCase().includes(text.toLowerCase())
        )
      );
    }
  }

  private handleSecuritySelectorControlChanges(): void {
    this.selectorControl.valueChanges
      .pipe(
        untilDestroyed(this),
        switchMap(security =>
          iif(
            () => this.isLocal,
            of(security),
            defer(() =>
              security?.id
                ? this.securityService.getSecurityBySecMarketIdV2(security.securityMarketId)
                : of(undefined)
            )
          )
        )
      )
      .subscribe(security => this.control.patchValue(security as SecurityV2 | Security));
  }

  private handleControlInitialValue(): void {
    const controlInitialValue = this.control?.value;
    if (controlInitialValue) {
      this.processSecurity(controlInitialValue);
    }
  }

  private handleSetValueOutside(): void {
    this.control?.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(this.processSecurity.bind(this));
  }

  private processSecurity(controlInitialValue: Security | SecurityV2): void {
    if ((controlInitialValue as SecurityV2)?.market) {
      this.processSecurityByModelType<SecurityV2>(
        controlInitialValue as SecurityV2,
        'securityV2ToSearchResult',
        security => security.market.id
      );
    } else if (controlInitialValue) {
      this.processSecurityByModelType<Security>(
        controlInitialValue as Security,
        'securityV1ToSearchResult',
        security => security.securityMarketId
      );
    } else {
      this.selectorControl.patchValue(undefined, { emitEvent: false });
    }
  }

  private handleDropdownSearching(): void {
    this.searchText$
      .pipe(
        filter(() => !this.isLocal),
        debounceTime(300),
        filter(text => text.length > 1),
        tap(() => this.isLoading$.next(true)),
        switchMap(text =>
          this.securityService.searchSecurities(
            text,
            this.assetType,
            [],
            this.includeCheckForExpiration
          )
        ),
        tap(() => this.isLoading$.next(false)),
        untilDestroyed(this)
      )
      .subscribe(data =>
        this.dropdownCollection$.next(
          this.setParamsToData(data.filter(this.additionalSecuritiesFilter))
        )
      );
  }

  private processSecurityByModelType<T>(
    securityOutside: T,
    securityModelMapperName: 'securityV1ToSearchResult' | 'securityV2ToSearchResult',
    securityMarketIdExtractor: (securityOutside: T) => number
  ): void {
    if (securityOutside) {
      const securityFromCollection = this.dropdownCollection$.value.find(
        security => security.securityMarketId === securityMarketIdExtractor(securityOutside)
      );

      if (securityFromCollection) {
        this.selectorControl.patchValue(securityFromCollection, { emitEvent: false });
      } else {
        const outsideSecurityInCollection = this.formatSecuritySearchResult(
          SecurityService[securityModelMapperName](securityOutside as any)
        );
        this.dropdownCollection$.next([
          ...this.dropdownCollection$.value,
          outsideSecurityInCollection
        ]);
        this.selectorControl.patchValue(outsideSecurityInCollection, { emitEvent: false });
      }
    } else {
      this.selectorControl.patchValue(undefined, { emitEvent: false });
    }
  }

  private setParamsToData(securities: SecuritySearchResult[]): SelectorSecurity[] {
    if (securities?.length) {
      this.selectorSecurities = _.cloneDeep(securities).map(security =>
        this.formatSecuritySearchResult(security)
      );
      return this.selectorSecurities;
    }
    return [];
  }

  private formatSecuritySearchResult(security: SecuritySearchResult): SelectorSecurity {
    return {
      ...security,
      color: this.colors[Math.floor(Math.random() * this.colors.length)],
      searchString:
        addParamIfExist(
          addParamIfExist(addParamIfExist('', security.assetType), security.ticker),
          security.cusip
        ) + security.description
    };
  }
}

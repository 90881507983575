import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RealtimePriceOverrideState } from './realtime-price-override.models';
import { realtimePriceOverrideSelectors } from './realtime-price-override.reducer';

const getPricingFeatureSelector = createFeatureSelector<{
  realtimePriceOverride: RealtimePriceOverrideState;
}>('pricing');

const getRealtimePriceOverrideSelector = createSelector(
  getPricingFeatureSelector,
  ({ realtimePriceOverride }) => realtimePriceOverride
);

const getRealtimePriceOverrideEntities = createSelector(
  getRealtimePriceOverrideSelector,
  ({ entities }) => entities
);

const getRealtimePriceOverrideBySecurityMarketId = (id: string | number) =>
  createSelector(getRealtimePriceOverrideSelector, ({ entities }) => entities[id]);

const getAllOverridesList = createSelector(
  getRealtimePriceOverrideSelector,
  realtimePriceOverrideSelectors.selectAll
);
const getAllOverridesMap = createSelector(
  getRealtimePriceOverrideSelector,
  realtimePriceOverrideSelectors.selectEntities
);

export const RealtimePriceOverrideQuery = {
  getRealtimePriceOverrideBySecurityMarketId,
  getRealtimePriceOverrideEntities,
  getAllOverridesMap,
  getAllOverridesList
};

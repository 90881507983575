import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';

import { Subscription } from 'rxjs';
import { DefaultConfirmActionService } from '../../services/';

@Component({
  selector: 'default-confirm-action',
  templateUrl: './default-confirm-action.component.html',
  styleUrls: ['./default-confirm-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultConfirmActionComponent implements OnDestroy, OnInit {
  private _subscription: Subscription;
  private readonly DEFAULT_MESSAGE = 'Are you sure you want to perform this action?';
  hideConfirmActionDialog = true;
  message: string;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly defaultConfirmActionService: DefaultConfirmActionService
  ) {}

  confirm(): void {
    this.defaultConfirmActionService.confirm();
    this.hideConfirmActionDialog = true;
    this.changeDetectorRef.detectChanges();
  }

  reject(): void {
    this.defaultConfirmActionService.reject();
    this.hideConfirmActionDialog = true;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this._subscription = this.defaultConfirmActionService.showCalled.subscribe(message =>
      this.show(message)
    );
  }

  private show(message?: string): void {
    message = message || this.DEFAULT_MESSAGE;
    this.message = message;
    this.showModal();
    this.changeDetectorRef.detectChanges();
  }

  private showModal(): void {
    this.hideConfirmActionDialog = false;
  }
}

interface CustomAssetValueFormatter {
  getFormattedValue: () => string;
}

/**
 * https://pinnakl.visualstudio.com/PinnaklFrontendApps/_workitems/edit/3854/
 * */
export class FutureAssetValueFormatter implements CustomAssetValueFormatter {
  private initialValueString = '';
  private tickerLowerCase = '';

  constructor(
    private readonly initialValue: number | string | null | undefined,
    private readonly ticker?: string | null,
    private readonly securitytype?: string
  ) {}

  getFormattedValue(): string {
    try {
      this.checkInputsValidity();
      this.setValues();

      const [integerValuePart, decimalValuePart] = this.initialValueString.split('.');
      if (this.returnInitialValue(decimalValuePart)) {
        return this.initialValueString;
      }

      const multipliedDecimal32 = this.getMultipliedDecimal(decimalValuePart, 32);
      const mainFormattedPart = this.getMainFormattedPart(
        integerValuePart,
        multipliedDecimal32.toString()
      );

      if (this.tickerStartWithUS() || this.tickerStartWithWN()) {
        return mainFormattedPart;
      }

      const [intPart, decPart] = multipliedDecimal32.toString().split('.');

      let multiplier: number | null = null;
      if (this.tickerStartWithTU() || this.securityTypeIsGOVT()) {
        multiplier = 4;
      } else if (this.tickerStartWithFV()) {
        multiplier = 2;
      } else if (this.tickerStartWithTY() || this.tickerStartWithUXY()) {
        multiplier = 1;
      }

      if (multiplier === null) {
        return this.initialValueString;
      }

      if (this.isDecimalPartInvalid(decPart)) {
        return mainFormattedPart;
      }

      const multipliedDecimal = this.getMultipliedDecimal(decPart, multiplier * 2);
      const secondFormattedPart = this.getSecondFormattedPart(multipliedDecimal, multiplier);
      return `${this.getMainFormattedPart(integerValuePart, intPart)} ${secondFormattedPart}`;
    } catch (e) {
      console.error('FutureAssetValueFormatter', e);
      return '';
    }
  }

  private isDecimalPartInvalid(decimalValue: string): boolean {
    return decimalValue == null || isNaN(+decimalValue);
  }

  private getMultipliedDecimal(decimalValue: string, multiplier: number): number {
    return +('0.' + decimalValue) * multiplier;
  }

  private tickerStartWithUS(): boolean {
    return this.tickerLowerCase.startsWith('us');
  }

  private tickerStartWithWN(): boolean {
    return this.tickerLowerCase.startsWith('wn');
  }

  private tickerStartWithTU(): boolean {
    return this.tickerLowerCase.startsWith('tu');
  }

  private tickerStartWithFV(): boolean {
    return this.tickerLowerCase.startsWith('fv');
  }

  private tickerStartWithTY(): boolean {
    return this.tickerLowerCase.startsWith('ty');
  }

  private tickerStartWithUXY(): boolean {
    return this.tickerLowerCase.startsWith('uxy');
  }

  private securityTypeIsGOVT(): boolean {
    return this.securitytype === 'GOVT';
  }

  private checkInputsValidity(): void {
    if (!this.ticker || typeof this.initialValue !== 'number' || isNaN(this.initialValue)) {
      throw new Error('Ticker or initialValue is not valid');
    }
  }

  private setValues(): void {
    this.initialValueString = this.initialValue?.toString() ?? '';
    this.tickerLowerCase = this.ticker?.toLowerCase() ?? '';
  }

  private getMainFormattedPart(firstPart: string, secondPart: string): string {
    return `${firstPart}-${secondPart}`;
  }

  private getSecondFormattedPart(valueToCheck: number, multiplier: number): string {
    return valueToCheck === multiplier ? '+' : `${valueToCheck}/${multiplier + multiplier}`;
  }

  private returnInitialValue(decimalValuePart: string): boolean {
    return (
      decimalValuePart == null ||
      isNaN(+decimalValuePart) ||
      (!this.securityTypeIsGOVT() &&
        !['us', 'wn', 'tu', 'fv', 'ty', 'uxy'].some(tickerStart =>
          this.tickerLowerCase.startsWith(tickerStart)
        ))
    );
  }
}

<kendo-grid
  (sortChange)="sortChange($event, folderComments.comments)"
  *ngIf="folderComments$ | async as folderComments; else loading"
  [data]="gridView"
  [sort]="sort"
  [sortable]="{ mode: 'single' }"
  [style.height.%]="100"
  [style.width.%]="100"
  class="folder-comments-table"
>
  <kendo-grid-column
    field="comment"
    title="Comment"
  ></kendo-grid-column>
  <kendo-grid-column
    field="authorName"
    title="Author name"
  ></kendo-grid-column>
  <kendo-grid-column
    field="timestamp"
    title="Timestamp"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      {{ dataItem.timestamp | utc | date: 'MMMM dd, y &nbsp;&nbsp; h:mm a' }}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
<ng-template #loading>
  <pinnakl-local-spinner></pinnakl-local-spinner>
</ng-template>

import { Injectable } from '@angular/core';
import {
  ErrorUIToastMessageCategory,
  InfoUIToastMessageCategory,
  PinnaklUIToastMessageAPI,
  SuccessUIToastMessageCategory,
  WarningUIToastMessageCategory
} from '@pinnakl/shared/types';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api/message';

@Injectable({
  providedIn: 'root'
})
export class UiToastMessageService implements PinnaklUIToastMessageAPI {
  add = this.messageService.add.bind(this.messageService);
  addAll = this.messageService.addAll.bind(this.messageService);
  clear = this.messageService.clear.bind(this.messageService);
  messageObserver = this.messageService.messageObserver;
  clearObserver = this.messageService.clearObserver;

  constructor(private readonly messageService: MessageService) {}

  error(
    msg: string,
    category: ErrorUIToastMessageCategory = ErrorUIToastMessageCategory.Error,
    options?: Message
  ): void {
    this.messageService.add({
      severity: 'error',
      summary: category,
      detail: msg,
      life: 10000,
      ...options
    });
  }

  info(
    msg: string,
    category: InfoUIToastMessageCategory = InfoUIToastMessageCategory.Info,
    options?: Message
  ): void {
    this.messageService.add({
      severity: 'info',
      summary: category,
      detail: msg,
      life: 10000,
      ...options
    });
  }

  success(
    msg: string,
    category: SuccessUIToastMessageCategory = SuccessUIToastMessageCategory.Success,
    options?: Message
  ): void {
    this.messageService.add({
      severity: 'success',
      summary: category,
      detail: msg,
      life: 5000,
      ...options
    });
  }

  warning(
    msg: string,
    category: WarningUIToastMessageCategory = WarningUIToastMessageCategory.Warning,
    options?: Message
  ): void {
    this.messageService.add({
      severity: 'warn',
      summary: category,
      detail: msg,
      life: 10000,
      ...options
    });
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressCircleComponent implements AfterViewInit {
  private readonly INVALID_COLOR = '#FF0000';
  private readonly CIRCLE_APPEARANCE_BOUNDARY = -10;
  @Input() diameter: number;
  @Input() strokeWidth = 2;
  @Input() strokeColor = '#168EFF';
  @Input() limit: number;
  @Input() current: number;
  @ViewChild('progressEl') progressEl: ElementRef;
  pathLength: number;

  get viewBox(): string {
    return `0 0 ${this.diameter} ${this.diameter}`;
  }

  get radius(): number {
    return this.diameter / 2 - this.strokeWidth / 2;
  }

  get offset(): number {
    if (!this.current || this.current < 0) {
      return this.pathLength;
    }
    if (this.current > this.limit) {
      return 0;
    }
    return this.pathLength - (this.current * this.pathLength) / this.limit;
  }

  get progressStroke(): string {
    return this.current > this.limit ? this.INVALID_COLOR : this.strokeColor;
  }

  get circleOpacity(): 1 | 0 {
    return this.limit - this.current <= this.CIRCLE_APPEARANCE_BOUNDARY ? 0 : 1;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.pathLength = Math.round(this.progressEl.nativeElement.getTotalLength());
    this.cdr.detectChanges();
  }
}

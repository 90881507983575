import { inject, Injectable } from '@angular/core';
import { BaseGrpcService } from './base-grpc.service';
import { grpcPricingToPricingItem } from './helpers';
import { PricingServiceClient } from './models/pricing.pbsc';
import { PricingItem, PricingResponse } from './pricing.model';

@Injectable({ providedIn: 'root' })
export class PricingGrpcService extends BaseGrpcService<PricingItem, PricingResponse> {
  override client = inject(PricingServiceClient);
  override transformFn = grpcPricingToPricingItem;
}

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SecurityPriceTimeItem } from '@pinnakl/poems/timeseries/domain';
import { Observable } from 'rxjs';
import { loadPricesTimeSeries } from './timeseries-prices.actions';
import { TimeseriesPricesState } from './timeseries-prices.models';
import { timeseriesPricesQuery } from './timeseries-prices.selectors';

@Injectable({
  providedIn: 'root'
})
export class TimeseriesPricesFacadeService {
  private readonly timeseriesPricesMap = new Map<
    string,
    Observable<SecurityPriceTimeItem[] | undefined>
  >();
  loading$ = this.store$.select(timeseriesPricesQuery.getPricesLoading);

  constructor(private readonly store$: Store<TimeseriesPricesState>) {}

  loadTimeseriesPricesBySecurityMarketId(securityMarketId: string, forceFetch = false): void {
    if (forceFetch || !this.timeseriesPricesMap.has(securityMarketId)) {
      this.store$.dispatch(loadPricesTimeSeries({ securityMarketId }));
    }
  }

  getTimeseriesPricesBySecurityMarketId(
    id: string
  ): Observable<SecurityPriceTimeItem[] | undefined> {
    let pricesStream = this.timeseriesPricesMap.get(id);

    if (pricesStream) {
      return pricesStream;
    }

    pricesStream = this.store$.pipe(select(timeseriesPricesQuery.getPricesBySecurityMarketId(id)));
    this.timeseriesPricesMap.set(id, pricesStream);

    return pricesStream;
  }
}

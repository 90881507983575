import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-cell-empty',
  template: ''
})
export class CellEmptyComponent implements AgRendererComponent {
  agInit(params: any): void {
    return;
  }

  refresh() {
    return false;
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FoldersState } from './folders.models';
import { foldersAdapter } from './folders.reducer';

export const featureSelectorName = 'folders';

const getFoldersStateSelector = createFeatureSelector<FoldersState>(featureSelectorName);

const { selectAll, selectEntities } = foldersAdapter.getSelectors();

const getFolders = createSelector(getFoldersStateSelector, selectAll);
const getFoldersEntities = createSelector(getFoldersStateSelector, selectEntities);
const getFoldersLoaded = createSelector(
  getFoldersStateSelector,
  ({ foldersLoaded }) => foldersLoaded
);
const getFoldersLoading = createSelector(
  getFoldersStateSelector,
  ({ foldersLoading }) => foldersLoading
);
const getFolderData = createSelector(
  getFoldersStateSelector,
  ({ customFolderData }) => customFolderData
);

const getSelectedFolderId = createSelector(
  getFoldersStateSelector,
  ({ selectedFolderId }) => selectedFolderId
);
const getSelectedFolder = createSelector(
  getFoldersEntities,
  getSelectedFolderId,
  (entities, selectedFolderId) => (selectedFolderId ? (entities[selectedFolderId] ?? null) : null)
);

const getCreateFolderStatus = createSelector(
  getFoldersStateSelector,
  ({ createFolderStatus }) => createFolderStatus
);
const getUpdateFolderStatus = createSelector(
  getFoldersStateSelector,
  ({ updateFolderStatus }) => updateFolderStatus
);
const getDeleteFolderStatus = createSelector(
  getFoldersStateSelector,
  ({ deleteFolderStatus }) => deleteFolderStatus
);
const getDeleteAllowedFolderStatus = createSelector(
  getFoldersStateSelector,
  ({ deleteAllowedFolderStatus }) => deleteAllowedFolderStatus
);

export const foldersQuery = {
  getFolders,
  getFolderData,
  getFoldersLoaded,
  getFoldersLoading,
  getSelectedFolderId,
  getSelectedFolder,
  getCreateFolderStatus,
  getUpdateFolderStatus,
  getDeleteFolderStatus,
  getDeleteAllowedFolderStatus
};

import { InjectionToken } from '@angular/core';
import { IDrawerConfig } from './interfaces/drawer-inputs.interface';

export const Document = new InjectionToken('document');

export const defaultConfig: IDrawerConfig = {
  autosize: false,
  hasBackdrop: false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  backdropClick: () => this.close(),
  mode: 'side',
  autoFocus: false,
  disableClose: false,
  opened: false,
  position: 'end'
};

import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { PmsAccountCashCombinedItem, PmsPosition, PmsSummaryWidgetData } from '@pinnakl/pms/domain';
import { isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import {
  loadPositions,
  reloadPositions,
  saveKeysMap,
  setCalculatedAllPositions,
  setCalculatedCashBalance,
  setCalculatedFilteredByAccountSummaryData,
  setCalculatedNavCurrentMap,
  setPinnedData,
  updateManyPositions,
  updatePosition
} from './positions.actions';
import { PositionsKeysMap, PositionsState } from './positions.models';
import { positionsQuery } from './positions.selectors';

@Injectable({
  providedIn: 'root'
})
export class PmsPositionsFacadeService {
  private _excludeSecurities$: BehaviorSubject<boolean>;
  /**
   * Has to be inside subject as it is used in some ag grid handlers
   * and data is changed inside so data from store can't be used due to immutability rule
   * @private
   */
  private _calculatedDataForGrid$: BehaviorSubject<PmsPosition[] | null>;
  // Security exclusion for certain specific client. Will be removed in future
  static SecuritiesMarketIdToExclude = [110175, 110176];
  pinnedData$ = this.store$
    .select(positionsQuery.getPinnedData)
    .pipe(filter(isNeitherNullNorUndefined));
  calculatedCashBalance$ = this.store$
    .select(positionsQuery.getCalculatedCashBalance)
    .pipe(filter(isNeitherNullNorUndefined));
  navCurrentMap$ = this.store$
    .select(positionsQuery.getNavCurrentMap)
    .pipe(filter(isNeitherNullNorUndefined));
  calculatedFilteredByAccountSummaryData$ = this.store$
    .select(positionsQuery.getCalculatedFilteredByAccountSummaryData)
    .pipe(filter(isNeitherNullNorUndefined));
  definedCalculatedDataForGrid$: Observable<PmsPosition[]>;
  calculatedDataForGrid$: Observable<PmsPosition[] | null>;
  positions$: Observable<PmsPosition[]>;
  // Security exclusion for certain specific client. Will be removed in future
  readonly excludeSecurities$: Observable<boolean>;

  constructor(
    private readonly store$: Store<PositionsState>,
    private readonly toastService: PinnaklUIToastMessage
  ) {
    // Security exclusion for certain specific client. Will be removed in future
    this._excludeSecurities$ = new BehaviorSubject<boolean>(false);
    this.excludeSecurities$ = this._excludeSecurities$.asObservable().pipe(distinctUntilChanged());

    this._calculatedDataForGrid$ = new BehaviorSubject<PmsPosition[] | null>(null);
    this.calculatedDataForGrid$ = this._calculatedDataForGrid$.asObservable().pipe(
      // Security exclusion for certain specific client. Will be removed in future
      withLatestFrom(this.excludeSecurities$),
      map(([positions, excludeSecurities]) =>
        excludeSecurities && positions?.length
          ? // Security exclusion for certain specific client. Will be removed in future
            positions.filter(
              ({ securitymarketid }) =>
                !PmsPositionsFacadeService.SecuritiesMarketIdToExclude.includes(securitymarketid)
            )
          : positions
      )
    );
    this.definedCalculatedDataForGrid$ = this.calculatedDataForGrid$.pipe(
      filter(isNeitherNullNorUndefined)
    );

    this.positions$ = combineLatest([
      this.store$.select(positionsQuery.getPositions).pipe(filter(isNeitherNullNorUndefined)),
      // Security exclusion for certain specific client. Will be removed in future
      this.excludeSecurities$
    ]).pipe(
      map(([positions, excludeSecurities]) =>
        excludeSecurities
          ? // Security exclusion for certain specific client. Will be removed in future
            positions.filter(
              ({ securitymarketid }) =>
                !PmsPositionsFacadeService.SecuritiesMarketIdToExclude.includes(securitymarketid)
            )
          : positions
      )
    );
  }

  loadPositions(): void {
    this.store$.dispatch(loadPositions());
  }

  reloadPositions(): void {
    this.store$.dispatch(reloadPositions());
  }

  updateManyPositions(changes: Update<PmsPosition>[]) {
    this.store$.dispatch(updateManyPositions({ changes }));
  }

  setCalculatedGridPositions(positions: PmsPosition[]) {
    this._calculatedDataForGrid$.next(positions);
  }

  updateCalculatedGridPosition(id: string, changes: Partial<PmsPosition>) {
    this._calculatedDataForGrid$.next(
      this._calculatedDataForGrid$.value?.map(i => {
        return id === i.id ? { ...i, ...changes } : { ...i };
      }) ?? null
    );
  }

  updatePosition(id: string, changes: Partial<PmsPosition>) {
    this.store$.dispatch(updatePosition({ id, changes }));
  }

  updatePositionBySecurityMarketId(
    securityMarketId: number,
    changesToUpdate: Partial<PmsPosition>
  ) {
    this.positions$.pipe(take(1)).subscribe(positions => {
      const positionsToUpdate = positions.filter(
        position => position.securitymarketid === securityMarketId
      );
      if (positionsToUpdate.length) {
        this.updateManyPositions(
          positionsToUpdate.map(position => ({
            id: position.id ?? '',
            changes: changesToUpdate
          }))
        );
      } else {
        this.toastService.warning('Position not found');
      }
    });
  }

  saveKeysMap(keysMap: PositionsKeysMap) {
    this.store$.dispatch(saveKeysMap({ keysMap }));
  }

  setPinnedData(pinnedData: PmsPosition[]) {
    this.store$.dispatch(setPinnedData({ pinnedData }));
  }

  setCalculatedAllPositions(positions: PmsPosition[]): void {
    this.store$.dispatch(setCalculatedAllPositions({ positions }));
  }

  setCalculatedFilteredByAccountSummaryData(summaryData: PmsSummaryWidgetData): void {
    this.store$.dispatch(setCalculatedFilteredByAccountSummaryData({ summaryData }));
  }

  setCalculatedCashBalance(cashBalance: PmsAccountCashCombinedItem[]): void {
    this.store$.dispatch(setCalculatedCashBalance({ cashBalance }));
  }

  setCalculatedNavCurrentMap(navCurrentMap: Record<string, number>): void {
    this.store$.dispatch(setCalculatedNavCurrentMap({ navCurrentMap }));
  }

  // Security exclusion for certain specific client. Will be removed in future
  setExcludeSecurities(value: boolean): void {
    this._excludeSecurities$.next(value);
  }

  // Security exclusion for certain specific client. Will be removed in future
  getExcludeSecurities(): boolean {
    return this._excludeSecurities$.value;
  }
}

import { Injectable } from '@angular/core';
import { IHtmlHelperService } from '@pinnakl/shared/types';

@Injectable({
  providedIn: 'root'
})
export class HtmlHelperService implements IHtmlHelperService {
  private readonly HTML_TAG_REGEXP = /(<\/?[a-zA-Z]+[\s a-zA-Z0-9="'-;:%]*[^<]*>)/g; // <p style="align-items: center;">
  private readonly HTML_COMMENT_REGEXP = /<![(?!-->)\r?\n].*-->/s;
  private readonly HTML_ENTITIES_REGEXP = /&[\w]+;/g;
  // Comment example:
  // <!-- [if gte mso 9]>
  //   <xml>
  //     <o:shapedefaults v:ext="edit" spidmax="1026" />
  //   </xml>
  // <![endif]-->
  private readonly HTML_TAGNAME_REGEXP = /<\/?([a-zA-Z0-9]+)[\sa-zA-Z0-9="'-_:;%]*>/;

  getPlainText(html: string, withSpaces = false): string {
    return html
      .replace(this.HTML_COMMENT_REGEXP, '')
      .replace(this.HTML_ENTITIES_REGEXP, ' ')
      .split(this.HTML_TAG_REGEXP)
      .filter(text => !this.isTag(text))
      .map(text => {
        if (withSpaces) return text;
        return text.trim();
      })
      .join('');
  }

  isTag(text: string): boolean {
    return text[0] === '<';
  }

  getTagName(tag: string): string {
    return tag.replace(this.HTML_TAGNAME_REGEXP, '$1');
  }

  findClosingTagIndex(list: string[], openedTagIndex: number): number {
    const name = this.getTagName(list[openedTagIndex]);

    // The regex for closing matching tag
    const closingTagRegex = new RegExp(`</ *${name} *>`, 'g');

    // The regex for matching tag (in case there are inner scoped same tags, we want to pass those)
    const sameTagRegex = new RegExp(`< *${name}[\\sa-zA-Z0-9="'-_:;]*>`, 'g');

    // Will count how many tags with the same name are in an inner hierarchy level, we need to pass those
    let sameTagsInsideCount = 0;
    for (let j = openedTagIndex + 1; j < list.length; j++) {
      if (list[j].match(sameTagRegex) !== null) sameTagsInsideCount++;
      if (list[j].match(closingTagRegex) !== null) {
        if (sameTagsInsideCount > 0) sameTagsInsideCount--;
        else {
          return j;
        }
      }
    }
    return -1;
  }

  trimHtmlContent(html: string, limit: number): string {
    let trimmed = '';
    const innerItems = html.split(this.HTML_TAG_REGEXP);
    for (let i = 0; i < innerItems.length; i++) {
      const item = innerItems[i];
      const trimmedTextLength = this.getPlainText(trimmed).length;
      if (this.isTag(item)) {
        const closingTagIndex = this.findClosingTagIndex(innerItems, i);
        if (closingTagIndex === -1) {
          trimmed = trimmed + item;
        } else {
          const innerHtml = innerItems.slice(i + 1, closingTagIndex).join('');
          trimmed =
            trimmed +
            item +
            this.trimHtmlContent(innerHtml, limit - trimmedTextLength) +
            innerItems[closingTagIndex];

          i = closingTagIndex;
        }
      } else {
        if (trimmedTextLength + item.length > limit) {
          trimmed = trimmed + item.slice(0, limit - trimmedTextLength);
          return trimmed;
        } else {
          trimmed = trimmed + item;
        }
      }
    }
    return trimmed;
  }
}

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { PnklChartsModule } from '@pinnakl/charts';
import { HTTP_SERVICE_URL } from '@pinnakl/shared/constants';
import { DirectivesModule } from '@pinnakl/shared/ui/directives-and-pipes';
import { GoogleMapsAppModule } from '@pinnakl/shared/util-external-services';
import { ChartModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ComboBoxModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import {
  DefaultConfirmActionComponent,
  NameAvatarComponent,
  ReconnectWindowComponent,
  RouteResolverComponent,
  TableColumnsSelectorComponent
} from './components';
import { CellEmptyComponent } from './components/common-ag-grid-cells/app-cell-empty/app-cell-empty.component';
import { FundBenchmarkComparisonChartComponent } from './components/fund-benchmark-comparison-chart/fund-benchmark-comparison-chart.component';
import { FundBenchmarkComparisonStatsComponent } from './components/fund-benchmark-comparison-stats/fund-benchmark-comparison-stats.component';
import { FundPerformanceRawDataComponent } from './components/fund-performance-raw-data/fund-performance-raw-data.component';
import { FilterPresetsComponent } from './components/grid-filter/filter-presets/filter-presets.component';
import { GridFilterComponent } from './components/grid-filter/grid-filter.component';
import { PinnaklGridToggleComponent } from './components/pinnakl-grid-toggle';
import { PinnaklInputModule } from './components/pinnakl-input/pinnakl-input.module';
import { PinnaklPageComponent } from './components/pinnakl-page/pinnakl-page.component';
import { ReconnectWindowService } from './components/reconnect-window/reconnect-window.service';
import { RichTextAreaComponent } from './components/rich-text-area/rich-text-area.component';
import { ConfirmActionModule } from './confirm-module/confirm-action.module';
import { SharedComponentsModule } from './pinnakl-grid-module';
import { PinnaklModalModule } from './pinnakl-modal';
import { CropTextPipe, MemoizeFunction, ShortNumberPipe, UtcDatePipe } from './pipes';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { IterateOverObjectPipe } from './pipes/iterate-over-object.pipe';
import { LimitDecimalPipe } from './pipes/limit-decimal.pipe';
import { NumberWithCommasPipe } from './pipes/number-with-commas.pipe';
import { PnklFilterPipe } from './pipes/pnkl-filter.pipe';

const EXPORT_COMPONENTS = [
  // ConfirmActionComponent,
  CellEmptyComponent,
  ReconnectWindowComponent,
  DefaultConfirmActionComponent,
  FilterPresetsComponent,
  GridFilterComponent,
  TableColumnsSelectorComponent,
  FundPerformanceRawDataComponent,
  FundBenchmarkComparisonChartComponent,
  FundBenchmarkComparisonStatsComponent,
  PinnaklPageComponent,
  RichTextAreaComponent,
  NameAvatarComponent,
  RouteResolverComponent,
  PinnaklGridToggleComponent
];

const EXPORT_PIPES = [
  CropTextPipe,
  PnklFilterPipe,
  NumberWithCommasPipe,
  LimitDecimalPipe,
  IterateOverObjectPipe,
  CurrencySymbolPipe,
  ShortNumberPipe,
  UtcDatePipe,
  MemoizeFunction
];

@NgModule({
  declarations: [...EXPORT_COMPONENTS, ...EXPORT_PIPES],
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule,
    GoogleMapsAppModule,
    EditorModule,
    SortableModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    PinnaklModalModule,
    SharedComponentsModule,
    MatMenuModule,
    PnklChartsModule,
    ComboBoxModule,
    MultiSelectModule,
    DateInputsModule,
    RouterModule,
    ConfirmActionModule,
    TooltipModule,
    DirectivesModule,
    PinnaklInputModule
  ],
  providers: [
    ReconnectWindowService,
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: 'tinymce/tinymce.min.js'
    }
  ],
  exports: [
    ...EXPORT_COMPONENTS,
    ...EXPORT_PIPES,

    ConfirmActionModule,
    TooltipModule,
    ChartModule,
    PinnaklModalModule,
    EditorModule,
    SortableModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatMenuModule,
    MultiSelectModule,
    DirectivesModule,
    PinnaklInputModule
  ]
})
export class SharedUiModule {
  public static register(tradingServiceUrl: string): ModuleWithProviders<SharedUiModule> {
    return {
      ngModule: SharedUiModule,
      providers: [
        {
          provide: HTTP_SERVICE_URL,
          useValue: tradingServiceUrl
        }
      ]
    };
  }
}

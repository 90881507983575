import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedUiModule } from '@pinnakl/shared-ui';
import {
  InputContainerComponent,
  InputValidationComponent,
  LabelContentComponent,
  PrimeInputMaskComponent,
  PrimeInputTextComponent
} from '@pinnakl/shared/ui/prime';
import { SharedFilterModule } from '@progress/kendo-angular-grid';
import { QrCodeModule } from 'ng-qrcode';
import { ChangePasswordFormComponent } from './change-password/change-password-form/change-password-form.component';
import { ChangePasswordPageComponent } from './change-password/change-password-page/change-password-page.component';
import { ChangePasswordModalComponent } from './modals/change-password-modal/change-password-modal.component';
import { RegisterNewUserModalComponent } from './modals/register-new-user-modal/register-new-user-modal.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { AuthPopupService } from './services';

@NgModule({
  declarations: [
    ChangePasswordPageComponent,
    ChangePasswordFormComponent,
    ChangePasswordModalComponent,
    RegisterNewUserModalComponent,
    QrCodeComponent
  ],
  imports: [
    ReactiveFormsModule,
    // SessionManagementModule,
    SharedFilterModule,
    QrCodeModule,
    SharedUiModule,
    InputContainerComponent,
    InputValidationComponent,
    LabelContentComponent,
    PrimeInputMaskComponent,
    PrimeInputTextComponent
  ],
  exports: [ChangePasswordFormComponent, ChangePasswordPageComponent, QrCodeComponent],
  providers: [AuthPopupService]
})
export class AuthenticationModule {}

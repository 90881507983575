import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

@Injectable()
export class GridColumnsManager<T> {
  private _allColumns$ = new BehaviorSubject<T[]>([]);
  private _selectedColumns$ = new BehaviorSubject<T[]>([]);
  private _selectedColumnsSortConfig$ = new BehaviorSubject<{
    sortField?: string;
    sortPredicate?: (columnA: T, columnB: T) => -1 | 0 | 1;
  } | null>(null);
  private _selectedColumnsFilters$ = new BehaviorSubject<
    ((currentColumn, allColumns) => boolean)[]
  >([]);
  private _unselectedColumns$ = new BehaviorSubject<T[]>([]);
  private _unselectedColumnsSortConfig$ = new BehaviorSubject<{
    sortField?: string;
    sortPredicate?: (columnA: T, columnB: T) => -1 | 0 | 1;
  } | null>(null);
  private _unselectedColumnsFilters$ = new BehaviorSubject<
    ((currentColumn, allColumns) => boolean)[]
  >([]);

  public allColumns$ = this._allColumns$.asObservable();
  public selectedColumns$ = this._selectedColumns$.asObservable();
  public unselectedColumns$ = this._unselectedColumns$.asObservable();

  set selectedColumnsFilters(filters: ((currentColumn: T, allColumns: T[]) => boolean)[]) {
    if (Array.isArray(filters)) {
      this._selectedColumnsFilters$.next(filters);
    }
  }

  set selectedColumnsSortConfig(
    sortConfig: {
      sortField?: string;
      sortPredicate?: (columnA: T, columnB: T) => -1 | 0 | 1;
    } | null
  ) {
    this._selectedColumnsSortConfig$.next(sortConfig);
  }

  set unselectedColumnsFilters(filters: ((currentColumn: T, allColumns: T[]) => boolean)[]) {
    if (Array.isArray(filters)) {
      this._unselectedColumnsFilters$.next(filters);
    }
  }

  set unselectedColumnsSortConfig(
    sortConfig: {
      sortField?: string;
      sortPredicate?: (columnA: T, columnB: T) => -1 | 0 | 1;
    } | null
  ) {
    this._unselectedColumnsSortConfig$.next(sortConfig);
  }

  get processedSelectedColumns$(): Observable<T[]> {
    return combineLatest([
      this._selectedColumns$,
      this._selectedColumnsSortConfig$,
      this._selectedColumnsFilters$
    ]).pipe(
      map(([selectedColumns, sortConfig, filters]) => {
        const filteredSelectedColumns: T[] = filters.length
          ? selectedColumns.filter(column =>
              filters.every(filter => filter(column, this._allColumns$.value))
            )
          : selectedColumns;

        if (sortConfig?.sortField) {
          const fieldName = sortConfig.sortField as string;
          return ([...filteredSelectedColumns] as []).sort(
            ({ [fieldName]: sortA }, { [fieldName]: sortB }) => (sortA < sortB ? -1 : 1)
          );
        } else if (sortConfig?.sortPredicate) {
          return [...filteredSelectedColumns].sort(sortConfig.sortPredicate);
        }
        return filteredSelectedColumns;
      })
    );
  }

  get processedUnselectedColumns$(): Observable<T[]> {
    return combineLatest([
      this._unselectedColumns$,
      this._unselectedColumnsSortConfig$,
      this._unselectedColumnsFilters$
    ]).pipe(
      map(([unselectedColumns, sortConfig, filters]) => {
        const filteredUnselectedColumns: T[] = filters.length
          ? unselectedColumns.filter(column =>
              filters.every(filter => filter(column, this._allColumns$.value))
            )
          : unselectedColumns;

        if (sortConfig?.sortField) {
          const fieldName = sortConfig.sortField as string;
          return ([...filteredUnselectedColumns] as []).sort(
            ({ [fieldName]: sortA }, { [fieldName]: sortB }) => (sortA < sortB ? -1 : 1)
          );
        } else if (sortConfig?.sortPredicate) {
          return [...filteredUnselectedColumns].sort(sortConfig.sortPredicate);
        }
        return filteredUnselectedColumns;
      })
    );
  }

  getSelectedColumns(): T[] {
    return this._selectedColumns$.value;
  }

  /**
   * Method to set up all columns, selected columns and unselected columns
   * @param allColumns
   * @param selectedColumns
   * @param differentiateRule predicate for marking column as unselected
   * (columnA, columnB) => return true if columns ARE equal
   * for example `return columnA.id === columnB.id`
   */
  setConfiguration(
    allColumns: T[],
    selectedColumns?: T[],
    differentiateRule?: (columnA: T, columnB: T) => boolean
  ): void {
    this._allColumns$.next(allColumns);

    if (selectedColumns) {
      this._selectedColumns$.next(selectedColumns);
      if (differentiateRule) {
        this._unselectedColumns$.next(
          allColumns.filter(
            column =>
              !selectedColumns.some(selectedColumn => differentiateRule(column, selectedColumn))
          )
        );
      } else {
        this._unselectedColumns$.next(
          allColumns.filter(column => !selectedColumns.includes(column))
        );
      }
    } else {
      this._selectedColumns$.next(allColumns);
      this._unselectedColumns$.next([]);
    }
  }

  setSelectedColumns(selectedColumns: T[]): void {
    this._selectedColumns$.next(selectedColumns);
  }

  selectAllColumns(): void {
    this.setConfiguration(this._allColumns$.value);
  }

  selectColumn(column?: T, predicate?: (column) => boolean): void {
    if (column) {
      this._unselectedColumns$.next(
        this._unselectedColumns$.value.filter(unselectedColumn => unselectedColumn !== column)
      );
      this._selectedColumns$.next([...this._selectedColumns$.value, column]);
    } else if (predicate) {
      this._unselectedColumns$.next(
        this._unselectedColumns$.value.filter(column => !predicate(column))
      );
      const columnToSelect = this._allColumns$.value.find(column => predicate(column));
      if (columnToSelect) {
        this._selectedColumns$.next([...this._selectedColumns$.value, columnToSelect]);
      } else {
        console.error('There is no column to select from all column by provided condition');
      }
    } else {
      console.error('No provided column to select');
    }
  }

  deselectColumn(column?: T, predicate?: (column) => boolean): void {
    if (column) {
      this._selectedColumns$.next(
        this._selectedColumns$.value.filter(selectedColumn => selectedColumn !== column)
      );
      this._unselectedColumns$.next([...this._unselectedColumns$.value, column]);
    } else if (predicate) {
      this._selectedColumns$.next(
        this._selectedColumns$.value.filter(column => !predicate(column))
      );
      const columnToDeselect = this._allColumns$.value.find(column => predicate(column));
      if (columnToDeselect) {
        this._unselectedColumns$.next([...this._unselectedColumns$.value, columnToDeselect]);
      } else {
        console.error('There is no column to deselect from all column by provided condition');
      }
    } else {
      console.error('No provided column to deselect');
    }
  }
}

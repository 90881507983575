<div>
  <editor
    (ngModelChange)="valueChanged($event)"
    [(ngModel)]="value"
    [disabled]="disableEditor"
    [init]="setInitParamsTextEditor()"
    (onInit)="onInit($event)"
    apiKey="jla9yxxlur1v30svavxt0q4h97emlc2rab2f0ys7yq7hv6eu"
  ></editor>
  <p
    *ngIf="isLocalPhoto"
    class="error-paste-photo"
  >
    It is recommended that images should be added from a File server or a CDN server for performance
    reasons.
  </p>
</div>

<pinnakl-modal
  (closeModal)="reject()"
  [hideDialog]="hideConfirmActionDialog"
>
  <div>
    <div class="modal-header"><h5>Confirm Action</h5></div>
    <div class="modal-body">{{ message }}</div>
    <div class="modal-footer">
      <button
        (click)="confirm()"
        class="btn btn-primary"
        type="button"
      >
        YES
      </button>
      <button
        (click)="reject()"
        class="btn btn-secondary"
        type="button"
      >
        NO
      </button>
    </div>
  </div>
</pinnakl-modal>

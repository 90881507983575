import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SecuritiesDataAccessModule } from '@pinnakl/securities/data-access';
import { SecurityDetailsModalFacadeService } from './security-details-modal-facade.service';
import { SecurityDetailsModalDataProcessor } from './security-details-modal.data-processor';
import { SecurityDetailsModalEffects } from './security-details-modal.effects';
import { initialState, securityDetailsModalReducer } from './security-details-modal.reducer';
import { featureSelectorName } from './security-details-modal.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureSelectorName, securityDetailsModalReducer, {
      initialState
    }),
    EffectsModule.forFeature([SecurityDetailsModalEffects]),
    SecuritiesDataAccessModule
  ],
  providers: [SecurityDetailsModalFacadeService, SecurityDetailsModalDataProcessor]
})
export class SecurityDetailsModalDataAccessModule {}

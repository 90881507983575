import { Component } from '@angular/core';
import { ReconnectWindowService } from './reconnect-window.service';

@Component({
  selector: 'reconnect-window',
  templateUrl: 'reconnect-window.component.html'
})
export class ReconnectWindowComponent {
  constructor(public reconnectWindowService: ReconnectWindowService) {}
}

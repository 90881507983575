import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  standalone: true
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: string, args: any): string {
    if (!args) {
      return value;
    }
    const re = new RegExp(args, 'gi');
    return value ? value.replace(re, '<mark>$&</mark>') : '';
  }
}

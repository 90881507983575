import { createAction, props } from '@ngrx/store';
import { PmsRebalanceConfig } from '@pinnakl/pms/domain';

export enum RebalanceConfigActionTypes {
  LoadRebalanceConfig = '[Pms Rebalance Config] Load rebalance config',
  loadRebalanceConfigFailure = '[Pms Rebalance Config] Load rebalance config error',
  SetRebalanceConfig = '[Pms Rebalance Config] Set rebalance config'
}

export const loadRebalanceConfig = createAction(RebalanceConfigActionTypes.LoadRebalanceConfig);
export const loadRebalanceConfigFailure = createAction(
  RebalanceConfigActionTypes.loadRebalanceConfigFailure,
  props<{ error: any }>()
);
export const setRebalanceConfig = createAction(
  RebalanceConfigActionTypes.SetRebalanceConfig,
  props<{ rebalanceConfig: PmsRebalanceConfig[] }>()
);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { TimeseriesPositionsFacadeService } from './positions';
import { TimeseriesPositionsApiService } from './positions/timeseries-positions-api.service';
import { TimeseriesPositionsEffects } from './positions/timeseries-positions.effects';
import { TimeseriesPositionsState } from './positions/timeseries-positions.models';
import { timeseriesPositionsReducer } from './positions/timeseries-positions.reducer';
import { TimeseriesPricesFacadeService } from './prices';
import { TimeseriesPricesApiService } from './prices/timeseries-prices-api.service';
import { TimeseriesPricesEffects } from './prices/timeseries-prices.effects';
import { TimeseriesPricesState } from './prices/timeseries-prices.models';
import { timeseriesPricesReducer } from './prices/timeseries-prices.reducer';

const featureName = 'timeseries';

export interface State {
  prices: TimeseriesPricesState;
  positions: TimeseriesPositionsState;
}

export const reducers: ActionReducerMap<State> = {
  prices: timeseriesPricesReducer,
  positions: timeseriesPositionsReducer
};

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([TimeseriesPositionsEffects, TimeseriesPricesEffects]),
    StoreModule.forFeature(featureName, reducers)
  ],
  providers: [
    TimeseriesPositionsApiService,
    TimeseriesPricesApiService,
    TimeseriesPositionsFacadeService,
    TimeseriesPricesFacadeService
  ]
})
export class TimeseriesDataAccessModule {}

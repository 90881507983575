import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TradeWorkflowSpecState } from './trade-workflow-spec.models';

export const featureSelectorName = 'trade_workflow_spec';

const getTradeWorkflowSpecStateSelector =
  createFeatureSelector<TradeWorkflowSpecState>(featureSelectorName);

const getTradeWorkflowSpec = createSelector(getTradeWorkflowSpecStateSelector, ({ spec }) => spec);
const getTradeWorkflowSpecLoading = createSelector(
  getTradeWorkflowSpecStateSelector,
  ({ loading }) => loading
);
const getTradeWorkflowSpecLoaded = createSelector(
  getTradeWorkflowSpecStateSelector,
  ({ loaded }) => loaded
);

export const tradeWorkflowSpecQuery = {
  getTradeWorkflowSpec,
  getTradeWorkflowSpecLoading,
  getTradeWorkflowSpecLoaded
};

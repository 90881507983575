import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommonDataState } from './common-data.model';

export const featureSelectorName = 'poems-common-data';

const getCommonDataStateSelector = createFeatureSelector<CommonDataState>(featureSelectorName);

const getFxRates = createSelector(getCommonDataStateSelector, ({ fxRates }) => fxRates);
const getCurrencies = createSelector(getCommonDataStateSelector, ({ currencies }) => currencies);
const getCurrenciesLoading = createSelector(
  getCommonDataStateSelector,
  ({ isCurrenciesLoading }) => isCurrenciesLoading
);
const getCurrenciesLoaded = createSelector(
  getCommonDataStateSelector,
  ({ isCurrenciesLoaded }) => isCurrenciesLoaded
);

export const CommonDataQuery = {
  getFxRates,
  getCurrencies,
  getCurrenciesLoading,
  getCurrenciesLoaded
};

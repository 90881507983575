import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { TradeWorkflowSpecFacadeService } from '@pinnakl/poems/trade-workflow-spec/data-access';
import { map } from 'rxjs/operators';
import { AccessBaseUrls, AccessFields, PageVersions } from './check-page-versions-access.guard';

export const checkPageCertainVersionAccessGuard =
  (accessField: AccessFields, pageVersion: PageVersions, baseUrlToNavigate: AccessBaseUrls) =>
  () => {
    const router = inject(Router);
    const tradesWorkflowSpecFacade = inject(TradeWorkflowSpecFacadeService);

    return tradesWorkflowSpecFacade.entities$.pipe(
      map(tradesWorkflowSpec => {
        const hasAccessToAllVersions = tradesWorkflowSpec[accessField] === 'V1_AND_V2';
        const hasAccessToRequestedPage =
          tradesWorkflowSpec[accessField] === pageVersion ||
          (tradesWorkflowSpec[accessField] === 'DEFAULT' && pageVersion === 'V1_ONLY');
        if (hasAccessToAllVersions || hasAccessToRequestedPage) {
          return true;
        }

        return router.parseUrl(`/${baseUrlToNavigate}`);
      })
    );
  };

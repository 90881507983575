export class AtdlDocumentModel {
  private strategiesMap: Map<string, Element> = new Map<string, Element>();

  private _document: Document | null = null;

  get document() {
    return this._document;
  }

  get strategyOptions(): string[] {
    if (this._document === null) return [];
    return Array.from(this.strategiesMap.keys());
  }

  constructor(xml: string) {
    const parser = new DOMParser();
    this._document = parser.parseFromString(xml, 'application/xml');
    const strategiesElements = Array.from(this._document.querySelectorAll('Strategy[uiRep]'));
    for (const strategyEl of strategiesElements) {
      const strategyName = strategyEl.getAttribute('uiRep');
      if (strategyName) {
        this.strategiesMap.set(strategyName, strategyEl);
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { Account } from '@pinnakl/poems/accounts/domain';
import { ExtendedTradeFlat, IExtendedTradeRequest } from '@pinnakl/poems/trades/domain';
import { Observable } from 'rxjs';
import { TradesApiService } from './trades-api.service';

@Injectable()
export class TradesFacadeService {
  constructor(private readonly api: TradesApiService) {}

  getTradeRequests(
    securityMarketIdOrUnderlyingSecurityId: string,
    selectedAccounts: Account[],
    idKey: 'securitymarketid' | 'underlyingsecid' = 'securitymarketid'
  ): Observable<IExtendedTradeRequest[]> {
    return selectedAccounts.length
      ? this.api.getTradeRequestsAllocations(
          securityMarketIdOrUnderlyingSecurityId,
          selectedAccounts,
          idKey
        )
      : this.api.getTradeRequests(securityMarketIdOrUnderlyingSecurityId, idKey);
  }

  getTradesFlat(
    securityMarketIdOrUnderlyingSecurityId: string,
    idKey: 'securitymarketid' | 'underlyingsecid' = 'securitymarketid',
    selectedAccounts?: Account[]
  ): Observable<ExtendedTradeFlat[]> {
    return this.api.getTradesFlat(securityMarketIdOrUnderlyingSecurityId, idKey, selectedAccounts);
  }
}

import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Aum } from '@pinnakl/poems/aum/domain';
import { setLoadedAndLoadingFields } from '@pinnakl/shared/util-helpers';
import { aumLoaded, loadAum } from './aum.actions';
import { AumState } from './aum.models';

export const aumsAdapter: EntityAdapter<Aum> = createEntityAdapter<Aum>({
  selectId: ({ accountId }) => accountId
});

export const initialState: AumState = aumsAdapter.getInitialState({
  loaded: false,
  loading: false
});

export const aumsReducer = createReducer(
  initialState,
  on(loadAum, state => ({
    ...state,
    ...setLoadedAndLoadingFields(false, true)
  })),
  on(aumLoaded, (state, { aums }) =>
    aumsAdapter.setAll(aums, {
      ...state,
      ...setLoadedAndLoadingFields(true, false)
    })
  )
);

<p-selectButton
  (onChange)="onChange.emit($event)"
  (onOptionClick)="onOptionClick.emit($event)"
  [ariaLabelledBy]="ariaLabelledBy"
  [dataKey]="dataKey"
  [disabled]="disabled"
  [formControl]="$any(formControl)"
  [multiple]="multiple"
  [optionDisabled]="optionDisabled"
  [optionLabel]="optionLabel"
  [optionValue]="optionValue"
  [options]="options"
  [styleClass]="styleClass"
  [style]="style"
  [tabindex]="tabindex"
  [unselectable]="unselectable"
>
  <ng-container *ngIf="itemTemplate">
    <ng-template
      let-item
      pTemplate="item"
    >
      <ng-container
        [ngTemplateOutletContext]="{ $implicit: item }"
        [ngTemplateOutlet]="itemTemplate"
      ></ng-container>
    </ng-template>
  </ng-container>
</p-selectButton>

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPinnaklModalComponent } from '@pinnakl/shared-ui';

@Component({
  selector: 'change-password-modal',
  templateUrl: 'change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent {
  constructor(
    private dialogRef: MatDialogRef<MatPinnaklModalComponent<ChangePasswordModalComponent>>
  ) {}

  passwordChanged(result: { password: string }) {
    this.dialogRef.close({ password: result.password });
  }
}

import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  loadPositionsBase,
  positionsBaseLoaded,
  updatePriceLast
} from './positions-general.actions';
import { PositionBaseInfoFullItem, PositionsGeneralState } from './positions-general.models';

const positionsBasesAdapter = createEntityAdapter<PositionBaseInfoFullItem>({
  selectId: ({ securityMarketId }) => securityMarketId,
  sortComparer: (p1, p2) => p1.securityMarketId.localeCompare(p2.securityMarketId)
});

export const initialState: PositionsGeneralState = {
  positionsBases: positionsBasesAdapter.getInitialState({
    loading: false
  })
};

export const positionsGeneralReducer = createReducer(
  initialState,
  on(loadPositionsBase, state => ({
    ...state,
    positionsBases: {
      ...state.positionsBases,
      loading: true
    }
  })),
  on(
    positionsBaseLoaded,
    (state, { securityMarketId, positionBase: { positions, mostRecentPrice } }) => ({
      ...state,
      positionsBases: {
        ...positionsBasesAdapter.setOne(
          {
            securityMarketId,
            positions,
            mostRecentPrice
          },
          state.positionsBases
        ),
        loading: false
      }
    })
  ),
  on(updatePriceLast, (state, { priceLast, securityMarketId }) => ({
    ...state,
    positionsBases: {
      ...positionsBasesAdapter.updateOne(
        {
          id: securityMarketId,
          changes: {
            mostRecentPrice: {
              ...state.positionsBases.entities[securityMarketId]!.mostRecentPrice,
              closeLocal: priceLast
            }
          }
        },
        state.positionsBases
      )
    }
  }))
);

export const PositionsBaseAdapterSelectors = positionsBasesAdapter.getSelectors();

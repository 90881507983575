<p
  [cdkDragDisabled]="data.dragDisable"
  [ngStyle]="data.headerStyle"
  [style.margin-top]="data.headerText ? '24px' : ''"
  [style.padding]="data.headerText ? '' : '24px 0 0 0'"
  cdkDrag
  cdkDragHandle
  cdkDragRootElement=".cdk-overlay-pane"
  class="dialog-paragraph mat-mdc-dialog-title"
>
  <span>{{ data.headerText }}</span>
  <pnkl-modal-close-btn
    (click)="dialogRef.close()"
    *ngIf="data.showCloseButton"
  ></pnkl-modal-close-btn>
</p>
<div class="mat-mdc-dialog-content">
  <ng-container [ngTemplateOutlet]="data.template"></ng-container>
  <ng-container #vc></ng-container>
</div>

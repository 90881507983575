import {
  ErrorUIToastMessageCategory,
  InfoUIToastMessageCategory,
  PinnaklUIToastMessageAPI,
  SuccessUIToastMessageCategory,
  WarningUIToastMessageCategory
} from '@pinnakl/shared/types';

export abstract class PinnaklUIToastMessage implements PinnaklUIToastMessageAPI {
  error(msg: string, category?: ErrorUIToastMessageCategory, options?: any): void {
    throw new Error('Method not implemented.');
  }

  info(msg: string, category?: InfoUIToastMessageCategory, options?: any): void {
    throw new Error('Method not implemented.');
  }

  success(msg: string, category?: SuccessUIToastMessageCategory, options?: any): void {
    throw new Error('Method not implemented.');
  }

  warning(msg: string, category?: WarningUIToastMessageCategory, options?: any): void {
    throw new Error('Method not implemented.');
  }
}

import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { BasePreset, Preset, PresetConfigName, PresetModules } from '@pinnakl/shared/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PresetsApiService {
  private readonly presetsEndpoint = `${V3_ENDPOINT}user_module_configs`;

  constructor(private readonly wsp: WebServiceProvider) {}

  createPreset(body: BasePreset): Observable<Preset> {
    return this.wsp.post<Preset>({
      endpoint: this.presetsEndpoint,
      body
    });
  }

  updatePreset(body: Preset): Observable<Preset> {
    return this.wsp.put<Preset>({
      endpoint: this.presetsEndpoint,
      body
    });
  }

  deletePreset(presetId: number): Observable<Preset> {
    return this.wsp.delete<Preset>({
      endpoint: `${this.presetsEndpoint}/${presetId}`
    });
  }

  getPresetsByFilters({
    userId,
    configName,
    module
  }: {
    userId?: number;
    configName?: PresetConfigName;
    module?: PresetModules;
  }): Observable<Preset[]> {
    const filters: { key: string; type: string; value: string[] }[] = [];
    if (userId) {
      filters.push({
        key: 'userId',
        type: 'EQ',
        value: [userId.toString()]
      });
    }
    if (configName) {
      filters.push({
        key: 'configName',
        type: 'EQ',
        value: [configName]
      });
    }
    if (module) {
      filters.push({
        key: 'module',
        type: 'EQ',
        value: [module]
      });
    }
    return this.wsp.get<Preset[]>({
      endpoint: this.presetsEndpoint,
      optionsParams: {
        fields: ['id', 'userId', 'module', 'configName', 'configValue'],
        filters
      }
    });
  }

  getPresetById(presetId: number): Observable<Preset> {
    return this.wsp.get<Preset>({
      endpoint: `${this.presetsEndpoint}/${presetId}`
    });
  }
}

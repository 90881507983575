import { createAction, props } from '@ngrx/store';
import { SecurityPositionsTimeItems } from './timeseries-positions.models';

export enum TimeseriesPositionsActions {
  LoadPositionsTimeSeries = '[Timeseries positions] Load positions by sec market id',
  PositionsTimeSeriesLoaded = '[Timeseries positions] Positions by sec market id loaded'
}

export const loadPositionsTimeSeries = createAction(
  TimeseriesPositionsActions.LoadPositionsTimeSeries,
  props<{ securityMarketId: string }>()
);

export const positionsTimeSeriesLoaded = createAction(
  TimeseriesPositionsActions.PositionsTimeSeriesLoaded,
  props<{ positionsTimeItems: SecurityPositionsTimeItems }>()
);

import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[pinnaklClickOutside]',
  standalone: true
})
export class ClickOutsideDirective implements OnInit {
  private document: Document = inject(DOCUMENT);
  @Input() clickOutsideEnabled = true;
  /**
   * Array of strings of DOM element queries to exclude when clicking outside the element.
   * Usually is different modals and overlays selectors.
   * For example: `[clickOutsideExclude]="['button,'.btn-primary']"`.
   */
  @Input() clickOutsideSelectorsExclude: string[] = [];
  /**
   * List of classes (without . prefix) to force exclude when clicking outside the main element
   * Use such check: clickTarget?.classList?.contains(forceClass) || clickTarget?.parentNode?.classList?.contains(forceClass)
   * Common usage for svg icons and dropdowns items
   */
  @Input() clickOutsideExcludeForceClasses: string[] = [];
  @Input() closeOnEscape = false;
  @Output() clickOutside = new EventEmitter<Event>();
  captured = false;

  constructor(private elRef: ElementRef) {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ): void {
    this.closeOnEscape && this.clickOutsideEnabled && this.clickOutside.emit(event);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    if (!this.clickOutsideEnabled) {
      return;
    }

    if (!this.captured) return;
    if (this.isForceExclude(event.target)) return;
    if (this.isExclude(event.target)) return;
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.clickOutside.emit(event);
    }
  }

  ngOnInit(): void {
    fromEvent(document, 'click', { capture: true })
      .pipe(take(1))
      .subscribe(() => (this.captured = true));
  }

  private excludeCheck(): HTMLElement[] {
    if (this.clickOutsideSelectorsExclude.length > 0) {
      try {
        const selector = this.clickOutsideSelectorsExclude.join(',');
        const nodes = Array.from(this.document.querySelectorAll(selector)) as Array<HTMLElement>;
        if (nodes) {
          return nodes;
        }
      } catch (err) {
        console.warn('[click-outside] Check your exclude selector syntax.', err);
      }
    }
    return [];
  }

  private isExclude(target: any): boolean {
    const nodesExcluded = this.excludeCheck();
    for (const excludedNode of nodesExcluded) {
      if (excludedNode.contains(target) || target === excludedNode) {
        return true;
      }
    }

    return false;
  }

  private isForceExclude(target: any): boolean {
    if (this.clickOutsideExcludeForceClasses.length > 0) {
      for (const forceClass of this.clickOutsideExcludeForceClasses) {
        if (
          target?.classList?.contains(forceClass) ||
          target?.parentNode?.classList?.contains(forceClass)
        ) {
          return true;
        }
      }
    }
    return false;
  }
}

import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { Account } from '@pinnakl/poems/accounts/domain';
import {
  ExtendedTradeFlat,
  IExtendedTradeRequest,
  ITradeAllocation,
  ITradeRequest,
  TradeFlat
} from '@pinnakl/poems/trades/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { ShortTranType } from '@pinnakl/shared/types';
import { GetFullTranType } from '@pinnakl/shared/util-helpers';
import { Observable, map } from 'rxjs';

const tradeRequestFields = [
  'accountcode',
  'foldercode',
  'tradedate',
  'trantype',
  'ticker',
  'cusip',
  'description',
  'quantity',
  'price',
  'currency',
  'commission',
  'netmoneylocal',
  'brokername',
  'commissionpershare'
];

const tradeAllocationFields = [
  'accountcode',
  // 'foldercode',
  'tradedate',
  'trantype',
  'ticker',
  'cusip',
  'description',
  'quantity',
  'price',
  'currency',
  'commission',
  'netmoneylocal',
  'brokername',
  'commpershare'
];

@Injectable()
export class TradesApiService {
  private readonly tradeRequests = `${V3_ENDPOINT}trades`;
  private readonly tradeAllocations = `${V3_ENDPOINT}trade_allocations`;
  private readonly tradesFlat = `${V3_ENDPOINT}trades_flat`;

  constructor(private readonly wsp: WebServiceProvider) {}

  getTradeRequests(
    itemId: string,
    idKey: 'securitymarketid' | 'underlyingsecid' = 'securitymarketid'
  ): Observable<IExtendedTradeRequest[]> {
    const params = {
      fields: tradeRequestFields,
      filters: [
        {
          key: idKey,
          type: 'EQ',
          value: [itemId]
        }
      ]
    };

    return this.wsp
      .get<ITradeRequest[]>({
        endpoint: this.tradeRequests,
        optionsParams: params
      })
      .pipe(
        map(trades =>
          trades.map(
            (trade): IExtendedTradeRequest => ({
              ...trade,
              parsedtradedate: new Date(trade.tradedate),
              parsedtrantype: GetFullTranType(trade.trantype.toLowerCase() as ShortTranType)
            })
          )
        )
      );
  }

  getTradeRequestsAllocations(
    itemId: string,
    accounts: Account[],
    idKey: 'securitymarketid' | 'underlyingsecid' = 'securitymarketid'
  ): Observable<IExtendedTradeRequest[]> {
    const params = {
      fields: tradeAllocationFields,
      filters: [
        {
          key: idKey as string,
          type: 'EQ',
          value: [itemId]
        }
      ]
    };

    if (accounts.length) {
      params.filters.push({
        key: 'accountcode',
        type: 'EQ',
        value: accounts.map(({ accountCode }) => accountCode)
      });
    }

    return this.wsp
      .get<ITradeAllocation[]>({
        endpoint: this.tradeAllocations,
        optionsParams: params
      })
      .pipe(
        map(trades =>
          trades.map(
            (trade): IExtendedTradeRequest => ({
              ...trade,
              brokername: trade.brokername,
              commissionpershare: trade.commpershare,
              parsedtradedate: new Date(trade.tradedate),
              parsedtrantype: GetFullTranType(trade.trantype.toLowerCase() as ShortTranType)
            })
          )
        )
      );
  }

  getTradesFlat(
    itemId: string,
    idKey: 'securitymarketid' | 'underlyingsecid' = 'securitymarketid',
    accounts?: Account[]
  ): Observable<ExtendedTradeFlat[]> {
    const filters = [
      {
        key: idKey as string,
        type: 'EQ',
        value: [itemId]
      }
    ];

    if (accounts?.length) {
      filters.push({
        key: 'accounts',
        type: 'IN',
        value: accounts.map(({ id }) => id.toString())
      });
    }

    return this.wsp
      .get<TradeFlat[]>({
        endpoint: this.tradesFlat,
        optionsParams: { filters }
      })
      .pipe(
        map(trades =>
          trades.map(trade => ({
            ...trade,
            parsedTradeDate: new Date(trade.tradeDate),
            parsedTranType: GetFullTranType(trade.tranType.toLowerCase() as ShortTranType)
          }))
        )
      );
  }
}

<div
  *ngIf="{ isEditMode: isEditMode$ | async, currencies: previousCloseCurrencies$ | async } as vm"
  class="flex align-items-center price-container px-2 mt-2 border-round relative"
>
  <div class="mr-2 mark-read-value">
    {{ price | number: '.2-2' }}
  </div>

  <i
    (click)="vm.currencies && toggleEditMode()"
    [class.p-disabled]="!vm.currencies"
    [inlineSVG]="'sprites.svg#pencil-edit'"
    class="flex cursor-pointer ml-auto edit-price-icon text-color-secondary"
  ></i>

  <ng-container *ngIf="vm.isEditMode">
    <div
      [@inOutAnimation]="vm.isEditMode"
      [formGroup]="editPreviousCloseForm"
      class="grid grid-nogutter w-100 align-items-center absolute top-0 left-0 px-2 price-container border-round"
    >
      <div class="col-11 pr-1 flex align-items-center">
        <div class="grid grid-nogutter w-100 gap-2 align-items-center">
          <prime-number-input
            [maxFractionDigits]="2"
            [max]="9999"
            [minFractionDigits]="2"
            [min]="0.01"
            formControlName="editPreviousCloseValue"
            inputStyleClass="w-100"
          ></prime-number-input>
          <prime-button
            (onClick)="savePreviousCloseValue(); toggleEditMode()"
            [disabled]="editPreviousCloseForm.invalid"
            icon="pi pi-save"
            label="Save"
            severity="success"
            size="sm"
            styleClass="w-100"
          ></prime-button>
        </div>
      </div>
      <div
        (click)="toggleEditMode()"
        class="col-1 cursor-pointer align-self-stretch flex align-items-center justify-content-center border-round close-edit-mode-section"
      >
        <i class="pi pi-times"></i>
      </div>
    </div>
  </ng-container>
</div>

import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PriceEventData } from '@pinnakl/poems/streams/price/domain';
import {
  emitConnectionError,
  emitConnectionEstablished,
  priceEventsReceived,
  subscribeToStream,
  subscribeToStreamFailure,
  unsubscribeFromStreamFailure
} from './price-events-stream.actions';
import { PriceEventsStreamState } from './price-events-stream.models';

const priceEventsStreamAdapter = createEntityAdapter<PriceEventData>({
  selectId: ({ securityMarketId }) => securityMarketId
});

export const initialState: PriceEventsStreamState = priceEventsStreamAdapter.getInitialState({
  loading: false,
  connected: false,
  connectionError: false,
  timestamp: null
});

export const priceEventsStreamReducer = createReducer(
  initialState,
  on(subscribeToStream, state => ({ ...state, loading: true })),
  on(subscribeToStreamFailure, (state, { error }) => ({ ...state, error, loading: false })),
  on(priceEventsReceived, (state, { events }) =>
    priceEventsStreamAdapter.setMany(events, {
      ...state,
      loading: false,
      connected: true,
      timestamp: Date.now()
    })
  ),
  on(unsubscribeFromStreamFailure, (state, { error }) => ({ ...state, error })),
  on(emitConnectionEstablished, (state, { disconnect }) => ({
    ...state,
    connected: disconnect ?? true
  })),
  on(emitConnectionError, (state, { isFixed }) => ({ ...state, connectionError: !isFixed }))
);

export const priceEventsSelectors = priceEventsStreamAdapter.getSelectors();

import { User } from '@pinnakl/shared/types';
import { RTLMembership } from '../trade-compliance-rules.model';

export class RestrictedSecurity {
  constructor(
    public id: number,
    public securityId: number,
    public ticker: string,
    public startDate: Date,
    public endDate: Date,
    public timestamp: Date,
    public notes: string,
    public assetTypes: string,
    public countryOfIncorporation: string,
    public author: User,
    public userId: string,
    public accountMemberships: RTLMembership[],
    public enforcement: string
  ) {}
}

export class RestrictedSecurityFromApi {
  constructor(
    public id: string,
    public securityId: string,
    public ticker: string,
    public startDate: string,
    public endDate: string,
    public timestamp: string,
    public notes: string,
    public assetTypes: string,
    public countryOfIncorporation: string,
    public author: User,
    public userId: string,
    public accountMemberships: RTLMembership[],
    public enforcement: string
  ) {}
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  TradeWorkflowSpec,
  TradeWorkflowSpecUpdateParams
} from '@pinnakl/poems/trade-workflow-spec/domain';
import { getLoadableEntities$, isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { filter } from 'rxjs';
import { loadTradeWorkflowSpec, updateTradeWorkflowSpec } from './trade-workflow-spec.actions';
import { TradeWorkflowSpecState } from './trade-workflow-spec.models';
import { tradeWorkflowSpecQuery } from './trade-workflow-spec.selectors';

@Injectable({
  providedIn: 'root'
})
export class TradeWorkflowSpecFacadeService {
  spec$ = this.store$
    .select(tradeWorkflowSpecQuery.getTradeWorkflowSpec)
    .pipe(filter(isNeitherNullNorUndefined));
  entities$ = getLoadableEntities$<TradeWorkflowSpec>(
    this.store$
      .select(tradeWorkflowSpecQuery.getTradeWorkflowSpec)
      .pipe(filter(isNeitherNullNorUndefined)),
    this.store$.select(tradeWorkflowSpecQuery.getTradeWorkflowSpecLoaded),
    this.store$.select(tradeWorkflowSpecQuery.getTradeWorkflowSpecLoading),
    this.loadTradeWorkflowSpec.bind(this)
  );

  constructor(private readonly store$: Store<TradeWorkflowSpecState>) {}

  loadTradeWorkflowSpec(): void {
    this.store$.dispatch(loadTradeWorkflowSpec());
  }

  updateTradeWorkflowSpec(spec: TradeWorkflowSpecUpdateParams): void {
    this.store$.dispatch(updateTradeWorkflowSpec({ spec }));
  }
}

import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input, ViewEncapsulation, inject } from '@angular/core';
import { ModalMinimizeService } from './modal-minimize.service';

@Component({
  standalone: true,
  imports: [AsyncPipe, NgIf],
  selector: 'pinnakl-modal-minimized-view',
  encapsulation: ViewEncapsulation.None,
  template: ` <div
    *ngIf="showMiniDialog | async"
    class="absolute bottom-0 left-50 -translate-x-50 border-1 border-bluegray-200 shadow-2 transition-all
    transition-duration-300 border-round bg-white flex flex-row align-items-center justify-content-center
    flex-nowrap opacity-20 hover:opacity-100 z-5 cursor-pointer p-3"
  >
    <h4 class="line-height-1_50rem capitalize white-space-nowrap mb-0">{{ headerLabel }}</h4>
    <button
      (click)="maximizeDialog()"
      class="ml-5 mr-2 flex align-items-center justify-content-center"
    >
      <i class="pi pi-stop"></i>
    </button>
    <button
      (click)="closeDialog()"
      class="flex align-items-center justify-content-center"
    >
      <i class="pi pi-times"></i>
    </button>
  </div>`
})
export class ModalMinimizedViewComponent {
  @Input() headerLabel = '';
  modalMinimizeService = inject(ModalMinimizeService);
  showMiniDialog = this.modalMinimizeService.minimizedView$;

  closeDialog(): void {
    this.modalMinimizeService.closeDialog();
  }

  maximizeDialog(): void {
    this.modalMinimizeService.maximizeDialog();
  }
}

<kendo-grid
  (groupChange)="groupChange($event)"
  (sortChange)="sortChange($event)"
  [data]="gridView"
  [group]="groups"
  [groupable]="true"
  [sort]="sort"
  [sortable]="{ mode: 'single' }"
  [style.height.%]="100"
  [style.width.%]="100"
  class="trade-history-table"
>
  <ng-template kendoGridToolbarTemplate>
    <div class="history-table-toolbar">
      <div class="header-modal">
        <div class="container-with-radio">
          <pinnakl-input
            [form]="historyForm"
            checkBoxStyle="toggle"
            controlName="underlying"
            label="Show all trades in underlying"
            type="boolean"
          >
          </pinnakl-input>
        </div>
        <button
          class="export-container"
          kendoGridExcelCommand
          type="button"
        >
          <div class="d-flex align-items-center justify-content-between">
            <i class="icon-pinnakl-exls"></i>
            <span>Export to Excel</span>
          </div>
        </button>
      </div>
    </div>
  </ng-template>
  <kendo-grid-column
    [width]="85"
    field="tradedate"
    title="Trade Date"
  ></kendo-grid-column>
  <kendo-grid-column
    [width]="145"
    field="ticker"
    title="Ticker"
  ></kendo-grid-column>
  <kendo-grid-column
    [width]="70"
    field="trantype"
    title="Tran"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      {{ dataItem.trantype }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]="61"
    class="align-number"
    field="quantity"
    format="{0:n}"
    title="Quantity"
  >
  </kendo-grid-column>
  <kendo-grid-column
    [headerClass]="'justify-center'"
    [width]="100"
    class="align-number"
    field="price"
    format="{0:c3}"
    title="Price"
  >
  </kendo-grid-column>
  <kendo-grid-column
    [width]="65"
    class="align-center"
    field="currency"
    title="Currency"
  >
  </kendo-grid-column>
  <kendo-grid-column
    [width]="110"
    class="align-number pr25"
    field="commissionpershare"
    format="{0:n2}"
    title="Comm Per Share"
  >
  </kendo-grid-column>
  <kendo-grid-column
    [headerClass]="'justify-center'"
    [width]="110"
    class="align-number"
    field="netmoneylocal"
    format="{0:c2}"
    title="Net Money"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="brokername"
    title="Broker Name"
  ></kendo-grid-column>
  <kendo-grid-excel fileName="trade-history.xlsx"></kendo-grid-excel>
</kendo-grid>

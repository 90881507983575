<div
  [style.height]="diameter"
  [style.width]="diameter"
  class="wrap"
>
  <svg
    [attr.height]="diameter"
    [attr.viewBox]="viewBox"
    [attr.width]="diameter"
    [style.opacity]="circleOpacity"
    class="svg"
  >
    <circle
      [attr.cx]="diameter / 2"
      [attr.cy]="diameter / 2"
      [attr.r]="radius"
      [attr.stroke-width]="strokeWidth"
      class="circle-placeholder"
      fill="none"
    />
    <circle
      #progressEl
      [attr.cx]="diameter / 2"
      [attr.cy]="diameter / 2"
      [attr.r]="radius"
      [attr.stroke-width]="strokeWidth"
      [attr.stroke]="progressStroke"
      [style.stroke-dasharray]="pathLength"
      [style.stroke-dashoffset]="offset"
      class="circle-progress"
      fill="none"
    />
  </svg>
  <div
    *ngIf="current > limit"
    class="exceed"
  >
    {{ limit - current }}
  </div>
</div>

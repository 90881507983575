import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { PmsPresets, PmsSavedColumns } from '@pinnakl/pms/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { PmsPresetsTypes, Preset } from '@pinnakl/shared/types';
import { getPresetWithParsedConfigValue } from '@pinnakl/shared/util-helpers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PmsPresetsApiService {
  private readonly endpoint = `${V3_ENDPOINT}pms_user_settings`;

  constructor(private readonly wsp: WebServiceProvider) {}

  getAllPmsPresets(): Observable<PmsPresets> {
    return this.wsp.get<Preset[]>({ endpoint: this.endpoint }).pipe(
      map(presets =>
        presets.reduce(
          (acc, preset) => {
            const configuration: Partial<PmsPresets> = {};

            switch (preset.configName) {
              case PmsPresetsTypes.PmsWidgetsOrder:
              case PmsPresetsTypes.PmsGridSelectedColumnsPreset:
              case PmsPresetsTypes.PmsOtherSettings:
              case PmsPresetsTypes.PmsFontSize:
              case PmsPresetsTypes.PmsSortType:
              case PmsPresetsTypes.PmsPositionSummary:
              case PmsPresetsTypes.PmsDeltaAdjustedExposure:
                configuration[preset.configName] = getPresetWithParsedConfigValue(preset);
                break;
              case PmsPresetsTypes.PmsGridColumns:
                configuration[preset.configName] = [
                  ...acc[preset.configName],
                  getPresetWithParsedConfigValue<PmsSavedColumns>(preset)
                ];
                break;
            }

            return {
              ...acc,
              ...configuration
            };
          },
          <PmsPresets>{
            [PmsPresetsTypes.PmsGridColumns]: [],
            [PmsPresetsTypes.PmsGridSelectedColumnsPreset]: null,
            [PmsPresetsTypes.PmsWidgetsOrder]: null,
            [PmsPresetsTypes.PmsOtherSettings]: null,
            [PmsPresetsTypes.PmsFontSize]: null,
            [PmsPresetsTypes.PmsSortType]: null,
            [PmsPresetsTypes.PmsPositionSummary]: null,
            [PmsPresetsTypes.PmsDeltaAdjustedExposure]: null
          }
        )
      )
    );
  }
}

import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CoreDataProvidersModule } from '@pinnakl/core/data-providers';
import {
  AG_GRID_KEY,
  AG_GRID_KEY_TOKEN,
  HTTP_SERVER_URL,
  LOCATION
} from '@pinnakl/shared/constants';
import { PageSubscriptionsHandler } from '@pinnakl/shared/util-helpers';
import { PinnaklErrorHandler } from '@pinnakl/shared/util-providers';
import { DialogModule } from '@progress/kendo-angular-dialog';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    CoreDataProvidersModule,
    HttpClientJsonpModule,
    HttpClientModule
  ],
  providers: [
    PageSubscriptionsHandler,
    {
      provide: LOCATION,
      useValue: location
    },
    {
      provide: ErrorHandler,
      useClass: PinnaklErrorHandler
    },
    {
      provide: AG_GRID_KEY_TOKEN,
      useValue: AG_GRID_KEY
    }
  ]
})
export class CoreMaintenanceModule {
  public static register(config: Record<string, any>): ModuleWithProviders<CoreMaintenanceModule> {
    return {
      ngModule: CoreMaintenanceModule,
      providers: [
        {
          provide: HTTP_SERVER_URL,
          useValue: config['coreApiServerUrl']
        }
      ]
    };
  }
}

import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const defaultConfig = {
  fractionSize: 2,
  digitsInfo: '1.2-2',
  currencySign: '$'
};

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {
  constructor(private readonly decimalPipe: DecimalPipe) {}

  transform(value: number | null, config = defaultConfig): string | null {
    config = {
      ...defaultConfig,
      ...config
    };
    if (value === null) return null;
    if (value === 0) return this.decimalPipe.transform(0, config.digitsInfo);
    const rounder = Math.pow(10, config.fractionSize);
    let absoluteValue = Math.abs(value);
    let key = '';
    const powers = [
      { key: 'Q', value: Math.pow(10, 15) },
      { key: 'T', value: Math.pow(10, 12) },
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'k', value: 1000 }
    ];
    for (let i = 0; i < powers.length; i++) {
      let reduced = absoluteValue / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        absoluteValue = reduced;
        key = powers[i].key;
        break;
      }
    }
    const numberValue = parseFloat(absoluteValue.toFixed(config.fractionSize));
    const decimalNumberValue = this.decimalPipe.transform(numberValue, config.digitsInfo);
    return (value < 0 ? '-' : '') + config.currencySign + decimalNumberValue + key;
  }
}

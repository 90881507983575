<ng-container *ngIf="!draggable">
  <kendo-dialog
    *ngIf="!hideDialog && !draggable"
    [height]="height"
    [maxHeight]="maxHeight"
    [minWidth]="minWidth"
    [ngClass]="className"
    [title]="title"
    [width]="width"
  >
    <div
      *ngIf="showCloseButton"
      class="pb-4 text-align-right"
    >
      <button
        (click)="closeModalEmit()"
        aria-label="Close"
        class="close"
        type="button"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <ng-content></ng-content>
  </kendo-dialog>
</ng-container>

<ng-container *ngIf="draggable">
  <kendo-window
    (close)="closeModalEmit()"
    *ngIf="!hideDialog"
    [draggable]="true"
    [height]="+height"
    [minWidth]="+minWidth"
    [ngClass]="className"
    [resizable]="true"
    [title]="title"
    [width]="+width"
  >
    <ng-content select="[draggable]"></ng-content>
  </kendo-window>
  <div class="k-overlay"></div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ServerSentEventsStreamService } from '@pinnakl/core/server-sent-events';
import { PriceEventsMessageService } from './price-events-message.service';
import { PriceEventsStreamFacadeService } from './price-events-stream-facade.service';
import { PriceEventsStreamEffects } from './price-events-stream.effects';
import { initialState, priceEventsStreamReducer } from './price-events-stream.reducer';
import { featureSelectorName } from './price-events-stream.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureSelectorName, priceEventsStreamReducer, {
      initialState
    }),
    EffectsModule.forFeature([PriceEventsStreamEffects])
  ],
  providers: [
    PriceEventsMessageService,
    PriceEventsStreamFacadeService,
    ServerSentEventsStreamService
  ]
})
export class PriceEventsStreamModule {}

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PositionService } from '@pinnakl/poems';
import { Account } from '@pinnakl/shared/types';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

interface PositionPopupTab {
  caption: string;
  name: PositionPopupTabName;
  isInitialized: boolean;
  hidden: boolean;
}

enum PositionPopupTabName {
  POSITION_SUMMARY = 'positionSummaryTab',
  TRADE_HISTORY = 'tradeHistoryTab',
  FOLDER_COMMENTS = 'folderComments',
  POSITION_VS_PRICE = 'positionVsPriceTab',
  PRICE_COMPARISON = 'priceComparisonTab'
}

@Component({
  templateUrl: './position-popup.component.html',
  styleUrls: ['./position-popup.component.scss']
})
export class PositionPopupComponent implements OnInit {
  readonly tabs: PositionPopupTab[] = [
    {
      name: PositionPopupTabName.POSITION_SUMMARY,
      caption: 'POSITION SUMMARY',
      isInitialized: false,
      hidden: false
    },
    {
      name: PositionPopupTabName.TRADE_HISTORY,
      caption: 'TRADE HISTORY',
      isInitialized: false,
      hidden: false
    },
    {
      name: PositionPopupTabName.FOLDER_COMMENTS,
      caption: 'FOLDER COMMENTS',
      isInitialized: false,
      hidden: false
    },
    {
      name: PositionPopupTabName.POSITION_VS_PRICE,
      caption: 'POSITION HISTORY',
      isInitialized: false,
      hidden: false
    },
    {
      name: PositionPopupTabName.PRICE_COMPARISON,
      caption: 'PRICE COMPARISON',
      isInitialized: false,
      hidden: false
    }
  ];

  activeTabName: PositionPopupTabName;
  accountForm: UntypedFormGroup;
  PositionPopupTabName = PositionPopupTabName;

  account?: Account;
  accounts: Account[];
  securityMarketId: number;
  underlyingSecId: number;
  posDate: string;
  folderCode: string;
  ticker: string;

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly fb: UntypedFormBuilder,
    private readonly config: DynamicDialogConfig,
    private readonly positionService: PositionService
  ) {
    const { account, accounts, securityMarketId, underlyingSecId, posDate, folderCode } =
      this.config.data;

    this.account = account;
    this.accounts = accounts;
    this.securityMarketId = securityMarketId;
    this.underlyingSecId = underlyingSecId;
    this.posDate = posDate;
    this.folderCode = folderCode;

    this.accountForm = this.fb.group({
      account: []
    });
    this.accountForm.valueChanges.subscribe(res => {
      this.positionService.selectedPositionPopupAccount$.next(res.account);
    });
  }

  ngOnInit(): void {
    if (this.account) {
      this.accountForm.patchValue({
        account: this.account
      });
    }

    const showFolderComments = !!this.folderCode;
    const commentsTab = this.getTabByName(PositionPopupTabName.FOLDER_COMMENTS);
    commentsTab.hidden = !showFolderComments;

    this.activateDefaultTab();
  }

  isTabActive(tabName: PositionPopupTabName): boolean {
    return tabName === this.activeTabName;
  }

  getTabByName(tabName: PositionPopupTabName) {
    return this.tabs.find(tab => tab.name === tabName);
  }

  activateTab(tabName: PositionPopupTabName): void {
    this.activeTabName = tabName;
    const tab = this.getTabByName(tabName);
    tab.isInitialized = true;
  }

  closeModal(): void {
    this.ref.close();
  }

  openWideSearch() {
    this.ref.close('wide-modal');
  }

  private activateDefaultTab(): void {
    this.activateTab(PositionPopupTabName.TRADE_HISTORY);
  }
}

import { Injectable } from '@angular/core';
import { ClosePriceOverrideBase } from '@pinnakl/poems/pricing/domain';
import { Observable } from 'rxjs';
import { ClosePriceOverrideApiService } from './close-price-override-api.service';

@Injectable()
export class ClosePriceOverrideFacadeService {
  constructor(private readonly api: ClosePriceOverrideApiService) {}

  createOverrideStatus(closePriceOverride: ClosePriceOverrideBase): Observable<number> {
    return this.api.createClosePriceOverride(closePriceOverride);
  }
}

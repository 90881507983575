import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PriceEventData } from '@pinnakl/poems/streams/price/domain';
import { isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { filter, Observable, take } from 'rxjs';
import {
  emitConnectionError,
  emitConnectionEstablished,
  subscribeToStream,
  unsubscribeFromStream
} from './price-events-stream.actions';
import { PriceEventsStreamState } from './price-events-stream.models';
import { priceEventsStreamQuery } from './price-events-stream.selectors';

@Injectable({
  providedIn: 'root'
})
export class PriceEventsStreamFacadeService {
  latestPriceEventsDictionary$ = this.store$.select(priceEventsStreamQuery.getAllEventsEntities);
  loading$ = this.store$.select(priceEventsStreamQuery.getStreamLoading);
  error$ = this.store$
    .select(priceEventsStreamQuery.getStreamError)
    .pipe(filter(isNeitherNullNorUndefined));
  connectedStatus$ = this.store$
    .select(priceEventsStreamQuery.getConnectedStatus)
    .pipe(filter(isNeitherNullNorUndefined));
  errorStatus$ = this.store$
    .select(priceEventsStreamQuery.getErrorStatus)
    .pipe(filter(isNeitherNullNorUndefined));
  timestamp$ = this.store$
    .select(priceEventsStreamQuery.getLastMessageTime)
    .pipe(filter(isNeitherNullNorUndefined));

  constructor(private readonly store$: Store<PriceEventsStreamState>) {}

  subscribeToStream(token: string) {
    this.store$
      .select(priceEventsStreamQuery.getConnectedStatus)
      .pipe(take(1))
      .subscribe(status => !status && this.store$.dispatch(subscribeToStream({ token })));
  }

  getPriceEventsBySecurityMarketId(
    securityMarketId: string | number
  ): Observable<PriceEventData | undefined> {
    return this.store$.select(
      priceEventsStreamQuery.getPriceEventsBySecurityMarketId(securityMarketId.toString())
    );
  }

  unsubscribeFromStream() {
    this.store$.dispatch(unsubscribeFromStream());
  }

  emitConnectionEstablished(disconnect?: boolean) {
    this.store$.dispatch(emitConnectionEstablished({ disconnect }));
  }

  emitConnectionError(isFixed?: boolean) {
    this.store$.dispatch(emitConnectionError({ isFixed }));
  }
}

import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import {
  Account,
  Admin,
  AdminAccount,
  AdminAccountFromApi,
  AdminFromApi
} from '@pinnakl/shared/types';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private readonly ADMINS_URL = 'entities/admins';
  private readonly ADMIN_ACCOUNTS_URL = 'entities/admin_accounts';
  private readonly ACCOUNTS_URL = 'v3/entities/accounts';
  private _accounts: Account[];

  constructor(private wsp: WebServiceProvider) {}

  getAdmins(): Promise<Admin[]> {
    return this.wsp
      .getHttp<AdminFromApi[]>({
        endpoint: this.ADMINS_URL,
        optionsParams: {
          fields: ['Code', 'Id', 'Name']
        }
      })
      .then(entities => entities.map(this.formatAdmin));
  }

  getAdminAccounts(): Promise<AdminAccount[]> {
    return this.wsp
      .getHttp<AdminAccountFromApi[]>({
        endpoint: this.ADMIN_ACCOUNTS_URL,
        optionsParams: {
          fields: ['Id', 'AccountId', 'AccountCode', 'AdminId', 'AdminCode']
        }
      })
      .then(adminAccounts => adminAccounts.map(this.formatAdminAccount));
  }

  getAccounts(): Promise<Account[]> {
    if (this._accounts) {
      return Promise.resolve(this._accounts);
    }

    return this.wsp
      .getHttp<Account[]>({
        endpoint: this.ACCOUNTS_URL
      })
      .then(accounts => {
        this._accounts = accounts;
        return this._accounts;
      });
  }

  private formatAdmin(entity: AdminFromApi): Admin {
    const id = parseInt(entity.id);
    return new Admin(entity.code, !isNaN(id) ? id : null, entity.name);
  }

  private formatAdminAccount(account: AdminAccountFromApi): AdminAccount {
    const accountId = parseInt(account.accountid),
      adminId = parseInt(account.adminid),
      id = parseInt(account.id);
    return new AdminAccount(
      account.accountcode,
      !isNaN(accountId) ? accountId : null,
      account.admincode,
      !isNaN(adminId) ? adminId : null,
      !isNaN(id) ? id : null
    );
  }
}

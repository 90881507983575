import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CommonDataApiService } from './common-data-api.service';
import {
  currenciesLoaded,
  fxRatesLoaded,
  loadCurrencies,
  loadCurrenciesFailure,
  loadFxRates,
  loadFxRatesFailure
} from './common-data.actions';

@Injectable()
export class CommonDataEffects {
  loadFxRates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFxRates),
      switchMap(() => this.api.getFxRates()),
      map(fxRates => fxRatesLoaded({ fxRates })),
      catchError(error => {
        this.toast.error(error);
        return of(loadFxRatesFailure({ error }));
      })
    )
  );

  loadCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrencies),
      switchMap(() => this.api.getCurrencies()),
      map(currencies => currenciesLoaded({ currencies })),
      catchError(error => {
        this.toast.error(error);
        return of(loadCurrenciesFailure({ error }));
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: CommonDataApiService,
    private readonly toast: PinnaklUIToastMessage
  ) {}
}

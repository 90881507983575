import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Account } from '@pinnakl/poems/accounts/domain';
import { addAccount, load, loadFailure, setAccounts } from './accounts.actions';
import { AccountsState } from './accounts.models';

export const accountAdapter: EntityAdapter<Account> = createEntityAdapter<Account>({
  sortComparer: false
});

export const initialState: AccountsState = accountAdapter.getInitialState({
  loading: false
});

export const accountsReducer = createReducer(
  initialState,
  on(load, state => ({ ...state, loading: true })),
  on(loadFailure, (state, { error }) => ({ ...state, error, loading: false })),
  on(setAccounts, (state, { accounts }) =>
    accountAdapter.setAll(accounts, { ...state, loading: false })
  ),
  on(addAccount, (state, { account }) => accountAdapter.setOne(account, state))
);

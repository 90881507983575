import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  FolderCommentsFacadeService,
  FoldersFacadeService
} from '@pinnakl/poems/folders/data-access';
import { Folder, FolderComment, FolderComments } from '@pinnakl/poems/folders/domain';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Observable, catchError, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'folder-comments-table',
  templateUrl: './folder-comments-table.component.html',
  styleUrls: ['./folder-comments-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderCommentsTableComponent implements OnInit {
  @ViewChild(GridComponent) grid?: GridComponent;
  @Input() folderCode?: string;
  gridView?: GridDataResult;
  sort: SortDescriptor[] = [];

  folderComments$?: Observable<FolderComments | undefined>;

  constructor(
    private readonly spinner: PinnaklSpinnerService,
    private readonly cdr: ChangeDetectorRef,
    private readonly toastr: PinnaklUIToastMessage,
    private readonly foldersFacadeService: FoldersFacadeService,
    private readonly folderCommentsFacadeService: FolderCommentsFacadeService
  ) {}

  ngOnInit() {
    this.spinner.spin();
    if (this.folderCode) {
      this.folderComments$ = this.foldersFacadeService.folders$.pipe(
        map(folders => folders.find(f => f.folderCode === this.folderCode)),
        switchMap((folder: Folder | undefined) => {
          if (folder) {
            this.folderCommentsFacadeService.loadFolderCommentsById(folder.id);
            return this.folderCommentsFacadeService.getFolderCommentsById(folder.id);
          } else {
            this.foldersFacadeService.loadFolders();
            return of(undefined);
          }
        }),
        tap(folderComments => {
          if (folderComments) {
            this.renderGrid(folderComments.comments);
            this.spinner.stop();
          }
        }),
        catchError(error => {
          this.spinner.stop();
          this.toastr.error(error);
          console.error('Error while load folder comments', error);
          throw error;
        })
      );
    }
  }

  sortChange(sort: SortDescriptor[], comments: FolderComment[]): void {
    this.sort = sort;
    this.renderGrid(comments);
  }

  private renderGrid(folderComments: FolderComment[]): void {
    this.gridView = {
      data: orderBy(folderComments, this.sort),
      total: folderComments.length
    };
    this.grid?.autoFitColumns();
    this.cdr.markForCheck();
  }
}

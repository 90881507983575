import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BenchmarkIndexIntradayPricesState } from './benchmark-index-intraday-prices.model';

const getBenchmarkIndexesFeatureSelector = createFeatureSelector<{
  benchmarkIndexIntradayPrices: BenchmarkIndexIntradayPricesState;
}>('pricing');

const benchmarkIndexesSelector = createSelector(
  getBenchmarkIndexesFeatureSelector,
  ({ benchmarkIndexIntradayPrices: { benchmarkIndexes } }) => benchmarkIndexes
);
const isInPollingSelector = createSelector(
  getBenchmarkIndexesFeatureSelector,
  ({ benchmarkIndexIntradayPrices: { isInPollingState } }) => isInPollingState
);
const mainAccountIdSelector = createSelector(
  getBenchmarkIndexesFeatureSelector,
  ({ benchmarkIndexIntradayPrices: { mainAccountId } }) => mainAccountId
);

export const benchmarkIndexIntradayPricesQuery = {
  benchmarkIndexesSelector,
  isInPollingSelector,
  mainAccountIdSelector
};

@if (inputIconTemplate) {
  <div class="relative w-full">
    <ng-container *ngTemplateOutlet="inputIconTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="multiselectComponent"></ng-container>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="multiselectComponent"></ng-container>
}

<ng-template #multiselectComponent>
  <p-multiSelect
    (onBlur)="onBlur.emit($event)"
    (onChange)="onChange.emit($event)"
    (onClear)="onClear.emit($event)"
    (onClick)="onClick.emit($event)"
    (onFilter)="onFilter.emit($event)"
    (onFocus)="onFocus.emit($event)"
    (onLazyLoad)="onLazyLoad.emit($event)"
    (onPanelHide)="onPanelHide.emit($event)"
    (onPanelShow)="onPanelShow.emit($event)"
    (onRemove)="onRemove.emit($event)"
    [appendTo]="appendTo"
    [ariaFilterLabel]="ariaFilterLabel"
    [ariaLabelledBy]="ariaLabelledBy"
    [autoOptionFocus]="autoOptionFocus"
    [autocomplete]="autocomplete"
    [autofocusFilter]="autofocusFilter"
    [autofocus]="autofocus"
    [dataKey]="dataKey"
    [disabled]="disabled"
    [displaySelectedLabel]="displaySelectedLabel"
    [display]="display"
    [dropdownIcon]="dropdownIcon"
    [emptyFilterMessage]="emptyFilterMessage"
    [emptyMessage]="emptyMessage"
    [filterBy]="filterBy"
    [filterLocale]="filterLocale"
    [filterMatchMode]="filterMatchMode"
    [filterPlaceHolder]="filterPlaceHolder"
    [filterValue]="filterValue"
    [filter]="filter"
    [formControl]="$any(formControl)"
    [group]="group"
    [inputId]="inputId"
    [lazy]="lazy"
    [maxSelectedLabels]="maxSelectedLabels"
    [name]="name"
    [ngClass]="{
      'with-input-icon': !!inputIconTemplate,
      rounded: rounded,
      'size-sm': size === 'sm',
      'fill-filled': fill === 'filled',
      'fill-ghosted': fill === 'ghosted',
      'fill-outlined': fill === 'outlined'
    }"
    [optionDisabled]="optionDisabled"
    [optionGroupChildren]="optionGroupChildren"
    [optionGroupLabel]="optionGroupLabel"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [options]="options"
    [overlayOptions]="overlayOptions"
    [overlayVisible]="overlayVisible"
    [panelStyleClass]="panelStyleClass"
    [panelStyle]="panelStyle"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [resetFilterOnHide]="resetFilterOnHide"
    [scrollHeight]="scrollHeight"
    [selectedItemsLabel]="selectedItemsLabel"
    [selectionLimit]="selectionLimit"
    [showClear]="showClear"
    [showHeader]="showHeader"
    [showToggleAll]="showToggleAll"
    [styleClass]="styleClass"
    [style]="style"
    [tabindex]="tabindex"
    [tooltipPositionStyle]="tooltipPositionStyle"
    [tooltipPosition]="tooltipPosition"
    [tooltipStyleClass]="tooltipStyleClass"
    [tooltip]="tooltip"
    [virtualScrollItemSize]="virtualScrollItemSize"
    [virtualScrollOptions]="virtualScrollOptions"
    [virtualScroll]="virtualScroll"
    class="w-full"
  >
    <ng-container *ngIf="headerTemplate">
      <ng-template pTemplate="header">
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="selectAllHeader">
      <ng-template pTemplate="header">
        <div
          (click)="!disabled && multiSelect?.onToggleAll($event)"
          class="flex align-items-center w-full"
        >
          <div
            (keydown)="!disabled && multiSelect?.onHeaderCheckboxKeyDown?.($event)"
            [ngClass]="{ 'p-checkbox-disabled': disabled }"
            class="p-checkbox p-component"
          >
            <div
              [attr.data-p-hidden-accessible]="true"
              class="p-hidden-accessible"
            >
              <input
                #headerCheckbox
                (blur)="multiSelect?.onHeaderCheckboxBlur()"
                (focus)="multiSelect?.onHeaderCheckboxFocus()"
                [attr.aria-label]="multiSelect?.toggleAllAriaLabel"
                [attr.checked]="multiSelect?.allSelected()"
                [disabled]="disabled"
                [readonly]="readonly"
                id="selectAll"
                type="checkbox"
              />
            </div>
            <div
              [attr.aria-checked]="multiSelect?.allSelected()"
              [ngClass]="{
                'p-highlight': multiSelect?.allSelected(),
                'p-focus': multiSelect?.headerCheckboxFocus,
                'p-disabled': multiSelect?.disabled
              }"
              class="p-checkbox-box"
              role="checkbox"
            >
              <ng-container *ngIf="multiSelect?.allSelected()">
                <CheckIcon
                  [attr.aria-hidden]="true"
                  [styleClass]="'p-checkbox-icon'"
                />
              </ng-container>
            </div>
          </div>
          <label
            class="select-label cursor-pointer"
            for="selectAll"
          >
            {{ multiSelect?.allSelected() ? 'Deselect All' : 'Select All' }}
          </label>
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="filterTemplate">
      <ng-template pTemplate="filter">
        <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="removetokeniconTemplate">
      <ng-template pTemplate="removetokenicon">
        <ng-container *ngTemplateOutlet="removetokeniconTemplate"></ng-container>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="dropdowniconTemplate">
      <ng-template pTemplate="dropdownicon">
        <ng-container *ngTemplateOutlet="dropdowniconTemplate"></ng-container>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="groupItemsTemplate">
      <ng-template
        let-group
        pTemplate="group"
      >
        <ng-container
          [ngTemplateOutletContext]="{ $implicit: group }"
          [ngTemplateOutlet]="groupItemsTemplate"
        ></ng-container>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="selectedItemsTemplate">
      <ng-template
        let-selectedItems
        pTemplate="selectedItems"
      >
        <ng-container
          [ngTemplateOutletContext]="{ $implicit: selectedItems }"
          [ngTemplateOutlet]="selectedItemsTemplate"
        ></ng-container>
      </ng-template>
    </ng-container>
    <ng-template
      *ngIf="itemTemplate"
      let-item
      pTemplate="item"
    >
      <ng-container
        [ngTemplateOutletContext]="{ $implicit: item }"
        [ngTemplateOutlet]="itemTemplate"
      ></ng-container>
    </ng-template>
  </p-multiSelect>
  <ng-container *ngTemplateOutlet="validationContent"></ng-container>
</ng-template>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PositionsGeneralFacadeService } from './positions-general';
import { PositionsGeneralApiService } from './positions-general/positions-general-api.service';
import { PositionsGeneralEffects } from './positions-general/positions-general.effects';
import { positionsGeneralReducer } from './positions-general/positions-general.reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([PositionsGeneralEffects]),
    StoreModule.forFeature('positions-general', positionsGeneralReducer)
  ],
  providers: [PositionsGeneralApiService, PositionsGeneralFacadeService]
})
export class PositionsDataAccessModule {}

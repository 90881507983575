import { Injectable } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, RowDataTransaction } from 'ag-grid-community';

@Injectable()
export class GridManagerService {
  private gridApi: GridApi;

  get isGridReady(): boolean {
    return !!this.gridApi;
  }

  get gridSize(): number {
    return this.gridApi.getDisplayedRowCount();
  }

  setGridInstance(params: GridReadyEvent): void {
    this.gridApi = params?.api;
  }

  setRowData<T>(rowData: T[]): void {
    this.gridApi.setGridOption('rowData', rowData);
  }

  setColDefs(colDefs: ColDef[]): void {
    this.gridApi.setGridOption('columnDefs', colDefs);
  }

  setColumnVisibility(key: string, isVisible: boolean): void {
    this.gridApi.setColumnsVisible([key], isVisible);
  }

  asyncUpdateRowData<T>(rowData: T[], cb?: () => void): void {
    const transactionDetails: RowDataTransaction = {
      update: rowData
    };

    this.gridApi.applyTransactionAsync(transactionDetails, cb);
  }

  recalculateRowsHeight(): void {
    this.gridApi.resetRowHeights();
  }

  refreshCells(): void {
    this.gridApi.refreshCells();
  }

  redrawRows(): void {
    this.gridApi.redrawRows();
  }

  private addRowToEnd<T>(row: T): void {
    const lastRowIndex = this.gridApi.getLastDisplayedRow();
    const transactionDetails: RowDataTransaction = {
      addIndex: lastRowIndex,
      add: [row]
    };

    this.gridApi.applyTransaction(transactionDetails);
  }

  private updateExistingRow<T>(row: T): void {
    const transactionDetails: RowDataTransaction = {
      update: [row]
    };

    this.gridApi.applyTransaction(transactionDetails);
  }
}

import { Component, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'cell-inline-svg',
  standalone: true,
  template: ` <div
    [innerHTML]="safeHtml"
    (click)="params.onClick?.()"
  ></div>`
})
export class CellInlineSvgComponent implements ICellRendererAngularComp {
  private sanitizer: DomSanitizer;
  public safeHtml: SafeHtml;
  public params: ICellRendererParams & { onClick?: () => void };

  constructor() {
    this.sanitizer = inject(DomSanitizer);
  }

  agInit(params: ICellRendererParams & { onClick?: () => void }): void {
    this.params = params;
    const inlineSvg = this.getInlineSVG(params);
    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(inlineSvg);
  }

  refresh(): boolean {
    return true;
  }

  private getInlineSVG(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
}

<p-inputMask
  (onBlur)="onBlur.emit($event)"
  (onClear)="onClear.emit($event)"
  (onComplete)="onComplete.emit($event)"
  (onFocus)="onFocus.emit($event)"
  (onInput)="onInput.emit($event)"
  (onKeydown)="onKeydown.emit($event)"
  [ariaLabel]="ariaLabel"
  [ariaRequired]="ariaRequired"
  [autoClear]="autoClear"
  [autoFocus]="autoFocus"
  [autocomplete]="autocomplete"
  [characterPattern]="characterPattern"
  [formControl]="$any(formControl)"
  [inputId]="inputId"
  [keepBuffer]="keepBuffer"
  [mask]="mask"
  [maxlength]="maxlength"
  [name]="name"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [required]="required"
  [showClear]="showClear"
  [size]="size"
  [slotChar]="slotChar"
  [styleClass]="styleClass"
  [style]="style"
  [tabindex]="tabindex"
  [title]="title"
  [type]="type"
  [unmask]="unmask"
></p-inputMask>

import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@pinnakl/core/data-providers';
import { ENVIRONMENT_SERVICE, EnvironmentService } from '@pinnakl/core/environment';
import {
  AppNames,
  IUser,
  SessionInformation,
  SessionInformationFromApi,
  TwoFactorType,
  UserLoginFromApi,
  UserTypes
} from '@pinnakl/shared/types';
import { getAppInstanceToken } from '@pinnakl/shared/util-helpers';
import {
  PinnaklFingerprintService,
  PinnaklSpinnerService,
  PinnaklUIToastMessage
} from '@pinnakl/shared/util-providers';
import {
  AuthStateResult,
  EventTypes,
  OidcSecurityService,
  PublicEventsService,
  ValidationResult
} from 'angular-auth-oidc-client';
import moment from 'moment';
import { BehaviorSubject, Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthSessionInfo, DEFAULTSCREEN, USERTYPE } from './models';

declare const require: any;
const packageJson = require('../../../../../package.json');

export interface Credentials {
  username: string;
  password: string;
  otp?: string;
}

interface Claim {
  sub: string;
  oi_au_id: string;
  azp: string;
  nonce: string;
  at_hash: string;
  oi_tkn_id: string;
  aud: string;
  exp: number;
  iss: string;
  iat: number;
}

const DEFAULT_SESSION = {
  visible: false,
  activeSessions: []
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  private ROUTE_AFTER_LOGIN = '/';
  sessionsInformation$: BehaviorSubject<AuthSessionInfo> = new BehaviorSubject<AuthSessionInfo>(
    DEFAULT_SESSION
  );
  refreshingAuthState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userData$: BehaviorSubject<Claim | null> = new BehaviorSubject<Claim | null>(null);
  version$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  errorMessage$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  otpSecret$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  isAuthenticated$: Observable<boolean> = this.oidcSecurityService.isAuthenticated$.pipe(
    switchMap(result => {
      const isAuthenticated = !!result?.isAuthenticated;
      return isAuthenticated ? this.userService.userAvailable$.asObservable() : of(isAuthenticated);
    })
  );
  isLoggingOff$ = new BehaviorSubject(false);
  baseUrl?: string;
  version?: string;
  application = 'Desktop';
  productionMode = false;
  appName?: AppNames;

  get getSearchParams() {
    return this.document?.location?.search ?? '';
  }

  get fingerprint() {
    return this.userFingerprintService?.fingerprint?.visitorId ?? '';
  }

  get configId() {
    const authConfig = this.envService.get('authConfig');
    return getAppInstanceToken(authConfig?.configId, location?.host, this.appName);
  }

  get authUrls(): Record<
    'login' | 'linkLogin' | 'generateLink' | 'verifyotp' | 'verifyQrOtp',
    string
  > {
    return {
      login: `${this.baseUrl}/account/login`,
      linkLogin: `${this.baseUrl}/account/link/login`,
      generateLink: `${this.baseUrl}/account/link`,
      verifyotp: `${this.baseUrl}/account/verifyotp`,
      verifyQrOtp: `${this.baseUrl}/account/totp/verify`
    };
  }

  get authWellKnownEndPoints(): Record<string, string> {
    try {
      const authData = localStorage.getItem(this.configId);
      const authDataParsed = JSON.parse(authData ?? '{}');
      const endpoints = authDataParsed?.authWellKnownEndPoints ?? {};
      const { issuer, ...rest } = endpoints;
      return { ...rest, ...this.authUrls };
    } catch (e) {
      return {};
    }
  }

  get isLoggingOff(): boolean {
    return this.isLoggingOff$.value;
  }

  set isLoggingOff(value: boolean) {
    this.isLoggingOff$.next(value);
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(DEFAULTSCREEN) readonly defaultScreen: { prod: string; dev: string },
    @Inject(USERTYPE) readonly userType: UserTypes,
    @Inject(ENVIRONMENT_SERVICE) readonly envService: EnvironmentService,
    private readonly router: Router,
    private readonly toast: PinnaklUIToastMessage,
    private readonly spinner: PinnaklSpinnerService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly eventService: PublicEventsService,
    private readonly userService: UserService,
    private readonly http: HttpClient,
    private readonly userFingerprintService: PinnaklFingerprintService
  ) {}

  // Should be initialized inside base app.component constructor
  init() {
    this.log({
      action: 'AuthService:init'
    });
    this.productionMode = this.envService.get('production');
    this.baseUrl = this.envService.get('authConfig')?.authority;
    this.appName = this.envService.get('appName');
    this.checkAppVersion();
    this.version$.next(packageJson.version);
    this.ROUTE_AFTER_LOGIN = this.productionMode ? this.defaultScreen.prod : this.defaultScreen.dev;
    console.log('Login page: PROD:', this.productionMode);
    console.log('Login page: DEFAULTSCREEN:', this.ROUTE_AFTER_LOGIN);
    window.onfocus = () => !isDevMode && this.checkUserSession();
    this.listenToAccessTokenRenew();
  }

  getIsAuthenticated(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated();
  }

  localLogout() {
    this.logout(true);
  }

  logout(local = false): boolean {
    if (this.isLoggingOff) return false;
    this.isLoggingOff = true;
    this.spinner.spin('auth');
    const user = this.userService.getUser();
    user?.firstName && localStorage.setItem('name', user?.firstName);
    this.userService.removeUser();
    if (local) {
      this.oidcSecurityService.logoffLocal();
      this.oidcSecurityService.authorize();
    } else {
      this.oidcSecurityService
        .logoffAndRevokeTokens(this.configId, {
          customParams: { redirect_uri: window.location.origin }
        })
        .subscribe();
    }
    return true;
  }

  forceLogout(): void {
    this.isLoggingOff = false;
    this.logout();
  }

  getAccessToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  updateQrSecret(callback: () => void): void {
    const httpOptions: Record<string, any> = {
      headers: new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8'),
      responseType: 'text' as const,
      withCredentials: true
    };
    this.http
      .get<string>(`${this.baseUrl}/account/totp/secret`, httpOptions)
      .pipe(
        take(1),
        tap(otpSecret => {
          if (otpSecret) {
            this.otpSecret$.next(otpSecret.replace(/^"|"$/g, ''));
            callback?.();
          }
        })
      )
      .subscribe();
  }

  deactivateAllSessions(activeSessions: SessionInformation[], callback: () => void): void {
    this.spinner.spin('auth');
    forkJoin(activeSessions.map(as => this.deAuthenticate(as.userToken)))
      .pipe(
        take(1),
        catchError(e => this.handleApiError(e, 'Failed to deactivate this session'))
      )
      .subscribe(res => {
        if (res) {
          this.sessionsInformation$.next(DEFAULT_SESSION);
          callback();
        }
        this.spinner.stop('auth');
      });
  }

  passwordResetDone() {
    this.checkAuthAndGetUser();
  }

  navigateAsAuthUser() {
    this.log({
      action: 'AuthService:navigateAsAuthUser'
    });
    this.router.navigate([this.ROUTE_AFTER_LOGIN]).then(() => {
      this.spinner.stop('auth');
    });
  }

  redirectToAuthApp() {
    this.startAuth();
  }

  checkAuthCodeAndProceedAuth(): void {
    this.checkAuthAndGetUser();
  }

  silentlyFetchUser(): void {
    if (this.isLoggingOff) return;
    this.checkAuthAndGetUser(false);
  }

  log(message) {
    // if (this.envService.get('appName') === AppNames.CRM_INVESTOR_PORTAL && this.envService.get('production') === true) {
    //   this.http.post(...this.logHelper.createLogRequestArgs(message)).subscribe();
    // }
  }

  private startAuth(): void {
    this.log({
      action: `AuthService:startAuth:config=${JSON.stringify(localStorage.getItem(this.configId))}`
    });
    this.oidcSecurityService.authorize();
  }

  private checkAuthAndGetUser(withLoading = true) {
    this.log({
      action: `AuthService:checkAuthAndGetUser:config=${JSON.stringify(
        localStorage.getItem(this.configId)
      )}`
    });
    withLoading && this.spinner.spin('auth');
    this.refreshingAuthState.next(true);
    this.oidcSecurityService
      .checkAuth()
      .pipe(
        take(1),
        switchMap((res: { accessToken: string; userData: Claim | null } | null) => {
          this.log({
            action: `AuthService:checkAuthAndGetUser:checkAuth:switchMap=${JSON.stringify(res)}`
          });
          if (res?.userData) {
            this.userData$.next(res.userData);
          }
          return res?.accessToken ? this.getUserInfo(res?.accessToken) : of(null);
        })
      )
      .subscribe((response: UserLoginFromApi | null) => {
        this.log({
          action: `AuthService:checkAuthAndGetUser:checkAuth:subscribe=${JSON.stringify(response)}`
        });
        if (response) {
          const userToSave: IUser = this.userService.formatLoginUser({
            ...response,
            username: this.userData$.value?.sub ?? ''
          });
          response?.user.otpSecret &&
            response?.user.otpChannel === TwoFactorType.QR &&
            this.otpSecret$.next(response?.user.otpSecret);
          this.userService.setUser(userToSave);
          this.refreshingAuthState.next(false);
          withLoading && this.navigateAsAuthUser();
          return;
        }
        this.refreshingAuthState.next(false);
        withLoading && this.spinner.stop('auth');
      });
  }

  private setCurrentAppVersion() {
    localStorage.setItem('appVersion', packageJson.version);
  }

  private isLatestAppVersion(): boolean {
    const appVersion = localStorage.getItem('appVersion');
    if (!appVersion) return false;
    return packageJson.version === appVersion;
  }

  private checkAppVersion() {
    if (!this.isLatestAppVersion()) {
      localStorage.removeItem(this.configId);
      this.localLogout();
    }
    this.setCurrentAppVersion();
  }

  private deAuthenticate(sessionToken: string) {
    return this.http.delete('/auth', {
      withCredentials: true,
      headers: { Authorization: `Bearer ${sessionToken}` }
    });
  }

  private checkUserSession() {
    this.oidcSecurityService
      .checkAuth()
      .pipe(take(1))
      .subscribe(result => {
        const isAuthenticated = !!result?.isAuthenticated;
        const user = this.userService.getUser();
        if (!isAuthenticated || !user) {
          this.localLogout();
        }
        this.checkAuthAndGetUser(false);
      });
  }

  private listenToAccessTokenRenew() {
    this.eventService
      .registerForEvents()
      .pipe(
        filter(notification => notification.type === EventTypes.NewAuthenticationResult),
        map(result => {
          const {
            value: { isAuthenticated, validationResult }
          } = result as { value: AuthStateResult };
          return isAuthenticated && validationResult === ValidationResult.Ok;
        }),
        switchMap(isAuthenticated => {
          !isAuthenticated && this.refreshingAuthState.next(!isAuthenticated);
          return !isAuthenticated
            ? this.oidcSecurityService.forceRefreshSession()
            : of({ isAuthenticated });
        })
      )
      .subscribe(result => {
        const isAuthenticated = !!result?.isAuthenticated;
        if (isAuthenticated) {
          !this.refreshingAuthState.getValue() && this.checkAuthAndGetUser(false);
        } else {
          this.localLogout();
        }
        this.refreshingAuthState.next(!isAuthenticated);
      });
    this.eventService
      .registerForEvents()
      .pipe(
        filter(notification =>
          [EventTypes.TokenExpired, EventTypes.IdTokenExpired].includes(notification.type)
        )
      )
      .subscribe(() => this.refreshingAuthState.next(true));
  }

  private getUserInfo(accessToken: string): Observable<UserLoginFromApi | null> {
    this.log({
      action: `AuthService:getUserInfo`
    });
    return this.http
      .get<UserLoginFromApi>(`${this.baseUrl}/connect/userinfo`, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .pipe(
        map(userinfo => {
          if (userinfo?.user?.userType !== this.userType) {
            throw new Error('Inapplicable user type');
          }
          return userinfo;
        }),
        catchError((e: HttpErrorResponse) => {
          console.error('ERROR', e);
          this.forceLogout();
          return of(null);
        })
      );
  }

  private handleApiError(e, toastMessage: string): Observable<any> {
    this.spinner.stop('auth');
    this.toast.error(toastMessage);
    return throwError(() => e);
  }

  private handleSessionsError(error): { message: string; activeSessions: SessionInformation[] } {
    const { message: errorMessage } = error;
    if (!errorMessage.toLowerCase().includes('too many logins')) {
      throw new Error(errorMessage);
    }
    const activeSessionsStringStartingIndex = errorMessage.indexOf('[');
    const activeSessionsStringEndingIndex = errorMessage.lastIndexOf(']') + 1;
    const activeSessionsString = errorMessage.slice(
      activeSessionsStringStartingIndex,
      activeSessionsStringEndingIndex
    );
    let activeSessions: SessionInformation[] = [];
    try {
      const activeSessionsFromApi: SessionInformationFromApi[] = JSON.parse(activeSessionsString);
      activeSessions = activeSessionsFromApi.map(this.formatSessionInformation);
    } catch (err) {
      console.error('Error:', err);
    }
    return {
      message: 'Too many logins',
      activeSessions
    };
  }

  private formatSessionInformation(entity: SessionInformationFromApi): SessionInformation {
    const createdBy = parseInt(entity.createdby),
      createdDateMoment = moment.utc(entity.createddate, 'MM/DD/YYYY hh:mm:ss a'),
      id = parseInt(entity.id),
      updatedBy = parseInt(entity.updatedby),
      updatedDateMoment = moment.utc(entity.updateddate, 'MM/DD/YYYY hh:mm:ss a'),
      userId = parseInt(entity.userid);
    return {
      active: entity.active === 'True',
      browser: entity.browser,
      city: entity.city,
      country: entity.country,
      createdBy: !isNaN(createdBy) ? createdBy : null,
      createdDate: createdDateMoment.isValid() ? createdDateMoment.toDate() : null,
      deviceDetail: entity.devicedetail,
      fingerprint: entity.fingerprint,
      id: !isNaN(id) ? id : null,
      ipAddress: entity.ipaddress,
      language: entity.language,
      mobileToken: entity.mobiletoken,
      os: entity.os,
      screenresolution: entity.screenresolution,
      timezone: entity.timezone,
      updatedBy: !isNaN(updatedBy) ? updatedBy : null,
      updatedDate: updatedDateMoment.isValid() ? updatedDateMoment.toDate() : null,
      userId: !isNaN(userId) ? userId : null,
      userToken: entity.usertoken
    };
  }
}

import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { CRMOptions, Investor, OrganizationFromApi } from '@pinnakl/crm/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvestorService {
  private readonly _crmOrganizationsEndpoint = 'entities/crm_investors';
  private readonly _crmOrganizationLastCommunicationSearchEndpoint =
    'entities/crm_investor_last_communication_search';
  private readonly _crmContactMigrationEndpoint = `${V3_ENDPOINT}contacts/migrate`;
  private readonly _CRMOptionsV3Endpoint = 'v3/entities/crm_options';

  private _CRMOptions!: CRMOptions;

  constructor(private readonly wsp: WebServiceProvider) {}

  getOrganizations(): Promise<Investor[]> {
    return this.wsp
      .getHttp<OrganizationFromApi[]>({
        endpoint: this._crmOrganizationsEndpoint,
        optionsParams: {
          fields: ['Id', 'InvestorName']
        }
      })
      .then(entities => entities.map(entity => this.formatOrganization(entity)));
  }

  getOrganizationsByLastCommunication(
    daysSinceLastCommunication: number
  ): Promise<{ id: number }[]> {
    return this.wsp
      .getHttp<{ id: string }[]>({
        endpoint: this._crmOrganizationLastCommunicationSearchEndpoint,
        optionsParams: {
          filters: [
            {
              key: 'daysSinceLastCommunication',
              type: 'EQ',
              value: [daysSinceLastCommunication.toString()]
            }
          ]
        }
      })
      .then(entities =>
        entities.map(entity => {
          const id = parseInt(entity.id, 10);
          return { id: !isNaN(id) ? id : 0 };
        })
      );
  }

  migrateContact(payload: {
    contactId: number;
    previousOrganizationId: number;
    newOrganizationId: number;
  }): Promise<any> {
    return this.wsp.postHttp({
      endpoint: this._crmContactMigrationEndpoint,
      body: payload
    });
  }

  getOrganizationsBySearchCriteria(
    criteria: string,
    criteriaType = 'InvestorName'
  ): Promise<Investor[]> {
    return this.wsp
      .getHttp<OrganizationFromApi[]>({
        endpoint: this._crmOrganizationsEndpoint,
        optionsParams: {
          fields: ['Id', 'InvestorName', 'StatusId'],
          filters: [{ key: criteriaType, type: 'LIKE', value: [criteria.toString()] }]
        }
      })
      .then(entities => entities.map(entity => this.formatOrganization(entity)));
  }

  // TODO: Unify with CrmApiService - duplicates
  getOrganizationNameById(id: string): Observable<Investor[]> {
    return this.wsp
      .get<OrganizationFromApi[]>({
        endpoint: this._crmOrganizationsEndpoint,
        optionsParams: {
          fields: ['Id', 'InvestorName'],
          filters: [{ key: 'Id', type: 'LIKE', value: [id] }]
        }
      })
      .pipe(map(entities => entities.map(entity => this.formatOrganization(entity))));
  }

  async getOptionsV2(reloadData = false): Promise<CRMOptions> {
    if (this._CRMOptions && !reloadData) {
      return Promise.resolve(this._CRMOptions);
    }
    this._CRMOptions = await this.wsp.getHttp<CRMOptions>({
      endpoint: this._CRMOptionsV3Endpoint,
      optionsParams: {
        fields: ['*']
      }
    });
    this._CRMOptions.users = this._CRMOptions.users.map(user => ({
      ...user,
      fullName: `${user.firstName} ${user.lastName}`,
      id: +user.id
    }));
    this._CRMOptions.phonesTypes = this._CRMOptions.phonesTypes.map(type => ({
      ...type,
      key: type.name.toLowerCase()
    }));
    this._CRMOptions.targetFunds = this._CRMOptions.targetFunds.map(tF => ({
      ...tF,
      targetFundId: tF.id
    }));
    this._CRMOptions.relationshipManagers = this._CRMOptions.relationshipManagers.map(tF => ({
      ...tF,
      fullName: tF.relMgrName
    }));

    return Promise.resolve(this._CRMOptions);
  }

  formatOrganization(entity: OrganizationFromApi): Investor {
    const creationDate = moment(entity.creationdate, 'MM/DD/YYYY'),
      id = parseInt(entity.id, 10),
      modifiedDate = moment(entity.modifieddate, 'MM/DD/YYYY'),
      primaryContactId = parseInt(entity.primarycontactid, 10),
      relationshipManagerId = parseInt(entity.relationshipmgrid, 10);

    return {
      aum: entity.aum,
      ciIntro: entity.ciintro,
      creationDate: creationDate.isValid() ? creationDate.toDate() : null,
      id: !isNaN(id) ? id : null,
      investedWith: entity.investedwith,
      investorCategory: entity.investorcategory,
      investorName: entity.investorname,
      isArchived: entity.isarchived === 'True',
      leadOrigination: entity.leadorigination,
      leadOriginator: entity.leadoriginator,
      modifiedDate: modifiedDate.isValid() ? modifiedDate.toDate() : null,
      notes: entity.notes,
      primaryContactId: !isNaN(primaryContactId) ? primaryContactId : null,
      relationshipManagerId: !isNaN(relationshipManagerId) ? relationshipManagerId : null,
      statusId: entity.statusid ? parseInt(entity.statusid, 10) : null,
      website: entity.website,
      isInvestor: entity.isinvestor,
      addresses: [],
      targetFunds: []
    };
  }
}

import { createAction, props } from '@ngrx/store';
import { PositionsBaseInfo } from '@pinnakl/poems/positions/domain';

export enum PositionsGeneralActions {
  LoadPositionsBase = '[Positions General] Load positions base per security market id',
  PositionsBaseLoaded = '[Positions General] Positions base per security market id loaded',
  UpdatePriceLast = '[Positions General] Update price last'
}

export const loadPositionsBase = createAction(
  PositionsGeneralActions.LoadPositionsBase,
  props<{ securityMarketId: string }>()
);

export const positionsBaseLoaded = createAction(
  PositionsGeneralActions.PositionsBaseLoaded,
  props<{ securityMarketId: string; positionBase: PositionsBaseInfo }>()
);

export const updatePriceLast = createAction(
  PositionsGeneralActions.UpdatePriceLast,
  props<{ securityMarketId: string; priceLast: number }>()
);

import { createAction, props } from '@ngrx/store';
import { Account } from '@pinnakl/poems/accounts/domain';

export enum AccountsActions {
  Load = '[Accounts] Load accounts',
  loadFailure = '[Accounts] Load accounts error',
  SetAccounts = '[Accounts] Set accounts',
  Add = '[Accounts] Add account'
}

export const load = createAction(AccountsActions.Load);
export const loadFailure = createAction(AccountsActions.loadFailure, props<{ error: any }>());
export const setAccounts = createAction(
  AccountsActions.SetAccounts,
  props<{ accounts: Account[] }>()
);
export const addAccount = createAction(AccountsActions.Add, props<{ account: Account }>());

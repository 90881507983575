import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  BenchmarkIndexIntradayPricesApiService,
  BenchmarkIndexIntradayPricesEffects,
  BenchmarkIndexIntradayPricesFacadeService,
  benchmarkIndexIntradayPricesReducer
} from './benchmark-index-intraday-prices';
import { ClosePriceOverrideFacadeService } from './close-price-override';
import { ClosePriceOverrideApiService } from './close-price-override/close-price-override-api.service';
import { RealtimePriceOverrideFacadeService } from './realtime-price-override';
import { RealtimePriceOverrideApiService } from './realtime-price-override/realtime-price-override-api.service';
import { RealtimePriceOverrideEffects } from './realtime-price-override/realtime-price-override.effects';
import { realtimePriceOverrideReducer } from './realtime-price-override/realtime-price-override.reducer';

const featureName = 'pricing';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureName, {
      realtimePriceOverride: realtimePriceOverrideReducer,
      benchmarkIndexIntradayPrices: benchmarkIndexIntradayPricesReducer
    }),
    EffectsModule.forFeature([RealtimePriceOverrideEffects, BenchmarkIndexIntradayPricesEffects])
  ],
  providers: [
    RealtimePriceOverrideApiService,
    ClosePriceOverrideApiService,
    RealtimePriceOverrideFacadeService,
    ClosePriceOverrideFacadeService,
    BenchmarkIndexIntradayPricesApiService,
    BenchmarkIndexIntradayPricesFacadeService
  ]
})
export class PricingDataAccessModule {}

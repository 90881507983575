import { inject, NgModule } from '@angular/core';
import { Router, RouterModule, Routes, UrlTree } from '@angular/router';
import { AuthenticatedGuardCanActivateChild } from '@pinnakl/auth/providers';
import { ENVIRONMENT_SERVICE, EnvironmentService, EnvNames } from '@pinnakl/core/environment';
import { TradeWorkflowSpecFacadeService } from '@pinnakl/poems/trade-workflow-spec/data-access';
import {
  checkPageCertainVersionAccessGuard,
  checkPageVersionsAccess
} from '@pinnakl/poems/trade-workflow-spec/shared';
import { RedirectComponent } from '@pinnakl/shared/base-components';
import { AppNames } from '@pinnakl/shared/types';
import { map } from 'rxjs/operators';

const pmsAccessPageGuard = (pageVersion: 'pms' | 'v1' | 'v2') => () => {
  const envService = inject(EnvironmentService);
  if (envService.get('envName') !== EnvNames.prod) {
    return true;
  }

  const router = inject(Router);
  const tradeWorkflowSpecFacade = inject(TradeWorkflowSpecFacadeService);

  return tradeWorkflowSpecFacade.entities$.pipe(
    map(spec => {
      if (
        spec.pmsVersionsAccessLevel === 'V1_AND_V2' ||
        (spec.pmsVersionsAccessLevel === 'V1_ONLY' && pageVersion === 'v1') ||
        (spec.pmsVersionsAccessLevel === 'V2_ONLY' && pageVersion === 'v2')
      ) {
        return true;
      }
      if (spec.pmsVersionsAccessLevel === 'V1_ONLY') {
        return router.parseUrl('pms/v1');
      }
      if (spec.pmsVersionsAccessLevel === 'V2_ONLY') {
        return router.parseUrl('pms/v2');
      }
      return false;
    })
  );
};

const authRoutes: Routes = [
  {
    path: 'cash-flow',
    loadChildren: () => import('../app/cash-flow/cash-flow.module').then(m => m.CashFlowModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('@pinnakl/authentication').then(m => m.SettingsModule),
    data: { projectName: AppNames.PLATFORM_WEB }
  },
  {
    path: 'corporate-actions',
    loadChildren: () =>
      import('./corporate-actions/corporate-actions-ui/corporate-actions-ui.module').then(
        m => m.CorporateActionsUiModule
      )
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard-ui/dashboard-ui.module').then(m => m.DashboardUiModule)
  },
  {
    path: 'help',
    loadChildren: () => import('@pinnakl/user-guide/shell').then(m => m.UserGuideShellModule)
  },
  {
    path: 'ems',
    pathMatch: 'full',
    loadChildren: () => import('@pinnakl/poems/ems/shell').then(m => m.EmsChooseVersionShellModule),
    canMatch: [checkPageVersionsAccess('emsVersionsAccessLevel', 'ems')]
  },
  {
    path: 'ems/v1',
    loadChildren: () => import('./ems/ems-ui/ems.module').then(m => m.EMSModule),
    canMatch: [checkPageCertainVersionAccessGuard('emsVersionsAccessLevel', 'V1_ONLY', 'ems')]
  },
  {
    path: 'ems/v2',
    loadChildren: () => import('@pinnakl/poems/ems/shell').then(m => m.EmsShellModule),
    canMatch: [checkPageCertainVersionAccessGuard('emsVersionsAccessLevel', 'V2_ONLY', 'ems')]
  },
  {
    path: 'rebalancing',
    pathMatch: 'full',
    loadChildren: () =>
      import('@pinnakl/rebalancing/shell').then(m => m.RebalancingChooseVersionShellModule)
  },
  {
    path: 'rebalancing/v1',
    loadChildren: () => import('./modeling/modeling-ui/modeling.module').then(m => m.ModelingModule)
  },
  {
    path: 'rebalancing/v2',
    loadChildren: () => import('@pinnakl/rebalancing/shell').then(m => m.RebalancingShellModule)
  },
  {
    path: 'oms',
    loadChildren: () => import('./oms/oms.module').then(m => m.OMSModule)
  },
  {
    path: 'pms',
    pathMatch: 'full',
    loadChildren: () => import('@pinnakl/pms/shell').then(m => m.PmsChooseVersionShellModule),
    canMatch: [pmsAccessPageGuard('pms')]
  },
  {
    path: 'pms/v1',
    loadChildren: () =>
      import('./positions/positions-ui/positions.module').then(m => m.PositionModule),
    canMatch: [pmsAccessPageGuard('v1')]
  },
  {
    path: 'pms/v2',
    loadChildren: () => import('@pinnakl/pms/shell').then(m => m.PmsShellModule),
    canMatch: [pmsAccessPageGuard('v2')]
  },
  {
    path: 'pricing',
    loadChildren: () => import('../app/pricing/pricing.module').then(m => m.PricingModule)
  },
  {
    path: 'performance-attribution',
    pathMatch: 'full',
    loadChildren: () =>
      import('@pinnakl/performance-attribution/shell').then(
        m => m.PerformanceChooseVersionShellModule
      ),
    canMatch: [
      () => {
        const envService = inject<EnvironmentService>(ENVIRONMENT_SERVICE);
        if (envService.get('envName') === EnvNames.prod) {
          return 'performance-attribution/v1';
        }
        return envService.get('envName') !== EnvNames.prod;
      }
    ]
  },
  {
    path: 'performance-attribution/v1',
    loadChildren: () => import('./profit-loss/profit-loss.module').then(m => m.ProfitLossModule)
  },
  {
    path: 'performance-attribution/v2',
    loadChildren: () =>
      import('@pinnakl/performance-attribution/shell').then(m => m.PERFORMANCE_ATTRIBUTION_ROUTES)
  },
  {
    path: 'reconciliation',
    loadChildren: () =>
      import('./reconciliation/reconciliation.module').then(m => m.ReconciliationModule)
  },
  {
    path: 'reporting',
    loadChildren: () => import('@pinnakl/reporting').then(m => m.ReportingModule),
    data: { projectName: AppNames.PLATFORM_WEB }
  },
  {
    path: 'risk',
    loadChildren: () => import('./risk/risk-ui/risk.module').then(m => m.RiskModule)
  },
  {
    path: 'securities',
    pathMatch: 'full',
    loadChildren: () =>
      import('@pinnakl/securities/shell').then(m => m.SecuritiesChooseVersionShellModule),
    canMatch: [
      (): boolean | UrlTree => {
        const router = inject(Router);
        const envService = inject<EnvironmentService>(ENVIRONMENT_SERVICE);
        if (envService.get('envName') !== EnvNames.prod) {
          return true;
        }

        return router.parseUrl(`/securities/v2`);
      },
      (): string | boolean => {
        const envService = inject<EnvironmentService>(ENVIRONMENT_SERVICE);
        if (envService.get('envName') === EnvNames.prod) {
          return 'securities/v1';
        }
        return envService.get('envName') !== EnvNames.prod;
      }
    ]
  },
  {
    path: 'securities/v1',
    loadChildren: () => import('./securities/securities.module').then(m => m.SecuritiesModule),
    canMatch: [
      (): boolean | UrlTree => {
        const router = inject(Router);
        const envService = inject<EnvironmentService>(ENVIRONMENT_SERVICE);
        if (envService.get('envName') !== EnvNames.prod) {
          return true;
        }

        return router.parseUrl(`/securities/v2`);
      }
    ]
  },
  {
    path: 'securities/v2',
    loadChildren: () => import('@pinnakl/securities/shell').then(m => m.SECURITIES_ROUTES)
  },
  {
    path: 'stock-loan',
    loadChildren: () => import('./stock-loan/stock-loan.module').then(m => m.StockLoanModule)
  },
  {
    path: 'shadow-nav',
    loadChildren: () =>
      import('./shadow-nav/shadow-nav-ui/shadow-nav.module').then(m => m.ShadowNAVModule)
  },
  {
    path: 'api-playground',
    loadChildren: () => import('@pinnakl/shared-ui').then(m => m.ApiPlaygroundModule)
  },
  {
    path: 'push-notification',
    loadChildren: () => import('@pinnakl/push-notifications').then(m => m.PushNotificationUiModule)
  },
  {
    path: 'redirect',
    component: RedirectComponent
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

const appRoutes: Routes = [
  {
    path: '',
    canActivateChild: [AuthenticatedGuardCanActivateChild],
    children: [...authRoutes]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

export enum SuccessUIToastMessageCategory {
  Success = 'Success',
  SuccessfullyCreated = 'Successfully Created'
}

export enum WarningUIToastMessageCategory {
  Warning = 'Warning',
  NoData = 'No Data'
}

export enum ErrorUIToastMessageCategory {
  Error = 'Error',
  RequestError = 'Request Error'
}

export enum InfoUIToastMessageCategory {
  Info = 'Info',
  NoChanges = 'No Changes'
}

export interface PinnaklUIToastMessageAPI {
  success(msg: string, category?: SuccessUIToastMessageCategory, options?: any): void;

  warning(msg: string, category?: WarningUIToastMessageCategory, options?: any): void;

  error(msg: string, category?: ErrorUIToastMessageCategory, options?: any): void;

  info(msg: string, category?: InfoUIToastMessageCategory, options?: any): void;
}

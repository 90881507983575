export enum CustomAttributeFeature {
  CONTACT = 'Contact',
  ORGANIZATION = 'Organization',
  SECURITY = 'Security'
}

export enum CustomAttributeMappingTable {
  CONTACT = 'c_CRMContacts',
  ORGANIZATION = 'c_Investors',
  SECURITY = 'c_Securities'
}

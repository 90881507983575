import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PmsOtherSettings, PmsSavedColumns, PmsWidgetsOrder } from '@pinnakl/pms/domain';
import { isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, take, withLatestFrom } from 'rxjs/operators';
import {
  loadPmsPresets,
  pmsDeleteColumnsConfig,
  pmsSaveOtherSettings,
  pmsSelectColumnsConfig,
  pmsSharePresets,
  savePmsColumnsConfig,
  savePmsWidgetsOrder
} from './presets.actions';
import { PmsPresetsState } from './presets.models';
import { pmsPresetsQuery } from './presets.selectors';

@Injectable()
export class PmsPresetsFacadeService {
  private _presetChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly otherSettings$ = this.store$
    .select(pmsPresetsQuery.pmsOtherSettingsConfigParsedValue)
    .pipe(filter(isNeitherNullNorUndefined));
  readonly fontSize$ = this.store$.select(pmsPresetsQuery.pmsOtherSettingsConfigParsedValue).pipe(
    filter(isNeitherNullNorUndefined),
    map(({ gridFontSize }) => gridFontSize)
  );
  readonly displayPositionSummary$ = this.store$
    .select(pmsPresetsQuery.pmsOtherSettingsConfigParsedValue)
    .pipe(
      filter(isNeitherNullNorUndefined),
      map(({ displayPositionSummaryWidget }) => displayPositionSummaryWidget)
    );
  readonly displayDeltaAdjustedExposure$ = this.store$
    .select(pmsPresetsQuery.pmsOtherSettingsConfigParsedValue)
    .pipe(
      filter(isNeitherNullNorUndefined),
      map(
        ({ displayDeltaAdjustedExposureInSummaryWidget }) =>
          displayDeltaAdjustedExposureInSummaryWidget
      )
    );
  readonly sortType$ = this.store$.select(pmsPresetsQuery.pmsOtherSettingsConfigParsedValue).pipe(
    filter(isNeitherNullNorUndefined),
    map(({ gridSortType }) => gridSortType)
  );
  readonly displayIntradayChart$ = this.store$
    .select(pmsPresetsQuery.pmsOtherSettingsConfigParsedValue)
    .pipe(
      filter(isNeitherNullNorUndefined),
      map(({ displayPnLChartWidget }) => displayPnLChartWidget)
    );
  readonly widgetsOrderConfig$ = this.store$
    .select(pmsPresetsQuery.pmsWidgetsOrderConfigParsedValue)
    .pipe(filter(isNeitherNullNorUndefined));
  readonly selectedColumns$ = this.store$.select(
    pmsPresetsQuery.pmsSelectedSavedColumnsConfigParsedValueColumns
  );
  readonly selectedFilters$ = this.store$
    .select(pmsPresetsQuery.pmsSelectedSavedColumnsConfigParsedValueFilters)
    .pipe(
      withLatestFrom(this.store$.select(pmsPresetsQuery.pmsPresetsLoaded)),
      filter(([, loaded]) => loaded),
      map(([filters]) => filters)
    );
  readonly selectedExclusiveSecurities$ = this.store$
    .select(pmsPresetsQuery.pmsSelectedSavedColumnsConfigParsedValueExclusive)
    .pipe(
      withLatestFrom(this.store$.select(pmsPresetsQuery.pmsPresetsLoaded)),
      filter(([, loaded]) => loaded),
      map(([isExclusive]) => isExclusive)
    );
  readonly selectedAccounts$ = this.store$
    .select(pmsPresetsQuery.pmsSelectedSavedColumnsConfigParsedValueAccounts)
    .pipe(
      withLatestFrom(this.store$.select(pmsPresetsQuery.pmsPresetsLoaded)),
      filter(([, loaded]) => loaded),
      map(([filters]) => filters)
    );
  readonly presetName$ = this.store$.select(pmsPresetsQuery.pmsSelectedSavedColumnsConfigName);
  readonly columnsConfigsNames$ = this.store$.select(pmsPresetsQuery.pmsColumnsConfigsNames);
  readonly selectedColumnsConfigId$ = this.store$.select(
    pmsPresetsQuery.pmsSelectedSavedColumnsConfigId
  );
  readonly selectedSavedColumnsConfig$ = this.store$.select(
    pmsPresetsQuery.pmsSelectedSavedColumnsConfig
  );
  readonly presetChanged$ = this._presetChanged.asObservable().pipe(debounceTime(500));

  constructor(
    private readonly toast: PinnaklUIToastMessage,
    private readonly store$: Store<PmsPresetsState>
  ) {}

  presetChanged(value = true): void {
    this._presetChanged.next(value);
  }

  loadPresets(): void {
    this.store$.dispatch(loadPmsPresets());
  }

  savePmsWidgetsOrder(order: PmsWidgetsOrder): void {
    this.store$.dispatch(savePmsWidgetsOrder({ order }));
  }

  savePmsSelectedColumnsConfig(savedColumnsConfig: PmsSavedColumns, skipMessages = false): void {
    this.store$.dispatch(savePmsColumnsConfig({ savedColumnsConfig, skipMessages, isNew: false }));
  }

  updatePmsSelectedColumnsConfigName(name: string): void {
    combineLatest([
      this.store$.select(pmsPresetsQuery.pmsSavedColumnsConfigs),
      this.store$.select(pmsPresetsQuery.pmsSelectedSavedColumnsConfigId)
    ])
      .pipe(take(1))
      .subscribe(([presets, id]) => {
        const preset = presets.find(p => p.id === id);
        if (preset) {
          this.savePmsSelectedColumnsConfig(
            {
              columns: preset.parsedConfigValue.columns,
              filters: preset.parsedConfigValue.filters,
              name
            },
            true
          );

          setTimeout(() => {
            this.toast.success('View renamed');
          }, 1000);
        }
      });
  }

  createPmsSelectedColumnsConfig(savedColumnsConfig: PmsSavedColumns): void {
    this.store$.dispatch(
      savePmsColumnsConfig({ savedColumnsConfig, isNew: true, skipMessages: false })
    );
  }

  selectPmsColumnsConfig(id: number): void {
    this.store$.dispatch(pmsSelectColumnsConfig({ id }));
  }

  deletePmsColumnsConfig(id: number): void {
    this.store$.dispatch(pmsDeleteColumnsConfig({ id }));
  }

  sharePresets(presetsIds: number[], usersIds: number[]): void {
    this.store$.dispatch(pmsSharePresets({ presetsIds, usersIds }));
  }

  saveOtherSettings(otherSettings: PmsOtherSettings): void {
    this.store$.dispatch(pmsSaveOtherSettings({ otherSettings }));
  }
}

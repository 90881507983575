import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PnklChartsModule } from '@pinnakl/charts';
import { PinnaklFormsModule } from '@pinnakl/forms';
import {
  PmsColumnsDataAccessModule,
  PmsGridColumnsDataAccessModule,
  PmsPortfolioLatestPricesModule,
  PmsPositionsDataAccessModule,
  PmsPresetsDataAccessModule,
  PmsRebalanceConfigDataAccessModule
} from '@pinnakl/pms/data-access';
import { AccountsDataAccessModule } from '@pinnakl/poems/accounts/data-access';
import {
  FolderCommentsDataAccessModule,
  FoldersDataAccessModule
} from '@pinnakl/poems/folders/data-access';
import { SecuritiesDataAccessModule } from '@pinnakl/securities/data-access';
import { SharedUiModule } from '@pinnakl/shared-ui';
import {
  LocalSpinnerComponent,
  LocalSpinnerSimpleModeContainerComponent
} from '@pinnakl/shared/base-components';
import { CurrencyIconPipe } from '@pinnakl/shared/ui/directives-and-pipes';
import {
  AccountMultiSelectorComponent,
  PrimeButtonComponent,
  PrimeInputNumberComponent,
  PrimeInputSwitchComponent,
  PrimeSelectButtonComponent,
  PrimeSingleDropdownComponent
} from '@pinnakl/shared/ui/prime';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { AgGridModule } from 'ag-grid-angular';
import { TabViewModule } from 'primeng/tabview';
import { SecurityDetailsModalContainerComponent } from './security-details-container/security-details-container.component';
import { PositionsTableComponent } from './security-details-exposure-summary/positions-table/positions-table.component';
import { PreviousClosePriceComponent } from './security-details-exposure-summary/previous-close-price/previous-close-price.component';
import { RealtimePriceComponent } from './security-details-exposure-summary/realtime-price/realtime-price.component';
import { SecurityDetailsExposureSummaryComponent } from './security-details-exposure-summary/security-details-exposure-summary.component';
import { SecurityDetailsFolderCommentsComponent } from './security-details-folder-comments/security-details-folder-comments.component';
import { SecurityDetailsPositionHistoryComponent } from './security-details-position-history/security-details-position-history.component';
import { SecurityDetailsTradeHistoryComponent } from './security-details-trade-history/security-details-trade-history.component';

@NgModule({
  imports: [
    CommonModule,
    PinnaklFormsModule,
    SharedUiModule,
    NgOptimizedImage,
    AgGridModule,
    PnklChartsModule,
    ReactiveFormsModule,
    SecuritiesDataAccessModule,
    TabViewModule,
    InlineSVGModule.forRoot({ baseUrl: '/assets/svg/' }),
    PrimeButtonComponent,
    AccountMultiSelectorComponent,
    PrimeInputSwitchComponent,
    PrimeInputNumberComponent,
    PrimeSelectButtonComponent,
    PrimeSingleDropdownComponent,
    RealtimePriceComponent,
    PositionsTableComponent,
    FoldersDataAccessModule,
    AccountsDataAccessModule,
    PmsPresetsDataAccessModule,
    PmsColumnsDataAccessModule,
    PmsPositionsDataAccessModule,
    PmsGridColumnsDataAccessModule,
    PmsPortfolioLatestPricesModule,
    PmsRebalanceConfigDataAccessModule,
    FolderCommentsDataAccessModule,
    PreviousClosePriceComponent,
    CurrencyIconPipe,
    LocalSpinnerComponent,
    LocalSpinnerSimpleModeContainerComponent
  ],
  declarations: [
    SecurityDetailsModalContainerComponent,
    SecurityDetailsFolderCommentsComponent,
    SecurityDetailsTradeHistoryComponent,
    SecurityDetailsPositionHistoryComponent,
    SecurityDetailsExposureSummaryComponent
  ],
  exports: [SecurityDetailsModalContainerComponent]
})
export class PoemsModalsSecurityDetailsFeatureModule {}

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  NgZone,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NgControl, ReactiveFormsModule } from '@angular/forms';
import { InjectNgControl } from '@pinnakl/shared/util-helpers';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { SharedModule } from 'primeng/api';
import { CheckIcon } from 'primeng/icons/check';
import { MultiSelect } from 'primeng/multiselect';
import { Nullable } from 'primeng/ts-helpers';
import { MultiSelectSize, PrimeMultiSelectComponent } from '../../base-components';
import { MultiSelectPublicApiComponent } from '../../base-components/multi-select/multi-select-public-api.component';
import { NoopValueAccessorDirective } from '../../directives';

@Component({
  selector: 'prime-account-multi-selector',
  standalone: true,
  imports: [
    CommonModule,
    PrimeMultiSelectComponent,
    ReactiveFormsModule,
    SharedModule,
    InlineSVGModule,
    CheckIcon
  ],
  hostDirectives: [NoopValueAccessorDirective],
  templateUrl: './account-multi-selector.component.html',
  styleUrls: ['./account-multi-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountMultiSelectorComponent extends MultiSelectPublicApiComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private readonly ngControl: NgControl;
  @Input() rounded: boolean;
  @Input() size: MultiSelectSize;
  public formControl?: FormControl;
  @ViewChild(PrimeMultiSelectComponent, { static: false })
  multiSelectComponent?: PrimeMultiSelectComponent;
  @ViewChild('headerCheckbox', { static: false }) headerCheckboxViewChild: Nullable<ElementRef>;

  get primeMultiSelect(): MultiSelect | undefined {
    return this.multiSelectComponent?.multiSelect;
  }

  constructor(private readonly zone: NgZone) {
    super();
    this.ngControl = InjectNgControl();
    this.filter = false;
    this.rounded = false;
    this.size = 'md';
    this.panelStyle = { 'margin-top': '2px' };
  }

  ngOnInit(): void {
    this.formControl = this.ngControl.control as FormControl;
    this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() =>
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.primeMultiSelect?.overlayViewChild?.alignOverlay();
        }, 1);
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PmsGridColumnsFacadeService } from '../grid-columns';
import { PmsColumnsApiService } from './columns-api.service';
import { loadColumns, loadColumnsFailure, setColumns } from './columns.actions';

@Injectable()
export class PmsColumnsEffects {
  loadColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadColumns),
      switchMap(() => this.api.loadColumns()),
      tap(() => this.gridColumnsFacade.prepareGridColumns()),
      map(columns => setColumns({ columns })),
      catchError(error => of(loadColumnsFailure({ error })))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: PmsColumnsApiService,
    private readonly gridColumnsFacade: PmsGridColumnsFacadeService
  ) {}
}

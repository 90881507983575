import { GreeksItem, gRPCGreeks } from './greeks.model';
import { PricingItem, gRPCPricing } from './pricing.model';

export const grpcGreeksToGreeksItem = (greekFromGRPC: gRPCGreeks): GreeksItem => ({
  source: greekFromGRPC.source,
  currency: greekFromGRPC.currency,
  clientid: +greekFromGRPC.clientId,
  pricetype: greekFromGRPC.priceType,
  value: greekFromGRPC.value?.value ?? 0,
  evaltime: +(greekFromGRPC.evalTime?.seconds ?? '0'),
  securitymarketid: greekFromGRPC.securityMarketId
});

export const grpcPricingToPricingItem = (pricingFromGRPC: gRPCPricing): PricingItem => ({
  source: pricingFromGRPC.source,
  currency: pricingFromGRPC.currency,
  clientid: +pricingFromGRPC.clientId,
  pricetype: pricingFromGRPC.priceType,
  value: pricingFromGRPC.value?.value ?? 0,
  evaltime: +(pricingFromGRPC.evalTime?.seconds ?? '0'),
  securitymarketid: pricingFromGRPC.securityMarketId
});

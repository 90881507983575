<div class="wrap">
  <form
    (ngSubmit)="changePassword()"
    [formGroup]="form"
    class="form"
  >
    <div class="form__fields">
      <div class="form__control">
        <pinnakl-input
          [form]="form"
          [inputOptions]="{
            floatingLabel: false,
            inputContainerCss: 'with-border'
          }"
          [showValidation]="false"
          autoComplete="current-password"
          class="login-password"
          controlName="password"
          label="New Password"
          labelClass="password-label"
          type="password"
        >
        </pinnakl-input>

        <div
          *ngIf="password.errors && isSubmitted"
          class="form__errors pnkl-validation-errors"
        >
          <ng-container *ngFor="let error of password.errors | keyvalue">
            <div class="color-red">
              <ng-container *ngIf="error.key === 'required'"> This field is required </ng-container>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="requirements">
        <div class="requirements__title">Password must contain:</div>
        <div class="requirements__row">
          <div
            *ngFor="let requirement of passwordRequirements"
            [ngClass]="{
              rule_checked: !hasError(requirement.errorName),
              rule_error: isSubmitted && hasError(requirement.errorName)
            }"
            class="rule"
          >
            <div class="rule__checkbox">
              <ng-container *ngIf="hasError(requirement.errorName); else checked">
                <svg
                  fill="none"
                  height="2"
                  viewBox="0 0 8 2"
                  width="8"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99998 1H0"
                    stroke="#C3C7CD"
                    stroke-width="2"
                  />
                </svg>
              </ng-container>
              <ng-template #checked>
                <svg
                  fill="none"
                  height="8"
                  viewBox="0 0 10 8"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 1L4.00002 6.00001L1 3"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </ng-template>
            </div>
            <div class="rule__label">{{ requirement.label }}</div>
            <div class="rule__example">{{ requirement.example }}</div>
          </div>
        </div>
      </div>

      <div class="form__control">
        <pinnakl-input
          [form]="form"
          [inputOptions]="{
            floatingLabel: false,
            inputContainerCss: 'with-border'
          }"
          [showValidation]="false"
          autoComplete="current-password"
          class="login-password"
          controlName="confirmation"
          label="Confirm Password"
          labelClass="password-label"
          type="password"
        >
        </pinnakl-input>

        <div
          *ngIf="form.get('confirmation').errors && isSubmitted"
          class="form__errors pnkl-validation-errors"
        >
          <ng-container *ngFor="let error of form.get('confirmation').errors | keyvalue">
            <div class="color-red">
              <ng-container *ngIf="error.key === 'required'"> This field is required </ng-container>
            </div>
          </ng-container>
        </div>
      </div>

      <div
        *ngIf="form.errors && isSubmitted"
        class="form__errors pnkl-validation-errors"
      >
        <ng-container *ngFor="let error of form.errors | keyvalue">
          <div class="color-red">
            <ng-container *ngIf="error.key === 'dontMatch'">
              Passwords do not match. Try again?
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>

    <div
      *ngIf="!hideSubmit"
      class="actions"
    >
      <button
        class="btn submit"
        type="submit"
      >
        Save
      </button>
    </div>
  </form>
</div>

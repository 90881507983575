<div [formGroup]="form">
  <kendo-combobox
    #kendoComboBoxInstance="kendoComboBox"
    (blur)="dropdownBlurred(kendoComboBoxInstance)"
    (click)="dropdownFocused(kendoComboBoxInstance)"
    (filterChange)="filterDropdown($event)"
    (open)="dropdownOpen($any($event), kendoComboBoxInstance)"
    (valueChange)="dropdownValueChanged($event)"
    [allowCustom]="!!dropdownOptions?.allowCustom"
    [attr.disabled]="disabled ? true : undefined"
    [clearButton]="clearButton"
    [data]="dropdownCollection"
    [disabled]="disabled"
    [filterable]="true"
    [formControlName]="controlName"
    [loading]="dropdownLoading"
    [popupSettings]="popupSettings"
    [textField]="dropdownOptions?.viewProperty ? dropdownOptions.viewProperty : undefined"
    [valueField]="
      dropdownOptions?.viewProperty
        ? dropdownOptions.modelProperty
          ? dropdownOptions.modelProperty
          : 'id'
        : undefined
    "
    [valueNormalizer]="
      dropdownOptions?.modelNormalizer ? dropdownOptions.modelNormalizer : defaultModelNormalizer
    "
    [valuePrimitive]="dropdownOptions ? !dropdownOptions.objectModel : true"
    [value]="defaultValue"
  >
  </kendo-combobox>
</div>

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  RouterStateSnapshot
} from '@angular/router';
import { UserService } from '@pinnakl/core/data-providers';
import { of } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

export const AuthenticatedGuardCanActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const userService = inject(UserService);

  return authService.getIsAuthenticated().pipe(
    take(1),
    switchMap(isAuthenticated => {
      if (isAuthenticated)
        return userService.userAvailable$.pipe(
          tap(userAvailable => {
            if (!userAvailable) {
              authService.silentlyFetchUser();
            }
          }),
          filter(userAvailable => userAvailable)
        );

      const { search } = new URL(window.location.href);
      const searchParams = new URLSearchParams(search);
      const hasCode = searchParams.has('code');
      if (hasCode) {
        authService.checkAuthCodeAndProceedAuth();
        return of(false);
      }
      userService.removeUser();
      authService.redirectToAuthApp();
      return of(false);
    })
  );
};

export const AuthenticatedGuardCanActivateChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => AuthenticatedGuardCanActivate(route, state);

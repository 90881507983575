import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PmsPositionsApiService } from './positions-api.service';
import { PmsPositionsEffects } from './positions.effects';
import { initialState, positionsReducer } from './positions.reducer';
import { featureSelectorName } from './positions.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureSelectorName, positionsReducer, {
      initialState
    }),
    EffectsModule.forFeature([PmsPositionsEffects])
  ],
  providers: [PmsPositionsApiService]
})
export class PmsPositionsDataAccessModule {}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  PmsOtherSettings,
  PmsSavedColumns,
  PmsSelectedSavedColumns,
  PmsWidgetsOrder,
  buildPmsBasePreset
} from '@pinnakl/pms/domain';
import { ParsedPreset, PmsPresetsTypes, Preset } from '@pinnakl/shared/types';
import { getPresetWithParsedConfigValue as withConfig } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage, PresetsApiService } from '@pinnakl/shared/util-providers';
import { Observable, combineLatest, of } from 'rxjs';
import { concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PmsPresetsApiService } from './presets-api.service';
import {
  loadPmsPresets,
  pmsColumnsConfigDeleted,
  pmsColumnsConfigSaved,
  pmsDeleteColumnsConfig,
  pmsDeleteSelectedColumnsConfig,
  pmsOtherSettingsSaved,
  pmsPresetActionStub,
  pmsPresetsLoaded,
  pmsSaveOtherSettings,
  pmsSelectColumnsConfig,
  pmsSelectedColumnsConfigDeleted,
  pmsSelectedColumnsConfigSaved,
  pmsSharePresets,
  pmsWidgetsOrderSaved,
  savePmsColumnsConfig,
  savePmsWidgetsOrder
} from './presets.actions';
import { PmsPresetsState, defaultOtherSettings, defaultWidgetsOrder } from './presets.models';
import { pmsPresetsQuery, pmsPresetsQuery as query } from './presets.selectors';

@Injectable()
export class PmsPresetsEffects {
  loadPmsPresets$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPmsPresets),
      switchMap(() => this.api.getAllPmsPresets()),
      map(presets => pmsPresetsLoaded({ presets }))
    )
  );

  createOtherSettingsIfNotExists$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pmsPresetsLoaded),
      map(({ presets: { pmsOtherSettings } }) => pmsOtherSettings),
      filter(preset => !preset),
      switchMap(() => this.createOtherSettingsPreset(defaultOtherSettings)),
      map(preset =>
        pmsOtherSettingsSaved({
          pmsOtherSettings: withConfig<PmsOtherSettings>(preset) as ParsedPreset<PmsOtherSettings>
        })
      )
    )
  );

  createSelectedColumnsConfigIfNeeded$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pmsPresetsLoaded),
      map(({ presets: { pmsGridSelectedColumnsPreset, pmsGridColumns } }) => ({
        selectedPreset: pmsGridSelectedColumnsPreset,
        presetsList: pmsGridColumns
      })),
      filter(({ selectedPreset, presetsList }) => selectedPreset == null && presetsList.length > 0),
      switchMap(({ presetsList }) => this.createSelectedColumnsConfigPreset(presetsList[0].id)),
      map(preset =>
        pmsSelectedColumnsConfigSaved({
          pmsGridSelectedColumnsPreset: withConfig<PmsSelectedSavedColumns>(
            preset
          ) as ParsedPreset<PmsSelectedSavedColumns>
        })
      )
    )
  );

  savePmsWidgetsOrder$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savePmsWidgetsOrder),
      withLatestFrom(this.store$.select(query.pmsWidgetsOrderConfigId)),
      switchMap(([{ order }, orderFromStoreId]) => {
        if (orderFromStoreId) {
          return this.presetsApi.updatePreset({
            id: orderFromStoreId,
            ...buildPmsBasePreset(PmsPresetsTypes.PmsWidgetsOrder, JSON.stringify(order))
          });
        }
        return this.presetsApi.createPreset(
          buildPmsBasePreset(PmsPresetsTypes.PmsWidgetsOrder, JSON.stringify(order))
        );
      }),
      map(preset => {
        const pmsWidgetsOrder = (withConfig(preset) ??
          defaultWidgetsOrder) as ParsedPreset<PmsWidgetsOrder>;
        return pmsWidgetsOrderSaved({
          pmsWidgetsOrder
        });
      })
    )
  );

  savePmsColumnsConfig$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savePmsColumnsConfig),
      withLatestFrom(this.store$.select(pmsPresetsQuery.pmsPresets)),
      concatMap(
        ([
          { isNew, savedColumnsConfig, skipMessages },
          { pmsGridSelectedColumnsPreset, pmsGridColumns }
        ]) => {
          // If create new columns config or no selected columns config then create new
          if (isNew || pmsGridSelectedColumnsPreset == null) {
            return this.createNewColumnsConfigPreset(savedColumnsConfig).pipe(
              tap(() => isNew && !skipMessages && this.toast.success('View created')),
              map(preset =>
                pmsColumnsConfigSaved({
                  savedColumnsConfig: withConfig(preset) as ParsedPreset<PmsSavedColumns>
                })
              )
            );
          }
          const saved = pmsGridColumns.find(
            ({ id }) => id === pmsGridSelectedColumnsPreset?.parsedConfigValue?.id
          );
          // If we update columns config which is already created then just update it
          if (saved) {
            return this.updateColumnsConfigPreset(saved.id, savedColumnsConfig).pipe(
              tap(() => !skipMessages && this.toast.success('Changes saved')),
              map(preset =>
                pmsColumnsConfigSaved({
                  savedColumnsConfig: withConfig(preset) as ParsedPreset<PmsSavedColumns>
                })
              )
            );
          }
          // Otherwise (trying to update columns config which doesn't exist) create new columns config
          return this.createNewColumnsConfigPreset(savedColumnsConfig).pipe(
            tap(() => !skipMessages && this.toast.success('View updated')),
            map(preset =>
              pmsColumnsConfigSaved({
                savedColumnsConfig: withConfig(preset) as ParsedPreset<PmsSavedColumns>
              })
            )
          );
        }
      )
    )
  );

  pmsColumnsConfigSaved$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pmsColumnsConfigSaved),
      withLatestFrom(this.store$.select(pmsPresetsQuery.pmsPresets)),
      concatMap(([{ savedColumnsConfig }, { pmsGridSelectedColumnsPreset }]) => {
        // If no selected saved columns config then create it
        if (pmsGridSelectedColumnsPreset == null) {
          return this.createSelectedColumnsConfigPreset(savedColumnsConfig.id).pipe(
            map(preset =>
              pmsSelectedColumnsConfigSaved({
                pmsGridSelectedColumnsPreset: withConfig<PmsSelectedSavedColumns>(
                  preset
                ) as ParsedPreset<PmsSelectedSavedColumns>
              })
            )
          );
        } else if (pmsGridSelectedColumnsPreset.parsedConfigValue.id !== savedColumnsConfig.id) {
          // If selected saved columns config store other columns config then we need to update it
          return this.updateSelectedColumnsConfigPreset(
            pmsGridSelectedColumnsPreset.id,
            savedColumnsConfig.id
          ).pipe(
            map(preset =>
              pmsSelectedColumnsConfigSaved({
                pmsGridSelectedColumnsPreset: withConfig<PmsSelectedSavedColumns>(
                  preset
                ) as ParsedPreset<PmsSelectedSavedColumns>
              })
            )
          );
        }
        // If selected saved columns config store columns config that was updated then do nothing
        return of(pmsPresetActionStub());
      })
    )
  );

  selectColumnsConfig$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pmsSelectColumnsConfig),
      withLatestFrom(this.store$.select(query.pmsPresets)),
      concatMap(([{ id }, { pmsGridSelectedColumnsPreset }]) => {
        // If there is selected saved columns config then we just need update it with proper columns config if
        if (pmsGridSelectedColumnsPreset) {
          return this.updateSelectedColumnsConfigPreset(pmsGridSelectedColumnsPreset?.id, id).pipe(
            map(preset =>
              pmsSelectedColumnsConfigSaved({
                pmsGridSelectedColumnsPreset: withConfig<PmsSelectedSavedColumns>(
                  preset
                ) as ParsedPreset<PmsSelectedSavedColumns>
              })
            )
          );
        }
        // If there is no selected columns config preset then we need create it
        return this.createSelectedColumnsConfigPreset(id).pipe(
          map(preset =>
            pmsSelectedColumnsConfigSaved({
              pmsGridSelectedColumnsPreset: withConfig<PmsSelectedSavedColumns>(
                preset
              ) as ParsedPreset<PmsSelectedSavedColumns>
            })
          )
        );
      })
    )
  );

  savePmsOtherSettingsConfig$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pmsSaveOtherSettings),
      withLatestFrom(this.store$.select(query.pmsOtherSettingsConfig)),
      switchMap(([{ otherSettings }, existingConfig]) => {
        if (existingConfig?.id) {
          return this.presetsApi.updatePreset({
            id: existingConfig.id,
            ...buildPmsBasePreset(PmsPresetsTypes.PmsOtherSettings, JSON.stringify(otherSettings))
          });
        }
        return this.presetsApi.createPreset(
          buildPmsBasePreset(PmsPresetsTypes.PmsOtherSettings, JSON.stringify(otherSettings))
        );
      }),
      map(preset =>
        pmsOtherSettingsSaved({
          pmsOtherSettings: withConfig(preset) as ParsedPreset<PmsOtherSettings>
        })
      )
    )
  );

  deleteColumnsConfig$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pmsDeleteColumnsConfig),
      concatMap(({ id }) => this.presetsApi.deletePreset(id)),
      map(({ id }) => pmsColumnsConfigDeleted({ id }))
    )
  );

  pmsDeleteSelectedColumnsConfig$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pmsDeleteSelectedColumnsConfig),
      withLatestFrom(this.store$.select(query.pmsPresets)),
      concatMap(([, { pmsGridSelectedColumnsPreset }]) => {
        // If we need to delete selected column config, and it exists - we delete it
        if (pmsGridSelectedColumnsPreset) {
          return this.presetsApi.deletePreset(pmsGridSelectedColumnsPreset.id);
        }
        // otherwise do nothing
        return of({});
      }),
      map(() => pmsSelectedColumnsConfigDeleted())
    )
  );

  selectColumnsConfigOnDelete$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pmsColumnsConfigDeleted),
      withLatestFrom(this.store$.select(query.pmsPresets)),
      tap(() => this.toast.success('View deleted')),
      map(([{ id }, { pmsGridSelectedColumnsPreset, pmsGridColumns }]) => {
        // If we delete not last and selected preset then we need to select last of the list
        if (
          pmsGridColumns.length > 0 &&
          pmsGridSelectedColumnsPreset?.parsedConfigValue?.id === id
        ) {
          return pmsSelectColumnsConfig({
            id: pmsGridColumns[pmsGridColumns.length - 1].id
          });
        }
        // If we delete last and selected (it should be 100% selected) then
        // we need to delete selected columns config preset
        if (
          pmsGridColumns.length === 0 &&
          pmsGridSelectedColumnsPreset?.parsedConfigValue?.id === id
        ) {
          this.store$.dispatch(pmsDeleteSelectedColumnsConfig());
        }

        // If we delete not last and not selected then do nothing
        return pmsPresetActionStub();
      })
    )
  );

  sharePresets$$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pmsSharePresets),
        withLatestFrom(this.store$.select(pmsPresetsQuery.pmsPresets)),
        concatMap(([{ presetsIds, usersIds }, { pmsGridColumns }]) =>
          combineLatest(
            presetsIds.map(presetId => {
              const presetFromStore = pmsGridColumns.find(({ id }) => id === presetId);
              if (presetFromStore) {
                return combineLatest(
                  usersIds.map(userId =>
                    this.presetsApi.createPreset({
                      userid: userId.toString(),
                      module: presetFromStore.module,
                      configName: presetFromStore.configName,
                      configValue: presetFromStore.configValue
                    })
                  )
                );
              }
              return of({});
            })
          )
        ),
        tap(() => this.toast.success('View shared'))
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: PmsPresetsApiService,
    private readonly presetsApi: PresetsApiService,
    private readonly store$: Store<PmsPresetsState>,
    private readonly toast: PinnaklUIToastMessage
  ) {}

  private createNewColumnsConfigPreset(savedColumnsConfig: PmsSavedColumns): Observable<Preset> {
    return this.presetsApi.createPreset(
      buildPmsBasePreset(PmsPresetsTypes.PmsGridColumns, JSON.stringify(savedColumnsConfig))
    );
  }

  private createSelectedColumnsConfigPreset(id: number): Observable<Preset> {
    return this.presetsApi.createPreset(
      buildPmsBasePreset(PmsPresetsTypes.PmsGridSelectedColumnsPreset, JSON.stringify({ id }))
    );
  }

  private createOtherSettingsPreset(otherSettings: PmsOtherSettings): Observable<Preset> {
    return this.presetsApi.createPreset(
      buildPmsBasePreset(PmsPresetsTypes.PmsOtherSettings, JSON.stringify(otherSettings))
    );
  }

  private updateColumnsConfigPreset(
    id: number,
    savedColumnsConfig: PmsSavedColumns
  ): Observable<Preset> {
    return this.presetsApi.updatePreset({
      id,
      ...buildPmsBasePreset(PmsPresetsTypes.PmsGridColumns, JSON.stringify(savedColumnsConfig))
    });
  }

  private updateSelectedColumnsConfigPreset(
    id: number,
    configToSelectId: number
  ): Observable<Preset> {
    return this.presetsApi.updatePreset({
      id,
      ...buildPmsBasePreset(
        PmsPresetsTypes.PmsGridSelectedColumnsPreset,
        JSON.stringify({ id: configToSelectId })
      )
    });
  }
}

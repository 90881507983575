<div
  *ngIf="{ isEditMode: isEditMode$ | async } as vm"
  class="flex align-items-center price-container px-2 mt-2 border-round relative"
>
  <div class="mr-2 mark-read-value">
    {{ price | number: '.2-2' }}
  </div>
  <div class="mark-read-type">{{ markType ?? 'Real Time' }}</div>

  <i
    (click)="toggleEditMode()"
    [inlineSVG]="'sprites.svg#pencil-edit'"
    class="flex cursor-pointer ml-auto edit-price-icon text-color-secondary"
  ></i>

  <ng-container *ngIf="vm.isEditMode">
    <div
      [@inOutAnimation]="vm.isEditMode"
      [formGroup]="editMarkForm"
      class="grid grid-nogutter align-items-center absolute top-0 left-0 px-2 price-container border-round"
    >
      <div class="col-11 pr-1">
        <div class="grid grid-nogutter">
          <prime-number-input
            [maxFractionDigits]="2"
            [max]="9999"
            [minFractionDigits]="2"
            [min]="0.01"
            class="col-6 pr-1"
            formControlName="editMarkValue"
            inputStyleClass="w-100"
          ></prime-number-input>
          <prime-single-dropdown
            [appendTo]="null"
            [options]="editMarkTypes"
            class="col-6 pl-1"
            formControlName="editMarkType"
            optionLabel="name"
            optionValue="value"
            styleClass="w-100"
          ></prime-single-dropdown>
          <prime-button
            (onClick)="saveNewMark(); toggleEditMode()"
            [disabled]="saveIsDisabled"
            class="col-6 pr-1 mt-1"
            icon="pi pi-save"
            label="Save"
            severity="success"
            size="sm"
            styleClass="w-100"
          ></prime-button>
          <prime-button
            (onClick)="markType && deleteNewMark(); markType && toggleEditMode()"
            [disabled]="!markType"
            [outlined]="true"
            class="col-6 pl-1 mt-1"
            icon="pi pi-trash"
            label="Reset"
            severity="danger"
            size="sm"
            styleClass="w-100"
          ></prime-button>
        </div>
      </div>
      <div
        (click)="toggleEditMode()"
        class="col-1 cursor-pointer align-self-stretch flex align-items-center justify-content-center border-round close-edit-mode-section"
      >
        <i class="pi pi-times"></i>
      </div>
    </div>
  </ng-container>
</div>

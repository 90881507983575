import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PmsColumn, PmsPosition, PmsPositionApi } from '@pinnakl/pms/domain';
import { buildGridPinnedBottomData } from '@pinnakl/shared/util-helpers';
import { mergeMap, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PmsColumnsFacadeService } from '../columns/columns-facade.service';
import { PmsPositionsApiService } from './positions-api.service';
import { PmsPositionsFacadeService } from './positions-facade.service';
import {
  buildPinnedData,
  loadPositions,
  loadPositionsFailure,
  reloadPositions,
  setPinnedData,
  setPositions
} from './positions.actions';
import { PositionsKeysMap } from './positions.models';

@Injectable({
  providedIn: 'root'
})
export class PmsPositionsEffects {
  keysMap: PositionsKeysMap = {} as PositionsKeysMap;

  loadPositions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPositions),
      switchMap(() => this.api.loadPositions()),
      map(positions => positions.map(p => this.processData(p))),
      tap(positions => this.facade.setCalculatedGridPositions(positions)),
      mergeMap(positions => [setPositions({ positions }), buildPinnedData({ positions })]),
      tap(() => this.facade.saveKeysMap(this.keysMap)),
      catchError(error => of(loadPositionsFailure(error)))
    )
  );

  reloadPositions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reloadPositions),
      switchMap(() => this.api.loadPositions()),
      map(positions => positions.map(p => this.processData(p, false))),
      withLatestFrom(this.facade.definedCalculatedDataForGrid$),
      map(([updatedPositions, currentPositions]) => {
        return updatedPositions.map(data => {
          const existingData = currentPositions.find(
            r =>
              r.accountid === data.accountid &&
              r.securitymarketid === data.securitymarketid &&
              r.folderid === data.folderid &&
              r.direction.toLowerCase() === data.direction.toLowerCase() &&
              r.custodianid === data.custodianid &&
              (r.subaccountid ? r.subaccountid === data.subaccountid : true)
          );
          return {
            ...data,
            id: existingData?.id ?? data.id,
            daycost: existingData?.daycost || data?.daycost || 0,
            mark: existingData?.mark || data?.mark || 0,
            delta: existingData?.delta || data?.delta || undefined,
            // Save entered rebalance data
            tgtpct: existingData?.tgtpct,
            rebalancequantity: existingData?.rebalancequantity,
            rebalancecost: existingData?.rebalancecost,
            rebalanceBy: existingData?.rebalanceBy
          };
        });
      }),
      map(positions => setPositions({ positions })),
      catchError(error => of(loadPositionsFailure(error)))
    )
  );

  buildPinnedData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(buildPinnedData),
      withLatestFrom(this.columnsFacade.columns$),
      map(([action, columns]) => this.buildPinnedData(action.positions, columns)),
      map(pinnedData => setPinnedData({ pinnedData }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: PmsPositionsApiService,
    private readonly facade: PmsPositionsFacadeService,
    private readonly columnsFacade: PmsColumnsFacadeService
  ) {}

  private processData(object: PmsPositionApi, buildKeyMap = true): PmsPosition {
    const position = {} as PmsPosition;
    for (const [key, value] of Object.entries(object)) {
      const lowerKey = key.toLowerCase();
      if (buildKeyMap) {
        this.keysMap[lowerKey as keyof PmsPosition] = key as keyof PmsPositionApi;
      }
      position[lowerKey] = value;
    }
    return {
      ...position,
      // rebalance
      tgtpct: undefined,
      rebalancequantity: undefined,
      rebalancecost: undefined
    };
  }

  private buildPinnedData(positions: PmsPosition[], columns: PmsColumn[]) {
    const accountIdsSet = new Set();
    positions.forEach(p => p.accountid && accountIdsSet.add(p.accountid));
    const extendedColumns = [...accountIdsSet].map(accountid => ({
      isAggregating: true,
      type: 'number',
      name: `MV${accountid}`
    }));
    const cols = columns.map(({ isAggregating, name, type }) => ({
      type,
      isAggregating,
      name: name.toLowerCase()
    }));

    return buildGridPinnedBottomData<PmsPosition>([...cols, ...extendedColumns], positions);
  }
}

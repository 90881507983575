import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmActionComponent } from './confirm-action/confirm-action.component';

import { PopupModule } from '@progress/kendo-angular-popup';

@NgModule({
  imports: [CommonModule, PopupModule],
  declarations: [ConfirmActionComponent],
  exports: [ConfirmActionComponent]
})
export class ConfirmActionModule {}

import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { PmsColumn } from '@pinnakl/pms/domain';
import { V4_ENDPOINT } from '@pinnakl/shared/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PmsColumnsApiService {
  private readonly endpoint = `${V4_ENDPOINT}pms_columns`;

  constructor(private readonly wsp: WebServiceProvider) {}

  loadColumns(): Observable<PmsColumn[]> {
    return this.wsp
      .get<PmsColumn[]>({
        endpoint: this.endpoint
      })
      .pipe(
        map(columns =>
          columns.map(column => ({
            ...column,
            formula: column.formula ? column.formula.toLowerCase() : null
          }))
        )
      );
  }
}

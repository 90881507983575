import { Component, Input, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { PositionService, PricingService } from '@pinnakl/poems';
import { PinnaklSpinnerService } from '@pinnakl/shared/util-providers';

import { chain, find, some } from 'lodash';
import moment from 'moment';

@Component({
  selector: 'position-summary',
  templateUrl: './position-summary.component.html',
  styleUrls: ['./position-summary.component.scss']
})
export class PositionSummaryComponent implements OnInit {
  @Input() posDate: any;
  @Input() accounts: any;
  @Input() securityMarketId: number;
  posJson: any = {};
  pnlValues = [];
  totalPosition: number;
  latestPricingDate: any;
  latestPnlDate: any;
  formattedPosSummary: any;
  custodianHeaderArr: any = [];
  lastPrice: any;
  accountId;

  constructor(
    private readonly positionService: PositionService,
    private readonly pricingService: PricingService,
    private readonly spinner: PinnaklSpinnerService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.spinner.spin();
    this.pricingService
      .getLatestPricingDate(this.posDate)
      .then(this.assignLatestPricingDateAndExecutePositionSummary.bind(this))
      .then(this.executeRenderPositionSummary.bind(this));
  }

  assignLatestPricingDateAndExecutePositionSummary(latestPricingDate: any): any {
    this.posJson.latestPricingDate = latestPricingDate;
    return this.positionService.getPositionSummary(
      this.securityMarketId,
      moment(this.posDate, 'MM/DD/YYYY').format('YYYYMMDD'),
      this.accountId
    );
  }

  executeRenderPositionSummary(positionSummaryRes: any): void {
    if (this.doesPositionSummaryExists(positionSummaryRes)) {
      this.renderPositionSummary(positionSummaryRes);
    }
    this.spinner.stop();
  }

  doesPositionSummaryExists(positionSummary: any[]): boolean {
    return positionSummary.length > 0;
  }

  renderPositionSummary(positionSummaryRes: any): void {
    const posSummaryData = this.getPositionSummaryDataForRendering(positionSummaryRes);
    this.latestPricingDate = moment(this.posJson.latestPricingDate, 'MM/DD/YYYY hh:mm:ss a').format(
      'MM/DD/YYYY'
    );

    this.totalPosition = posSummaryData.totalPosition;
    this.formattedPosSummary = posSummaryData.formattedPosSummary;
    this.custodianHeaderArr = posSummaryData.custodianHeaderArr;
    this.lastPrice = parseFloat(positionSummaryRes[0].priceLocal);
  }

  getPositionSummaryDataForRendering(positionSummary: any): any {
    const custodianHeaderArr = this.getCustodians(positionSummary);
    const totPos = chain(positionSummary)
      .map(p => (p.position = parseFloat(p.position)))
      .sum()
      .value();

    const formattedPosSummary = this.getFormattedPositionSummaryWithTotalPositions(positionSummary);
    return {
      totalPosition: totPos,
      formattedPosSummary: formattedPosSummary,
      custodianHeaderArr: custodianHeaderArr
    };
  }

  getFormattedPositionSummaryWithTotalPositions(positionSummary: any): any {
    const formattedPosSummary = this.getFormattedPositionSummaryInfo(positionSummary);
    const formattedPosSummaryWithTotalPositions =
      this.addTotalPositionsToFormattedSummary(formattedPosSummary);
    const positionSummaryWithOrderOfImportance = this.addOrderOfImportance(
      formattedPosSummaryWithTotalPositions
    );
    positionSummaryWithOrderOfImportance.sort((a, b) =>
      a.orderOfImportance > b.orderOfImportance
        ? 1
        : b.orderOfImportance > a.orderOfImportance
          ? -1
          : 0
    );
    return positionSummaryWithOrderOfImportance;
  }

  addOrderOfImportance(positionSummary: any): any {
    positionSummary.forEach(posSummary => {
      this.accounts.forEach(account => {
        if (account.accountcode === posSummary.accountCode) {
          posSummary.orderOfImportance = account.orderofimportance;
        }
      });
    });
    return positionSummary;
  }

  doesArrayContainObjectValue(arr: any[], ...comparisonArr: any): any {
    let result = false;
    arr.forEach(arrJson => {
      const keys = Object.keys(arrJson);
      keys.forEach(key => {
        comparisonArr.forEach(comparisonJson => {
          if (key === comparisonJson.prop && arrJson[key] === comparisonJson.value) {
            result = true;
          }
        });
      });
    });
    return result;
  }

  getArrayIndex(key1: string, value1: any, key2: string, value2: any, arr: any[]): number {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][key1] === value1 && arr[i][key2] === value2) {
        return i;
      }
    }
    return -1;
  }

  addTotalPositionsToFormattedSummary(positionSummary: any): any {
    positionSummary.forEach(summaryJson => {
      summaryJson.totalPosition = 0;
      if (summaryJson.custodians.length > 1) {
        summaryJson.displayTotalPosition = true;
      } else {
        summaryJson.displayTotalPosition = false;
      }
      summaryJson.custodians.forEach(custodian => {
        summaryJson.totalPosition = summaryJson.totalPosition + parseInt(custodian.position);
      });
    });
    return positionSummary;
  }

  getCustodians(posSummary: any): any {
    const posSummarylist = posSummary;
    const custodianArr = [];
    posSummarylist.forEach(posSummaryJson => {
      // if ($filter('filter')(custodianArr, {
      //     'custodian': posSummaryJson.CustodianCode
      // }, true).length === 0) {

      if (
        !this.doesArrayContainObjectValue(custodianArr, {
          prop: 'custodian',
          value: posSummaryJson.custodianCode
        })
      ) {
        custodianArr.push({
          custodian: posSummaryJson.custodianCode
        });
      }
    });
    return custodianArr;
  }

  doesTradeDateExists(tradeDate: any): boolean {
    return tradeDate && tradeDate.length > 0;
  }

  doesLatestPricingDateExists(latestPricingDate: any): boolean {
    if (latestPricingDate) {
      return true;
    }
    return false;
  }

  private isFloat(n: string): boolean {
    return parseFloat(n) % 1 !== 0;
  }

  private getFormattedPositionSummaryInfo(positionSummary: any): any[] {
    const formattedPositionSummary = [];
    positionSummary.forEach(posSummary => {
      if (
        !some(formattedPositionSummary, {
          AccountCode: posSummary.accountCode
        })
      ) {
        formattedPositionSummary.push(this.getNewAccountRow(posSummary));
      } else {
        this.addCustodianAndPositions(
          find(formattedPositionSummary, {
            AccountCode: posSummary.accountCode
          }),
          posSummary
        );
      }
    });
    return formattedPositionSummary;
  }

  private addCustodianAndPositions(accountRow: any, posSummary: any): void {
    let position: number;
    if (this.isFloat(posSummary.position)) {
      position = parseFloat(posSummary.position.toFixed(2));
    } else {
      position = parseInt(posSummary.position);
    }

    accountRow.totalPosition += position;
    const existingCustodian: any = find(accountRow.custodian, {
      custodianCode: posSummary.custodianCode
    });
    if (existingCustodian) {
      existingCustodian.position += position;
    } else {
      accountRow.custodians.push({
        custodian: posSummary.custodianCode,
        position
      });
    }
    accountRow.marketValue += parseFloat(parseFloat(posSummary.mvusd).toFixed(2));
    accountRow.aumPercent += parseFloat(parseFloat(posSummary.mvusdPct).toFixed(2));
  }

  private getNewAccountRow(posSummary: any): any {
    const accountRow = {} as any;
    let position: number;
    accountRow.AccountCode = posSummary.accountCode;
    if (this.isFloat(posSummary.position)) {
      position = parseFloat(posSummary.position.toFixed(2));
    } else {
      position = parseInt(posSummary.position);
    }
    accountRow.custodians = [{ custodian: posSummary.custodianCode, position }];
    accountRow.totalPosition = position;
    accountRow.marketValue = parseFloat(parseFloat(posSummary.mvusd).toFixed(2));
    accountRow.aumPercent = parseFloat(parseFloat(posSummary.mvusdPct).toFixed(2));
    return accountRow;
  }
}

export const sizeClasses = {
  xs: 'p-button-xs',
  sm: 'p-button-sm',
  md: 'p-button-md',
  lg: 'p-button-lg',
  xl: 'p-button-xl'
};

export const buttonClasses = {
  ...sizeClasses
};

<div class="close-button py-2">
  <div class="row w-100 justify-content-between">
    <div class="account-selector">
      <pinnakl-input
        [dropdownOptions]="{
          objectModel: true,
          viewProperty: 'accountCode'
        }"
        [dropdownSource]="accounts"
        [form]="accountForm"
        [showValidation]="false"
        controlName="account"
        label="Account"
        type="dropdown"
      >
      </pinnakl-input>
    </div>

    <button
      (click)="closeModal()"
      (keydown.enter)="closeModal()"
      [ngClass]="'p-dialog-header-icon p-dialog-header-maximize p-link'"
      type="button"
    >
      <TimesIcon [styleClass]="'p-dialog-header-close-icon'" />
    </button>
  </div>
  <div class="row w-100">
    <div
      (click)="openWideSearch()"
      class="container-input"
    >
      <i class="icon-pinnakl-search-security"></i>
      <span>{{ ticker }}</span>
    </div>
    <div class="nav-container">
      <ng-container *ngFor="let tab of tabs">
        <a
          (click)="activateTab(tab.name)"
          *ngIf="!tab.hidden"
          [ngClass]="{ active: activeTabName === tab.name }"
          class="tab-container"
          href="javascript:"
        >
          {{ tab.caption }}
        </a>
      </ng-container>
    </div>
  </div>
</div>

<div>
  <div class="modal-body padding0">
    <section class="section-padding section-white">
      <div class="w-100">
        <div
          *ngIf="tabs && tabs.length > 0"
          class="tab-content"
        >
          <div class="tab-pane active">
            <div
              class="{{
                isTabActive(PositionPopupTabName.POSITION_SUMMARY)
                  ? 'display-block'
                  : 'display-none'
              }}"
            >
              <position-summary
                *ngIf="getTabByName(PositionPopupTabName.POSITION_SUMMARY)?.isInitialized"
                [accounts]="accounts"
                [posDate]="posDate"
                [securityMarketId]="securityMarketId"
              ></position-summary>
            </div>
            <div
              class="trade-history-container {{
                isTabActive(PositionPopupTabName.TRADE_HISTORY) ? 'display-block' : 'display-none'
              }}"
            >
              <trade-history
                *ngIf="getTabByName(PositionPopupTabName.TRADE_HISTORY)?.isInitialized"
                [secId]="securityMarketId"
                [underlyingSecId]="underlyingSecId"
              ></trade-history>
            </div>
            <div
              class="{{
                isTabActive(PositionPopupTabName.FOLDER_COMMENTS) ? 'display-block' : 'display-none'
              }}"
            >
              <folder-comments-table
                [folderCode]="folderCode"
                class="mt-4"
              ></folder-comments-table>
            </div>
            <div
              class="{{
                isTabActive(PositionPopupTabName.POSITION_VS_PRICE)
                  ? 'display-block'
                  : 'display-none'
              }}"
            >
              <position-vs-price
                *ngIf="getTabByName(PositionPopupTabName.POSITION_VS_PRICE)?.isInitialized"
                [securityMarketId]="securityMarketId"
              ></position-vs-price>
            </div>
            <div
              class="{{
                isTabActive(PositionPopupTabName.PRICE_COMPARISON)
                  ? 'display-block'
                  : 'display-none'
              }}"
            >
              <price-comparison
                *ngIf="getTabByName(PositionPopupTabName.PRICE_COMPARISON)?.isInitialized"
                [securityMarketId]="securityMarketId"
              ></price-comparison>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

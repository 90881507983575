import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Folder } from '@pinnakl/poems/folders/domain';
import { getLoadableEntities$, isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { filter, Observable, take } from 'rxjs';
import { FoldersApiService } from './folders-api.service';
import {
  createFolder,
  deleteAllowedFolder,
  deleteFolder,
  loadFolderData,
  loadFolders,
  selectFolder,
  updateFolder
} from './folders.actions';
import { FoldersState } from './folders.models';
import { foldersQuery } from './folders.selectors';

@Injectable({
  providedIn: 'root'
})
export class FoldersFacadeService {
  createFolderStatus$ = this.store$.select(foldersQuery.getCreateFolderStatus);
  updateFolderStatus$ = this.store$.select(foldersQuery.getUpdateFolderStatus);
  deleteFolderStatus$ = this.store$.select(foldersQuery.getDeleteFolderStatus);
  deleteAllowedStatus$ = this.store$.select(foldersQuery.getDeleteAllowedFolderStatus);

  folders$ = getLoadableEntities$<Folder[]>(
    this.store$.select(foldersQuery.getFolders).pipe(filter(isNeitherNullNorUndefined)),
    this.store$.select(foldersQuery.getFoldersLoaded),
    this.store$.select(foldersQuery.getFoldersLoading),
    this.loadFolders.bind(this, true)
  );
  folderData$ = this.store$.select(foldersQuery.getFolderData);
  selectedFolder$ = this.store$.select(foldersQuery.getSelectedFolder);

  constructor(
    private readonly api: FoldersApiService,
    private readonly store$: Store<FoldersState>
  ) {}

  loadFolders(forceLoad = false): void {
    if (forceLoad) {
      this.store$.dispatch(loadFolders());
    } else {
      this.store$.pipe(select(foldersQuery.getFolders), take(1)).subscribe(folders => {
        if (!folders || folders.length < 1) {
          this.store$.dispatch(loadFolderData());
        }
      });
    }
  }

  loadFolderData(forceLoad = false): void {
    if (forceLoad) {
      this.store$.dispatch(loadFolderData());
    } else {
      this.store$.pipe(select(foldersQuery.getFolderData), take(1)).subscribe(folderData => {
        if (!folderData) {
          this.store$.dispatch(loadFolderData());
        }
      });
    }
  }

  selectFolder(id: string | null): void {
    this.store$.dispatch(selectFolder({ id }));
  }

  updateFolder(folder: Partial<Folder>): void {
    this.store$.dispatch(updateFolder({ folder }));
  }

  createFolder(folder: Omit<Folder, 'id'>): void {
    this.store$.dispatch(createFolder({ folder }));
  }

  checkDeleteFolder(id: string): void {
    this.store$.dispatch(deleteAllowedFolder({ id }));
  }

  deleteFolder(id: string): void {
    this.store$.dispatch(deleteFolder({ id }));
  }

  getMostRecentFolderEndpoint(id: string): Observable<string> {
    return this.api.getMostRecentFolderEndpoint(id);
  }
}

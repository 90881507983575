import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({ template: '', standalone: true })
export class InputMaskPublicApiComponent {
  /**
   * HTML5 input type.
   * @group Props
   */
  @Input() type = 'text';
  /**
   * Placeholder character in mask, default is underscore.
   * @group Props
   */
  @Input() slotChar = '_';
  /**
   * Clears the incomplete value on blur.
   * @group Props
   */
  @Input() autoClear = true;
  /**
   * When enabled, a clear icon is displayed to clear the value.
   * @group Props
   */
  @Input() showClear = false;
  /**
   * Inline style of the input field.
   * @group Props
   */
  @Input() style: { [klass: string]: any } | null | undefined;
  /**
   * Identifier of the focus input to match a label defined for the component.
   * @group Props
   */
  @Input() inputId: string | undefined;
  /**
   * Style class of the input field.
   * @group Props
   */
  @Input() styleClass: string | undefined;
  /**
   * Advisory information to display on input.
   * @group Props
   */
  @Input() placeholder: string | undefined;
  /**
   * Size of the input field.
   * @group Props
   */
  @Input() size: number | undefined;
  /**
   * Maximum number of character allows in the input field.
   * @group Props
   */
  @Input() maxlength: number | undefined;
  /**
   * Specifies tab order of the element.
   * @group Props
   */
  @Input() tabindex: string | undefined;
  /**
   * Title text of the input text.
   * @group Props
   */
  @Input() title: string | undefined;
  /**
   * Used to define a string that labels the input element.
   * @group Props
   */
  @Input() ariaLabel: string | undefined;
  /**
   * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   * @group Props
   */
  @Input() ariaLabelledBy: string | undefined;
  /**
   * Used to indicate that user input is required on an element before a form can be submitted.
   * @group Props
   */
  @Input() ariaRequired: boolean | undefined;
  /**
   * When present, it specifies that an input field is read-only.
   * @group Props
   */
  @Input() readonly: boolean | undefined;
  /**
   * Defines if ngModel sets the raw unmasked value to bound value or the formatted mask value.
   * @group Props
   */
  @Input() unmask: boolean | undefined;
  /**
   * Name of the input field.
   * @group Props
   */
  @Input() name: string | undefined;
  /**
   * When present, it specifies that an input field must be filled out before submitting the form.
   * @group Props
   */
  @Input() required: boolean | undefined;
  /**
   * Regex pattern for alpha characters
   * @group Props
   */
  @Input() characterPattern = '[A-Za-z]';
  /**
   * When present, the input gets a focus automatically on load.
   * @group Props
   */
  @Input() autoFocus: boolean | undefined;
  /**
   * Used to define a string that autocomplete attribute the current element.
   * @group Props
   */
  @Input() autocomplete: string | undefined;
  /**
   * When present, it specifies that whether to clean buffer value from model.
   * @group Props
   */
  @Input() keepBuffer = false;
  /**
   * Mask pattern.
   * @group Props
   */
  @Input() mask: string | undefined | null;
  /**
   * Callback to invoke when the mask is completed.
   * @group Emits
   */
  @Output() onComplete: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Callback to invoke when the component receives focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  @Output() onFocus: EventEmitter<Event> = new EventEmitter<Event>();
  /**
   * Callback to invoke when the component loses focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  @Output() onBlur: EventEmitter<Event> = new EventEmitter<Event>();
  /**
   * Callback to invoke on input.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  @Output() onInput: EventEmitter<Event> = new EventEmitter<Event>();
  /**
   * Callback to invoke on input key press.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  @Output() onKeydown: EventEmitter<Event> = new EventEmitter<Event>();
  /**
   * Callback to invoke when input field is cleared.
   * @group Emits
   */
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
}

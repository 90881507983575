/// <reference types="@types/googlemaps" />

import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Place } from '@pinnakl/shared/types';
import { GoogleMapsAppService } from '@pinnakl/shared/util-external-services';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pinnakl-place-autocomplete',
  template: `
    <input
      type="text"
      (change)="textChanged($event)"
      class="pnkl-input"
      #input
    />
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PinnaklPlaceAutocompleteComponent),
      multi: true
    }
  ]
})
export class PinnaklPlaceAutocompleteComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('input', { static: true }) private inputElementRef: ElementRef;
  private inputElement: HTMLInputElement;
  private placeTypeValid: boolean;
  private suggestionTypeValid = false;
  @Input() placeType: string;
  @Input() sendPlaceDetails = false;
  @Input() suggestionType: string;
  @Output() placeDetailsReceived = new EventEmitter<Place>();
  public destroy$ = new Subject<void>();

  constructor(
    private readonly ngZone: NgZone,
    private googleMapsAppService: GoogleMapsAppService
  ) {}

  ngOnInit(): void {
    this.inputElement = this.inputElementRef.nativeElement;
    this.placeType = this.placeType ? this.placeType.toLowerCase() : '';
    this.placeTypeValid = _.includes(
      ['city', 'country', 'postalcode', 'state', 'street'],
      this.placeType
    );
    this.suggestionTypeValid = _.includes(
      ['(cities)', '(regions)', 'address', 'establishment', 'geocode'],
      this.suggestionType
    );
  }

  ngAfterViewInit(): void {
    this.initializeAutocomplete();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {}

  textChanged(event: Event): void {
    const value = (<HTMLInputElement>event.target).value;
    this.propagateChange(value);
  }

  writeValue(value: string): void {
    this.inputElement.value = value;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getAddressComponent(
    addressComponents: google.maps.GeocoderAddressComponent[],
    componentType: string
  ): string {
    const component = addressComponents.find(addressComponent =>
      _.includes(addressComponent.types, componentType)
    );
    return component ? component.long_name : null;
  }

  private getCity(addressComponents: google.maps.GeocoderAddressComponent[]): string {
    let city = this.getAddressComponent(addressComponents, 'locality');
    if (!city) {
      city = this.getAddressComponent(addressComponents, 'postal_town');
    }
    return city;
  }

  private getStreet(place: google.maps.places.PlaceResult, field: 'street' | 'street1'): string {
    if (field === 'street1') {
      const street1 = this.getAddressComponent(place.address_components ?? [], 'subpremise');
      return street1 ? street1 : null;
    } else {
      const route = this.getAddressComponent(place.address_components, 'route'),
        streetNumber = this.getAddressComponent(place.address_components, 'street_number'),
        streetAddress = !route ? null : streetNumber ? `${streetNumber} ${route}` : route;
      return streetAddress ? streetAddress : null;
    }
  }

  private initializeAutocomplete(): void {
    this.googleMapsAppService.onGoogleMapsApiLoaded.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const autocomplete: google.maps.places.Autocomplete = this.suggestionTypeValid
        ? new google.maps.places.Autocomplete(this.inputElement, {
            types: [this.suggestionType]
          })
        : new google.maps.places.Autocomplete(this.inputElement);

      autocomplete.addListener('place_changed', () =>
        this.ngZone.run(() => {
          const place = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.placeChanged(place);
        })
      );
    });
  }

  private placeChanged(place: google.maps.places.PlaceResult): void {
    let city = '',
      country = '',
      postalCode = '',
      state = '',
      street = '',
      street1 = '';
    if (!this.placeTypeValid) {
      this.setComponentValue(`${place.name}, ${place.formatted_address}`);
    } else {
      switch (this.placeType) {
        case 'city':
          city = this.getCity(place.address_components);
          this.setComponentValue(city);
          break;
        case 'country':
          country = this.getAddressComponent(place.address_components, 'country');
          this.setComponentValue(country);
          break;
        case 'postalcode':
          postalCode = this.getAddressComponent(place.address_components, 'postal_code');
          this.setComponentValue(postalCode);
          break;
        case 'state':
          state = this.getAddressComponent(place.address_components, 'administrative_area_level_1');
          this.setComponentValue(state);
          break;
        case 'street': {
          street = this.getStreet(place, 'street');
          this.setComponentValue(street);
          break;
        }
        case 'street1': {
          street1 = this.getStreet(place, 'street1');
          this.setComponentValue(street);
          break;
        }
      }
    }
    if (this.sendPlaceDetails) {
      this.sendPlaceComponents(city, country, place, postalCode, state, street, street1);
    }
  }

  private propagateChange(placeComponent: string): void {}

  private sendPlaceComponents(
    city: string,
    country: string,
    place: google.maps.places.PlaceResult,
    postalCode: string,
    state: string,
    street: string,
    street1: string
  ): void {
    const addressComponents = place.address_components;
    city = city !== '' ? city : this.getCity(addressComponents);
    country = country !== '' ? country : this.getAddressComponent(addressComponents, 'country');
    postalCode =
      postalCode !== '' ? postalCode : this.getAddressComponent(addressComponents, 'postal_code');
    state =
      state !== ''
        ? state
        : this.getAddressComponent(addressComponents, 'administrative_area_level_1');
    street = street !== '' ? street : this.getStreet(place, 'street');
    street1 = street1 !== '' ? street1 : this.getStreet(place, 'street1');
    this.placeDetailsReceived.emit(new Place(city, country, postalCode, state, street, street1));
  }

  private setComponentValue(value: string): void {
    this.propagateChange(value);
    this.inputElement.value = value;
  }
}

export interface DateInfo<T = string | number> {
  year: T;
  month: T;
  day: T;
}

export enum CommonDateFormats {
  DAY = 'DD',
  MONTH_NUMBER = 'MM',
  MONTH_SHORT_NAME = 'MMM',
  MONTH_FULL_NAME = 'MMMM',
  YEAR = 'YYYY',
  SHORT = 'YYYY-MM-DD',
  LONG = 'YYYY-MM-DD HH:mm:ss'
}

export enum DateRanges {
  TD = 'Today',
  PD = 'Previous Day',
  WTD = 'Week to day',
  PW = 'Previous Week',
  PM = 'Previous Month',
  PQ = 'Previous Quarter',
  HYTD = 'Half Year to day',
  PY = 'Previous Year',
  MTD = 'Month to Date',
  QTD = 'Quarter to Date',
  YTD = 'Year to Date'
}

export enum MonthRanges {
  PM = 'Previous Month',
  PQ = 'Previous Quarter',
  LTM = 'Last 12 months',
  LTY = 'Last 3 Years',
  LFY = 'Last 5 Years',
  PY = 'Previous Year',
  PTY = 'Previous 3 Years',
  PFY = 'Previous 5 Years'
}

export enum DateRangesShort {
  TD = 'Today',
  PD = 'Prev Day',
  WTD = 'Week to Date',
  PW = 'Prev Week',
  PM = 'Prev Month',
  PQ = 'Prev Quarter',
  HYTD = 'Half Year to Date',
  PY = 'Prev Year',
  MTD = 'MTD',
  QTD = 'QTD',
  YTD = 'YTD'
}

export type DateRangeTuple = [
  startDate: number | Date | null | undefined,
  endDate: number | Date | null | undefined
];

export type CalendarDateRangeTuple = [startDate: Date, endDate: Date | null];
export type DefinedCalendarDateRangeTuple = [startDate: Date, endDate: Date];

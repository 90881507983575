import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { v4 as uuid } from 'uuid';
import { NoopValueAccessorDirective } from '../../directives';
import { ControlInjectedInputSwitchComponent } from './control-injected-input-switch.component';

@Component({
  imports: [CommonModule, InputSwitchModule, ReactiveFormsModule, FormsModule],
  standalone: true,
  selector: 'prime-input-switch',
  templateUrl: 'prime-input-switch.component.html',
  styleUrls: ['prime-input-switch.component.scss'],
  hostDirectives: [NoopValueAccessorDirective],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimeInputSwitchComponent
  extends ControlInjectedInputSwitchComponent
  implements OnInit
{
  @Input() label?: string;
  @Input() labelPos?: 'left' | 'right';
  @Input() labelClass = '';
  @Input() containerClass = '';

  @ContentChild('validationContent', { static: false })
  validationContent: TemplateRef<null> | null = null;

  override ngOnInit(): void {
    super.ngOnInit();
    if (!this.ariaLabelledBy && !this.inputId) {
      this.inputId = `input-switch-${uuid()}`;
      this.ariaLabelledBy = `input-switch-${uuid()}`;
    }
  }

  preventClickBehavior({ originalEvent }): void {
    if (originalEvent) {
      originalEvent.preventDefault();
      originalEvent.stopPropagation();
    }
  }
}

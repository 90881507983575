/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpClient,
  HttpContext,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVER_URL } from '@pinnakl/shared/constants';
import {
  DeleteHttpRequest,
  GetHttpRequest,
  PatchHttpRequest,
  PostHttpRequest,
  PutHttpRequest
} from '@pinnakl/shared/types';
import { Observable, catchError, firstValueFrom, throwError } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UPLOAD_ID } from './upload-listener.interceptor';

const enum REQUEST_TYPE {
  GET = 'get',
  PUT = 'put',
  PATCH = 'patch',
  POST = 'post',
  DELETE = 'delete'
}

@Injectable({
  providedIn: 'root'
})
export class PinnaklHttpService {
  constructor(
    @Inject(HTTP_SERVER_URL) private readonly _HTTP_URL: string,
    private readonly http: HttpClient
  ) {}

  static getEndpointUrlWithParams(
    endpoint: string,
    options,
    params: null | Record<string, any> = null
  ): string {
    if (!options && !params) {
      return endpoint;
    }
    let queryParams = '';
    if (params) {
      let qp = new HttpParams();
      Object.keys(params).forEach(
        key => params[key] !== undefined && (qp = qp.set(key, params[key]))
      );
      queryParams = qp.toString();
    }

    let optionsParams = '';
    if (options) {
      const paramsWithReplacedAmpersand = JSON.stringify(options).replace('&', '%26');
      optionsParams = `options=${encodeURI(paramsWithReplacedAmpersand)}`;
    }

    return `${endpoint}?${queryParams}${optionsParams && queryParams ? '&' : ''}${optionsParams}`;
  }

  delete<T>({ endpoint, body, customHostUrl }: DeleteHttpRequest): Promise<T> {
    return firstValueFrom<T>(
      this.sendHttpRequest<any>(
        REQUEST_TYPE.DELETE,
        endpoint,
        {
          body
        },
        customHostUrl
      )
    ).catch(e => this.handleErrorPromise<T>(e, REQUEST_TYPE.DELETE));
  }

  deleteObservable<T>({ endpoint, body, customHostUrl }: DeleteHttpRequest): Observable<T> {
    return this.sendHttpRequest<T>(
      REQUEST_TYPE.DELETE,
      endpoint,
      {
        body
      },
      customHostUrl
    ).pipe(catchError(error => this.handleError<T>(error, REQUEST_TYPE.DELETE)));
  }

  get<T>({ optionsParams, otherParams, endpoint, customHostUrl }: GetHttpRequest): Promise<T> {
    const preparedEndpointUrl = PinnaklHttpService.getEndpointUrlWithParams(
      endpoint,
      optionsParams,
      otherParams
    );
    return firstValueFrom<T>(
      this.sendHttpRequest<T>(REQUEST_TYPE.GET, preparedEndpointUrl, {}, customHostUrl)
    ).catch(e => this.handleErrorPromise<T>(e, REQUEST_TYPE.GET));
  }

  getObservable<T>({
    optionsParams,
    otherParams,
    endpoint,
    customHostUrl
  }: GetHttpRequest): Observable<T> {
    const preparedEndpointUrl = PinnaklHttpService.getEndpointUrlWithParams(
      endpoint,
      optionsParams,
      otherParams
    );
    return this.sendHttpRequest<T>(REQUEST_TYPE.GET, preparedEndpointUrl, {}, customHostUrl).pipe(
      catchError(error => this.handleError<T>(error, REQUEST_TYPE.GET))
    );
  }

  post<T>({ body, endpoint, customHostUrl }: PostHttpRequest): Promise<T> {
    return firstValueFrom<T>(
      this.sendHttpRequest<T>(
        REQUEST_TYPE.POST,
        endpoint,
        {
          body: body
        },
        customHostUrl
      )
    ).catch(e => this.handleErrorPromise<T>(e, REQUEST_TYPE.POST));
  }

  postObservable<T>({ body, endpoint, customHostUrl }: PostHttpRequest): Observable<T> {
    return this.sendHttpRequest<T>(
      REQUEST_TYPE.POST,
      endpoint,
      {
        body: body
      },
      customHostUrl
    ).pipe(catchError(e => this.handleError<T>(e, REQUEST_TYPE.POST)));
  }

  put<T>({ endpoint, body, customHostUrl }: PutHttpRequest): Promise<T> {
    return firstValueFrom<T>(
      this.sendHttpRequest<any>(
        REQUEST_TYPE.PUT,
        endpoint,
        {
          body: body
        },
        customHostUrl
      )
    ).catch(e => this.handleErrorPromise<T>(e, REQUEST_TYPE.PUT));
  }

  putObservable<T>({ endpoint, body, customHostUrl }: PutHttpRequest): Observable<T> {
    return this.sendHttpRequest<T>(
      REQUEST_TYPE.PUT,
      endpoint,
      {
        body: body
      },
      customHostUrl
    ).pipe(catchError(e => this.handleError<T>(e, REQUEST_TYPE.PUT)));
  }

  patchObservable<T>({ endpoint, body, customHostUrl }: PatchHttpRequest): Observable<T> {
    return this.sendHttpRequest<T>(
      REQUEST_TYPE.PATCH,
      endpoint,
      {
        body: body
      },
      customHostUrl
    ).pipe(catchError(e => this.handleError<T>(e, REQUEST_TYPE.PATCH)));
  }

  postFilesObservable<T>({
    endpoint,
    body,
    customHostUrl,
    uploadId,
    headers
  }: PostHttpRequest): Observable<T> {
    const hostUrl = customHostUrl ?? this._HTTP_URL;
    const context = new HttpContext().set(UPLOAD_ID, uploadId);
    return this.http
      .post<HttpEvent<any>>(`${hostUrl}/${endpoint}`, JSON.stringify(body), {
        ...(headers ? { headers: new HttpHeaders(headers) } : {}),
        context,
        observe: 'events',
        reportProgress: true
      })
      .pipe(
        filter((ev: HttpEvent<any>) => ev.type === HttpEventType.Response),
        map((response: any) => (response as HttpResponse<any>).body),
        catchError(e => this.handleError<T>(e, REQUEST_TYPE.POST))
      );
  }

  getObservableWithFullResponse<T>({
    optionsParams,
    otherParams,
    endpoint,
    customHostUrl
  }: GetHttpRequest): Observable<HttpResponse<T>> {
    const preparedEndpointUrl = PinnaklHttpService.getEndpointUrlWithParams(
      endpoint,
      optionsParams,
      otherParams
    );
    return this.http.get<T>(`${customHostUrl ?? this._HTTP_URL}/${preparedEndpointUrl}`, {
      observe: 'response',
      headers: new HttpHeaders().set('Access-Control-Allow-Headers', 'Location')
    });
  }

  private handleError<T>(error: any, requestType: REQUEST_TYPE): Observable<T> {
    console.log(`Error in ${requestType} request`);
    return throwError(() => error);
  }

  private handleErrorPromise<T>(error: any, requestType: REQUEST_TYPE): Promise<T> {
    console.log(`Error in ${requestType} request`);
    return Promise.reject(error) as Promise<T>;
  }

  private sendHttpRequest<T>(
    type: REQUEST_TYPE,
    endpoint: string,
    data: { headers?: any; body?: any },
    customHostUrl?: string
  ): Observable<T> {
    const hostUrl = customHostUrl ?? this._HTTP_URL;

    switch (type) {
      case 'get':
        return this.http.get<T>(
          `${hostUrl}/${endpoint}`,
          data.headers ? { headers: new HttpHeaders(data.headers) } : undefined
        );
      case 'delete':
        return this.http.delete<T>(
          `${hostUrl}/${endpoint}`,
          data
            ? {
                ...(data.headers ? { headers: new HttpHeaders(data.headers) } : {}),
                ...(data.body ? { body: data.body } : {})
              }
            : undefined
        );
      case 'put':
        data.headers = {
          ...data.headers,
          'Content-Type': 'application/json'
        };
        return this.http.put<T>(
          `${hostUrl}/${endpoint}`,
          JSON.stringify(data.body),
          data.headers ? { headers: new HttpHeaders(data.headers) } : undefined
        );
      case 'patch':
        data.headers = {
          ...data.headers,
          'Content-Type': 'application/json-patch+json'
        };
        return this.http.patch<T>(
          `${hostUrl}/${endpoint}`,
          JSON.stringify(data.body),
          data.headers ? { headers: new HttpHeaders(data.headers) } : undefined
        );
      case 'post':
        data.headers = {
          ...data.headers,
          'Content-Type': 'application/json'
        };
        return this.http.post<T>(
          `${hostUrl}/${endpoint}`,
          JSON.stringify(data.body),
          data.headers ? { headers: new HttpHeaders(data.headers) } : undefined
        );
      default:
        throw new Error(`Request type ${type} is not supportable`);
    }
  }
}

import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PmsColumn } from '@pinnakl/pms/domain';
import { loadColumns, loadColumnsFailure, setColumns } from './columns.actions';
import { PmsColumnsState } from './columns.models';

export const columnsAdapter: EntityAdapter<PmsColumn> = createEntityAdapter<PmsColumn>({
  sortComparer: false
});

export const initialState: PmsColumnsState = columnsAdapter.getInitialState({
  loading: false
});

export const columnsReducer = createReducer(
  initialState,
  on(loadColumns, state => ({ ...state, loading: true })),
  on(loadColumnsFailure, (state, { error }) => ({ ...state, error, loading: false })),
  on(setColumns, (state, { columns }) =>
    columnsAdapter.setAll(columns, { ...state, loading: false })
  )
);

import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { Account } from '@pinnakl/poems/accounts/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountsApiService {
  private readonly endpoint = `${V3_ENDPOINT}accounts`;

  constructor(private readonly wsp: WebServiceProvider) {}

  getAccounts(): Observable<Account[]> {
    return this.wsp
      .get<Account[]>({
        endpoint: this.endpoint
      })
      .pipe(
        map(accounts =>
          accounts.map(account => ({
            ...account,
            accountCodeLabel: account.accountCode.toUpperCase()
          }))
        )
      );
  }
}

import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { FolderComment } from '@pinnakl/poems/folders/domain';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FolderCommentsApiService {
  private readonly folderCommentsEndpoint = 'v3/entities/folder_comments';

  constructor(private readonly wsp: WebServiceProvider) {}

  getFolderCommentsByFolderId(folderId: string): Observable<FolderComment[]> {
    return this.wsp.get<FolderComment[]>({
      endpoint: this.folderCommentsEndpoint,
      optionsParams: {
        fields: ['id', 'folderId', 'comment', 'timestamp', 'authorId', 'authorName'],
        filters: [
          {
            key: 'folderId',
            type: 'EQ',
            value: [folderId]
          }
        ]
      }
    });
  }

  getFolderCommentsByCode(folderCode: string): Observable<FolderComment[]> {
    return this.wsp.get<FolderComment[]>({
      endpoint: this.folderCommentsEndpoint,
      optionsParams: {
        fields: ['id', 'folderId', 'comment', 'timestamp', 'authorId', 'authorName'],
        filters: [
          {
            key: 'folderCode',
            type: 'EQ',
            value: [folderCode]
          }
        ]
      }
    });
  }

  postFolderComment(comment: Partial<FolderComment>): Observable<FolderComment> {
    return this.wsp.post<FolderComment>({
      endpoint: this.folderCommentsEndpoint,
      body: {
        folderId: comment.folderId,
        comment: comment.comment,
        authorId: comment.authorId,
        timestamp: new Date()
      }
    });
  }

  putFolderComment(comment: Partial<FolderComment>): Observable<FolderComment> {
    return this.wsp.put<FolderComment>({
      endpoint: this.folderCommentsEndpoint,
      body: {
        id: comment.id,
        folderId: comment.folderId,
        comment: comment.comment,
        authorId: comment.authorId,
        timestamp: new Date()
      }
    });
  }

  deleteFolderComment(id: string): Observable<void> {
    return this.wsp.delete<void>({
      endpoint: `${this.folderCommentsEndpoint}/${id}`
    });
  }
}

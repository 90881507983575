import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import {
  PageAccessLevel,
  PageAccessLevelCode,
  TradeWorkflowSpec,
  TradeWorkflowSpecApi,
  TradeWorkflowSpecUpdateParams
} from '@pinnakl/poems/trade-workflow-spec/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { Observable, map, tap } from 'rxjs';

type UpdateTradeWorkflowSpec = TradeWorkflowSpecUpdateParams & Pick<TradeWorkflowSpec, 'id'>;

@Injectable({
  providedIn: 'root'
})
export class TradeWorkflowSpecApiService {
  private readonly tradeWorkflowSpecEndpoint = `${V3_ENDPOINT}oms_config`;

  constructor(
    private readonly wsp: WebServiceProvider,
    private readonly toast: PinnaklUIToastMessage
  ) {}

  getTradeWorkflowSpec(): Observable<TradeWorkflowSpec> {
    return this.wsp
      .get<TradeWorkflowSpecApi[]>({
        endpoint: this.tradeWorkflowSpecEndpoint
      })
      .pipe(
        tap(values => values.length === 0 && this.toast.error('No trade workflow spec found')),
        map(
          (values): TradeWorkflowSpec => ({
            ...(values?.[0] ?? ({} as TradeWorkflowSpec)),
            pmsVersionsAccessLevel: PageAccessLevelCode[
              values?.[0]?.pmsAccessLevel ?? PageAccessLevelCode.DEFAULT
            ] as PageAccessLevel,
            emsVersionsAccessLevel: PageAccessLevelCode[
              values?.[0]?.emsAccessLevel ?? PageAccessLevelCode.DEFAULT
            ] as PageAccessLevel
          })
        )
      );
  }

  updateTradeWorkflowSpec(
    tradeWorkflowSpec: UpdateTradeWorkflowSpec
  ): Observable<TradeWorkflowSpec> {
    return this.wsp.put<TradeWorkflowSpec>({
      endpoint: this.tradeWorkflowSpecEndpoint,
      body: tradeWorkflowSpec
    });
  }

  createTradeWorkflowSpec(
    tradeWorkflowSpec: Partial<TradeWorkflowSpec>
  ): Observable<TradeWorkflowSpec> {
    return this.wsp.post<TradeWorkflowSpec>({
      endpoint: this.tradeWorkflowSpecEndpoint,
      body: tradeWorkflowSpec
    });
  }
}

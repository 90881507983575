import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadPositionsBase, updatePriceLast } from './positions-general.actions';
import { PositionBaseInfoFullItem, PositionsGeneralState } from './positions-general.models';
import { PositionsGeneralQuery } from './positions-general.selectors';

@Injectable()
export class PositionsGeneralFacadeService {
  private readonly basePositionsMap = new Map<
    string,
    Observable<PositionBaseInfoFullItem | undefined>
  >();
  basePositionsLoading$ = this.store$.select(PositionsGeneralQuery.getBasePositionsLoading);

  constructor(private readonly store$: Store<PositionsGeneralState>) {}

  loadPositionBaseInfoBySecurityMarketId(securityMarketId: string, forceFetch = false): void {
    if (forceFetch || !this.basePositionsMap.has(securityMarketId)) {
      this.store$.dispatch(loadPositionsBase({ securityMarketId }));
    }
  }

  getPositionBaseInfoBySecurityMarketId(
    id: string
  ): Observable<PositionBaseInfoFullItem | undefined> {
    let positionsStream = this.basePositionsMap.get(id);

    if (positionsStream) {
      return positionsStream;
    }

    positionsStream = this.store$.pipe(select(PositionsGeneralQuery.getBasePositionsById(id)));
    this.basePositionsMap.set(id, positionsStream);

    return positionsStream;
  }

  positionBaseInfoLoaded(securityMarketId: string): void {
    this.basePositionsMap.set(
      securityMarketId,
      this.store$.pipe(select(PositionsGeneralQuery.getBasePositionsById(securityMarketId)))
    );
  }

  updatePriceLastForSecurityMarketIdInMostRecentPrice(
    securityMarketId: string,
    priceLast: number
  ): void {
    this.store$.dispatch(
      updatePriceLast({
        securityMarketId,
        priceLast
      })
    );
  }
}

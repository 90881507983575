import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TimeseriesPricesState } from './timeseries-prices.models';

const getTimeseriesItemsStateSelector = createFeatureSelector<{ prices: TimeseriesPricesState }>(
  'timeseries'
);

const pricesSelector = createSelector(getTimeseriesItemsStateSelector, state => state.prices);

const getPricesBySecurityMarketId = (id: string) =>
  createSelector(pricesSelector, ({ entities }) => entities[id]?.prices);

const getPricesLoading = createSelector(pricesSelector, ({ loading }) => loading);

export const timeseriesPricesQuery = {
  getPricesBySecurityMarketId,
  getPricesLoading
};

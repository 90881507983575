<span
  (click)="clickOnTheToggle()"
  [ngClass]="{ checked: modelValue }"
  class="big-toggle"
>
  <label
    [for]="'activeTrading' + uniqId"
    class="b-switch"
  >
    <input
      (click)="clickOnTheToggle()"
      [id]="'activeTrading' + uniqId"
      [value]="modelValue"
      type="checkbox"
    />
  </label>
  <span class="label-toggle"> {{ modelValue ? 'Active' : 'Inactive' }}</span>
</span>

import {
  ComponentFactory,
  ComponentFactoryResolver,
  Injectable,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { MatDialogService } from './mat-dialog.service';
import { MatPinnaklModalComponent } from './mat-pinnakl-modal.component';

export interface DialogData<T = undefined> {
  headerText: string;
  component?: any;
  factoryComponent?: ComponentFactory<any>;
  template?: TemplateRef<any>;
  context?: T;
  showCloseButton?: boolean;
  dragDisable?: boolean;
  componentData?: any;
  headerStyle?: any;
  // passing viewContainerRef is required to access view providers (by now modal can access only root providers)
  viewContainerRef?: ViewContainerRef;
}

export interface DialogOptions {
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  disableClose?: boolean;
  panelClass?: string | string[]; // for add a few css class use format ['class 1', 'class 2']
  height?: number | string;
  maxHeight?: number | string;
  minHeight?: number | string;
  autoFocus?: string | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MatDialogFactoryService<T = undefined> {
  constructor(
    private dialog: MatDialog,
    private resolver: ComponentFactoryResolver
  ) {}

  /**
   *
   * @param dialogData general options for modal, need set component or factoryComponent or template
   * @param options
   * @returns
   */
  open(
    dialogData: DialogData<T>,
    options: DialogOptions = { width: 500, disableClose: true }
  ): MatDialogService<T> {
    if (dialogData.component) {
      dialogData.factoryComponent = this.resolver.resolveComponentFactory(dialogData.component);
    }
    const dialogRef = this.dialog.open<MatPinnaklModalComponent<T>, DialogData<T>>(
      MatPinnaklModalComponent,
      {
        ...this.mapOptions(options),
        data: dialogData,
        viewContainerRef: dialogData.viewContainerRef
      }
    );

    dialogRef.afterClosed().pipe(first());

    return new MatDialogService(dialogRef);
  }

  private mapOptions({
    width,
    minWidth,
    maxWidth,
    disableClose,
    panelClass,
    height,
    maxHeight,
    minHeight,
    autoFocus
  }: DialogOptions): MatDialogConfig<DialogData<T>> {
    return {
      width: `${width}px`,
      minWidth: `${minWidth}px`,
      maxWidth: `${maxWidth}px`,
      height: `${height}px`,
      maxHeight: `${maxHeight}px`,
      minHeight: `${minHeight}px`,
      panelClass,
      disableClose,
      autoFocus
    };
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FolderCommentsEffects } from './folder-comments.effects';
import { folderCommentsReducer, initialState } from './folder-comments.reducer';
import { featureSelectorName } from './folder-comments.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureSelectorName, folderCommentsReducer, {
      initialState
    }),
    EffectsModule.forFeature([FolderCommentsEffects])
  ]
})
export class FolderCommentsDataAccessModule {}

import { createAction, props } from '@ngrx/store';
import { SecurityPricesTimeItems } from './timeseries-prices.models';

export enum TimeseriesPricesActions {
  LoadPricesTimeSeries = '[Timeseries prices] Load prices by sec market id',
  PricesTimeSeriesLoaded = '[Timeseries prices] Prices by sec market id loaded'
}

export const loadPricesTimeSeries = createAction(
  TimeseriesPricesActions.LoadPricesTimeSeries,
  props<{ securityMarketId: string }>()
);

export const pricesTimeSeriesLoaded = createAction(
  TimeseriesPricesActions.PricesTimeSeriesLoaded,
  props<{ pricesTimeItems: SecurityPricesTimeItems }>()
);

<kendo-datepicker
  (valueChange)="valueChanged($event)"
  [(value)]="value"
  [bottomView]="bottomView"
  [disabled]="disableInput ? true : undefined"
  [format]="dateOptions ? dateOptions.format : undefined"
  [ngClass]="inputClass"
  [topView]="topView"
>
</kendo-datepicker>

<!--positionChange handler is required to run inner change detection and initially render accordingly to anchorAlign-->
<kendo-popup
  (positionChange)="markForCheck()"
  *ngIf="showConfirmation"
  [anchorAlign]="anchorAlign"
  [anchor]="anchor"
  [animate]="animate"
  [collision]="collision"
  [popupAlign]="popupAlign"
  class="row"
>
  <div
    [ngClass]="containerClass"
    class="row"
  >
    <div [ngClass]="messageClass">
      <span [innerHTML]="confirmationMessage"></span>
    </div>
    <div class="col-md-default-width">
      <i
        (click)="actionConfirmed()"
        class="icon-pinnakl-ok color-green cursor-pointer"
      ></i>
    </div>
    <div class="col-md-default-width">
      <i
        (click)="cancelAction()"
        class="icon-pinnakl-cancel color-red cursor-pointer"
      ></i>
    </div>
  </div>
</kendo-popup>

import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { loadPricesTimeSeries, pricesTimeSeriesLoaded } from './timeseries-prices.actions';
import { SecurityPricesTimeItems, TimeseriesPricesState } from './timeseries-prices.models';

export const timeseriesPricesAdapter: EntityAdapter<SecurityPricesTimeItems> =
  createEntityAdapter<SecurityPricesTimeItems>({
    selectId: ({ securityMarketId }) => securityMarketId
  });

export const initialState: TimeseriesPricesState = timeseriesPricesAdapter.getInitialState({
  loading: false
});

export const timeseriesPricesReducer = createReducer(
  initialState,
  on(loadPricesTimeSeries, state => ({ ...state, loading: true })),
  on(pricesTimeSeriesLoaded, (state, { pricesTimeItems }) =>
    timeseriesPricesAdapter.addOne(pricesTimeItems, {
      ...state,
      loading: false
    })
  )
);

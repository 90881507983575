import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { filter } from 'rxjs';
import { prepareGridColumns, resetColumns } from './grid-columns.actions';
import { GridColumnsState } from './grid-columns.models';
import { gridColumnsQuery } from './grid-columns.selectors';

@Injectable()
export class PmsGridColumnsFacadeService {
  gridColumns$ = this.store$
    .select(gridColumnsQuery.getGridColumns)
    .pipe(filter(isNeitherNullNorUndefined));

  constructor(private readonly store$: Store<GridColumnsState>) {}

  prepareGridColumns(): void {
    this.store$.dispatch(prepareGridColumns());
  }

  reset() {
    this.store$.dispatch(resetColumns());
  }
}

import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapsAppService } from './google-maps-app.service';

@NgModule({
  imports: [GoogleMapsModule],
  providers: [HttpClient, GoogleMapsAppService],
  exports: [GoogleMapsModule]
})
export class GoogleMapsAppModule {}

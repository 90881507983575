import { createAction, props } from '@ngrx/store';
import { Aum } from '@pinnakl/poems/aum/domain';

export enum AumActions {
  LoadAum = '[AUM V2] Load aum',
  AumLoaded = '[AUM V2] Aum loaded'
}

export const loadAum = createAction(AumActions.LoadAum);
export const aumLoaded = createAction(AumActions.AumLoaded, props<{ aums: Aum[] }>());

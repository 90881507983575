import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { debounce } from 'lodash';
import optiscroll from 'optiscroll';

@Directive({
  standalone: true,
  selector: '[pnklCustomScroll]'
})
export class CustomScrollDirective implements OnInit {
  private lightScrollBarClass = 'custom-scroll-light';
  private optiScrollClass = 'optiscroll';
  @Input() darkMode = false;
  @Input() useInfinitiveScroll = false;
  @Input() emitReachBottomEvents = false;
  @Output() whenScrolledTo = new EventEmitter<void>();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(this.elementRef.nativeElement, this.optiScrollClass);
  }

  ngOnInit(): void {
    if (this.darkMode) {
      this.renderer.addClass(this.elementRef.nativeElement, this.lightScrollBarClass);
    }
    const element = this.elementRef?.nativeElement;
    optiscroll(element, {});

    if (this.useInfinitiveScroll) {
      element?.addEventListener('scrollreachbottom', () =>
        debounce(
          () => {
            if (this.emitReachBottomEvents) {
              this.whenScrolledTo.emit();
            }
          },
          100,
          {}
        )
      );
    }
  }
}

import { createReducer, on } from '@ngrx/store';
import {
  benchmarkIndexIntradayPricesFailure,
  benchmarkIndexIntradayPricesSuccess,
  clearMainAccountId,
  setMainAccountId,
  startBenchmarkIndexesPolling,
  stopBenchmarkIndexesPolling
} from './benchmark-index-intraday-prices.actions';
import { BenchmarkIndexIntradayPricesState } from './benchmark-index-intraday-prices.model';

const initialState: BenchmarkIndexIntradayPricesState = {
  benchmarkIndexes: [],
  isInPollingState: false
};

export const benchmarkIndexIntradayPricesReducer = createReducer(
  initialState,
  on(benchmarkIndexIntradayPricesSuccess, (state, { indexes }) => ({
    ...state,
    benchmarkIndexes: indexes.sort((a, b) => a.timestamp.localeCompare(b.timestamp))
  })),
  on(benchmarkIndexIntradayPricesFailure, (state, { error }) => ({
    ...state,
    benchmarkIndexes: []
  })),
  on(startBenchmarkIndexesPolling, state => ({ ...state, isInPollingState: true })),
  on(stopBenchmarkIndexesPolling, state => ({ ...state, isInPollingState: false })),
  on(setMainAccountId, (state, { id }) => ({ ...state, mainAccountId: id })),
  on(clearMainAccountId, state => ({ ...state, mainAccountId: undefined }))
);

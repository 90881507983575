import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ISecurity, SecurityBase, SecurityPostPayload } from '@pinnakl/securities/domain';
import { SecuritySearchResult } from '@pinnakl/shared/types';
import { Observable, map, of, switchMap, take } from 'rxjs';
import { SecuritiesApiService } from './securities-api.service';
import { loadSecurities } from './securities.actions';
import { SecuritiesState } from './securities.models';
import { securitiesQuery } from './securities.selectors';

@Injectable()
export class SecuritiesFacadeService {
  securities$ = this.store$.select(securitiesQuery.getAllSecurities);

  constructor(
    private readonly store$: Store<SecuritiesState>,
    private readonly api: SecuritiesApiService
  ) {}

  loadSecurities(forceFetch = false): void {
    this.securities$
      .pipe(take(1))
      .subscribe(
        securities => (forceFetch || !securities) && this.store$.dispatch(loadSecurities())
      );
  }

  loadSecurityInfoBySecurityMarketId(securityMarketId: string): Observable<ISecurity> {
    return this.api.getSecurityInfoBySecurityMarketId(securityMarketId);
  }

  getSecurityInfoBySecurityMarketId(securityMarketId: string): Observable<ISecurity> {
    return this.securities$.pipe(
      take(1),
      map(
        securities =>
          securities &&
          securities.find(
            ({ securityMarketId: secMarketId }) => securityMarketId === secMarketId?.toString()
          )
      ),
      switchMap(security =>
        security ? of(security) : this.loadSecurityInfoBySecurityMarketId(securityMarketId)
      )
    );
  }

  searchSecurities(
    text: string,
    assetType: string | null,
    additionalFilters: {
      key: string;
      type: string;
      value: string[];
    }[] = [],
    includeCheckForExpiration = false
  ): Observable<SecuritySearchResult[]> {
    return this.api.searchSecurities(text, assetType, additionalFilters, includeCheckForExpiration);
  }

  postSecurity(payload: SecurityPostPayload): Observable<SecurityBase> {
    return this.api.postSecurity(payload);
  }

  getSecurityById(id: number): Observable<SecurityBase> {
    return this.api.getSecurityById(id);
  }
}

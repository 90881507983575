import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { PinnaklEnvironment } from './environment.model';
import { EnvironmentService } from './environment.service';
import { ENVIRONMENT_CONFIG, ENVIRONMENT_SERVICE } from './environment.tokens';

export interface EnvironmentConfig {
  config?: PinnaklEnvironment;
}

const registerEnvironment = (
  config: PinnaklEnvironment,
  environmentService: EnvironmentService
): EnvironmentService => {
  environmentService.registerEnvironment(config);
  return environmentService;
};

@NgModule({
  imports: [CommonModule]
})
export class EnvironmentModule {
  static forRoot(options: EnvironmentConfig): ModuleWithProviders<EnvironmentModule> {
    if (!options) throw new Error('Environment configuration was not defined');
    return {
      ngModule: EnvironmentModule,
      providers: [
        {
          provide: ENVIRONMENT_CONFIG,
          useValue: options.config
        },
        {
          provide: ENVIRONMENT_SERVICE,
          useFactory: registerEnvironment,
          deps: [ENVIRONMENT_CONFIG, EnvironmentService]
        }
      ]
    };
  }
}

<div
  *ngIf="label && labelPos; else withoutLabel"
  class="prime-switch-container flex w-full align-items-center"
>
  @if (label && labelPos === 'left') {
    <ng-container *ngTemplateOutlet="labelContent"></ng-container>
  }
  <ng-container *ngTemplateOutlet="switchInput"></ng-container>
  @if (label && labelPos === 'right') {
    <ng-container *ngTemplateOutlet="labelContent"></ng-container>
  }
</div>
<ng-container *ngTemplateOutlet="validationContent"></ng-container>

<ng-template #labelContent>
  <label
    [for]="inputId"
    [id]="ariaLabelledBy"
    [ngClass]="[labelClass, labelPos === 'left' ? 'mr-2' : 'ml-2']"
    class="prime-switch-label cursor-pointer mb-0 select-none"
    >{{ label }}</label
  >
</ng-template>

<ng-template #withoutLabel>
  <ng-container *ngTemplateOutlet="switchInput"></ng-container>
</ng-template>

<ng-template #switchInput>
  <p-inputSwitch
    (onChange)="preventClickBehavior($event); onChange.emit($event)"
    [ariaLabel]="ariaLabel"
    [ariaLabelledBy]="ariaLabelledBy"
    [falseValue]="falseValue"
    [formControl]="$any(formControl)"
    [inputId]="inputId"
    [name]="name"
    [readonly]="readonly"
    [styleClass]="styleClass"
    [style]="style"
    [tabindex]="tabindex"
    [trueValue]="trueValue"
    class="block {{ containerClass }}"
  ></p-inputSwitch>
</ng-template>

import { createAction, props } from '@ngrx/store';
import { RealtimePriceOverride, RealtimePriceOverrideBase } from '@pinnakl/poems/pricing/domain';

export enum RealtimePriceOverrideActions {
  LoadAllRealtimePriceOverrides = '[Pricing | Realtime override] Load all realtime price overrides',
  AllRealtimePriceOverridesLoaded = '[Pricing | Realtime override] All realtime price overrides loaded',
  LoadRealtimeOverrideBySecurityMarketId = '[Pricing | Realtime override] Load realtime override by sec market id',
  RealtimeOverrideBySecurityMarketIdLoaded = '[Pricing | Realtime override] Realtime override by sec market id loaded',
  CreateRealtimeOverride = '[Pricing | Realtime override] Create realtime override',
  RealtimeOverrideStatusCreated = '[Pricing | Realtime override] Realtime override created',
  DeleteRealtimeOverride = '[Pricing | Realtime override] Delete realtime override',
  RealtimeOverrideDeleted = '[Pricing | Realtime override] Realtime override deleted'
}

export const loadAllRealtimePriceOverrides = createAction(
  RealtimePriceOverrideActions.LoadAllRealtimePriceOverrides
);

export const allRealtimePriceOverridesLoaded = createAction(
  RealtimePriceOverrideActions.AllRealtimePriceOverridesLoaded,
  props<{ overrides: RealtimePriceOverride[] }>()
);

export const loadOverrideBySecurityMarketId = createAction(
  RealtimePriceOverrideActions.LoadRealtimeOverrideBySecurityMarketId,
  props<{ securityMarketId: string }>()
);

export const overrideBySecurityMarketIdLoaded = createAction(
  RealtimePriceOverrideActions.RealtimeOverrideBySecurityMarketIdLoaded,
  props<{ realtimePriceOverride: RealtimePriceOverride }>()
);

export const createOverride = createAction(
  RealtimePriceOverrideActions.CreateRealtimeOverride,
  props<{ realtimePriceOverride: RealtimePriceOverrideBase }>()
);
export const overrideCreated = createAction(
  RealtimePriceOverrideActions.RealtimeOverrideStatusCreated,
  props<{ realtimePriceOverride: RealtimePriceOverride }>()
);

export const deleteOverride = createAction(
  RealtimePriceOverrideActions.DeleteRealtimeOverride,
  props<{ securityMarketId: string }>()
);
export const overrideDeleted = createAction(
  RealtimePriceOverrideActions.RealtimeOverrideDeleted,
  props<{ securityMarketId: string }>()
);

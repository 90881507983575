import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputOptions } from '@pinnakl/shared/types';

@Component({
  selector: 'pinnakl-input-floating-label',
  templateUrl: './pinnakl-input-floating-label.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PinnaklInputFloatingLabelComponent),
      multi: true
    }
  ]
})
export class PinnaklInputFloatingLabelComponent implements OnInit, ControlValueAccessor {
  private inputInActive = 'is-ready';
  private inputActiveCss = 'is-active is-completed';
  applyActiveCss = false;
  @Input() class: string;
  @Input() disabledInput: boolean;
  @Input() inputClass: string;
  @Input() inputOptions: InputOptions;
  @Input() label: string;
  @Input() required: boolean;
  labelAlignCss = '';
  @Input() type: string;
  @Input() placeholder = '';
  @Input() autoComplete = '';
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;
  @Output() onKeyDownText = new EventEmitter();
  value: any;
  showPassword = false;
  onChange: (value: any) => void;

  get isPassword(): boolean {
    return this.type === 'password';
  }

  constructor() {}

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    if (value || value === 0) {
      this.applyActiveCss = true;
      this.labelAlignCss = this.inputInActive;
    }
  }

  registerOnTouched(fn: (value: any) => void): void {}

  inputFocused(): void {
    if (this.inputOptions && this.inputOptions.floatingLabel) {
      this.labelAlignCss = this.inputActiveCss;
      this.applyActiveCss = true;
    }
  }

  ngOnInit(): void {
    if (this.value || this.value === 0) {
      this.applyActiveCss = true;
      this.labelAlignCss = this.inputInActive;
    }
  }

  inputFocusedOut(): void {
    if (this.inputOptions && this.inputOptions.floatingLabel) {
      if (this.value || this.value === 0) {
        this.applyActiveCss = true;
        this.labelAlignCss = this.inputInActive;
      } else {
        this.applyActiveCss = false;
      }
    }
  }

  labelClicked(): void {
    if (!this.value) {
      this.inputElement.nativeElement.focus();
    }
  }

  valueChanged(val: any): void {
    this.onChange(val);
  }

  keyDown(e: any) {
    this.onKeyDownText.emit(e);
  }
}

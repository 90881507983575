import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';
import { AumApiService } from './aum-api.service';
import { aumLoaded, loadAum } from './aum.actions';

@Injectable()
export class AumEffects {
  loadAums$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAum),
      switchMap(() => this.api.getAums()),
      map(aums => aumLoaded({ aums }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: AumApiService
  ) {}
}

import { createReducer, on } from '@ngrx/store';
import {
  resetState,
  securityDetailsModalFolderCommentsLoaded,
  selectAccounts,
  selectFolderId,
  setActiveSecurityMarketId,
  setActiveTicker,
  setActiveUnderlyingSecurityId,
  setExposureSummaryInfo,
  setMarkOverrideData,
  setSecurity,
  setTimeseriesLoading,
  setTimeseriesPositions,
  setTimeseriesPrices
} from './security-details-modal.actions';
import { SecurityDetailsModalState } from './security-details-modal.models';

export const initialState: SecurityDetailsModalState = {
  selectedAccounts: [],
  timeseriesLoading: false
};

export const securityDetailsModalReducer = createReducer(
  initialState,
  on(selectAccounts, (state, { accounts }) => ({
    ...state,
    selectedAccounts: accounts
  })),
  on(selectFolderId, (state, { folderId }) => ({
    ...state,
    selectedFolderId: folderId
  })),
  on(securityDetailsModalFolderCommentsLoaded, (state, { folderComments }) => ({
    ...state,
    folderComments
  })),
  on(setActiveTicker, (state, { ticker }) => ({
    ...state,
    activeTicker: ticker
  })),
  on(setActiveSecurityMarketId, (state, { securityMarketId }) => ({
    ...state,
    activeSecurityMarketId: securityMarketId
  })),
  on(setActiveUnderlyingSecurityId, (state, { underlyingSecurityId }) => ({
    ...state,
    activeUnderlyingSecurityId: underlyingSecurityId
  })),
  on(resetState, () => Object.assign({}, initialState)),
  on(setTimeseriesLoading, (state, { loading }) => ({ ...state, timeseriesLoading: loading })),
  on(setTimeseriesPrices, (state, { prices }) => ({
    ...state,
    timeseriesPrices: prices
  })),
  on(setTimeseriesPositions, (state, { positions }) => ({
    ...state,
    timeseriesPositions: positions
  })),
  on(setExposureSummaryInfo, (state, { exposureSummaryInfo }) => ({
    ...state,
    exposureSummaryInfo
  })),
  on(setMarkOverrideData, (state, { markOverrideData }) => ({
    ...state,
    markOverrideData,
    markOriginalOverriddenValue:
      markOverrideData?.overriddenPrice ?? state.markOriginalOverriddenValue
  })),
  on(setSecurity, (state, { security }) => ({
    ...state,
    security
  }))
);

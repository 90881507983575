import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'pinned-cell',
  template: `<span [ngStyle]="style">{{ params.value }}</span>`
})
export class CellRendererPinnedCustomStyleComponent implements ICellRendererAngularComp {
  public params: any;
  public style!: { [klass: string]: any };

  agInit(params: any): void {
    this.params = params;
    this.style = this.params.style;
  }

  refresh(): boolean {
    return false;
  }
}

<div
  [@visibleChanged]="visible"
  class="config-wrapper z-1"
>
  <div class="row pnkl-page-header prospecting-header-height">
    <div class="col-md-12 full-width-padding-fix">
      <div class="row">
        <div class="col-md-7">
          <h3 class="text-color-secondary">Configuration</h3>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="{
      unSelectedColumns: unSelectedColumns$ | async,
      selectedColumns: selectedColumns$ | async
    } as columnsConfig"
    class="row crm-prospecting-filter-body config-body"
  >
    <div class="col-md-12">
      <div class="configuration-lists-holder row no-gutters">
        <div class="configuration-lists-item">
          <ul
            [darkMode]="true"
            class="configuration-lists mt-3"
            pnklCustomScroll
          >
            <p class="text-uppercase">Available Items</p>
            <div class="input-field is-ready text-uppercase mb-3 m-t-minus-2">
              <input
                (ngModelChange)="searchChanged($event)"
                [(ngModel)]="searchQuery"
                class="search-security-input"
                type="text"
              />
            </div>
            <a
              (click)="addAllColumns()"
              class="text-uppercase icon-pinnakl-plus cursor-pointer"
              >Add ALL</a
            >
            <li
              *ngFor="let column of columnsConfig.unSelectedColumns ?? []"
              [class.color-blue]="!canRemove(column)"
            >
              {{ column.headerName }}
              <a
                (click)="addColumn(column)"
                class="icon-pinnakl-plus cursor-pointer"
              ></a>
            </li>
          </ul>
        </div>
        <div class="configuration-lists-item">
          <ul
            [darkMode]="true"
            class="mt-3 configuration-lists"
            pnklCustomScroll
          >
            <p class="text-uppercase">Current Set</p>
            <li
              *ngFor="let column of columnsConfig.selectedColumns ?? []"
              [class.color-blue]="!canRemove(column)"
            >
              {{ column.headerName }}
              <a
                (click)="removeColumn(column)"
                *ngIf="columnsConfig.selectedColumns.length > 1 && canRemove(column)"
                class="icon-pinnakl-cancel cancel-btn cursor-pointer"
              ></a>
            </li>
          </ul>
        </div>
      </div>
      <div
        *ngIf="presetId"
        class="row mt-3"
      >
        <div class="col-md-12">
          <p class="filter-presets-title text-uppercase">Saved</p>
          <div class="preset">
            Preset
            <button
              #anchor
              (click)="confirmationVisible = true"
              class="btn-remove"
            >
              <i class="icon-pinnakl-close"></i>
            </button>
            <confirm-action
              (onCancelled)="confirmationVisible = false"
              (onConfirmed)="removeImportantColumn()"
              [anchor]="anchor"
              [showConfirmation]="confirmationVisible"
              confirmationMessage="Are you sure you want to remove this preset? Removing it will remove saved preset configuration."
            >
            </confirm-action>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

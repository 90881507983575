import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { PositionsBaseInfo } from '@pinnakl/poems/positions/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { Observable } from 'rxjs';

@Injectable()
export class PositionsGeneralApiService {
  private readonly positionBaseEndpoint = `${V3_ENDPOINT}position_base`;

  constructor(private readonly wsp: WebServiceProvider) {}

  getPositionBaseInfoBySecurityMarketId(securityMarketId: string): Observable<PositionsBaseInfo> {
    return this.wsp.get<PositionsBaseInfo>({
      endpoint: this.positionBaseEndpoint,
      optionsParams: {
        filters: [{ key: 'securityMarketId', type: 'EQ', value: [securityMarketId] }]
      }
    });
  }
}

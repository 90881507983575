import {
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  numberAttribute,
  Output
} from '@angular/core';

type ButtonIconPosition = 'left' | 'right' | 'top' | 'bottom';

@Component({ template: '', standalone: true })
export class ButtonPublicApiComponent {
  /**
   * Type of the button.
   * @group Props
   */
  @Input() type = 'button';
  /**
   * Position of the icon.
   * @group Props
   */
  @Input() iconPos: ButtonIconPosition = 'left';
  /**
   * Name of the icon.
   * @group Props
   */
  @Input() icon: string | undefined;
  /**
   * Value of the badge.
   * @group Props
   */
  @Input() badge: string | undefined;
  /**
   * Uses to pass attributes to the label's DOM element.
   * @group Props
   */
  @Input() label: string | undefined;
  /**
   * When present, it specifies that the component should be disabled.
   * @group Props
   */
  @Input({ transform: booleanAttribute }) disabled: boolean | undefined;
  /**
   * Whether the button is in loading state.
   * @group Props
   */
  @Input({ transform: booleanAttribute }) loading = false;
  /**
   * Icon to display in loading state.
   * @group Props
   */
  @Input() loadingIcon: string | undefined;
  /**
   * Add a shadow to indicate elevation.
   * @group Props
   */
  @Input({ transform: booleanAttribute }) raised = false;
  /**
   * Add a circular border radius to the button.
   * @group Props
   */
  @Input({ transform: booleanAttribute }) rounded = false;
  /**
   * Add a textual class to the button without a background initially.
   * @group Props
   */
  @Input({ transform: booleanAttribute }) text = false;
  /**
   * Add a plain textual class to the button without a background initially.
   * @group Props
   */
  @Input({ transform: booleanAttribute }) plain = false;
  /**
   * Defines the style of the button.
   * @group Props
   */
  @Input() severity:
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'help'
    | 'primary'
    | 'secondary'
    | 'contrast'
    | null
    | undefined;
  /**
   * Add a border class without a background initially.
   * @group Props
   */
  @Input({ transform: booleanAttribute }) outlined = false;
  /**
   * Add a link style to the button.
   * @group Props
   */
  @Input({ transform: booleanAttribute }) link = false;
  /**
   * Add a tabindex to the button.
   * @group Props
   */
  @Input({ transform: numberAttribute }) tabindex: number | undefined;
  // Replaced with custom button size configuration
  // /**
  //  * Defines the size of the button.
  //  * @group Props
  //  */
  // @Input() size: 'small' | 'large' | undefined;
  /**
   * Inline style of the element.
   * @group Props
   */
  @Input() style: { [klass: string]: any } | null | undefined;
  /**
   * Class of the element.
   * @group Props
   */
  @Input() styleClass: string | undefined;
  /**
   * Style class of the badge.
   * @group Props
   */
  @Input() badgeClass: string | undefined;
  /**
   * Used to define a string that autocomplete attribute the current element.
   * @group Props
   */
  @Input() ariaLabel: string | undefined;
  /**
   * When present, it specifies that the component should automatically get focus on load.
   * @group Props
   */
  @Input({ transform: booleanAttribute }) autofocus: boolean | undefined;
  /**
   * Callback to execute when button is clicked.
   * This event is intended to be used with the <p-button> component. Using a regular <button> element, use (click).
   * @param {MouseEvent} event - Mouse event.
   * @group Emits
   */
  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter();
  /**
   * Callback to execute when button is focused.
   * This event is intended to be used with the <p-button> component. Using a regular <button> element, use (focus).
   * @param {FocusEvent} event - Focus event.
   * @group Emits
   */
  @Output() onFocus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
  /**
   * Callback to execute when button loses focus.
   * This event is intended to be used with the <p-button> component. Using a regular <button> element, use (blur).
   * @param {FocusEvent} event - Focus event.
   * @group Emits
   */
  @Output() onBlur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
}

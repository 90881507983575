import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComboBoxModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { CheckBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import {
  PinnaklLabelComponent,
  PinnaklSimpleDropdownComponent,
  PinnaklValidationErrorsComponent
} from './components';
import { PinnaklMultiselectComponent } from './components/pinnakl-multiselect/pinnakl-multiselect.component';
import { PinnaklSwitchComponent } from './components/pinnakl-switch/pinnakl-switch.component';

const components = [
  PinnaklLabelComponent,
  PinnaklValidationErrorsComponent,
  PinnaklSimpleDropdownComponent,
  PinnaklMultiselectComponent,
  PinnaklSwitchComponent
];

@NgModule({
  imports: [
    CommonModule,
    ComboBoxModule,
    FormsModule,
    ReactiveFormsModule,
    LabelModule,
    MultiSelectModule,
    CheckBoxModule
  ],
  declarations: [...components],
  exports: [...components]
})
export class PinnaklFormsModule {}

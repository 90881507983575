import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PositionActiveStreamsInfo {
  private greeksStreamTime$ = new BehaviorSubject(0);
  private pricingStreamTime$ = new BehaviorSubject(0);
  private portfolioStreamTime$ = new BehaviorSubject(0);
  private greeksStreamEstablished$ = new BehaviorSubject(false);
  private pricingStreamEstablished$ = new BehaviorSubject(false);
  private portfolioStreamEstablished$ = new BehaviorSubject(false);
  private greeksStreamErrored$ = new BehaviorSubject(false);
  private pricingStreamErrored$ = new BehaviorSubject(false);
  private portfolioStreamErrored$ = new BehaviorSubject(false);

  // Do not remove! They are used indirectly
  greeksTime$ = this.greeksStreamTime$.asObservable();
  pricingTime$ = this.pricingStreamTime$.asObservable();
  portfolioTime$ = this.portfolioStreamTime$.asObservable();

  greeksEstablished$ = this.greeksStreamEstablished$.asObservable();
  pricingEstablished$ = this.pricingStreamEstablished$.asObservable();
  portfolioEstablished$ = this.portfolioStreamEstablished$.asObservable();

  greeksErrored$ = this.greeksStreamErrored$.asObservable();
  pricingErrored$ = this.pricingStreamErrored$.asObservable();
  portfolioErrored$ = this.portfolioStreamErrored$.asObservable();

  newMessageFromGreeks(): void {
    this.greeksStreamTime$.next(Date.now());
  }

  newMessageFromPricing(): void {
    this.pricingStreamTime$.next(Date.now());
  }

  newMessageFromPortfolio(): void {
    this.portfolioStreamTime$.next(Date.now());
  }

  greeksStreamEstablished(disconnect = false): void {
    this.greeksStreamEstablished$.next(!disconnect);
  }

  pricingStreamEstablished(disconnect = false): void {
    this.pricingStreamEstablished$.next(!disconnect);
  }

  portfolioStreamEstablished(disconnect = false): void {
    this.portfolioStreamEstablished$.next(!disconnect);
  }

  greeksStreamErrored(isFixed = false): void {
    this.greeksStreamErrored$.next(!isFixed);
  }

  pricingStreamErrored(isFixed = false): void {
    this.pricingStreamErrored$.next(!isFixed);
  }

  portfolioStreamErrored(isFixed = false): void {
    this.portfolioStreamErrored$.next(!isFixed);
  }
}

import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import {
  ColumnRowGroupChangedEvent,
  DragStoppedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
  RowGroupingDisplayType,
  SortChangedEvent
} from 'ag-grid-community';
import { GridManagerService } from './grid-manager.service';

import { PinnaklColDef } from '../pinnakl-col-def.model';

@Component({
  selector: 'pinnakl-grid',
  templateUrl: './pinnakl-grid.component.html'
})
export class PinnaklGridComponent implements OnInit {
  @Output() private columnMoved = new EventEmitter<{ name: string; viewOrder: number }[]>();
  @Output() private groupChanged = new EventEmitter<{ name: string; groupOrder: number }[]>();
  @Output() private sortChanged = new EventEmitter<
    { name: string; sortAscending: boolean; sortOrder: number }[]
  >();
  private readonly agGridPanelClass = 'ag-root-wrapper-body';
  private bodyScrollTriggerTime: Date;
  private gridApi: GridApi;
  @Input() autoGroupColumnDef: any;
  @Input() columnDefs: PinnaklColDef[];
  @Input() defaultColDefs: PinnaklColDef;
  @Input() gridHeight: string;
  @Input() gridOptions: GridOptions;
  @Input() groupIncludeFooter: boolean;
  @Input() groupMultiAutoColumn: boolean;
  @Input() headerHeight: number;
  @Input() leftOverSpaceRatioThreshold = 0.35;
  @Input() pinnedBottomRowData: any[];
  @Input() getRowId: (data: any) => string;
  @Input() rowData: any[];
  @Input() rowGroupPanelShow!: 'always' | 'onlyWhenGrouping' | 'never' | undefined;
  @Input() rowHeight: number;
  @Input() rowSelection: 'single' | 'multiple' | undefined;
  @Input() searchText = '';
  @Input() styleClass = '';
  @Input() themeClass = 'ag-theme-fresh';
  @Input() frameworkComponents:
    | {
        [p: string]: {
          new (): any;
        };
      }
    | any
    | undefined;
  @Input() defaultSortState: any;
  @Input() autoResizeColumn = true;
  @Input() columnsToFitOnFirstDataRendered = false;
  @Input() domLayout?: 'normal' | 'autoHeight' | 'print';
  @Input() groupDefaultExpanded = 0;
  @Input() groupDisplayType: RowGroupingDisplayType;
  @Output() rowClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowSelectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridReady: EventEmitter<GridReadyEvent> = new EventEmitter<GridReadyEvent>();
  @Input() context: { ['key']: () => void };
  public defaultColDef: PinnaklColDef;

  constructor(@Optional() private readonly gridManagerService: GridManagerService) {}

  ngOnInit(): void {
    this.defaultColDef = <PinnaklColDef>{
      filter: true,
      resizable: true,
      sortable: true,
      ...this.defaultColDefs
    };
  }

  bodyScrollHandler(): void {
    this.bodyScrollTriggerTime = new Date();
  }

  columnMovedHandler({ api }: DragStoppedEvent): void {
    const newViewOrders = api.getColumnState().map(({ colId }, i) => ({
      name: colId.replace('_1', ''),
      viewOrder: i + 1
    }));
    this.columnMoved.emit(newViewOrders);
  }

  selectionChanged(): void {
    this.rowSelectionChanged.emit();
  }

  // this is used to handle group collapse/expand or "expand/collapse all" events
  // rowGroupOpened is not prefered as it doesn't work for "expand/collapse all"
  modelUpdateHandler(event: ModelUpdatedEvent): void {
    if (!event.newData) {
      this.customResizeColumns();
    }
  }

  onGridReady(params?: GridReadyEvent): void {
    this.gridApi = params?.api;
    this.gridApi?.expandAll();

    this.gridManagerService?.setGridInstance(params);

    if (this.defaultSortState) {
      this.setDefaultSorting();
    }

    this.gridReady.emit(params);
  }

  onRowDataChange(): void {
    if (!this.gridApi) {
      return;
    }
    this.gridApi?.resetRowHeights();
  }

  onRowClicked(event: any): void {
    this.rowClicked.emit(event);
  }

  onCellClicked(event: any): void {
    this.cellClicked.emit(event);
  }

  onFirstDataRendered(params: { api: { sizeColumnsToFit: any } }) {
    if (this.columnsToFitOnFirstDataRendered) {
      params.api.sizeColumnsToFit();
    }
  }

  sortHandler({ api }: SortChangedEvent): void {
    const newSortOrders = api.getColumnState().map(({ colId: name, sort }, i) => ({
      name,
      sortAscending: sort === 'asc',
      sortOrder: i + 1
    }));
    this.sortChanged.emit(newSortOrders);
    this.customResizeColumns();
    this.gridApi.resetRowHeights();
  }

  setDefaultSorting() {
    this.gridApi.applyColumnState({
      state: this.defaultSortState,
      defaultState: { sort: null }
    });
  }

  sortGroupsAndSetVisibility(event: ColumnRowGroupChangedEvent): void {
    const { api, columns: groupColumns } = event,
      allColumns = api.getColumns();
    const newGroupOrders = groupColumns.map((c, i) => ({
      name: c.getColId(),
      groupOrder: i + 1
    }));
    this.groupChanged.emit(newGroupOrders);
    api.setColumnsVisible(allColumns, true);
    api.setColumnsVisible(groupColumns, false);
    api.expandAll();
  }

  viewPortChangedHandler(): void {
    if (!this.bodyScrollTriggerTime) {
      this.customResizeColumns();
    } else if (
      // check if viewportchange was triggered because of scrolling
      new Date().getTime() - this.bodyScrollTriggerTime.getTime() >
      500
    ) {
      this.customResizeColumns();
    }
  }

  private customResizeColumns(): void {
    if (!this.gridApi || !this.autoResizeColumn) {
      return;
    }
    const allColumnIds = this.gridApi.getAllDisplayedColumns().map(column => column.getColId());
    this.gridApi.autoSizeColumns(allColumnIds);
  }
}

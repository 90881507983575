export enum IntradayGLGroupingTypes {
  SECURITY = 'SECURITY',
  ORGTICKER = 'ORGTICKER',
  FOLDER = 'FOLDER'
}

export enum PageAccessLevelCode {
  DEFAULT = 0,
  V1_ONLY = 1,
  V2_ONLY = 2,
  V1_AND_V2 = 3
}

export type PageAccessLevel = keyof typeof PageAccessLevelCode;

export interface TradeWorkflowSpecApi {
  id: number;
  isSapiUser: boolean;
  manualApproval: boolean;
  nonListedFills: boolean;
  pmsAccessLevel: PageAccessLevelCode;
  emsAccessLevel: PageAccessLevelCode;
  listedExecution: boolean;
  locatesIntegration: boolean;
  minIncrement: boolean;
  quantityAsPct: number;
  omsTradeVerificationEnabled: boolean;
  onlyViewTodaysTradesInOMS: boolean;
  defaultAllocationAccts: string;
  preTradeAllocationsRequired: boolean;
  riskModuleEnabled: boolean;
  realTimePortfolio: boolean;
  taxLotsEnabled: boolean;
  rebalanceBpsAdjVisible: boolean;
  intradayGLGrouping: IntradayGLGroupingTypes;
  clientId?: string;
  calcAUM?: boolean;
  usdSecurityId?: number;
  usdSecMarketId?: number;
  baseFolderId?: number;
  folderId?: number | null;
}

export interface TradeWorkflowSpec extends TradeWorkflowSpecApi {
  pmsVersionsAccessLevel: PageAccessLevel;
  emsVersionsAccessLevel: PageAccessLevel;
}

export type TradeWorkflowSpecUpdateParams = Partial<TradeWorkflowSpec>;

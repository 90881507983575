import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom } from 'rxjs';
import { TradeWorkflowSpecApiService } from './trade-workflow-spec-api.service';
import {
  loadTradeWorkflowSpec,
  tradeWorkflowSpecLoaded,
  tradeWorkflowSpecUpdated,
  updateTradeWorkflowSpec
} from './trade-workflow-spec.actions';
import { TradeWorkflowSpecState } from './trade-workflow-spec.models';
import { tradeWorkflowSpecQuery } from './trade-workflow-spec.selectors';

@Injectable()
export class TradeWorkflowSpecEffects {
  loadTradeWorkflowSpec$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTradeWorkflowSpec),
      switchMap(() => this.api.getTradeWorkflowSpec()),
      map(spec => tradeWorkflowSpecLoaded({ spec }))
    )
  );

  updateTradeWorkflowSpec$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTradeWorkflowSpec),
      withLatestFrom(this.store$.select(tradeWorkflowSpecQuery.getTradeWorkflowSpec)),
      switchMap(([{ spec }, specFromStore]) => {
        if (specFromStore) {
          return this.api.updateTradeWorkflowSpec({
            ...spec,
            id: specFromStore.id
          });
        }
        return this.api.createTradeWorkflowSpec(spec);
      }),
      map(spec => tradeWorkflowSpecUpdated({ spec }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: TradeWorkflowSpecApiService,
    private readonly store$: Store<TradeWorkflowSpecState>
  ) {}
}

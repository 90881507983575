import {
  ApplicationRef,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  ViewContainerRef
} from '@angular/core';
import { DrawerComponent } from '../drawer.component';
import { Document } from '../drawer.constants';
import { IDrawerConfig, IDrawerContentComponent } from '../interfaces/drawer-inputs.interface';
import { InitializedDrawer } from '../interfaces/drawer-outputs.interface';

@Injectable()
export class DrawerControllerService {
  private drawerComponentRef: ComponentRef<DrawerComponent<any>>;
  private viewContainerRef: ViewContainerRef;

  constructor(
    @Inject(Document) private readonly document: HTMLDocument,
    private readonly appRef: ApplicationRef,
    private readonly injector: Injector
  ) {}

  initWrapper(viewContainerRef: ViewContainerRef): void {
    this.viewContainerRef = viewContainerRef;

    const componentRef = this.viewContainerRef.createComponent(DrawerComponent, {
      injector: this.injector
    });

    this.drawerComponentRef = componentRef;
  }

  initDrawerChild<T, R>(
    componentConfig: IDrawerContentComponent<T, R>,
    drawerConfig: IDrawerConfig = {}
  ): InitializedDrawer<T> {
    const { ref, component, data, injector } = componentConfig;

    const [componentDOM] = (this.drawerComponentRef.hostView as EmbeddedViewRef<any>).rootNodes;
    ref.nativeElement.append(componentDOM);

    const drawerComponent = this.drawerComponentRef.instance;

    drawerComponent.config = { position: 'end', ...drawerConfig };
    drawerComponent.templateRef?.clear();

    const childComponentRef = drawerComponent.templateRef?.createComponent(component, {
      index: undefined,
      injector
    });

    (childComponentRef?.instance as { data: any }).data = data;

    drawerComponent.open();

    const drawer = drawerComponent.drawer;

    return {
      drawerChild: childComponentRef!,
      drawerCloseStream: drawer!._closedStream,
      drawerOpenStream: drawer!._openedStream,
      close: drawerComponent.close.bind(drawerComponent),
      open: drawerComponent.open.bind(drawerComponent)
    };
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PortfolioLatestPricesApiService } from './portfolio-latest-prices-api.service';
import { PortfolioLatestPricesFacadeService } from './portfolio-latest-prices-facade.service';
import { PortfolioLatestPricesEffects } from './portfolio-latest-prices.effects';
import { initialState, portfolioLatestPricesReducer } from './portfolio-latest-prices.reducer';
import { featureSelectorName } from './portfolio-latest-prices.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureSelectorName, portfolioLatestPricesReducer, {
      initialState
    }),
    EffectsModule.forFeature([PortfolioLatestPricesEffects])
  ],
  providers: [PortfolioLatestPricesApiService, PortfolioLatestPricesFacadeService]
})
export class PmsPortfolioLatestPricesModule {}

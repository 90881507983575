import { formatCurrency, formatDate, formatNumber, formatPercent } from '@angular/common';
import { PmsColumn } from '@pinnakl/pms/domain';
import {
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ExcelExportParams,
  IAggFunc,
  ICellRendererParams,
  IRowNode,
  ProcessCellForExportParams,
  ProcessRowGroupForExportParams,
  ValueFormatterParams,
  ValueGetterFunc
} from 'ag-grid-community';
import { CellRendererPinnedCustomStyleComponent } from './cell-renderers';

export type GridAggFunc = string | IAggFunc | null;

export interface NumberFormatOptions {
  isCurrency?: boolean;
  decimalPlaces?: number;
  minIntegerDigits?: number;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  isPercentage?: boolean;
  isAggregating?: boolean;
  emptyOnZeroValue?: boolean;
  emptyOnNullValue?: boolean;
  multiplier?: number;
}

export const agGridResizeColumnsWidth = (event: ColumnResizedEvent): ColDef[] => {
  const colDefsWithUserSettings: ColDef[] = event.api.getAllGridColumns().map(el => {
    return el.getUserProvidedColDef();
  });
  const allGridColumnsWithInitialWidth = event.api.getAllGridColumns();
  return colDefsWithUserSettings.map(item => {
    const actualColumn = allGridColumnsWithInitialWidth.find(
      _item => _item.getColId() === item?.field
    );
    const column = { ...item };
    column.width = actualColumn.getActualWidth();
    if (item?.field === event?.column?.getColId()) {
      column.width = event?.column?.getActualWidth();
    }
    return column;
  });
};

export const agGridMoveColumnsOrder = (event: ColumnMovedEvent): ColDef[] => {
  const colDefs: ColDef[] = event.api.getAllGridColumns().map(el => el.getUserProvidedColDef());
  return colDefs.filter(el => el.headerName !== 'Action');
};

export const decimalFormatter = (
  {
    isCurrency,
    decimalPlaces,
    minIntegerDigits = 1,
    minFractionDigits,
    maxFractionDigits,
    isPercentage,
    multiplier = 1
  }: NumberFormatOptions,
  { value }: { value: number | undefined }
): string | number => {
  if (decimalPlaces || decimalPlaces === 0) {
    minFractionDigits = maxFractionDigits = decimalPlaces;
  }
  const digitsInfo = `${minIntegerDigits}.${minFractionDigits}-${maxFractionDigits}`;
  try {
    if (isPercentage && !isFinite(value)) {
      return '';
    }
    if (isCurrency) {
      return formatCurrency(value, 'en-US', '$', undefined, digitsInfo);
    } else if (isPercentage) {
      return formatPercent((value / 100) * multiplier, 'en-US', digitsInfo);
    } else if (typeof value === 'number') {
      return formatNumber(value, 'en-US', digitsInfo);
    } else if (typeof value === 'string') {
      return formatNumber(+value, 'en-US', digitsInfo);
    }
    if (!value || !isFinite(value)) return '';
    return value;
  } catch (e) {
    return value;
  }
};

export const dateFormatter = (
  { value }: ValueFormatterParams,
  format = 'MM/dd/y',
  locale = 'en-US'
): string => {
  if (!value) {
    return '';
  }
  try {
    return formatDate(value, format, locale);
  } catch (e) {
    return value;
  }
};

export const numericCellRenderer = (
  params: ICellRendererParams & { formatOptions: NumberFormatOptions }
): string => {
  let value;
  const colId = params.colDef.colId;
  const isFooter = params.node.group || params.node.footer;
  if (isFooter) {
    // Utilize provided valueGetter (for formula calculations)
    const valueGetter = params.column.getUserProvidedColDef()?.valueGetter;
    value = aggregateValues(params.node?.allLeafChildren ?? [], colId, valueGetter);
  } else {
    if (typeof params.value !== 'number' && params.formatOptions.emptyOnNullValue) {
      return '';
    }

    // if params.valueFormatted (from formula) is 0 or undefined, we will grab regular value
    value = Number(params?.valueFormatted || params.value || 0);
  }
  if (Math.abs(value) === Infinity || isNaN(value)) {
    value = 0;
  }
  const { isPercentage, emptyOnZeroValue, ...formatOptions } = params.formatOptions;
  if (value === 0 && emptyOnZeroValue) return '';

  let valueFormatted = decimalFormatter(formatOptions, { value });
  if (isPercentage) {
    valueFormatted = valueFormatted + '%';
  }
  if (isFooter || params.node.rowPinned) {
    const isAggregating = params.formatOptions.isAggregating;
    return `<b>${isAggregating ? valueFormatted : ''}</b>`;
  }
  return String(valueFormatted);
};

const aggregateValues = (
  rowNodes: IRowNode<PmsColumn>[],
  colId: string,
  valueGetter: string | ValueGetterFunc<PmsColumn>
): number => {
  const shouldFormat = valueGetter instanceof Function;
  return rowNodes.reduce((acc: number, i: IRowNode<PmsColumn>) => {
    const value = i?.data?.[colId] ?? i?.data?.[colId.toUpperCase()] ?? 0;
    if (value) {
      acc += (shouldFormat ? valueGetter(value) : value) ?? 0;
    }
    return acc;
  }, 0);
};

export const checkmarkCellRenderer = (params: ICellRendererParams): string => {
  return params.value || params.value.toLowerCase() === 'true'
    ? `<i class="icon-pinnakl-ok color-green"></i>`
    : '';
};

export const textCellRenderer = (params: ICellRendererParams): string => {
  const isGroup = params.node.group && params.colDef.headerName !== 'Group';
  return isGroup ? '' : params.value;
};

export const getExcelDefaultParams: () => ExcelExportParams = () => ({
  processCellCallback(params: ProcessCellForExportParams): string {
    const { value, node } = params;
    return value == null && node.footer ? '0' : value;
  },
  processRowGroupCallback(params: ProcessRowGroupForExportParams): string {
    const { node } = params;
    if (!node.footer) {
      return `Row group: ${node.key}`;
    }
    const isRootLevel = node.level === -1;
    if (isRootLevel) {
      return 'Grand Total';
    }
    return `Sub Total (${node.key})`;
  }
});

export const pinnedRowCellRenderer = (params: ICellRendererParams): undefined | unknown => {
  if (params.node.rowPinned) {
    return {
      component: CellRendererPinnedCustomStyleComponent,
      params: {
        style: { fontWeight: 'bold' }
      }
    };
  } else {
    // rows that are not pinned don't use any cell renderer
    return undefined;
  }
};

export const getColumnAggregations = (
  type: string,
  colId: string,
  isAggregating: boolean | null
): GridAggFunc => {
  let aggFunc: GridAggFunc = null;
  if (isAggregating) {
    aggFunc = 'sum';
  }
  return aggFunc;
};

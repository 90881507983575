export const includesStringCaseInsensitive = (
  originalStr: string | undefined,
  searchStr: string
): boolean => (originalStr ?? '').toLowerCase().includes(searchStr.toLowerCase());

export const GetInterpolatedString = (
  stringToInterpolate: string,
  values: Record<string, string | number | boolean>,
  regularExpressionToReplace = new RegExp(/\{\{.*?}}/)
) => {
  let stringToReturn = stringToInterpolate;
  while (regularExpressionToReplace.test(stringToReturn)) {
    stringToReturn = stringToReturn.replace(regularExpressionToReplace, variableToReplace => {
      const keyName = variableToReplace.slice(2, variableToReplace.length - 2);
      if (!keyName || values[keyName] == null) {
        return '';
      }
      return values[keyName].toString();
    });
  }
  return stringToReturn;
};

export const MakeFirstLetterUppercase = (str: string, restInLowercase = true): string =>
  str.charAt(0).toUpperCase() + (restInLowercase ? str.slice(1).toLowerCase() : str.slice(1));

<div class="close-button mt-3">
  <pnkl-modal-close-btn (click)="closeModal()"></pnkl-modal-close-btn>
</div>

<form [formGroup]="searchForm">
  <input
    #searchField
    formControlName="searchField"
    placeholder="Type to search"
    tabindex="0"
    type="search"
  />
</form>

<pnkl-tab-selector
  (itemSelected)="itemSelected($any($event))"
  [tabList]="tabList"
>
  <ng-container tab-content="">
    <ng-template
      [ngIfElse]="mainContent"
      [ngIf]="isLoading"
    >
      <div class="search-info-text">Loading...</div>
      <div class="pnkl-infinity-loader"></div>
    </ng-template>
    <ng-template #mainContent>
      <ng-template
        [ngIfElse]="filteredLsit"
        [ngIf]="searchFieldIsEmpty()"
      >
        <div class="search-info-text">Nothing to display, start typing...</div>
      </ng-template>
      <ng-template #filteredLsit>
        <ng-container *ngIf="securityList.length === 0">
          <div class="search-info-text">
            No data was found for the combination of search text and selected filter ;(
          </div>
        </ng-container>
        <ng-container *ngIf="securityList.length > 0">
          <div class="security-items-wrapper">
            <pnkl-security-items
              #securityItemsList
              (itemClicked)="itemClicked($event)"
              [items]="securityList"
              [searchText]="searchForm.controls.searchField.value"
            >
            </pnkl-security-items>
          </div>
        </ng-container>
      </ng-template>
    </ng-template>
  </ng-container>
</pnkl-tab-selector>

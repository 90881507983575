import { Injectable } from '@angular/core';
import {
  LatestPrice,
  PmsPositionsFacadeService,
  PortfolioLatestPricesFacadeService
} from '@pinnakl/pms/data-access';
import { PoemsCommonDataFacadeService } from '@pinnakl/poems/data-access';
import {
  FolderCommentsFacadeService,
  FoldersFacadeService
} from '@pinnakl/poems/folders/data-access';
import {
  MarkOverrideData,
  PositionsDetailsMap,
  SecurityDetailsComponentData
} from '@pinnakl/poems/modals/security-details/domain';
import { PositionsGeneralFacadeService } from '@pinnakl/poems/positions/data-access';
import { PositionBaseDetails } from '@pinnakl/poems/positions/domain';
import { RealtimePriceOverrideFacadeService } from '@pinnakl/poems/pricing/data-access';
import { LastPrice } from '@pinnakl/poems/pricing/domain';
import { PriceEventsStreamFacadeService } from '@pinnakl/poems/streams/price/data-access';
import { PriceEventData } from '@pinnakl/poems/streams/price/domain';
import {
  TimeseriesPositionsFacadeService,
  TimeseriesPricesFacadeService
} from '@pinnakl/poems/timeseries/data-access';
import { SecurityPositionTimeItem } from '@pinnakl/poems/timeseries/domain';
import { SecuritiesFacadeService } from '@pinnakl/securities/data-access';
import { ISecurity } from '@pinnakl/securities/domain';
import { isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { Subscription, combineLatest, map, of, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SecurityDetailsModalFacadeService } from './security-details-modal-facade.service';

@Injectable()
export class SecurityDetailsModalDataProcessor {
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly foldersFacadeService: FoldersFacadeService,
    private readonly securitiesFacadeService: SecuritiesFacadeService,
    private readonly pmsPositionsFacadeService: PmsPositionsFacadeService,
    private readonly commonDataFacadeService: PoemsCommonDataFacadeService,
    private readonly positionsGeneralFacade: PositionsGeneralFacadeService,
    private readonly timeseriesPricesFacade: TimeseriesPricesFacadeService,
    private readonly securityDetailsFacade: SecurityDetailsModalFacadeService,
    private readonly folderCommentsFacadeService: FolderCommentsFacadeService,
    private readonly portfolioLatestPrices: PortfolioLatestPricesFacadeService,
    private readonly timeseriesPositionsFacade: TimeseriesPositionsFacadeService,
    private readonly priceEventsStreamFacadeService: PriceEventsStreamFacadeService,
    private readonly realtimePriceOverrideFacadeService: RealtimePriceOverrideFacadeService
  ) {}

  processData(data: SecurityDetailsComponentData): void {
    data.folderId && this.processFolderComments(data.folderId);
    this.processSecurityDetails(data.securityMarketId);
    this.processExposureSummaryMarkInfo(data.securityMarketId);
    this.processTimeseriesDataForPositionHistoryTab(data.securityMarketId);
    this.processPositionsGeneralDataForExposureSummaryTab(data.securityMarketId);
  }

  resetData(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private processSecurityDetails(securityMarketId: string): void {
    this.subscriptions.push(
      this.securitiesFacadeService
        .getSecurityInfoBySecurityMarketId(securityMarketId)
        .subscribe(security => this.securityDetailsFacade.setSecurity(security))
    );
  }

  private processExposureSummaryMarkInfo(securityMarketId: string): void {
    this.subscriptions.push(
      this.realtimePriceOverrideFacadeService
        .getRealtimePriceOverrideBySecurityMarketId(securityMarketId)
        .subscribe(override =>
          this.securityDetailsFacade.setMarkOverrideData(
            override
              ? {
                  price: override.overridePrice,
                  overriddenPrice: override.currentPrice,
                  type: override.overrideExpiration
                }
              : undefined
          )
        )
    );
  }

  private processFolderComments(dataFolderId: number): void {
    this.subscriptions.push(
      this.foldersFacadeService.folders$
        .pipe(
          switchMap(folders => {
            const folder = folders.find(({ id }) => id === dataFolderId.toString());
            if (folder?.id) {
              this.folderCommentsFacadeService.loadFolderCommentsById(folder.id);
              return this.folderCommentsFacadeService.getFolderCommentsById(folder.id);
            } else {
              return of(undefined);
            }
          })
        )
        .subscribe(folderComments =>
          this.securityDetailsFacade.setFolderComments(
            folderComments ? folderComments.comments : undefined
          )
        )
    );
  }

  private processTimeseriesDataForPositionHistoryTab(securityMarketId: string): void {
    this.timeseriesPricesFacade.loadTimeseriesPricesBySecurityMarketId(securityMarketId);
    this.timeseriesPositionsFacade.loadTimeseriesPositionsBySecurityMarketId(securityMarketId);

    this.subscriptions.push(
      combineLatest([this.timeseriesPricesFacade.loading$, this.timeseriesPositionsFacade.loading$])
        .pipe(map(([pricesLoading, positionsLoading]) => pricesLoading || positionsLoading))
        .subscribe(loading => this.securityDetailsFacade.setTimeseriesLoading(loading))
    );

    this.subscriptions.push(
      this.timeseriesPricesFacade
        .getTimeseriesPricesBySecurityMarketId(securityMarketId)
        .pipe(filter(isNeitherNullNorUndefined))
        .subscribe(prices => this.securityDetailsFacade.setTimeseriesPrices(prices))
    );

    this.subscriptions.push(
      combineLatest([
        this.timeseriesPositionsFacade
          .getTimeseriesPositionsBySecurityMarketId(securityMarketId)
          .pipe(
            filter(isNeitherNullNorUndefined),
            map(data => {
              const accountsIdsSet = new Set<number>();
              const dataMap: { [key: string]: { [key: number]: SecurityPositionTimeItem } } =
                data.reduce((acc, item) => {
                  if (!acc[item.date]) {
                    acc[item.date] = {};
                  }
                  accountsIdsSet.add(item.accountId);
                  acc[item.date][item.accountId] = item;

                  return acc;
                }, {});

              return {
                dataMap,
                accountsIdsSet: Array.from(accountsIdsSet)
              };
            })
          ),
        this.securityDetailsFacade.selectedAccounts$
      ]).subscribe(([initialPositions, selectedAccounts]) => {
        const accountsIds = selectedAccounts.length
          ? selectedAccounts.map(({ id }) => id)
          : initialPositions.accountsIdsSet;

        this.securityDetailsFacade.setTimeseriesPositions(
          Object.keys(initialPositions.dataMap).reduce(
            (positions: SecurityPositionTimeItem[], date) => [
              ...positions,
              {
                date,
                accountId: 0,
                quantity: accountsIds.reduce(
                  (sum, id) => sum + initialPositions.dataMap[date][id]?.quantity ?? 0,
                  0
                ),
                tradeQuantity: accountsIds.reduce(
                  (sum, id) => sum + initialPositions.dataMap[date][id]?.tradeQuantity ?? 0,
                  0
                )
              }
            ],
            []
          )
        );
      })
    );
  }

  private processPositionsGeneralDataForExposureSummaryTab(securityMarketId: string): void {
    this.positionsGeneralFacade.loadPositionBaseInfoBySecurityMarketId(securityMarketId, true);

    const positionsBaseInfo$ = this.positionsGeneralFacade
      .getPositionBaseInfoBySecurityMarketId(securityMarketId)
      .pipe(filter(isNeitherNullNorUndefined));

    this.subscriptions.push(
      combineLatest([
        positionsBaseInfo$,
        this.securityDetailsFacade.selectedAccounts$,
        this.priceEventsStreamFacadeService.getPriceEventsBySecurityMarketId(securityMarketId),
        this.commonDataFacadeService.fxRates$.pipe(filter(isNeitherNullNorUndefined)),
        this.portfolioLatestPrices.getLatestPriceBySecurityMarketId(+securityMarketId),
        this.pmsPositionsFacadeService.navCurrentMap$,
        this.securityDetailsFacade.securityObject$.pipe(filter(isNeitherNullNorUndefined)),
        this.securityDetailsFacade.markOverrideData$,
        this.securityDetailsFacade.markOriginalOverriddenValue$
      ])
        .pipe(map(this.formatPositionsGeneralInformation))
        .subscribe(
          ({
            navCurrentMap,
            mostRecentPrice,
            fxRate,
            security,
            priceData,
            positions,
            latestPrice,
            markOverrideData,
            markOriginalOverriddenValue
          }) => {
            const { closeLocal, currencyCode, priceDate } = mostRecentPrice ?? {};

            let totalPosition = 0;
            const fullCustodiansMap = new Map<string, { code: string; id: number }>();
            const longMultipliersMap: Record<number, number> = {};
            const shortMultipliersMap: Record<number, number> = {};
            const longPositionsMap: PositionsDetailsMap = {
              quantityValues: {},
              mvPctValues: {},
              totalQuantities: {},
              totalMarketValues: {},
              accounts: []
            };
            const shortPositionsMap: PositionsDetailsMap = {
              quantityValues: {},
              mvPctValues: {},
              totalQuantities: {},
              totalMarketValues: {},
              accounts: []
            };

            positions.forEach(
              ({
                multiplier,
                quantity,
                accountId,
                accountCode,
                custodianId,
                custodianCode,
                longShortCode
              }) => {
                fullCustodiansMap.set(custodianCode, { code: custodianCode, id: custodianId });
                totalPosition += quantity;
                const mapToUse = longShortCode === 'L' ? longPositionsMap : shortPositionsMap;
                const multipliersMap =
                  longShortCode === 'L' ? longMultipliersMap : shortMultipliersMap;
                multipliersMap[accountId] = multiplier;

                if (mapToUse.quantityValues[accountId] == null) {
                  mapToUse.quantityValues[accountId] = {
                    [custodianId]: 0
                  };
                  mapToUse.totalQuantities[accountId] = 0;
                  mapToUse.accounts.push({ code: accountCode, id: accountId });
                }

                if (mapToUse.quantityValues[accountId][custodianId] == null) {
                  mapToUse.quantityValues[accountId][custodianId] = 0;
                }

                mapToUse.totalQuantities[accountId] += quantity;
                mapToUse.quantityValues[accountId][custodianId] += quantity;
              }
            );

            const priceToUse =
              markOverrideData?.price ??
              priceData?.value ??
              markOriginalOverriddenValue ??
              latestPrice?.priceLocal ??
              0;
            [
              {
                map: longPositionsMap,
                multipliers: longMultipliersMap
              },
              {
                map: shortPositionsMap,
                multipliers: shortMultipliersMap
              }
            ].forEach(({ map, multipliers }) =>
              map.accounts.forEach(({ id, code: accountCode }) => {
                const totalQuantity = Object.entries(map.quantityValues).reduce(
                  (accumulator, [accountId, custodiansQuantityData]) =>
                    id.toString() === accountId.toString()
                      ? accumulator +
                        Object.values(custodiansQuantityData).reduce((acc, item) => acc + item, 0)
                      : accumulator,
                  0
                );

                const multiplier = multipliers[id] ?? 1;
                const marketValue = totalQuantity * priceToUse * multiplier * fxRate;
                map.totalMarketValues[id] = marketValue;

                // Calculate MV%
                const navCurrentValue = navCurrentMap
                  ? Object.entries(navCurrentMap)
                      .filter(([code]) => accountCode.toLowerCase() === code)
                      .reduce((acc, [, navCurrentValue]) => acc + navCurrentValue, 0)
                  : null;

                map.mvPctValues[id] = navCurrentValue
                  ? (marketValue * 100) / navCurrentValue
                  : Infinity;
              })
            );

            this.securityDetailsFacade.setExposureSummaryInfo({
              navCurrentMap,
              fullCustodiansList: Array.from(fullCustodiansMap.values()),
              positions,
              currency: security.currency ?? latestPrice?.currency ?? currencyCode ?? 'USD',
              totalPosition,
              closeLocal: closeLocal ?? 0,
              priceDate,
              mark: priceToUse,
              positionsMap: {
                long: Object.keys(longPositionsMap.quantityValues).length
                  ? longPositionsMap
                  : undefined,
                short: Object.keys(shortPositionsMap.quantityValues).length
                  ? shortPositionsMap
                  : undefined
              }
            });
          }
        )
    );
  }

  private formatPositionsGeneralInformation([
    { positions, mostRecentPrice },
    selectedAccounts,
    priceData,
    fxRates,
    latestPrice,
    navCurrentMap,
    security,
    markOverrideData,
    markOriginalOverriddenValue
  ]): {
    priceData?: PriceEventData;
    mostRecentPrice?: LastPrice;
    navCurrentMap: Record<string, number> | null;
    positions: PositionBaseDetails[];
    fxRate: number;
    markOverrideData: MarkOverrideData | undefined;
    security: ISecurity;
    markOriginalOverriddenValue?: number;
    latestPrice: LatestPrice | null;
  } {
    return {
      latestPrice,
      priceData,
      navCurrentMap,
      mostRecentPrice,
      fxRate:
        fxRates.find(({ currency: { code } }) => code === security.currency)
          ?.fxRateForCalculations ?? 1,
      security,
      markOriginalOverriddenValue,
      markOverrideData,
      positions: selectedAccounts.length
        ? positions.filter(({ accountId }) => selectedAccounts.find(({ id }) => id === accountId))
        : positions
    };
  }
}

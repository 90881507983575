import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'prime-input-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './input-container.component.html',
  styleUrls: ['./input-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputContainerComponent {
  @Input() labelClasses:
    | string
    | string[]
    | Set<string>
    | {
        [klass: string]: any;
      } = '';

  @ContentChild('inputContent', { static: false }) inputContent: TemplateRef<null> | null = null;
  @ContentChild('labelContent', { static: false }) labelContent: TemplateRef<null> | null = null;
  @ContentChild('validationContent', { static: false })
  validationContent: TemplateRef<null> | null = null;
}

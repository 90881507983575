import { ParsedPreset, PmsPresetsTypes } from '@pinnakl/shared/types';
import { PmsGridColumns } from './pms-grid.model';
import { AccountCodeFilter, ColumnsWithFilters, PmsWidgetsOrder } from './pms-widgets.model';

export type CustomFiltersConfig = Record<ColumnsWithFilters, string[]>;
export type PmsAllFiltersConfig = CustomFiltersConfig & Record<AccountCodeFilter, string[]>;

export interface PmsSavedColumns {
  name: string;
  columns: PmsGridColumns;
  filters: PmsAllFiltersConfig;
  excludeSecurities?: boolean;
}

export interface PmsSelectedSavedColumns {
  id: number;
}

export enum PmsFontSizes {
  xs = 13,
  s = 14,
  m = 15,
  l = 16,
  xl = 17,
  xxl = 18
}

export interface PmsOtherSettings {
  gridHeight: number | null;
  gridFontSize: PmsFontSizes;
  gridSortType: PmsSortTypes;
  displayPnLChartWidget: boolean;
  displayPositionSummaryWidget: boolean;
  displayDeltaAdjustedExposureInSummaryWidget: boolean;
}

export enum PmsSortTypes {
  Manual = 'manual',
  Auto = 'auto'
}

export type PmsWidgetOrderConfigPreset = ParsedPreset<PmsWidgetsOrder>;
export type PmsSavedColumnsConfigs = ParsedPreset<PmsSavedColumns>[];
export type PmsSelectedSavedColumnsConfig = ParsedPreset<PmsSelectedSavedColumns>;
export type PmsOtherSettingsConfig = ParsedPreset<PmsOtherSettings>;

export interface PmsPresets {
  [PmsPresetsTypes.PmsWidgetsOrder]: PmsWidgetOrderConfigPreset | null;
  [PmsPresetsTypes.PmsGridColumns]: PmsSavedColumnsConfigs;
  [PmsPresetsTypes.PmsGridSelectedColumnsPreset]: PmsSelectedSavedColumnsConfig | null;
  [PmsPresetsTypes.PmsOtherSettings]: PmsOtherSettingsConfig | null;
}

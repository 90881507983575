import { ActionReducer } from '@ngrx/store';

import { ClearStore, ClearStoreActionTypes } from './clear-store.actions';

export function clearStore(reducer: ActionReducer<any>): any {
  return function (state, action: typeof ClearStore) {
    let stateToSet = state;
    if (action.type === ClearStoreActionTypes.ClearStore) {
      stateToSet = {};
    }

    return reducer(stateToSet, action);
  };
}

import { createAction, props } from '@ngrx/store';
import { BenchmarkIndex } from '@pinnakl/poems/pricing/domain';

export enum BenchmarkIndexIntradayPricesActions {
  LoadBenchmarkIndexIntradayPrices = '[Pricing | Benchmark index intraday] Load benchmark index intraday prices',
  LoadLatestBenchmarkIndexIntradayPrices = '[Pricing | Benchmark index intraday] Load latest benchmark index intraday prices',
  BenchmarkIndexIntradayPricesSuccess = '[Pricing | Benchmark index intraday] Benchmark index intraday prices loaded',
  BenchmarkIndexIntradayPricesFailure = '[Pricing | Benchmark index intraday] Benchmark index intraday prices failed',
  StartBenchmarkIndexesPolling = '[Pricing | Benchmark index intraday] Start polling',
  StopBenchmarkIndexesPolling = '[Pricing | Benchmark index intraday] Stop polling',
  SetMainAccountId = '[Pricing | Benchmark index intraday] Set main account id',
  ClearMainAccountId = '[Pricing | Benchmark index intraday] Clear main account id'
}

export const loadBenchmarkIndexIntradayPrices = createAction(
  BenchmarkIndexIntradayPricesActions.LoadBenchmarkIndexIntradayPrices
);
export const loadLatestBenchmarkIndexIntradayPrices = createAction(
  BenchmarkIndexIntradayPricesActions.LoadLatestBenchmarkIndexIntradayPrices
);
export const benchmarkIndexIntradayPricesSuccess = createAction(
  BenchmarkIndexIntradayPricesActions.BenchmarkIndexIntradayPricesSuccess,
  props<{ indexes: BenchmarkIndex[] }>()
);
export const benchmarkIndexIntradayPricesFailure = createAction(
  BenchmarkIndexIntradayPricesActions.BenchmarkIndexIntradayPricesFailure,
  props<{ error: any }>()
);
export const startBenchmarkIndexesPolling = createAction(
  BenchmarkIndexIntradayPricesActions.StartBenchmarkIndexesPolling
);
export const stopBenchmarkIndexesPolling = createAction(
  BenchmarkIndexIntradayPricesActions.StopBenchmarkIndexesPolling
);
export const setMainAccountId = createAction(
  BenchmarkIndexIntradayPricesActions.SetMainAccountId,
  props<{ id: number }>()
);
export const clearMainAccountId = createAction(
  BenchmarkIndexIntradayPricesActions.ClearMainAccountId
);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DatePickerModule,
  DateTimePickerModule,
  TimePickerModule
} from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { KeysPipe } from '../../pipes/keys.pipe';

import { ProgressCircleComponent } from '../progress-circle/progress-circle.component';
import { FileInputComponent } from './file-input/file-input.component';
import { PinnaklBigToggleComponent } from './pinnakl-big-toggle/pinnakl-big-toggle.component';
import { PinnaklDateInputComponent } from './pinnakl-date-input/pinnakl-date-input.component';
import { PinnaklDateTimeInputComponent } from './pinnakl-date-time-input/pinnakl-date-time-input.component';
import { PinnaklDropdownComponent } from './pinnakl-dropdown/pinnakl-dropdown.component';
import { PinnaklEditorComponent } from './pinnakl-editor/pinnakl-editor.component';
import { PinnaklInputFloatingLabelComponent } from './pinnakl-input-floating-label/pinnakl-input-floating-label.component';
import { PinnaklInputComponent } from './pinnakl-input.component';
import { PinnaklNumFormatDirective } from './pinnakl-numformat.directive';
import { PinnaklPlaceAutocompleteComponent } from './pinnakl-place-autocomplete/pinnakl-place-autocomplete.component';
import { PinnaklSearchInputComponent } from './pinnakl-search-input/pinnakl-search-input.component';
import { PinnaklTextAreaComponent } from './pinnakl-text-area/pinnakl-text-area.component';
import { PinnaklTimeInputComponent } from './pinnakl-time-input/pinnakl-time-input.component';
import { PinnaklToggleComponent } from './pinnakl-toggle/pinnakl-toggle.component';
import { PnklMultiselectDropdownComponent } from './pnkl-multiselect-dropdown/pnkl-multiselect-dropdown.component';
import { PnklValidationComponent } from './pnkl-validation/pnkl-validation.component';
import { RoundedCheckboxComponent } from './rounded-checkbox/rounded-checkbox.component';

const components = [
  RoundedCheckboxComponent,
  PinnaklToggleComponent,
  PinnaklBigToggleComponent,
  FileInputComponent,
  PinnaklDateInputComponent,
  PinnaklDateTimeInputComponent,
  PinnaklDropdownComponent,
  PinnaklEditorComponent,
  PinnaklInputFloatingLabelComponent,
  PinnaklInputComponent,
  PinnaklNumFormatDirective,
  PinnaklPlaceAutocompleteComponent,
  PinnaklSearchInputComponent,
  PinnaklTextAreaComponent,
  PinnaklTimeInputComponent,
  PnklMultiselectDropdownComponent,
  PnklValidationComponent,
  ProgressCircleComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DatePickerModule,
    DropDownsModule,
    TimePickerModule,
    EditorComponent,
    DateTimePickerModule
  ],
  exports: [...components, KeysPipe],
  declarations: [...components, KeysPipe]
})
export class PinnaklInputModule {}

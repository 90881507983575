/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  HttpContextToken,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { UploadProgressService } from './upload-progress.service';

export const UPLOAD_ID = new HttpContextToken<string | undefined>(() => undefined);

@Injectable({ providedIn: 'root' })
export class UploadListenerInterceptor implements HttpInterceptor {
  private uploadProgressService = inject(UploadProgressService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => this.handleProgress$(request, event)),
      filter(response => response instanceof HttpResponse)
    );
  }

  private handleProgress$(request: HttpRequest<any>, event: HttpEvent<any>): void {
    if (request.reportProgress && event.type) {
      const uploadId = request.context.get(UPLOAD_ID);
      if (event.type === HttpEventType.UploadProgress) {
        const progress = Math.min(0.99, event.loaded / (event?.total ?? 0));
        uploadId &&
          this.uploadProgressService.updateProgressMap(uploadId, Math.round(progress * 100));
      } else if (
        event.type === HttpEventType.Response ||
        (event.type === HttpEventType.ResponseHeader && !event.ok)
      ) {
        uploadId && this.uploadProgressService.updateProgressMap(uploadId, 100);
      }
    }
  }
}

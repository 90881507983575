import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, DecimalPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RealtimePriceOverrideCreateObj } from '@pinnakl/poems/modals/security-details/domain';
import { RealtimePriceOverrideTypes } from '@pinnakl/poems/pricing/domain';
import {
  PrimeButtonComponent,
  PrimeInputNumberComponent,
  PrimeSingleDropdownComponent
} from '@pinnakl/shared/ui/prime';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { BehaviorSubject } from 'rxjs';

@Component({
  standalone: true,
  selector: 'pinnakl-realtime-price',
  templateUrl: 'realtime-price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./realtime-price.component.scss'],
  imports: [
    DecimalPipe,
    NgIf,
    PrimeButtonComponent,
    PrimeInputNumberComponent,
    PrimeSingleDropdownComponent,
    ReactiveFormsModule,
    AsyncPipe,
    InlineSVGModule
  ],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.2s ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [style({ opacity: 1 }), animate('0.2s ease-in', style({ opacity: 0 }))])
    ])
  ]
})
export class RealtimePriceComponent {
  @Input({ required: true }) price!: number;
  @Input({ required: true }) editMarkForm!: FormGroup;
  @Input() markType?: RealtimePriceOverrideTypes;

  @Output() isEditMode = new EventEmitter<boolean>();
  @Output() resetForm = new EventEmitter<void>();
  @Output() saveNewMarkValue = new EventEmitter<RealtimePriceOverrideCreateObj | null>();

  editMarkTypes = [
    { value: RealtimePriceOverrideTypes.DAY, name: 'Day' },
    { value: RealtimePriceOverrideTypes.GTC, name: 'GTC' }
  ];
  isEditMode$ = new BehaviorSubject(false);

  get saveIsDisabled(): boolean {
    const markValue = this.editMarkForm.get('editMarkValue')?.value as string | number | null;
    return (
      markValue === null || markValue === 0 || this.editMarkForm.get('editMarkType')?.value === null
    );
  }

  toggleEditMode(): void {
    const newValue = !this.isEditMode$.value;
    this.isEditMode$.next(newValue);
    this.isEditMode.emit(newValue);
  }

  saveNewMark(): void {
    const { editMarkValue, editMarkType } = this.editMarkForm.value;

    editMarkType &&
      this.saveNewMarkValue.emit({
        value: editMarkValue ? parseFloat(editMarkValue) : 0,
        oldValue: this.price,
        currencyCode: '',
        type: editMarkType
      });
  }

  deleteNewMark(): void {
    this.saveNewMarkValue.emit(null);
    this.resetForm.emit();
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  PinnaklModuleNames,
  PresetsService,
  UserService,
  filterPresetConfigName
} from '@pinnakl/core/data-providers';
import { PresetModel } from '@pinnakl/shared/types';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { isNil, omitBy } from 'lodash';

@Component({
  selector: 'filter-presets',
  templateUrl: './filter-presets.component.html',
  styleUrls: ['./filter-presets.component.scss']
})
export class FilterPresetsComponent implements OnInit {
  private readonly module: PinnaklModuleNames;
  @Input() presetValues: any = null;
  @Output() closeModal = new EventEmitter<void>();
  presetsForm: UntypedFormGroup;

  constructor(
    private readonly _router: Router,
    private readonly _userService: UserService,
    private readonly presetsService: PresetsService,
    private readonly _spinner: PinnaklSpinnerService,
    private readonly _fb: UntypedFormBuilder,
    private readonly _toastr: PinnaklUIToastMessage
  ) {
    this.module = this._router.url.includes(PinnaklModuleNames.OMS.toLowerCase())
      ? PinnaklModuleNames.OMS
      : PinnaklModuleNames.EMS;
  }

  ngOnInit(): void {
    this.initPresetsForm();
  }

  submitForm(): void {
    const preset: any = {};
    const presetValue = omitBy(this.presetValues, isNil);
    if (!this.presetsForm.get('includeDateRange').value) {
      delete presetValue.startdate;
      delete presetValue.enddate;
    }
    presetValue.presetname = this.presetsForm.get('presetName').value;
    preset.userid = this._userService.getUser().id.toString();
    preset.module = this.module;
    preset.configname = filterPresetConfigName;
    preset.configvalue = JSON.stringify(presetValue);
    this.createPreset(preset);
  }

  private createPreset(preset: PresetModel): void {
    this._spinner.spin();
    this.presetsService
      .createPreset(preset)
      .then(res => {
        this._spinner.stop();
        return res;
      })
      .then(
        () => {
          this._toastr.success('Preset was created');
          this.closeModal.emit();
        },
        (err: HttpErrorResponse) => {
          this._toastr.error('Error while create preset');
          console.error('Error while create preset', err);
        }
      );
  }

  private initPresetsForm(): void {
    this.presetsForm = this._fb.group({
      presetName: ['', [Validators.required]],
      includeDateRange: false
    });
  }
}

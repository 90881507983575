import { EnvNames } from '@pinnakl/core/environment';
import { AllowedUserTypeAuthParam } from '@pinnakl/shared/types';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

const crmAppUrls = {
  local: 'https://localhost:53591/',
  dev: 'https://crmappstage.azurewebsites.net/',
  staging: 'https://crmappstage.azurewebsites.net/',
  prod: 'https://backendMiscService.pinnakl.com/'
};

export const grpcApiServerUrls = {
  local: 'https://datastream-grpc-dev.pinnakl.com',
  dev: 'https://datastream-grpc-dev.pinnakl.com',
  staging: 'https://datastream-grpc-prod.pinnakl.com',
  prod: 'https://datastream-grpc-prod.pinnakl.com'
};

export const fileServiceUrls = {
  local: 'https://localhost:53591/File/',
  dev: 'https://external-services-dev.pinnakl.com/File/',
  staging: 'https://external-services-prod.pinnakl.com/File/',
  prod: 'https://external-services-prod.pinnakl.com/File/'
};

export const firebaseConfigs = {
  prod: {
    messagingSenderId: '477103941646',
    apiKey: 'AIzaSyCaNwNtlA8Tmwzmg6SbNb4jyQ7zce8-A-A',
    projectId: 'pinnakl-production',
    appId: '1:477103941646:web:1d103839f7e2bd238b745f'
  },
  staging: {
    messagingSenderId: '920036889346',
    apiKey: 'AIzaSyDd3c-4d3UHAWbHGPangyHlRPWEs9ZLNCg',
    projectId: 'pinnakl-staging',
    appId: '1:920036889346:web:902816d3ffe7dcddbfe81b'
  }
};

const FRONT_END_ERROR = 'FrontEndError/Post';

export const frontEndErrorServiceUrls = {
  local: `${crmAppUrls.local}${FRONT_END_ERROR}`,
  dev: `${crmAppUrls.dev}${FRONT_END_ERROR}`,
  staging: `${crmAppUrls.staging}${FRONT_END_ERROR}`,
  prod: `${crmAppUrls.prod}${FRONT_END_ERROR}`
};

export const requestTimeoutPeriods = {
  local: 3000000,
  dev: 300000,
  staging: 300000,
  prod: 30000
};

export const datastream1Urls = {
  local: 'https://localhost:44393/api/',
  dev: 'https://appsvc-sse-dev.azurewebsites.net/api/',
  staging: 'https://sse.pinnakl.com/api/',
  prod: 'https://sse.pinnakl.com/api/'
};

export const datastream2Urls = {
  local: 'https://appsvc-pinnakl-sse-pmsv2-dev.azurewebsites.net/api/',
  dev: 'https://appsvc-sse-dev.azurewebsites.net/api/',
  staging: 'https://appsvc-pinnakl-sse-sub1.azurewebsites.net/api/',
  prod: 'https://appsvc-pinnakl-sse-sub1.azurewebsites.net/api/'
};

export const coreApiServerUrls = {
  local: 'https://localhost:51490',
  dev: 'https://apicore-dev.pinnakl.com',
  staging: 'https://apicore-prod-002.pinnakl.com',
  prod: 'https://apicore-prod-001.pinnakl.com'
};

export const authUrls = {
  local: 'https://localhost:5149',
  dev: 'https://apicore-dev.pinnakl.com',
  staging: 'https://apicore-prod-001.pinnakl.com',
  prod: 'https://apicore-prod-001.pinnakl.com'
};

export const tradingServiceUrls = {
  local: 'https://localhost:44323/api/',
  dev: 'https://appsvc-legacy-trading-dev.azurewebsites.net/api/',
  staging: 'https://services.pinnakl.com/api/',
  prod: 'https://services.pinnakl.com/api/'
};

export const authConfig = (envName: EnvNames): OpenIdConfiguration => ({
  authority: authUrls[envName],
  postLogoutRedirectUri: window.location.origin,
  redirectUrl: window.location.origin,
  clientId: 'angularclient',
  configId: 'pinnakl_' + envName,
  scope: 'openid profile email api_core offline_access',
  responseType: 'code',
  silentRenew: true,
  useRefreshToken: true,
  ignoreNonceAfterRefresh: true,
  logLevel: envName === EnvNames.dev ? LogLevel.Debug : LogLevel.Error,
  historyCleanupOff: false,
  autoCleanStateAfterAuthentication: true,
  triggerAuthorizationResultEvent: false,
  startCheckSession: false,
  disableRefreshIdTokenAuthTimeValidation: true,
  renewTimeBeforeTokenExpiresInSeconds: 60 * 15,
  tokenRefreshInSeconds: 60 * 10,
  autoUserInfo: false,
  unauthorizedRoute: '/login' + window.location.search,
  customParamsAuthRequest: { aut: AllowedUserTypeAuthParam.INTERNAL },
  customParamsRefreshTokenRequest: { aut: AllowedUserTypeAuthParam.INTERNAL },
  customParamsEndSessionRequest: { aut: AllowedUserTypeAuthParam.INTERNAL }
});

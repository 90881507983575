import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LatestPrice } from './portfolio-latest-prices.models';

@Injectable()
export class PortfolioLatestPricesApiService {
  private readonly endpoint = `${V3_ENDPOINT}portfolio_latest_prices`;

  constructor(private readonly wsp: WebServiceProvider) {}

  getPortfolioLatestPrices(): Observable<LatestPrice[]> {
    return this.wsp
      .get<LatestPrice[]>({
        endpoint: this.endpoint
      })
      .pipe(map(response => response ?? []));
  }
}

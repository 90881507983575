<ng-container *ngIf="statusChanges$ && (statusChanges$ | async); else hint">
  <ng-container *ngIf="dirty && errors; else hint">
    <div class="input-validation-error">{{ errorMsg }}</div>
  </ng-container>
</ng-container>

<ng-template #hint>
  <ng-container *ngIf="hintContent; else otherHints">
    <ng-container *ngTemplateOutlet="hintContent"></ng-container>
  </ng-container>
  <ng-template #otherHints>
    <ng-container *ngIf="hintLabel ?? reserveHeight">
      <div
        *ngIf="showAmountSection; else simpleHint"
        class="flex align-items-center"
      >
        <div class="complex-hint-label input-hint flex-grow-1">
          {{ hintLabel }}
        </div>
        <div class="complex-hint-amount input-hint">
          {{ valueLength || 0 }}<span *ngIf="maxLengthHintValue">/{{ maxLengthHintValue }}</span>
        </div>
      </div>
      <ng-template #simpleHint>
        <div
          [ngClass]="{ 'input-hint-reserve-height': reserveHeight }"
          class="input-hint"
        >
          {{ hintLabel }}
        </div>
      </ng-template>
    </ng-container>
  </ng-template>
</ng-template>

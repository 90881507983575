import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { InputOptions, Place } from '@pinnakl/shared/types';
import { HtmlHelperService } from '@pinnakl/shared/util-providers';
import { CalendarView, FormatSettings } from '@progress/kendo-angular-dateinputs';
import { PopupSettings } from '@progress/kendo-angular-dropdowns/common/models/popup-settings';
import { Observable } from 'rxjs';

@Component({
  selector: 'pinnakl-input',
  templateUrl: './pinnakl-input.component.html',
  styleUrls: ['./pinnakl-input.component.scss']
})
export class PinnaklInputComponent {
  private _checkBoxStyle: string;
  @Input() controlName: string;
  @Input() dateOptions: { format: string | FormatSettings };
  @Input() popupSettings?: PopupSettings;
  @Input() decimals = 15;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() maxLength: number;
  @Input() dropdownSource: any[];
  @Output() dropdownFetchData = new EventEmitter<void>();
  @Output() onDropdownValueChange = new EventEmitter<any>();
  @Input()
  dropdownOptions: {
    allowCustom?: boolean;
    isAsync?: boolean;
    clearButton?: boolean;
    modelNormalizer?: (textObservable: Observable<string>) => Observable<any>;
    modelProperty?: string;
    objectModel?: boolean;
    viewProperty?: string;
    disableSelectAll?: boolean;
  };
  @Input() form: UntypedFormGroup | AbstractControl;
  @Input() format: string;
  @Input() hideLabel = false;
  @Input() inputClass: string;
  @Input() containerClass: string;
  @Input() showValidation = true;
  @Input() validationClass: string;
  @Input() inputOptions: InputOptions;
  @Input() label: string;
  @Input() labelClass: string;
  @Input() autoComplete: string;
  @Output() placeDetailsReceived = new EventEmitter<Place>();
  @Output() onFilterValueChange = new EventEmitter<string>();
  @Output() onKeyDownText = new EventEmitter();
  @Output() onChangeToggle = new EventEmitter();
  @Output() pressEnter = new EventEmitter();
  @Input()
  placeOptions: {
    placeType: string;
    sendPlaceDetails: boolean;
    suggestionType: string;
  };
  @Input() required: boolean;
  @Input() textEditorHeight = 500;
  @Input() type: string;
  @Input() placeholder = '';
  @Input() textalign = 'left';
  @Input() value = false;
  @Input() topView: CalendarView = 'month';
  @Input() bottomView: CalendarView = 'month';
  @Input() toggleSource: any[];
  @Input() errosMsg: string;
  @Input()
  toggleOptions: {
    valueProperty?: string;
    viewProperty?: string;
  };
  @Input() labelToggle: string;
  @Input() labelPosition: 'top' | 'left' = 'left';
  // TODO: Should be false by default! This brings a lot of issues for other form controls that depends on that control!
  @Input() setDefaultSingleValue = true; // for dropdown select value if we have only one option
  toggleStyle = { holder: '', switch: '' };
  randomNumber = Math.floor(Math.random() * new Date().getTime());

  get checkBoxStyle(): string {
    return this._checkBoxStyle;
  }

  @Input()
  set checkBoxStyle(checkBoxStyle: string) {
    if (checkBoxStyle === 'toggle') {
      this._checkBoxStyle = 'toggle';
      this.toggleStyle.holder = 'slider round';
      this.toggleStyle.switch = 'switch';
    } else {
      this._checkBoxStyle = 'input-check';
    }
  }

  get currentLength(): number {
    const value = this.form?.get(this.controlName)?.value;
    if (!value) return 0;
    if (this.type.toLowerCase() === 'richtextarea') {
      return this.htmlHelperService.getPlainText(value).length;
    } else {
      return value?.length;
    }
  }

  constructor(private readonly htmlHelperService: HtmlHelperService) {}

  dropdownFetchDataEmit(): void {
    this.dropdownFetchData.emit();
  }

  dropdownValueChanged(changedDropdownModelValue: any): void {
    this.onDropdownValueChange.emit(changedDropdownModelValue);
  }

  filterValueChange(text: string) {
    this.onFilterValueChange.emit(text);
  }

  setCheckboxStyle(): string {
    return this.checkBoxStyle ? this.checkBoxStyle : (this.checkBoxStyle = 'input-check');
  }

  keyDown(e: any) {
    this.onKeyDownText.emit(e);
  }

  onPressEnter(e: any) {
    if (!e.shiftKey) {
      this.pressEnter.emit();
    }
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ACCOUNT_PNL } from '@pinnakl/pms/domain';
import { AccountBenchmarkIndex, BenchmarkIndex } from '@pinnakl/poems/pricing/domain';
import {
  DateHelpers,
  isNeitherNullNorUndefined,
  pollingFactory
} from '@pinnakl/shared/util-helpers';
import moment from 'moment';
import { combineLatest, filter, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { BenchmarkIndexIntradayPricesApiService } from './benchmark-index-intraday-prices-api.service';
import { BenchmarkIndexIntradayPricesFacadeService } from './benchmark-index-intraday-prices-facade.service';
import {
  benchmarkIndexIntradayPricesSuccess,
  loadBenchmarkIndexIntradayPrices,
  loadLatestBenchmarkIndexIntradayPrices
} from './benchmark-index-intraday-prices.actions';
import { BenchmarkIndexIntradayPricesState } from './benchmark-index-intraday-prices.model';
import { benchmarkIndexIntradayPricesQuery } from './benchmark-index-intraday-prices.selectors';

const REFETCH_INTERVAL = 60 * 1000; // 1 minute refresh interval
const INITIAL_DELAY = 60 * 1000; // 1 minute initial delay

const createBenchIndexFromAccIndex = (item: AccountBenchmarkIndex): BenchmarkIndex => ({
  benchmarkIndex: ACCOUNT_PNL,
  price: item.pl * 100,
  timestamp: item.timestamp,
  intradayReturn: 0 // placeholder until we have the data from the backend
});

@Injectable()
export class BenchmarkIndexIntradayPricesEffects {
  // load benchmark indexes for the maximum period of a day
  loadBenchmarkIndexes$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBenchmarkIndexIntradayPrices),
      withLatestFrom(
        this.store$
          .select(benchmarkIndexIntradayPricesQuery.mainAccountIdSelector)
          .pipe(filter(isNeitherNullNorUndefined))
      ),
      switchMap(([, mainAccountId]) =>
        combineLatest([
          this.api.loadIntradayPerformance<BenchmarkIndex>('index'),
          this.api
            .loadIntradayPerformance<AccountBenchmarkIndex>('account')
            .pipe(
              map(items =>
                items
                  .filter(({ accountId }) => accountId === mainAccountId)
                  .map(createBenchIndexFromAccIndex)
              )
            )
        ]).pipe(take(1))
      ),
      map(([indexBenchmarks, accountBenchmarks]) =>
        benchmarkIndexIntradayPricesSuccess({
          indexes: [...accountBenchmarks, ...indexBenchmarks]
        })
      )
    )
  );

  // load latest benchmark indexes and combine with existing
  loadLatestBenchmarkIndexes$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLatestBenchmarkIndexIntradayPrices),
      withLatestFrom(
        this.store$
          .select(benchmarkIndexIntradayPricesQuery.mainAccountIdSelector)
          .pipe(filter(isNeitherNullNorUndefined)),
        this.store$
          .select(benchmarkIndexIntradayPricesQuery.benchmarkIndexesSelector)
          .pipe(filter(values => !!values.length))
      ),
      switchMap(([, mainAccountId, indexesFromStore]) =>
        combineLatest([
          this.api.loadIntradayPerformance<BenchmarkIndex>('index', true),
          this.api
            .loadIntradayPerformance<AccountBenchmarkIndex>('account', true)
            .pipe(
              map(items =>
                items
                  .filter(({ accountId }) => accountId === mainAccountId)
                  .map(createBenchIndexFromAccIndex)
              )
            )
        ]).pipe(
          take(1),
          map(([indexBench, accountBench]) => {
            const prevTimestamp = indexesFromStore[indexesFromStore.length - 1].timestamp;
            const prevTime = moment(prevTimestamp);
            const reducedNewIndexes = [...indexBench, ...accountBench].reduce(
              (acc, index) => {
                const newTime = moment(index.timestamp);
                if (newTime.isAfter(prevTime)) {
                  return acc.concat(index);
                }
                return acc;
              },
              <BenchmarkIndex[]>[]
            );

            return [...indexesFromStore, ...reducedNewIndexes];
          })
        )
      ),
      map(indexes =>
        benchmarkIndexIntradayPricesSuccess({
          indexes
        })
      )
    )
  );

  checkPolling$$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(benchmarkIndexIntradayPricesSuccess),
        switchMap(() => {
          if (!DateHelpers.isTodayMarketDay()) {
            return of(false);
          }

          let pollingInitialDelay = INITIAL_DELAY;

          // check if market is open
          if (!DateHelpers.isMarketOpen()) {
            const currentDateTime = new Date();
            if (currentDateTime > DateHelpers.endTimeMarket()) {
              return of(false);
            }

            // calculate the delay till market opens
            pollingInitialDelay =
              DateHelpers.startTimeMarket().getTime() - currentDateTime.getTime();
          }

          return pollingFactory(
            REFETCH_INTERVAL,
            combineLatest([
              this.store$.select(benchmarkIndexIntradayPricesQuery.isInPollingSelector),
              this.facade.isMarketOpen$
            ]).pipe(map(([isInPolling, isMarketOpen]) => isInPolling && isMarketOpen)),
            pollingInitialDelay
          );
        }),
        tap(loadData => loadData && this.store$.dispatch(loadLatestBenchmarkIndexIntradayPrices())) // dispatch load latest benchmark indexes if polling and market open
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: BenchmarkIndexIntradayPricesApiService,
    private readonly facade: BenchmarkIndexIntradayPricesFacadeService,
    private readonly store$: Store<BenchmarkIndexIntradayPricesState>
  ) {}
}

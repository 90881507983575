import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';

@Component({ template: '', standalone: true })
export class InputSwitchPublicApiComponent {
  /**
   * Inline style of the component.
   * @group Props
   */
  @Input() style: { [klass: string]: any } | null | undefined;
  /**
   * Style class of the component.
   * @group Props
   */
  @Input() styleClass: string | undefined;
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  @Input() tabindex: number | undefined;
  /**
   * Identifier of the input element.
   * @group Props
   */
  @Input() inputId: string | undefined;
  /**
   * Name of the input element.
   * @group Props
   */
  @Input() name: string | undefined;
  /**
   * When present, it specifies that the element should be disabled.
   * @group Props
   */
  @Input() disabled: boolean | undefined;
  /**
   * When present, it specifies that the component cannot be edited.
   * @group Props
   */
  @Input() readonly: boolean | undefined;
  /**
   * Value in checked state.
   * @group Props
   */
  @Input() trueValue: any = true;
  /**
   * Value in unchecked state.
   * @group Props
   */
  @Input() falseValue: any = false;
  /**
   * Used to define a string that autocomplete attribute the current element.
   * @group Props
   */
  @Input() ariaLabel: string | undefined;
  /**
   * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   * @group Props
   */
  @Input() ariaLabelledBy: string | undefined;
  /**
   * Callback to invoke when the on value change.
   * @param {InputSwitchChangeEvent} event - Custom change event.
   * @group Emits
   */
  @Output() onChange: EventEmitter<InputSwitchChangeEvent> =
    new EventEmitter<InputSwitchChangeEvent>();
}

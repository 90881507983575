import {
  PmsFontSizes,
  PmsOtherSettings,
  PmsPresets,
  PmsSortTypes,
  PmsWidgetsItems
} from '@pinnakl/pms/domain';

export const defaultWidgetsOrder = [
  { key: PmsWidgetsItems.Grid },
  { key: PmsWidgetsItems.Summary },
  { key: PmsWidgetsItems.Balance },
  { key: PmsWidgetsItems.Chart }
];
export const defaultOtherSettings: PmsOtherSettings = {
  gridHeight: null,
  gridFontSize: PmsFontSizes.xs,
  gridSortType: PmsSortTypes.Auto,
  displayPnLChartWidget: true,
  displayPositionSummaryWidget: true,
  displayDeltaAdjustedExposureInSummaryWidget: true
};

export interface PmsPresetsState extends PmsPresets {
  loaded: boolean;
  loading: boolean;
}

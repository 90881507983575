import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { PinnaklSpinnerService } from '@pinnakl/shared/util-providers';
import { defaultConfig } from './drawer.constants';
import { DrawerWrapperComponent, IDrawerConfig } from './interfaces/drawer-inputs.interface';

@Component({
  selector: 'pinnkl-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent<T> implements DrawerWrapperComponent {
  private readonly defaultConfig: IDrawerConfig = defaultConfig;
  private _config: IDrawerConfig = {};
  spinnerVisible$;
  @ViewChild('templateRef', { static: false, read: ViewContainerRef })
  templateRef?: ViewContainerRef;
  @ViewChild('drawer', { static: false }) drawer?: MatSidenav;

  get config(): IDrawerConfig {
    return this._config;
  }

  set config(newConfig: IDrawerConfig) {
    this._config = { ...this.config, ...newConfig };
  }

  constructor(private readonly spinner: PinnaklSpinnerService) {
    this.spinnerVisible$ = this.spinner.isSpinning$;
    this._config = { ...this.defaultConfig, ...this.config };
  }

  open(): void {
    this.drawer?.open();
  }

  close(): void {
    this.drawer?.close();
  }
}

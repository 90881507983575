import { combineLatest, EMPTY, filter, iif, map, Observable, switchMap, take, timer } from 'rxjs';

export function isNeitherNullNorUndefined<T>(value: T): value is NonNullable<T> {
  return value != null;
}

type NonUndefined<T> = T extends undefined ? never : T;

export function isNotUndefined<T>(value: T): value is NonUndefined<T> {
  return value !== undefined;
}

export const getLoadableEntities$ = <T>(
  dataSelector$: Observable<T>,
  isLoaded$: Observable<boolean>,
  isLoading$: Observable<boolean>,
  loadFunc: () => void
): Observable<T> => {
  return combineLatest([isLoaded$, isLoading$]).pipe(
    map(([loaded, loading]) => {
      if (!loaded && !loading) {
        loadFunc();
      }
      return loaded && !loading;
    }),
    filter(dataLoaded => dataLoaded),
    take(1),
    switchMap(() => dataSelector$)
  );
};

export const emitWhenLoaded$ = <T>(
  dataSelector$: Observable<T>,
  isLoaded$: Observable<boolean>,
  isLoading$: Observable<boolean>
): Observable<T> => {
  return combineLatest([isLoaded$, isLoading$]).pipe(
    map(([loaded, loading]) => {
      return loaded && !loading;
    }),
    filter(isReady => isReady),
    take(1),
    switchMap(() => dataSelector$)
  );
};

export const pollingFactory = (interval: number, stop$: Observable<boolean>, delay = 0) => {
  return stop$.pipe(switchMap(value => iif(() => value, timer(delay, interval), EMPTY)));
};

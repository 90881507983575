import { createReducer, on } from '@ngrx/store';
import { PmsSavedColumns, PmsSavedColumnsConfigs } from '@pinnakl/pms/domain';
import { ParsedPreset, PmsPresetsTypes } from '@pinnakl/shared/types';
import { setLoadedAndLoadingFields } from '@pinnakl/shared/util-helpers';
import {
  loadPmsPresets,
  pmsColumnsConfigDeleted,
  pmsColumnsConfigSaved,
  pmsOtherSettingsSaved,
  pmsPresetsLoaded,
  pmsSelectedColumnsConfigDeleted,
  pmsSelectedColumnsConfigSaved,
  pmsWidgetsOrderSaved
} from './presets.actions';
import { PmsPresetsState } from './presets.models';

export const initialState: PmsPresetsState = {
  [PmsPresetsTypes.PmsWidgetsOrder]: null,
  [PmsPresetsTypes.PmsGridColumns]: [],
  [PmsPresetsTypes.PmsGridSelectedColumnsPreset]: null,
  [PmsPresetsTypes.PmsOtherSettings]: null,
  loaded: false,
  loading: false
};

export const presetsReducer = createReducer(
  initialState,
  on(loadPmsPresets, state => ({ ...state, ...setLoadedAndLoadingFields(false, true) })),
  on(
    pmsPresetsLoaded,
    (
      state,
      {
        presets: { pmsWidgetsOrder, pmsGridColumns, pmsGridSelectedColumnsPreset, pmsOtherSettings }
      }
    ) => ({
      ...state,
      pmsWidgetsOrder,
      pmsGridColumns,
      pmsGridSelectedColumnsPreset,
      pmsOtherSettings,
      ...setLoadedAndLoadingFields(true, false)
    })
  ),
  on(pmsWidgetsOrderSaved, (state, { pmsWidgetsOrder }) => ({
    ...state,
    pmsWidgetsOrder
  })),
  on(pmsOtherSettingsSaved, (state, { pmsOtherSettings }) => ({
    ...state,
    pmsOtherSettings
  })),
  on(pmsColumnsConfigSaved, (state, { savedColumnsConfig }) => ({
    ...state,
    pmsGridColumns: addOrUpdateColumnsConfig(state.pmsGridColumns, savedColumnsConfig)
  })),
  on(pmsSelectedColumnsConfigSaved, (state, { pmsGridSelectedColumnsPreset }) => ({
    ...state,
    pmsGridSelectedColumnsPreset
  })),
  on(pmsColumnsConfigDeleted, (state, { id }) => ({
    ...state,
    pmsGridColumns: removeColumnsConfig(state.pmsGridColumns, id)
  })),
  on(pmsSelectedColumnsConfigDeleted, state => ({
    ...state,
    pmsGridSelectedColumnsPreset: null
  }))
);

const addOrUpdateColumnsConfig = (
  allConfigs: PmsSavedColumnsConfigs,
  newConfig: ParsedPreset<PmsSavedColumns> | null
): PmsSavedColumnsConfigs => {
  if (newConfig) {
    let isExist = false;
    const updatedConfigs = allConfigs.map(config => {
      if (config.id === newConfig.id) {
        isExist = true;
        return newConfig;
      }
      return config;
    });

    if (!isExist) {
      return [...updatedConfigs, newConfig];
    }

    return updatedConfigs;
  }
  return allConfigs;
};

const removeColumnsConfig = (
  allConfigs: PmsSavedColumnsConfigs,
  idToRemove: number
): PmsSavedColumnsConfigs => {
  return allConfigs.filter(({ id }) => id !== idToRemove);
};

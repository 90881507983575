import { ComponentFactory, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { MatPinnaklModalComponent } from './mat-pinnakl-modal.component';

@Injectable({
  providedIn: 'root'
})
export class MatDialogService<T = undefined> {
  opened$ = this.dialogRefT.afterOpened().pipe(first());

  get context() {
    return this.dialogRefT.componentInstance.data.context;
  }

  get dialogRef(): MatDialogRef<MatPinnaklModalComponent<T>> {
    return this.dialogRefT;
  }

  constructor(private dialogRefT: MatDialogRef<MatPinnaklModalComponent<T>>) {}

  close() {
    this.dialogRefT.close();
  }

  setHeaderText(headerText: string): void {
    this.dialogRefT.componentInstance.data.headerText = headerText;
  }

  setFactoryComponent(factoryComponent: ComponentFactory<any>): void {
    this.dialogRefT.componentInstance.data.factoryComponent = factoryComponent;
  }
}

import { ColDef, ColumnState } from 'ag-grid-community';

export type PmsGridColumns = ColumnState[];
export type PmsGridSelectionColumns = {
  colId: string;
  hidden: boolean;
  disabled: boolean;
  name: string;
  tooltip?: string;
  order: number;
};

export const CASH_ASSETTYPE = 'CASH';

export enum COLUMNS_NEEDED_FOR_REBALANCE {
  accountcode = 'accountcode',
  direction = 'direction'
}

export enum REBALANCE_COLUMN_KEYS {
  tgtpct = 'tgtpct',
  rebalancequantity = 'rebalancequantity',
  rebalancecost = 'rebalancecost'
}

export interface PmsGridColDef extends ColDef {
  customType?: string;
  field?: string;
  colId: string;
}

export const EVENT_STREAM_BUFFER_TIMEOUT = 1000;
export const GRID_PROCESSING_TIMEOUT = 1000;
export const ALL_POSITIONS_CALCULATION_PROCESSING_TIMEOUT = 1000;
export const EVENT_PRICE_STREAM_KEYS_MAP = {
  mid: 'mark',
  undprc: 'undprc',
  delta: 'delta',
  gamma: 'gamma'
};
export const EVENT_PRICE_STREAM_KEYS = Object.keys(EVENT_PRICE_STREAM_KEYS_MAP);

export const POSITION_KEYS = {
  daycost: 'daycost',
  currentposition: 'currentposition',
  securitymarketid: 'securitymarketid',
  accountid: 'accountid',
  accountcode: 'accountcode'
};

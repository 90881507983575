import { Injectable } from '@angular/core';

@Injectable()
export class ReconnectWindowService {
  visible = false;

  hide(): void {
    this.visible = false;
  }

  show(): void {
    this.visible = true;
  }
}

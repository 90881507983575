import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ISecurity } from '@pinnakl/securities/domain';
import { setLoadedAndLoadingFields } from '@pinnakl/shared/util-helpers';
import { loadSecurities, securitiesLoaded } from './securities.actions';
import { SecuritiesState } from './securities.models';

export const securitiesAdapter: EntityAdapter<ISecurity> = createEntityAdapter<ISecurity>();

export const initialState: SecuritiesState = securitiesAdapter.getInitialState({
  loaded: false,
  loading: false
});

export const securitiesReducer = createReducer(
  initialState,
  on(loadSecurities, state => ({
    ...state,
    ...setLoadedAndLoadingFields(false, true)
  })),
  on(securitiesLoaded, (state, { securities }) =>
    securitiesAdapter.setAll(securities, {
      ...state,
      ...setLoadedAndLoadingFields(true, false)
    })
  )
);

<div
  class="col-12 d-flex align-items-center justify-content-between flex-no-wrap buttons-block-padding"
>
  <pnkl-line-stockchart-date-options
    (ngModelChange)="onOptionsChange($event)"
    [(ngModel)]="options"
    [hideDailyAndWeeklyOptions]="hideDailyAndWeeklyOptions"
    [hideSelected]="hideSelected"
    [selectionItems]="selectionItems"
    class="flex-grow-1"
  >
  </pnkl-line-stockchart-date-options>

  <div class="d-flex align-items-end align-self-end buttons-block mb-4">
    <button class="btn switch-btn download-btn">
      <i
        (click)="exportToExcel()"
        class="icon-pinnakl-arrow-down download-icon cursor-pointer"
      ></i>
    </button>
    <ng-content></ng-content>
  </div>
</div>
<kendo-stockchart [seriesColors]="colors">
  <kendo-chart-legend
    orientation="horizontal"
    position="bottom"
  ></kendo-chart-legend>
  <kendo-chart-tooltip [format]="valueFormat"></kendo-chart-tooltip>
  <kendo-chart-series>
    <kendo-chart-series-item
      *ngFor="let item of options.selectedItems"
      [data]="chartDataItems"
      [field]="item"
      [name]="item"
      [style]="'smooth'"
      categoryField="date"
      type="line"
    >
    </kendo-chart-series-item>
  </kendo-chart-series>
  <kendo-chart-value-axis>
    <kendo-chart-value-axis-item [title]="{ text: axisLabel }">
      <kendo-chart-value-axis-item-labels
        [format]="valueFormat"
      ></kendo-chart-value-axis-item-labels>
    </kendo-chart-value-axis-item>
  </kendo-chart-value-axis>
  <kendo-chart-navigator>
    <kendo-chart-navigator-series>
      <kendo-chart-navigator-series-item
        *ngFor="let item of options.selectedItems"
        [data]="chartDataItems"
        [field]="item"
        categoryField="date"
        type="area"
      >
      </kendo-chart-navigator-series-item>
    </kendo-chart-navigator-series>
  </kendo-chart-navigator>
</kendo-stockchart>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PmsRebalanceConfigState } from './rebalance-config-state.model';
import { rebalanceConfigAdapter } from './rebalance-config-state.reducer';

export const featureSelectorName = 'pms-rebalance-config';

const getRebalanceConfigStateSelector =
  createFeatureSelector<PmsRebalanceConfigState>(featureSelectorName);

const { selectAll, selectEntities } = rebalanceConfigAdapter.getSelectors();

const getRebalanceConfig = createSelector(getRebalanceConfigStateSelector, selectAll);
const getRebalanceConfigEntities = createSelector(getRebalanceConfigStateSelector, selectEntities);
const getRebalanceConfigLoading = createSelector(
  getRebalanceConfigStateSelector,
  ({ loading }) => loading
);
const getSelectedRebalanceConfigId = createSelector(
  getRebalanceConfigStateSelector,
  ({ selectedRebalanceConfigId }) => selectedRebalanceConfigId
);

const getSelectedRebalanceConfig = createSelector(
  getRebalanceConfigEntities,
  getSelectedRebalanceConfigId,
  (entities, selectedRebalanceConfigId) =>
    selectedRebalanceConfigId ? (entities[selectedRebalanceConfigId] ?? null) : null
);

export const rebalanceConfigQuery = {
  getRebalanceConfig,
  getRebalanceConfigEntities,
  getRebalanceConfigLoading,
  getSelectedRebalanceConfigId,
  getSelectedRebalanceConfig
};

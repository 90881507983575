import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import {
  RealtimePriceOverride,
  RealtimePriceOverrideBase,
  RealtimePriceOverrideFromApi
} from '@pinnakl/poems/pricing/domain';
import { V4_ENDPOINT } from '@pinnakl/shared/constants';
import { makeSomeFieldsToStringFactory } from '@pinnakl/shared/util-helpers';
import { Observable, map } from 'rxjs';

const realtimePriceOverrideMapper = makeSomeFieldsToStringFactory<
  RealtimePriceOverrideFromApi,
  RealtimePriceOverride
>(['securityMarketId']);

@Injectable()
export class RealtimePriceOverrideApiService {
  private readonly overrideEndpoint = `${V4_ENDPOINT}pricing/realtime-price-override`;

  constructor(private readonly wsp: WebServiceProvider) {}

  getAllOverrides(): Observable<RealtimePriceOverride[]> {
    return this.wsp
      .get<{ priceOverrides: RealtimePriceOverrideFromApi[] }>({
        endpoint: this.overrideEndpoint
      })
      .pipe(map(({ priceOverrides }) => priceOverrides.map(realtimePriceOverrideMapper)));
  }

  getRealtimePriceOverrideBySecurityMarketId(
    securityMarketId: string
  ): Observable<RealtimePriceOverride | null> {
    return this.wsp
      .get<RealtimePriceOverrideFromApi[]>({
        endpoint: this.overrideEndpoint,
        optionsParams: {
          filters: [{ key: 'securityMarketId', type: 'EQ', value: [securityMarketId] }]
        }
      })
      .pipe(map(statuses => (statuses.length ? realtimePriceOverrideMapper(statuses[0]) : null)));
  }

  createRealtimePriceOverride(
    realtimePriceOverride: RealtimePriceOverrideBase
  ): Observable<RealtimePriceOverride> {
    return this.wsp
      .post<RealtimePriceOverrideFromApi>({
        endpoint: this.overrideEndpoint,
        body: realtimePriceOverride
      })
      .pipe(map(realtimePriceOverrideMapper));
  }

  deleteRealtimePriceOverride(statusId: number): Observable<{ id: string; status: boolean }> {
    return this.wsp.delete<{ id: string; status: boolean }>({
      endpoint: `${this.overrideEndpoint}/${statusId}`
    });
  }
}

export enum PresetModules {
  Pms = 'pms',
  Oms = 'oms',
  Ems = 'ems',
  Crm = 'crm',
  PerformanceAttribution = 'performance-attribution',
  Rebalancing = 'rebalancing'
}

export enum PmsPresetsTypes {
  PmsWidgetsOrder = 'pmsWidgetsOrder',
  PmsOtherSettings = 'pmsOtherSettings',
  PmsGridColumns = 'pmsGridColumns',
  PmsGridSelectedColumnsPreset = 'pmsGridSelectedColumnsPreset',
  PmsFontSize = 'pmsFontSize',
  PmsSortType = 'pmsSortType',
  PmsPositionSummary = 'pmsPositionSummary',
  PmsDeltaAdjustedExposure = 'pmsDeltaAdjustedExposure'
}

export enum EmsPresetsTypes {
  EmsHome = 'emsHomePreset',
  EmsColumnsPreset = 'emsColumnsPreset',
  EmsGridFontSizePreset = 'emsGridFontSizePreset',
  EmsGridThemePreset = 'emsGridThemePreset',
  EmsFilterPreset = 'emsFilterPreset',
  EmsGridQuickFilterPreset = 'emsGridQuickFilterPreset',
  TraderTextPreset = 'emsTraderTextPreset',
  MultiplePlacementsPreset = 'emsMultiplePlacementsPreset',
  OrderScalingPreset = 'emsOrderScalingPreset',
  OrderTypePreset = 'emsOrderTypePreset',
  OrderTIFPreset = 'emsOrderTIFPreset',
  AlgoParamsDefaultsConfig = 'algoParamsDefaultsConfig',
  EmsSidePanelPreset = 'emsSidePanelPreset'
}

export enum RebalancePresetsTypes {
  RebalancingOtherSettings = 'rebalancingOtherSettings',
  RebalancingGridColumns = 'rebalancingGridColumns',
  RebalancingGridSelectedColumnsPreset = 'rebalancingGridSelectedColumnsPreset'
}

export enum PerformanceAttributionPresetsTypes {
  PerformanceAttributionColumnsSetting = 'performanceAttributionColumnsSetting'
}

// Will add other modules config names when those modules will be refactored
// PmsPresetsTypes | OmsPresetsTypes | EmsPresetsTypes etc
export type PresetConfigName =
  | PmsPresetsTypes
  | EmsPresetsTypes
  | RebalancePresetsTypes
  | PerformanceAttributionPresetsTypes;

export interface BasePreset {
  userid?: string;
  module: PresetModules;
  configName: PresetConfigName;
  configValue: string;
}

export interface Preset extends BasePreset {
  userId?: number;
  id: number;
}

export interface ParsedPreset<T> extends Preset {
  parsedConfigValue: T;
}

export type BasePresetWithModuleBuilder = (
  configName: PresetConfigName,
  configValue: string
) => BasePreset;

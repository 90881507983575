import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { SecurityPositionTimeItem } from '@pinnakl/poems/timeseries/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { Observable } from 'rxjs';

@Injectable()
export class TimeseriesPositionsApiService {
  private readonly positionTimeseriesEndpoint = `${V3_ENDPOINT}position_timeseries`;

  constructor(private readonly wsp: WebServiceProvider) {}

  getTimeItemsBySecurityMarketId(securityMarketId: string): Observable<SecurityPositionTimeItem[]> {
    return this.wsp.get<SecurityPositionTimeItem[]>({
      endpoint: this.positionTimeseriesEndpoint,
      optionsParams: {
        filters: [{ key: 'securityMarketId', type: 'EQ', value: [securityMarketId] }]
      }
    });
  }
}

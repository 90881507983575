import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FolderCommentsState } from './folder-comments.models';
import { folderCommentsAdapter } from './folder-comments.reducer';

export const featureSelectorName = 'folder_comments';

const getFolderCommentsStateSelector =
  createFeatureSelector<FolderCommentsState>(featureSelectorName);

const { selectEntities } = folderCommentsAdapter.getSelectors();

const folderCommentsEntities = createSelector(getFolderCommentsStateSelector, selectEntities);
const getFolderCommentsById = (id: string) =>
  createSelector(folderCommentsEntities, entities => entities[id]);

const getCreateFolderCommentStatus = createSelector(
  getFolderCommentsStateSelector,
  ({ createFolderCommentStatus }) => createFolderCommentStatus
);
const getUpdateFolderCommentStatus = createSelector(
  getFolderCommentsStateSelector,
  ({ updateFolderCommentStatus }) => updateFolderCommentStatus
);
const getDeleteFolderCommentStatus = createSelector(
  getFolderCommentsStateSelector,
  ({ deleteFolderCommentStatus }) => deleteFolderCommentStatus
);

export const folderCommentsQuery = {
  getFolderCommentsById,
  getCreateFolderCommentStatus,
  getUpdateFolderCommentStatus,
  getDeleteFolderCommentStatus
};

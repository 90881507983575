import { ErrorHandler, Injectable } from '@angular/core';
import { FrontendErrorService } from './frontend-error.service';

@Injectable()
export class PinnaklErrorHandler implements ErrorHandler {
  constructor(private frontendErrorService: FrontendErrorService) {}

  handleError(error: any): void {
    this.frontendErrorService.handleError(error);
  }
}

import { Directive } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'noop-value-accessor',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NoopValueAccessorDirective
    }
  ]
})
export class NoopValueAccessorDirective<T, CF = () => void, TF = () => void>
  implements ControlValueAccessor
{
  writeValue(obj: T): void {
    return;
  }

  registerOnChange(fn: CF): void {
    return;
  }

  registerOnTouched(fn: TF): void {
    return;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PmsGridColumnsDataManager } from './grid-col-defs-data-manager.service';
import { PmsGridColumnsFacadeService } from './grid-columns-facade.service';
import { PmsGridColumnsEffects } from './grid-columns.effects';
import { gridColumnsReducer, initialState } from './grid-columns.reducer';
import { featureSelectorName } from './grid-columns.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureSelectorName, gridColumnsReducer, {
      initialState
    }),
    EffectsModule.forFeature([PmsGridColumnsEffects])
  ],
  providers: [PmsGridColumnsFacadeService, PmsGridColumnsDataManager]
})
export class PmsGridColumnsDataAccessModule {}

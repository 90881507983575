import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GlobalErrorAction, GlobalStubAction } from '@pinnakl/shared/constants';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { catchError, concatMap, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { RealtimePriceOverrideFacadeService } from './';
import { RealtimePriceOverrideApiService } from './realtime-price-override-api.service';
import {
  allRealtimePriceOverridesLoaded,
  createOverride,
  deleteOverride,
  loadAllRealtimePriceOverrides,
  loadOverrideBySecurityMarketId,
  overrideBySecurityMarketIdLoaded,
  overrideCreated,
  overrideDeleted
} from './realtime-price-override.actions';
import { RealtimePriceOverrideState } from './realtime-price-override.models';
import { RealtimePriceOverrideQuery } from './realtime-price-override.selectors';

@Injectable()
export class RealtimePriceOverrideEffects {
  loadAllRealtimePriceOverrides$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllRealtimePriceOverrides),
      switchMap(() => this.api.getAllOverrides()),
      map(overrides => allRealtimePriceOverridesLoaded({ overrides }))
    )
  );

  loadOverrideStatusBySecurityMarketId$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOverrideBySecurityMarketId),
      concatMap(({ securityMarketId }) =>
        this.api.getRealtimePriceOverrideBySecurityMarketId(securityMarketId)
      ),
      tap(realtimePriceOverride =>
        setTimeout(
          () =>
            realtimePriceOverride &&
            this.facade.realtimePriceOverrideLoaded(
              realtimePriceOverride.securityMarketId.toString()
            ),
          0
        )
      ),
      map(realtimePriceOverride =>
        realtimePriceOverride
          ? overrideBySecurityMarketIdLoaded({ realtimePriceOverride })
          : GlobalStubAction()
      )
    )
  );

  createOverrideStatus$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOverride),
      concatMap(({ realtimePriceOverride }) =>
        this.api.createRealtimePriceOverride(realtimePriceOverride)
      ),
      tap(() => this.toast.success('Price is saved')),
      map(overrideStatus => overrideCreated({ realtimePriceOverride: overrideStatus }))
    )
  );

  deleteOverrideStatus$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteOverride),
      withLatestFrom(
        this.store$.select(RealtimePriceOverrideQuery.getRealtimePriceOverrideEntities)
      ),
      concatMap(([{ securityMarketId }, entities]) =>
        entities[securityMarketId]?.id
          ? this.api
              .deleteRealtimePriceOverride(entities[securityMarketId]!.id)
              .pipe(map(() => securityMarketId))
          : of(null)
      ),
      tap(
        securityMarketId =>
          securityMarketId && this.toast.success('Price override has been successfully removed')
      ),
      map(securityMarketId =>
        securityMarketId ? overrideDeleted({ securityMarketId }) : GlobalStubAction()
      ),
      catchError(error =>
        of(
          GlobalErrorAction({ errorMsg: 'Delete realtime price override failed', errorObj: error })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly toast: PinnaklUIToastMessage,
    private readonly api: RealtimePriceOverrideApiService,
    private readonly store$: Store<RealtimePriceOverrideState>,
    private readonly facade: RealtimePriceOverrideFacadeService
  ) {}
}

<div [ngClass]="{ 'validation-min-height': reserveSpaceForValidation }">
  <div
    *ngIf="!form.controls[controlName].valid"
    class="pnkl-validation-errors color-red {{ validationClass }}"
  >
    <div
      *ngIf="
        form.controls[controlName].errors && form.controls[controlName].errors.required && !errosMsg
      "
    >
      This field is required.
    </div>
    <div
      *ngIf="
        form.controls[controlName].errors &&
        (!form.controls[controlName].errors.required || errosMsg)
      "
    >
      <ng-container *ngIf="errosMsg; else defaultError">
        <div>{{ errosMsg }}</div>
      </ng-container>
      <ng-template #defaultError>
        <div *ngFor="let obj of form.controls[controlName].errors | keys">
          <div *ngIf="obj.value.errorMessage">{{ obj.value.errorMessage }}</div>
          <div
            *ngIf="!obj.value.errorMessage"
            [ngSwitch]="obj.key"
          >
            <div *ngSwitchCase="'min'">Min value is {{ obj.value.min }}</div>
            <div *ngSwitchCase="'max'">Max value is {{ obj.value.max }}</div>
            <div *ngSwitchCase="'maxlength'">Maximum length exceeded</div>
            <div *ngSwitchCase="'pattern'">Invalid data</div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

import { createAction, props } from '@ngrx/store';
import {
  PmsOtherSettings,
  PmsOtherSettingsConfig,
  PmsPresets,
  PmsSavedColumns,
  PmsSelectedSavedColumnsConfig,
  PmsWidgetOrderConfigPreset,
  PmsWidgetsOrder
} from '@pinnakl/pms/domain';
import { ParsedPreset, PmsPresetsTypes } from '@pinnakl/shared/types';

export enum PmsPresetsActions {
  LoadPmsPresets = '[Pms Presets] Load pms presets',
  PmsPresetsLoaded = '[Pms Presets] Pms presets loaded',
  SavePmsWidgetsOrder = '[Pms Presets] Save Pms widgets order',
  PmsWidgetsOrderSaved = '[Pms Presets] Pms widgets order saved',
  SaveOtherSettings = '[Pms Presets] Save Pms other settings',
  OtherSettingsSaved = '[Pms Presets] Pms other settings saved',
  SavePmsColumnsConfig = '[Pms Presets] Save Pms columns config',
  PmsColumnsConfigSaved = '[Pms Presets] Pms columns config saved',
  PmsSelectedColumnsConfigSaved = '[Pms Presets] Pms selected columns config saved',
  PmsSelectColumnsConfig = '[Pms Presets] Pms select columns config',
  PmsDeleteColumnsConfig = '[Pms Presets] Pms delete columns config',
  PmsColumnsConfigDeleted = '[Pms Presets] Pms columns config deleted',
  PmsDeleteSelectedColumnsConfig = '[Pms Presets] Pms delete selected columns config',
  PmsSelectedColumnsConfigDeleted = '[Pms Presets] Pms selected columns config deleted',
  PmsPresetActionStub = '[Pms Presets] Pms preset action stub',
  PmsSharePresets = '[Pms Presets] Pms share presets'
}

export const loadPmsPresets = createAction(PmsPresetsActions.LoadPmsPresets);
export const pmsPresetsLoaded = createAction(
  PmsPresetsActions.PmsPresetsLoaded,
  props<{
    presets: PmsPresets;
  }>()
);

export const savePmsWidgetsOrder = createAction(
  PmsPresetsActions.SavePmsWidgetsOrder,
  props<{
    order: PmsWidgetsOrder;
  }>()
);
export const pmsWidgetsOrderSaved = createAction(
  PmsPresetsActions.PmsWidgetsOrderSaved,
  props<{
    [PmsPresetsTypes.PmsWidgetsOrder]: PmsWidgetOrderConfigPreset;
  }>()
);

export const pmsSaveOtherSettings = createAction(
  PmsPresetsActions.SaveOtherSettings,
  props<{
    otherSettings: PmsOtherSettings;
  }>()
);
export const pmsOtherSettingsSaved = createAction(
  PmsPresetsActions.OtherSettingsSaved,
  props<{
    [PmsPresetsTypes.PmsOtherSettings]: PmsOtherSettingsConfig;
  }>()
);

export const savePmsColumnsConfig = createAction(
  PmsPresetsActions.SavePmsColumnsConfig,
  props<{
    savedColumnsConfig: PmsSavedColumns;
    skipMessages: boolean;
    isNew: boolean;
  }>()
);
export const pmsColumnsConfigSaved = createAction(
  PmsPresetsActions.PmsColumnsConfigSaved,
  props<{
    savedColumnsConfig: ParsedPreset<PmsSavedColumns>;
  }>()
);

export const pmsSelectedColumnsConfigSaved = createAction(
  PmsPresetsActions.PmsSelectedColumnsConfigSaved,
  props<{
    [PmsPresetsTypes.PmsGridSelectedColumnsPreset]: PmsSelectedSavedColumnsConfig;
  }>()
);

export const pmsSelectColumnsConfig = createAction(
  PmsPresetsActions.PmsSelectColumnsConfig,
  props<{ id: number }>()
);
export const pmsDeleteColumnsConfig = createAction(
  PmsPresetsActions.PmsDeleteColumnsConfig,
  props<{ id: number }>()
);
export const pmsColumnsConfigDeleted = createAction(
  PmsPresetsActions.PmsColumnsConfigDeleted,
  props<{ id: number }>()
);
export const pmsDeleteSelectedColumnsConfig = createAction(
  PmsPresetsActions.PmsDeleteSelectedColumnsConfig
);
export const pmsSelectedColumnsConfigDeleted = createAction(
  PmsPresetsActions.PmsSelectedColumnsConfigDeleted
);
export const pmsPresetActionStub = createAction(PmsPresetsActions.PmsPresetActionStub);

export const pmsSharePresets = createAction(
  PmsPresetsActions.PmsSharePresets,
  props<{ presetsIds: number[]; usersIds: number[] }>()
);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonDataApiService } from './common-data-api.service';
import { PoemsCommonDataFacadeService } from './common-data-facade.service';
import { CommonDataEffects } from './common-data.effects';
import { commonDataReducer, initialState } from './common-data.reducer';
import { featureSelectorName } from './common-data.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureSelectorName, commonDataReducer, {
      initialState
    }),
    EffectsModule.forFeature([CommonDataEffects])
  ],
  providers: [CommonDataApiService, PoemsCommonDataFacadeService]
})
export class CommonDataDataAccessModule {}

<div
  [ngClass]="[
    isPassword ? 'password-container' : '',
    applyActiveCss ? labelAlignCss : '',
    inputOptions && inputOptions.floatingLabel ? 'input-field' : '',
    inputOptions && inputOptions.inputContainerCss && inputOptions.inputContainerCss.length
      ? inputOptions.inputContainerCss
      : ''
  ]"
>
  <input
    #inputElement
    (focus)="inputFocused()"
    (focusout)="inputFocusedOut()"
    (keydown)="keyDown($event)"
    (ngModelChange)="valueChanged($event)"
    [(ngModel)]="value"
    [attr.disabled]="disabledInput ? true : undefined"
    [autocomplete]="autoComplete"
    [ngClass]="inputClass"
    [placeholder]="placeholder"
    [type]="isPassword ? (showPassword ? 'text' : type) : type"
    class="pnkl-input"
  />
  <ng-container *ngIf="isPassword">
    <svg
      (click)="showPassword = !showPassword"
      [ngClass]="{ active: showPassword }"
      class="password-visibility-trigger"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clip-rule="evenodd"
        d="M2.31518 8C2.63093 8.34042 3.07652 8.78059 3.6247 9.21913C4.79677 10.1568 6.32803 11 8 11C9.67197 11 11.2032 10.1568 12.3753 9.21913C12.9235 8.78059 13.3691 8.34042 13.6848 8C13.3691 7.65958 12.9235 7.21941 12.3753 6.78087C11.2032 5.84321 9.67197 5 8 5C6.32803 5 4.79677 5.84321 3.6247 6.78087C3.07652 7.21941 2.63093 7.65958 2.31518 8ZM15 8C15.8 7.4 15.7996 7.39945 15.7996 7.39945L15.7979 7.39726L15.7948 7.39306L15.7851 7.38036C15.7772 7.37002 15.7663 7.35595 15.7526 7.33842C15.7251 7.30335 15.6861 7.25434 15.636 7.19339C15.5359 7.07157 15.3913 6.9015 15.2059 6.69928C14.8363 6.29602 14.2989 5.75848 13.6247 5.21913C12.2968 4.15679 10.328 3 8 3C5.67197 3 3.70323 4.15679 2.3753 5.21913C1.70112 5.75848 1.16375 6.29602 0.794096 6.69928C0.608722 6.9015 0.464059 7.07157 0.363993 7.19339C0.313923 7.25434 0.27491 7.30335 0.24744 7.33842C0.233702 7.35595 0.22284 7.37002 0.214916 7.38036L0.205236 7.39306L0.20206 7.39726L0.20089 7.39882C0.20089 7.39882 0.2 7.4 1 8L0.2 7.4C-0.0666667 7.75556 -0.0666667 8.24444 0.2 8.6L1 8C0.2 8.6 0.2 8.6 0.2 8.6L0.20089 8.60119L0.20206 8.60274L0.205236 8.60694L0.214916 8.61964C0.22284 8.62998 0.233702 8.64405 0.24744 8.66158C0.27491 8.69665 0.313923 8.74566 0.363993 8.80661C0.464059 8.92843 0.608722 9.0985 0.794096 9.30072C1.16375 9.70398 1.70112 10.2415 2.3753 10.7809C3.70323 11.8432 5.67197 13 8 13C10.328 13 12.2968 11.8432 13.6247 10.7809C14.2989 10.2415 14.8363 9.70398 15.2059 9.30072C15.3913 9.0985 15.5359 8.92843 15.636 8.80661C15.6861 8.74566 15.7251 8.69665 15.7526 8.66158C15.7663 8.64405 15.7772 8.62998 15.7851 8.61964L15.7948 8.60694L15.7979 8.60274L15.7991 8.60119C15.7991 8.60119 15.8 8.6 15 8ZM15 8L15.8 8.6C16.0667 8.24444 16.0663 7.75501 15.7996 7.39945L15 8Z"
        fill="inherit"
        fill-rule="evenodd"
      />
      <path
        d="M10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z"
        fill="inherit"
      />
    </svg>
  </ng-container>
  <label
    (click)="labelClicked()"
    *ngIf="inputOptions && inputOptions.floatingLabel"
    >{{ label }}
    <span
      *ngIf="required"
      class="color-red pl-0-25"
      >*</span
    ></label
  >
</div>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PositionsState } from './positions.models';
import { positionsAdapter } from './positions.reducer';

export const featureSelectorName = 'pms-positions';

const getPositionsStateSelector = createFeatureSelector<PositionsState>(featureSelectorName);

const { selectAll } = positionsAdapter.getSelectors();

const getPositions = createSelector(getPositionsStateSelector, selectAll);
const getPositionsLoading = createSelector(getPositionsStateSelector, ({ loading }) => loading);
const getColumnsKeysMap = createSelector(getPositionsStateSelector, ({ keysMap }) => keysMap);
const getCalculatedAllPositions = createSelector(
  getPositionsStateSelector,
  ({ calculatedAllPositions }) => calculatedAllPositions
);
const getCalculatedFilteredByAccountSummaryData = createSelector(
  getPositionsStateSelector,
  ({ calculatedFilteredByAccountSummaryData }) => calculatedFilteredByAccountSummaryData
);
const getCalculatedCashBalance = createSelector(
  getPositionsStateSelector,
  ({ calculatedCashBalance }) => calculatedCashBalance
);
const getPinnedData = createSelector(getPositionsStateSelector, ({ pinnedData }) => pinnedData);
const getNavCurrentMap = createSelector(
  getPositionsStateSelector,
  ({ navCurrentMap }) => navCurrentMap
);

export const positionsQuery = {
  getPositions,
  getPinnedData,
  getNavCurrentMap,
  getColumnsKeysMap,
  getPositionsLoading,
  getCalculatedCashBalance,
  getCalculatedAllPositions,
  getCalculatedFilteredByAccountSummaryData
};

import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { PmsRebalanceConfig } from '@pinnakl/pms/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { Observable } from 'rxjs';

@Injectable()
export class PmsRebalanceConfigApiService {
  private readonly endpoint = `${V3_ENDPOINT}pms_rebalance_config`;

  constructor(private readonly wsp: WebServiceProvider) {}

  getConfig(): Observable<PmsRebalanceConfig[]> {
    return this.wsp.get<PmsRebalanceConfig[]>({
      endpoint: this.endpoint,
      optionsParams: {
        fields: [
          'bpsPctVisible',
          'equityCommAssumption',
          'optionCommAssumption',
          'equityBrokerId',
          'optionBrokerId'
        ]
      }
    });
  }
}

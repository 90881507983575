import { sumBy } from 'lodash';

export const buildGridPinnedBottomData = <T extends object>(
  columns: { isAggregating?: boolean | null; name: string; type: string }[],
  rows: T[]
): T[] => {
  if (!columns) {
    return [];
  }
  // To add sum of trade cost column (from rebalancing)
  columns = columns.concat([
    {
      isAggregating: true,
      name: 'rebalancecost',
      type: 'number'
    }
  ]);
  const reportAggregation = columns.reduce((acc, { isAggregating, name, type }) => {
    acc[name] = '';
    if (isAggregating && name.toLowerCase() !== 'mark') {
      acc[name] = sumBy(rows, name);
    }

    // ag grid throw warning if numeric column values is empty string
    const lowerName = name.toLowerCase();
    if (
      acc[name] === '' &&
      (['mark', 'daycost', 'pricesodlocal'].includes(lowerName) ||
        type === 'number' ||
        type === 'numeric' ||
        type === 'calculation')
    ) {
      acc[lowerName] = null;
    }

    return acc;
  }, {} as T);
  for (const key of Object.keys(reportAggregation)) {
    if (
      reportAggregation[key] === null &&
      !['mark', 'daycost', 'pricesodlocal'].includes(key.toLowerCase())
    ) {
      reportAggregation[key] = '';
    }
  }

  return [reportAggregation];
};

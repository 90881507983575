import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { CurrencyModel, FxRate } from '@pinnakl/poems/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { Observable } from 'rxjs';

@Injectable()
export class CommonDataApiService {
  private readonly currenciesEndpoint = `${V3_ENDPOINT}global_currencies`;
  private readonly fxRatesEndpoint = `${V3_ENDPOINT}fx_rates_currencies_latest`;

  constructor(private readonly wsp: WebServiceProvider) {}

  getFxRates(): Observable<FxRate[]> {
    return this.wsp.get<FxRate[]>({
      endpoint: this.fxRatesEndpoint
    });
  }

  getCurrencies(): Observable<CurrencyModel[]> {
    return this.wsp.get<CurrencyModel[]>({
      endpoint: this.currenciesEndpoint
    });
  }
}

import { createAction, props } from '@ngrx/store';
import { PmsColumn } from '@pinnakl/pms/domain';

export enum PmsColumnsActions {
  loadColumns = '[Pms Columns] Load columns',
  loadColumnsFailure = '[Pms Columns] Load columns error',
  SetColumns = '[Pms Columns] Set columns'
}

export const loadColumns = createAction(PmsColumnsActions.loadColumns);
export const loadColumnsFailure = createAction(
  PmsColumnsActions.loadColumnsFailure,
  props<{ error: any }>()
);
export const setColumns = createAction(
  PmsColumnsActions.SetColumns,
  props<{ columns: PmsColumn[] }>()
);

import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'utc'
})
export class UtcDatePipe implements PipeTransform {
  transform(date: string | Date): string {
    return moment.utc(date).toString();
  }
}

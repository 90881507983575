import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { filter } from 'rxjs/operators';
import { loadColumns } from './columns.actions';
import { PmsColumnsState } from './columns.models';
import { columnsQuery } from './columns.selectors';

@Injectable()
export class PmsColumnsFacadeService {
  columns$ = this.store$.select(columnsQuery.getColumns).pipe(filter(isNeitherNullNorUndefined));
  columnsLoading$ = this.store$
    .select(columnsQuery.getColumnsLoading)
    .pipe(filter(isNeitherNullNorUndefined));

  constructor(private readonly store$: Store<PmsColumnsState>) {}

  loadColumns(): void {
    this.store$.dispatch(loadColumns());
  }
}

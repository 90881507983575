import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../';

@Component({
  selector: 'mat-pinnakl-modal',
  templateUrl: './mat-pinnakl-modal.component.html',
  styleUrls: ['./mat-pinnakl-modal.component.scss']
})
export class MatPinnaklModalComponent<T> implements AfterViewInit {
  @ViewChild('vc', { read: ViewContainerRef }) vc: ViewContainerRef;

  constructor(
    public dialogRef: MatDialogRef<MatPinnaklModalComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    if (this.data.factoryComponent) {
      const comp = this.vc.createComponent(this.data.factoryComponent);
      if (this.data.componentData) {
        Object.assign(comp.instance, this.data.componentData);
      }
      this.cdr.detectChanges();
    }
  }
}

<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<p-confirmDialog key="global"></p-confirmDialog>
<nav class="navbar navbar-fixed-top">
  <div class="navbar-inner-holder">
    <a class="navbar-brand">
      <img
        alt=""
        src="/assets/images/elements/site-logo.svg"
      />
    </a>
  </div>

  <div id="main-nav">
    <nav class="nav pnkl-navbar-nav navbar-right">
      <li class="dropdown">
        <a
          (click)="searchModalOpen()"
          class="pointer"
        >
          <div>
            <i class="icon-pinnakl-search navbar-icon"></i>
          </div>
        </a>
      </li>
      <li class="dropdown">
        <a routerLink="/help">
          <div class="navbar-notification-holder">
            <i class="custom-icon-help"></i>
          </div>
        </a>
      </li>
      <li class="dropdown">
        <a (click)="toggleNotifications()">
          <div class="navbar-notification-holder">
            <i class="icon-pinnakl-bell-alt navbar-icon"></i>
          </div>
        </a>
        <ul
          (clickOutside)="showNotifications = false"
          *ngIf="showNotifications"
          [clickOutsideEnabled]="showNotifications"
          class="notification-dropdown-align dropdown-menu dropdown-notification"
          pinnaklClickOutside
        >
          <li class="dropdown-header font-size-12px">You don't have any notifications.</li>
          <!-- <li><b>Kurt</b> entered a Buy of 1000 shares of IBM.</li>
                                          <li><b>Kurt</b> deleted the IBM trade.</li>
                                          <li><b>Kurt</b> entered a Buy of 1000 shares of IBM.</li>
                                          <li><b>Kurt</b> deleted the IBM trade.</li> -->
        </ul>
      </li>
      <li class="dropdown">
        <a
          (click)="navigateToSettings()"
          class="pointer"
        >
          <div class="navbar-notification-holder">
            <i class="icon-pinnakl-cog navbar-icon"></i>
          </div>
        </a>
      </li>
      <li class="navbar-profile">
        <a
          (click)="showUserActionsDropdown = !showUserActionsDropdown"
          class="cursor-pointer"
        >
          {{ userService.getUser()?.fullName }}
          <img
            alt=""
            class="profile-pic"
            src="/assets/images/elements/user-profile.png"
          />
        </a>
        <ul
          (clickOutside)="showUserActionsDropdown = false"
          *ngIf="showUserActionsDropdown"
          [clickOutsideEnabled]="showUserActionsDropdown"
          class="acc-dropdown password-change-dropdown-align dropdown-menu dropdown-notification"
          pinnaklClickOutside
        >
          <li
            (click)="showUserActionsDropdown = false; logout()"
            class="logout-btn cursor-pointer"
          >
            <b>Logout</b>
            <hr />
          </li>
          <li>
            <div class="versionNo">Version No: {{ version$ | async }}</div>
          </li>
        </ul>
      </li>
    </nav>
  </div>
</nav>
<aside
  [ngClass]="{ 'aside-opened': menuOpen }"
  class="aside-menu-holder"
  id="main-aside"
>
  <a
    (click)="toggleMenu()"
    class="aside-menu-holder-trigger aside-item"
  >
    <i class="icon-pinnakl-menu"></i>
  </a>
  <ul class="aside-menu-list">
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/dashboard"
        title=""
      >
        <i
          class="icon-pinnakl-side-dashboard-active"
          title="Dashboard"
        ></i>
        <span>Dashboard</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/oms"
        title=""
      >
        <i
          class="icon-pinnakl-side-oms"
          title="OMS"
        ></i>
        <span>OMS</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/ems"
        title=""
      >
        <i
          class="icon-pinnakl-globe"
          title="EMS"
        ></i>
        <span>EMS</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/pms"
        title=""
      >
        <i
          class="icon-pinnakl-side-positions-active"
          title="PMS"
        ></i>
        <span>PMS</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/pricing"
        title=""
      >
        <i
          class="icon-pinnakl-side-pricing-active"
          title="Pricing"
        ></i>
        <span>Pricing</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/risk/risk-home"
        title=""
      >
        <i
          class="icon-pinnakl-side-risk-active"
          title="Risk"
        ></i>
        <span>Risk</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/reconciliation"
        title=""
      >
        <i
          class="icon-pinnakl-side-reconciliation-active-svg"
          title="Recon"
        ></i>
        <span>Reconciliation</span>
      </a>
    </li>

    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/performance-attribution"
        title=""
      >
        <i
          class="icon-pinnakl-side-pl-active"
          title="Performance attribution"
        ></i>
        <span>Performance attribution</span>
      </a>
    </li>

    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/reporting/all-reports"
        title=""
      >
        <i
          class="icon-pinnakl-side-reports-active"
          title="Reports"
        ></i>
        <span>Reports</span>
      </a>
    </li>
    <li
      *ngIf="showIncompleteScreens()"
      routerLinkActive="active"
    >
      <a
        class="tooltip"
        routerLink="/shadow-nav/shadow-nav-home"
        title=""
      >
        <i
          class="icon-pinnakl-shadow-nav-active"
          title="Shadow NAV"
        ></i>
        <span>Shadow NAV</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/securities"
        title=""
      >
        <i
          class="icon-pinnakl-side-securities-active"
          title="Securities"
        ></i>
        <span>Securities</span>
      </a>
    </li>

    <li
      *ngIf="includeTesting"
      routerLinkActive="active"
    >
      <a
        class="tooltip"
        routerLink="/testing"
        title=""
      >
        <i
          class="icon-pinnakl-side-securities-active"
          title="Testing"
        ></i>
        <span>Testing</span>
      </a>
    </li>

    <!-- <li routerLinkActive="active">
                    <a routerLink="/rebalancing/rebalancing-home" class="tooltip" title="">
                      <i title="Rebalancing" class="icon-pinnakl-rebalance"></i>
                      <span>Rebalancing</span>
                    </a>
                  </li> -->

    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/rebalancing"
        title=""
      >
        <i
          class="icon-pinnakl-rebalance"
          title="Modeling"
        ></i>
        <span>Rebalancing</span>
      </a>
    </li>

    <li
      *ngIf="false"
      routerLinkActive="active"
    >
      <a
        class="tooltip"
        routerLink="/cash-flow"
        title=""
      >
        <i
          class="custom-icon-cashflow position-icon"
          title="Cash Flow"
        ></i>
        <span>Cash Flow</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/stock-loan"
        title=""
      >
        <i
          class="custom-icon-stock-loan position-icon"
          title="Stock Loan"
        ></i>
        <span>Stock Loan</span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a
        class="tooltip"
        routerLink="/corporate-actions"
        title=""
      >
        <i
          class="custom-icon-corporate-action position-icon"
          title="Corporate Actions"
        ></i>
        <span>Corporate Actions</span>
      </a>
    </li>
    <li
      *ngIf="showIncompleteScreens()"
      routerLinkActive="active"
    >
      <a
        class="tooltip"
        routerLink="/reporting/manager-reports"
        title=""
      >
        <i
          class="icon-pinnakl-security-add-manually"
          title="Manager Reports"
        ></i>
        <span>Manager Reports</span>
      </a>
    </li>
    <li
      *ngIf="isNotProduction"
      routerLinkActive="active"
    >
      <a
        class="tooltip"
        routerLink="/api-playground"
        title=""
      >
        <i class="icon-pinnakl-fire"></i>
        <span>API Playground</span>
      </a>
    </li>
  </ul>
</aside>

<main
  [ngClass]="{ 'aside-opened': menuOpen }"
  class="main-holder"
>
  <pinnakl-spinner></pinnakl-spinner>
  <reconnect-window></reconnect-window>
  <default-confirm-action></default-confirm-action>
  <router-outlet></router-outlet>
</main>

<div kendoDialogContainer></div>
<div kendoWindowContainer></div>

<div
  class="atdl-content"
  id="reactDropdown"
></div>

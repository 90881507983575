import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PortfolioLatestPricesState } from './portfolio-latest-prices.models';
import { portfolioLatestPricesAdapter } from './portfolio-latest-prices.reducer';

export const featureSelectorName = 'pms-portfolio-latest-prices';

const getPricingFeatureSelector =
  createFeatureSelector<PortfolioLatestPricesState>(featureSelectorName);

const { selectAll } = portfolioLatestPricesAdapter.getSelectors();

const getPortfolioLatestPrices = createSelector(getPricingFeatureSelector, selectAll);
const getPortfolioLatestPriceBySecMarketId = id =>
  createSelector(getPricingFeatureSelector, ({ entities }) => entities[id] ?? null);

export const PortfolioLatestPricesQuery = {
  getPortfolioLatestPrices,
  getPortfolioLatestPriceBySecMarketId
};

import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';
import { EventObj } from '@tinymce/tinymce-angular/editor/Events';
import { Editor as TinyMCEEditor } from 'tinymce';

@Component({
  selector: 'pinnakl-editor',
  templateUrl: './pinnakl-editor.component.html',
  styleUrls: ['./pinnakl-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PinnaklEditorComponent),
      multi: true
    }
  ]
})
export class PinnaklEditorComponent implements ControlValueAccessor {
  private readonly defaultToolbar =
    'insert | undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat';
  @Input() controlName: string;
  @Input() disableEditor: boolean;
  @Input() form: UntypedFormGroup;
  @Input() textEditorHeight: string | number = 500;
  @Output() initEvent = new EventEmitter<{ editor: TinyMCEEditor }>();
  onChange: (value: any) => void;
  value: any;
  isLocalPhoto = false;

  setInitParamsTextEditor(): any {
    return {
      branding: false,
      height: this.textEditorHeight,
      toolbar: this.disableEditor ? false : this.defaultToolbar,
      plugins: ['powerpaste', 'image', 'lists', 'link'],
      contextmenu: false,
      menubar: !this.disableEditor,
      browser_spellcheck: true,
      base_url: '/tinymce',
      suffix: '.min',
      promotion: false,
      powerpaste_allow_local_images: true,
      powerpaste_word_import: 'prompt',
      powerpaste_html_import: 'prompt',
      images_dataimg_filter: (img: any) => {
        this.isLocalPhoto = true;
        return img.hasAttribute('internal-blob');
      },
      content_style:
        ".mce-content-body {font-size:14px;font-family:'Source Sans Pro','Arial',sans-serif;color:#262A32}"
    };
  }

  onInit({ editor }: EventObj<any>): void {
    this.initEvent.emit({ editor });
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnTouched(fn: (value: any) => void): void {}

  valueChanged(body: any): void {
    this.onChange(body);
  }
}

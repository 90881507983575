import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { PortfolioLatestPricesApiService } from './portfolio-latest-prices-api.service';
import {
  loadPortfolioLatestPrices,
  portfolioLatestPricesLoaded
} from './portfolio-latest-prices.actions';

@Injectable()
export class PortfolioLatestPricesEffects {
  loadPortfolioLatestPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPortfolioLatestPrices),
      exhaustMap(() => this.api.getPortfolioLatestPrices()),
      map(prices =>
        portfolioLatestPricesLoaded({ prices: prices.filter(({ type }) => type === 'mid') })
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: PortfolioLatestPricesApiService
  ) {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TradesApiService } from './trades-api.service';
import { TradesFacadeService } from './trades-facade.service';

@NgModule({
  imports: [CommonModule],
  providers: [TradesApiService, TradesFacadeService]
})
export class TradesDataAccessModule {}

import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface PasswordRequirement {
  label: string;
  example: string;
  errorName: PasswordErrorName;
}

enum PasswordErrorName {
  LowerCase = 'lowercase',
  UpperCase = 'uppercase',
  Digit = 'digit',
  Symbol = 'symbol',
  Length = 'length'
}

const PASSWORD_MIN_LENGTH = 8;

@UntilDestroy()
@Component({
  selector: 'change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChangePasswordFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ChangePasswordFormComponent,
      multi: true
    }
  ]
})
export class ChangePasswordFormComponent implements Validator {
  @Input() hideSubmit = false;
  @Input() isSubmitted = false;
  @Output() submitted = new EventEmitter<{ password: string }>();
  onChange: (value: string) => void;
  form: UntypedFormGroup;

  passwordRequirements: PasswordRequirement[] = [
    { label: 'Characters', example: '8+', errorName: PasswordErrorName.Length },
    { label: 'Uppercase', example: 'AA', errorName: PasswordErrorName.UpperCase },
    { label: 'Lowercase', example: 'aa', errorName: PasswordErrorName.LowerCase },
    { label: 'Number', example: '123', errorName: PasswordErrorName.Digit },
    { label: 'Symbol', example: '@$#', errorName: PasswordErrorName.Symbol }
  ];

  get password(): FormControl {
    return this.form.get('password') as FormControl;
  }

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            this.lowerCaseValidator,
            this.upperCaseValidator,
            this.digitValidator,
            this.symbolValidator,
            this.lengthValidator
          ]
        ],
        confirmation: ['', [Validators.required]]
      },
      { validators: this.passwordMatchesValidator }
    );
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(({ password }) => {
      this.onChange?.(this.form.valid ? password : null);
    });
  }

  lowerCaseValidator(control: AbstractControl): ValidationErrors | null {
    return control.value && /[a-z]/.test(control.value)
      ? null
      : { [PasswordErrorName.LowerCase]: true };
  }

  upperCaseValidator(control: AbstractControl): ValidationErrors | null {
    return /[A-Z]/.test(control.value) ? null : { [PasswordErrorName.UpperCase]: true };
  }

  digitValidator(control: AbstractControl): ValidationErrors | null {
    return /[0-9]/.test(control.value) ? null : { [PasswordErrorName.Digit]: true };
  }

  symbolValidator(control: AbstractControl): ValidationErrors | null {
    return /[!@#$%^&*]/.test(control.value) ? null : { [PasswordErrorName.Symbol]: true };
  }

  lengthValidator(control: AbstractControl): ValidationErrors | null {
    return control.value?.length >= PASSWORD_MIN_LENGTH
      ? null
      : { [PasswordErrorName.Length]: true };
  }

  passwordMatchesValidator(control: AbstractControl): ValidationErrors | null {
    const form = control as UntypedFormGroup;
    const { password, confirmation } = form.getRawValue();
    if (password === confirmation) return null;
    return { dontMatch: true };
  }

  changePassword(): void {
    this.isSubmitted = true;
    if (this.form.invalid) return;
    const { password } = this.form.getRawValue();
    this.submitted.emit({ password });
    this.form.reset();
    this.isSubmitted = false;
  }

  hasError(errorName: PasswordErrorName): boolean {
    return this.password?.errors && this.password.errors[errorName];
  }

  writeValue(password: string): void {
    if (password) {
      this.isSubmitted = false;
      this.form.patchValue({
        password,
        confirmation: null
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.errors) return this.form.errors;
    if (this.password.errors) return this.password.errors;
    return null;
  }
}

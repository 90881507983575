import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

const ARROW_UP = '\u2191';
const ARROW_DOWN = '\u2193';

type RendererParams = ICellRendererParams & {
  customValueFormatter?: (value: number, data?: any) => string;
};

@Component({
  selector: 'distinct-value-cell',
  template: `<span
    ><span
      #delta
      class="ag-value-change-delta"
    ></span
    ><span
      #value
      class="ag-value-change-value"
      >{{
        params.customValueFormatter
          ? params.customValueFormatter(lastValue, params.data)
          : (lastValue | number: '1.2-2')
      }}</span
    ></span
  >`
})
export class CellRendererDistinctValueComponent implements ICellRendererAngularComp {
  private refreshCount = 0;
  @ViewChild('delta') eDelta: ElementRef<HTMLElement>;
  @ViewChild('value') eValue: ElementRef<HTMLElement>;
  lastValue: number;
  cellValue: number;
  params!: RendererParams;

  public agInit(params: RendererParams): void {
    this.params = params;
    if (params.node.footer || params.node.rowPinned) return;
    this.cellValue = params.value;
    this.refresh(params);
  }

  public refresh(params: RendererParams): boolean {
    this.params = params;
    if (params.node.footer) return true;
    const value = params.value;
    if (value === this.lastValue) {
      return true;
    }
    if (typeof value === 'number' && typeof this.lastValue === 'number') {
      const delta = value - this.lastValue;
      this.showDelta(delta);
      this.setTimerToRemoveDelta();
    }
    this.lastValue = value;
    return true;
  }

  private showDelta(delta: number): void {
    const deltaUp = delta >= 0;
    if (this.eDelta) {
      this.eDelta.nativeElement.innerHTML = deltaUp ? ARROW_UP : ARROW_DOWN;
      this.eDelta.nativeElement.parentElement.classList.toggle('ag-value-change-delta-up', deltaUp);
      this.eDelta.nativeElement.parentElement.classList.toggle(
        'ag-value-change-delta-down',
        !deltaUp
      );
    }
  }

  private setTimerToRemoveDelta(): void {
    // the refreshCount makes sure that if the value updates again while
    // the below timer is waiting, then the below timer will realise it
    // is not the most recent and will not try to remove the delta value.
    this.refreshCount++;
    const refreshCountCopy = this.refreshCount;
    window.setTimeout(() => {
      if (refreshCountCopy === this.refreshCount) {
        this.refreshCount = 0;
        this.hideDeltaValue();
        this.params?.refreshCell();
      }
    }, 1000);
  }

  private hideDeltaValue(): void {
    this.clearElement(this.eDelta?.nativeElement);
  }

  private clearElement(el: HTMLElement): void {
    while (el && el.firstChild) {
      el.removeChild(el.firstChild);
    }
  }
}

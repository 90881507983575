import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PinnaklSpinnerService {
  private readonly DEFAULT_SCOPE = 'default';
  private scopes$ = new BehaviorSubject<Set<string>>(new Set());
  isSpinning$ = this.scopes$.asObservable().pipe(
    map(scopes => !!scopes.size),
    distinctUntilChanged()
  );

  get isSpinning(): boolean {
    return !!this.scopes$.getValue().size;
  }

  spin(scope: string = this.DEFAULT_SCOPE): void {
    this.addScope(scope);
  }

  stop(scope: string = this.DEFAULT_SCOPE): void {
    this.removeScope(scope);
  }

  stopAll(): void {
    this.clearScopes();
  }

  isScopeSpinning$(scope: string): Observable<boolean> {
    return this.scopes$.asObservable().pipe(
      map(scopes => scopes.has(scope)),
      distinctUntilChanged()
    );
  }

  private addScope(scope: string): void {
    const scopes = this.scopes$.getValue();
    scopes.add(scope);
    this.scopes$.next(scopes);
  }

  private removeScope(scope: string): void {
    const scopes = this.scopes$.getValue();
    scopes.delete(scope);
    this.scopes$.next(scopes);
  }

  private clearScopes(): void {
    this.scopes$.next(new Set());
  }
}

import { createAction, props } from '@ngrx/store';
import { LatestPrice } from './portfolio-latest-prices.models';

export enum PortfolioLatestPricesActions {
  LoadPortfolioLatestPrices = '[PMS | Portfolio latest prices] Load portfolio latest prices',
  PortfolioLatestPricesLoaded = '[PMS | Portfolio latest prices] Portfolio latest prices loaded',
  EditPortfolioLatestPrice = '[PMS | Portfolio latest prices] Edit Portfolio latest price by sec market id'
}

export const loadPortfolioLatestPrices = createAction(
  PortfolioLatestPricesActions.LoadPortfolioLatestPrices
);

export const portfolioLatestPricesLoaded = createAction(
  PortfolioLatestPricesActions.PortfolioLatestPricesLoaded,
  props<{ prices: LatestPrice[] }>()
);

export const editPortfolioLatestPrice = createAction(
  PortfolioLatestPricesActions.EditPortfolioLatestPrice,
  props<{ securityMarketId: number; newLatestPrice: number }>()
);

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SecurityPositionTimeItem } from '@pinnakl/poems/timeseries/domain';
import { Observable } from 'rxjs';
import { loadPositionsTimeSeries } from './timeseries-positions.actions';
import { TimeseriesPositionsState } from './timeseries-positions.models';
import { timeseriesPositionsQuery } from './timeseries-positions.selectors';

@Injectable()
export class TimeseriesPositionsFacadeService {
  private readonly timeseriesPositionsMap = new Map<
    string,
    Observable<SecurityPositionTimeItem[] | undefined>
  >();
  loading$ = this.store$.select(timeseriesPositionsQuery.getPositionsLoading);

  constructor(private readonly store$: Store<TimeseriesPositionsState>) {}

  loadTimeseriesPositionsBySecurityMarketId(securityMarketId: string, forceFetch = false): void {
    if (forceFetch || !this.timeseriesPositionsMap.has(securityMarketId)) {
      this.store$.dispatch(loadPositionsTimeSeries({ securityMarketId }));
    }
  }

  getTimeseriesPositionsBySecurityMarketId(
    id: string
  ): Observable<SecurityPositionTimeItem[] | undefined> {
    let positionsStream = this.timeseriesPositionsMap.get(id);

    if (positionsStream) {
      return positionsStream;
    }

    positionsStream = this.store$.pipe(
      select(timeseriesPositionsQuery.getPositionsBySecurityMarketId(id))
    );
    this.timeseriesPositionsMap.set(id, positionsStream);

    return positionsStream;
  }
}

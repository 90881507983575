import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FolderComment, FolderComments } from '@pinnakl/poems/folders/domain';
import { Observable, take } from 'rxjs';
import {
  createFolderComment,
  deleteFolderComment,
  loadFolderComments,
  updateFolderComment
} from './folder-comments.actions';
import { FolderCommentsState } from './folder-comments.models';
import { folderCommentsQuery } from './folder-comments.selectors';

@Injectable({
  providedIn: 'root'
})
export class FolderCommentsFacadeService {
  createFolderCommentStatus$ = this.store$.select(folderCommentsQuery.getCreateFolderCommentStatus);
  updateFolderCommentStatus$ = this.store$.select(folderCommentsQuery.getUpdateFolderCommentStatus);
  deleteFolderCommentStatus$ = this.store$.select(folderCommentsQuery.getDeleteFolderCommentStatus);

  constructor(private readonly store$: Store<FolderCommentsState>) {}

  loadFolderCommentsById(id: string, forceFetch = false): void {
    if (forceFetch) {
      this.store$.dispatch(loadFolderComments({ id }));
    } else {
      this.store$
        .pipe(select(folderCommentsQuery.getFolderCommentsById(id)), take(1))
        .subscribe(folderComments => {
          if (!folderComments) {
            this.store$.dispatch(loadFolderComments({ id }));
          }
        });
    }
  }

  getFolderCommentsById(id: string): Observable<FolderComments | undefined> {
    return this.store$.pipe(select(folderCommentsQuery.getFolderCommentsById(id)));
  }

  createFolderComment(folderComment: Partial<FolderComment>): void {
    this.store$.dispatch(createFolderComment({ folderComment }));
  }

  updateFolderComment(folderComment: Partial<FolderComment>): void {
    this.store$.dispatch(updateFolderComment({ folderComment }));
  }

  deleteFolderComment(id: string, folderId: string): void {
    this.store$.dispatch(deleteFolderComment({ id, folderId }));
  }
}

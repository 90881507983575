import { EmbeddedViewRef, inject, Injectable, OnDestroy, ViewContainerRef } from '@angular/core';
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Nullable } from 'primeng/ts-helpers';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ModalMinimizedViewComponent } from './minimized-view.component';

@Injectable({ providedIn: 'root' })
export class ModalMinimizeService implements OnDestroy {
  private vcr: ViewContainerRef | null = null;
  private primeDialogService = inject(DialogService);
  private _dialogRef: DynamicDialogRef | null = null;
  private _dialogInstance: DynamicDialogComponent | null = null;
  public minimizedView$ = new BehaviorSubject(false);

  get dialogRef(): DynamicDialogRef | null {
    return this._dialogRef;
  }

  get dialogInstance(): DynamicDialogComponent | null {
    return this._dialogInstance;
  }

  get dialogWrapper(): Nullable<HTMLElement> | undefined {
    return this._dialogInstance?.wrapper;
  }

  get dialogAlreadyOpened(): boolean {
    return !!this._dialogRef && !!this._dialogInstance;
  }

  ngOnDestroy(): void {
    this.resetRefs();
    document.body.classList.remove('enable-dialog-minimize');
  }

  setDialogInstance(dialogRef: DynamicDialogRef, viewContainerRef: ViewContainerRef): void {
    this.vcr = viewContainerRef;
    this._dialogRef = dialogRef;
    this._dialogInstance = this.primeDialogService.getInstance(this._dialogRef);
    this._dialogRef.onClose.pipe(finalize(() => this.resetRefs())).subscribe();
    document.body.classList.add('enable-dialog-minimize');
  }

  closeDialog<T>(value?: T): void {
    this._dialogRef?.close?.(value);
  }

  initDrag(event: MouseEvent): void {
    this._dialogInstance?.initDrag(event);
  }

  minimizeDialog(): void {
    this._dialogInstance?.unbindDocumentDragListener?.();
    this.minimizedView$.next(true);
    document.body.classList.add('minimized');
  }

  maximizeDialog(): void {
    this._dialogInstance?.bindDocumentDragListener?.();
    this.minimizedView$.next(false);
    document.body.classList.remove('minimized');
  }

  private resetRefs(): void {
    this.maximizeDialog();
    this._dialogRef = null;
    this._dialogInstance = null;
    this.vcr = null;
  }

  // Appending preview component can be added in the future
  private appendComponentToModal(): void {
    const componentRef = this.vcr?.createComponent(ModalMinimizedViewComponent);
    if (componentRef) {
      componentRef.instance.headerLabel =
        this._dialogInstance?.data?.headerLabel ?? this._dialogInstance?.header ?? '';
      const domElem = (componentRef.hostView as EmbeddedViewRef<ModalMinimizedViewComponent>)
        .rootNodes[0] as HTMLElement;
      this.dialogWrapper?.parentElement?.appendChild(domElem);
    }
  }

  private removeComponentFromModal(): void {
    this.vcr?.clear();
  }
}

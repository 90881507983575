import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getLoadableEntities$, isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { filter } from 'rxjs';
import { load } from './accounts.actions';
import { AccountsState } from './accounts.models';
import { accountQuery } from './accounts.selectors';

@Injectable({
  providedIn: 'root'
})
export class AccountsFacadeService {
  accounts$ = getLoadableEntities$(
    this.store$.select(accountQuery.getAccounts).pipe(filter(isNeitherNullNorUndefined)),
    this.store$.select(accountQuery.isLoaded),
    this.store$.select(accountQuery.isLoading),
    this.loadAccounts.bind(this)
  );

  constructor(private readonly store$: Store<AccountsState>) {}

  loadAccounts(): void {
    this.store$.dispatch(load());
  }
}

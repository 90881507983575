import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_SERVICE, EnvironmentService } from '@pinnakl/core/environment';
import { ServerSentEventsStreamService } from '@pinnakl/core/server-sent-events';
import { PriceEventData, PriceEventDataType } from '@pinnakl/poems/streams/price/domain';
import { EventSourceHandlers } from '@pinnakl/shared/types';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

interface PriceEventDataApi {
  ClientId: number;
  Currency: string;
  EvalTime: string;
  PriceType: PriceEventDataType;
  SecurityMarketId: number;
  Source: number;
  Value: number;
}

@Injectable()
export class PriceEventsMessageService {
  dataStreamUrl: string | null = null;
  readonly error = 'No data stream URL provided for prices stream!';

  constructor(
    @Inject(ENVIRONMENT_SERVICE) private readonly envService: EnvironmentService,
    private readonly eventStreamService: ServerSentEventsStreamService
  ) {
    this.dataStreamUrl = this.envService.get('datastream2Url');
    if (!this.dataStreamUrl) {
      throw new Error(this.error);
    }
  }

  subscribeToStream(handlers?: EventSourceHandlers): Observable<PriceEventData> {
    return this.dataStreamUrl
      ? this.eventStreamService
          .subscribeToEvents<{
            Message: PriceEventDataApi;
          }>(this.dataStreamUrl, [], 'RTPrice', handlers)
          .pipe(
            map(
              ({
                Message: {
                  ClientId,
                  Currency,
                  EvalTime,
                  PriceType,
                  SecurityMarketId,
                  Source,
                  Value
                }
              }) => ({
                clientId: ClientId,
                currency: Currency,
                evalTime: EvalTime,
                priceType: PriceType,
                securityMarketId: SecurityMarketId,
                source: Source,
                value: Value
              })
            )
          )
      : throwError(() => this.error);
  }

  unsubscribeFromStream(): void {
    if (this.dataStreamUrl) {
      this.eventStreamService.unsubscribeFromEvents(this.dataStreamUrl, [], 'RTPrice');
    } else {
      throw new Error('No data stream URL provided for prices stream!');
    }
  }
}

<div class="filter-presets-modal-container">
  <div class="presets-modal-header">Save Preset</div>
  <div
    *ngIf="presetsForm"
    class="presets-modal-body"
  >
    <form [formGroup]="presetsForm">
      <div class="presets-control">
        <pinnakl-input
          [form]="presetsForm"
          [required]="true"
          controlName="presetName"
          label="NAME"
          type="text"
        >
        </pinnakl-input>
      </div>
      <div class="include-date-range">
        <div class="presets-control-label">Include date range</div>
        <pinnakl-input
          [form]="presetsForm"
          checkBoxStyle="toggle"
          controlName="includeDateRange"
          type="boolean"
        >
        </pinnakl-input>
      </div>
    </form>
  </div>
  <div class="presets-modal-actions">
    <button
      (click)="presetsForm.valid && submitForm()"
      [disabled]="!presetsForm?.dirty || !presetsForm?.valid"
      class="btn btn-blue"
      type="button"
    >
      SAVE
    </button>
  </div>
</div>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SecuritiesState } from './securities.models';
import { securitiesAdapter } from './securities.reducer';

export const featureSelectorName = 'securities';

const getSecuritiesStateSelector = createFeatureSelector<SecuritiesState>(featureSelectorName);

const { selectAll } = securitiesAdapter.getSelectors();

const getSecurities = createSelector(getSecuritiesStateSelector, selectAll);

const getAllSecurities = createSelector(
  getSecuritiesStateSelector,
  getSecurities,
  ({ loaded }, entities) => (loaded ? entities : null)
);

export const securitiesQuery = {
  getAllSecurities
};

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TimeseriesPositionsState } from './timeseries-positions.models';

const getTimeseriesItemsStateSelector = createFeatureSelector<{
  positions: TimeseriesPositionsState;
}>('timeseries');

const positionsSelector = createSelector(getTimeseriesItemsStateSelector, state => state.positions);

const getPositionsBySecurityMarketId = (id: string) =>
  createSelector(positionsSelector, ({ entities }) => entities[id]?.positions);

const getPositionsLoading = createSelector(positionsSelector, ({ loading }) => loading);

export const timeseriesPositionsQuery = {
  getPositionsBySecurityMarketId,
  getPositionsLoading
};

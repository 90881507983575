import { Component, HostBinding } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AgRendererComponent } from 'ag-grid-angular';
import { Column, ICellRendererParams, IRowNode } from 'ag-grid-community';

@UntilDestroy()
@Component({
  selector: 'pinnakl-grid-toggle',
  templateUrl: './pinnakl-grid-toggle.component.html',
  styleUrls: ['./pinnakl-grid-toggle.component.scss']
})
export class PinnaklGridToggleComponent implements AgRendererComponent {
  private _column?: Column;
  private _node?: IRowNode<any>;
  summaryRow = false;
  type: 'default' | 'success' = 'success';
  form = new FormGroup({
    toggle: new FormControl()
  });
  @HostBinding('class') hostClasses = 'd-flex align-items-center justify-content-center';

  agInit({ column, node, value, context, data }: ICellRendererParams): void {
    this._column = column;
    this._node = node;
    if (value !== '') {
      this.form.patchValue({
        toggle: value
      });
    } else {
      this.summaryRow = true;
    }

    if (context.toggle) {
      this.form.controls.toggle.valueChanges.pipe(untilDestroyed(this)).subscribe(value =>
        context.toggle({
          data,
          value
        })
      );
    }
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return true;
  }
}

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import {
  AssetType,
  RestrictedSecurity,
  RestrictedSecurityFromApi,
  Security,
  SecurityAttributeOption,
  SecurityAttributeOptionFromApi,
  SecurityFromApi,
  SecuritySearchResult,
  SecurityType,
  SecurityTypeFromApi,
  SecurityV2
} from '@pinnakl/shared/types';
import moment from 'moment';
import { Observable, from } from 'rxjs';

export interface OptionLookupChainExpiration {
  callStrikes: number[];
  putStrikes: number[];
  expirationDate: string;
}

export interface OptionLookupChainResponse {
  expirations: OptionLookupChainExpiration[];
}

@Injectable()
export class SecurityService {
  private readonly _securitiesEndpoint = 'entities/securities';
  private readonly _securitiesSearchEndpoint = 'v3/entities/security_search';
  private readonly _assetTypesEndpoint = 'entities/asset_types';
  private readonly _securityAttributesEndpoint = 'entities/security_attribute_options';
  private readonly _securityTypesEndpoint = 'entities/security_types';
  private readonly _securityOptionLookupEndpoint = 'v3/entities/option_chain_lookup';
  private readonly _securityIdentifiersTickerSearchEndpoint = 'entities/idc_ticker_search';
  private readonly _rtlv2 = 'v3/entities/restricted_trading_list';

  private readonly _securityFields = [
    'Id',
    'AssetType',
    'AssetTypeId',
    'SecType',
    'secTypeId',
    'SecTypeDescription',
    'Ticker',
    'Cusip',
    'Sedol',
    'LoanId',
    'OpraCode',
    'Isin',
    'Description',
    'PrivateIndicator',
    'CurrencyId',
    'Currency',
    'Multiplier',
    'Principal_Factor',
    'InitialMargin',
    'MaintenanceMargin',
    'Maturity',
    'underlyingsecid',
    'active_trading_indicator',
    'bloombergidentifier',
    'clientid',
    'country_of_quotation',
    'countryofincorporation',
    'countryofrisk',
    'datasource',
    'manualpricingindicator',
    'marketid',
    'mic',
    'moodyrating',
    'organization_id',
    'organization_status_descr',
    'organization_status_id',
    'organizationname',
    'organizationticker',
    'pricingsourcename',
    'primary_market_indicator',
    'sandprating',
    'sector',
    'securitymarketid',
    'securitypricingid',
    'sharesoutstanding',
    'userid',
    'countryOfQuotationId',
    'countryOfQuotationCode',
    'countryOfQuotationName',
    'countryOfIncorporationCode',
    'countryOfIncorporationName'
  ];

  private _assetTypes: AssetType[];
  private _securities: Security[];
  private _securityTypes: SecurityType[];

  constructor(
    private readonly wsp: WebServiceProvider,
    private readonly http: HttpClient
  ) {}

  static securityV2ToSecurityV1(security: SecurityV2): Security {
    return {
      ...security,
      ticker: security.market.ticker
    } as unknown as Security;
  }

  static securityV1ToSearchResult(security: Security): SecuritySearchResult {
    return {
      ...security,
      assetTypeId: security.assetTypeId,
      assetType: security.assetType,
      countryOfIncorporationCode: security.countryOfIncorporationCode,
      countryOfQuotationCode: security.countryOfQuotationCode,
      cusip: security.cusip,
      description: security.description,
      id: +security.id,
      isin: security.isin,
      maturity: '',
      position: 0,
      securityMarketId: security.securityMarketId,
      sedol: security.sedol,
      ticker: security.ticker
    };
  }

  static securityV2ToSearchResult(security: SecurityV2): SecuritySearchResult {
    return {
      ...security,
      assetTypeId: security.assetTypeId,
      assetType: security.assetType,
      countryOfIncorporationCode: '',
      countryOfQuotationCode: '',
      cusip: security.cusip,
      description: security.description,
      id: +security.id,
      isin: security.isin,
      maturity: '',
      position: 0,
      securityMarketId: security.market.id,
      sedol: security.market.sedol,
      ticker: security.market.ticker
    };
  }

  getCusipsByTicker(identifier: string): Observable<any> {
    return from(
      this.wsp.getHttp({
        endpoint: this._securityIdentifiersTickerSearchEndpoint,
        optionsParams: { filters: [{ key: 'ticker', type: 'EQ', value: [identifier] }] }
      })
    );
  }

  getAllSecurities(): Promise<Security[]> {
    return this.wsp
      .getHttp<SecurityFromApi[]>({
        endpoint: this._securitiesEndpoint,
        optionsParams: { fields: this._securityFields }
      })
      .then(entities => {
        this._securities = entities.map(entity => this.formatSecurity(entity));
        return this._securities;
      });
  }

  getAllTradingSecurities(): Promise<Security[]> {
    const SECURITIES_URL = 'securities';
    return this.http
      .get(SECURITIES_URL)
      .toPromise()
      .then((securitiesApiData: SecurityFromApi[]) => {
        const securities = securitiesApiData.map(p => this.formatSecurity(p));
        return securities;
      });
  }

  searchSecurities(
    text: string,
    assetType: string,
    additionalFilters: {
      key: string;
      type: string;
      value: string[];
    }[] = [],
    includeCheckForExpiration = false
  ): Observable<SecuritySearchResult[]> {
    const filters = [{ key: 'searchstring', type: 'LIKE', value: [text] }].concat(
      additionalFilters
    );
    if (assetType) {
      filters.push({ key: 'assettype', type: 'EQ', value: [assetType] });
      if (includeCheckForExpiration) {
        filters.push({
          key: 'checkforexpiration',
          type: 'EQ',
          value: [`${(assetType === 'option').toString()}`]
        });
      }
    }
    return this.wsp.get<SecuritySearchResult[]>({
      endpoint: this._securitiesSearchEndpoint,
      optionsParams: {
        filters
      }
    });
  }

  getAssetTypes(): Promise<AssetType[]> {
    if (this._assetTypes) {
      return Promise.resolve(this._assetTypes);
    }

    return this.wsp
      .getHttp<any[]>({
        endpoint: this._assetTypesEndpoint,
        optionsParams: {
          fields: ['id', 'assettype', 'daysforsettlement', 'multiplier']
        }
      })
      .then(assetTypes => {
        this._assetTypes = assetTypes.map(x => {
          return this.formatAssetType(x);
        });
        return this._assetTypes;
      });
  }

  public formatAssetType(assetTypeFromApi: any): AssetType {
    return new AssetType(
      +assetTypeFromApi.id,
      assetTypeFromApi.assettype,
      +assetTypeFromApi.daysforsettlement,
      assetTypeFromApi.multiplier
    );
  }

  async getSecurityBySecMarkedId(secMarketId: number): Promise<Security> {
    const result = await this.wsp.getHttp<SecurityFromApi[]>({
      endpoint: this._securitiesEndpoint,
      optionsParams: {
        fields: this._securityFields,
        filters: [{ key: 'securityMarketId', type: 'EQ', value: [secMarketId] }]
      }
    });

    return this.formatSecurity(result[0]);
  }

  async getSecurityBySecMarketIdV2(secMarketId: number): Promise<SecurityV2> {
    return this.wsp.getHttp<SecurityV2>({
      endpoint: `v3/entities/securitiesv3/${secMarketId}`
    });
  }

  getSecurityAttributeOptions(
    attribute: string,
    isNumeric?: boolean
  ): Promise<SecurityAttributeOption[]> {
    attribute = attribute.replace(/ /g, '%20');
    return this.wsp
      .getHttp<SecurityAttributeOptionFromApi[]>({
        endpoint: this._securityAttributesEndpoint,
        optionsParams: {
          fields: ['optionvalue', 'optiondescription'],
          filters: [{ key: 'attribute', type: 'EQ', value: [attribute] }]
        }
      })
      .then(entities => {
        const securityAttributeOptions = entities.map(entity =>
          this.formatSecurityAttributeOption(entity)
        );
        if (isNumeric) {
          for (const securityAttributeOption of securityAttributeOptions) {
            const optionValue = parseInt(<string>securityAttributeOption.optionValue);
            securityAttributeOption.optionValue = !isNaN(optionValue) ? optionValue : null;
          }
        }
        return securityAttributeOptions;
      });
  }

  getSecurityTypes(): Promise<SecurityType[]> {
    if (this._securityTypes) {
      return Promise.resolve(this._securityTypes);
    }

    return this.wsp
      .getHttp<SecurityTypeFromApi[]>({
        endpoint: this._securityTypesEndpoint,
        optionsParams: {
          fields: ['id', 'AssetTypeId', 'SecType', 'SecTypeDescription']
        }
      })
      .then(entities => {
        this._securityTypes = entities.map(this.formatSecurityType);
        return this._securityTypes;
      });
  }

  getSecurityTypesForAssetType(assetTypeId: number): Promise<SecurityType[]> {
    return this.getSecurityTypes().then(securityTypes => {
      return securityTypes.filter(secType => secType.assetTypeId === assetTypeId);
    });
  }

  postSecurity(entityToSave: Security): Promise<Security> {
    const requestBody = this.getSecurityForServiceRequest(entityToSave);
    return this.wsp
      .postHttp<SecurityFromApi>({
        endpoint: this._securitiesEndpoint,
        body: requestBody
      })
      .then((entity: SecurityFromApi) => this.formatSecurity(entity));
  }

  putSecurity(entityToSave: Security): Promise<Security> {
    const requestBody = this.getSecurityForServiceRequest(entityToSave);
    return this.wsp
      .putHttp<SecurityFromApi>({
        endpoint: this._securitiesEndpoint,
        body: requestBody
      })
      .then((entity: SecurityFromApi) => this.formatSecurity(entity));
  }

  async saveSecurityAutomatically(
    autoAdd: boolean,
    identifierType: string,
    identifierValue: string
  ): Promise<Security> {
    try {
      const securityFromApi = await this.wsp.postHttp<SecurityFromApi>({
        endpoint: this._securitiesEndpoint,
        body: {
          autoAdd: autoAdd === true ? 'true' : 'false',
          identifierType: identifierType,
          identifierValue: identifierValue
        }
      });
      return this.formatSecurity(securityFromApi);
    } catch (e) {
      if (e.message && e.message.toLowerCase().includes('mandatory fields')) {
        throw { clientMessage: e.message };
      }
      throw e;
    }
  }

  async getRestrictedTradingList(): Promise<RestrictedSecurity[]> {
    const entities = await this.wsp.getHttp<RestrictedSecurityFromApi[]>({
      endpoint: this._rtlv2
    });

    return entities.map(this.formatRestricedTradingListItem);
  }

  deleteRestricedTradingListItem(id: number): Promise<any> {
    return this.wsp.deleteHttp({
      endpoint: `${this._rtlv2}/${id}`
    });
  }

  public postRestrictedTrade(trade: RestrictedSecurity): Promise<RestrictedSecurity> {
    return this.wsp
      .postHttp<RestrictedSecurityFromApi>({
        endpoint: this._rtlv2,
        body: {
          assetTypes: trade.assetTypes.toString(),
          endDate: trade.endDate,
          notes: trade.notes,
          securityId: trade.securityId,
          startDate: trade.startDate,
          userId: +trade.userId,
          accountMemberships: trade.accountMemberships,
          enforcement: trade.enforcement
        }
      })
      .then(res => this.formatRestricedTradingListItem(res));
  }

  public putRestrictedTrade(trade: RestrictedSecurity): Promise<RestrictedSecurity> {
    return this.wsp
      .putHttp<RestrictedSecurityFromApi>({
        endpoint: this._rtlv2,
        body: {
          assetTypes: trade.assetTypes.toString(),
          endDate: trade.endDate,
          notes: trade.notes,
          securityId: trade.securityId,
          startDate: trade.startDate,
          userId: +trade.userId,
          id: trade.id,
          accountMemberships: trade.accountMemberships,
          enforcement: trade.enforcement
        }
      })
      .then(res => this.formatRestricedTradingListItem(res));
  }

  public optionChainLookup(ticker: string, callPut: string): Observable<OptionLookupChainResponse> {
    return this.wsp.post<OptionLookupChainResponse>({
      endpoint: this._securityOptionLookupEndpoint,
      body: {
        ticker,
        callPut
      }
    });
  }

  private formatSecurity(entity: SecurityFromApi): Security {
    if (!entity) {
      return;
    }
    const assetTypeId = +entity.assettypeid,
      currencyId = +entity.currencyid,
      dataSourceId = +entity.datasource,
      id = +entity.id,
      multiplier = +entity.multiplier,
      organizationId = +entity.organization_id,
      securityTypeId = +entity.sectypeid,
      principalFactor = parseFloat(entity.principal_factor),
      initialMargin = parseFloat(entity.initialmargin),
      maintenanceMargin = parseFloat(entity.maintenancemargin),
      maturityMoment = moment(entity.maturity, 'MM/DD/YYYY');

    return new Security(
      entity.assettype,
      !isNaN(assetTypeId) ? assetTypeId : null,
      entity.countryofincorporation,
      entity.countryofrisk,
      +entity.countryofquotationid,
      entity.countryofquotationcode,
      entity.countryofquotationname,
      entity.countryofincorporationcode,
      entity.countryofincorporationname,
      entity.currency,
      !isNaN(currencyId) ? currencyId : null,
      entity.cusip,
      !isNaN(dataSourceId) ? dataSourceId : null,
      entity.description,
      !isNaN(id) ? id : null,
      entity.isin,
      entity.loanid,
      entity.manualpricingindicator === 'True',
      entity.moodyrating,
      !isNaN(multiplier) ? multiplier : null,
      entity.opracode,
      !isNaN(organizationId) ? organizationId : null,
      entity.organizationname,
      entity.organization_status_descr,
      entity.organization_status_id,
      entity.organizationticker,
      entity.privateindicator === 'True',
      entity.sandprating,
      entity.sector,
      entity.sectype,
      entity.sectypedescription,
      !isNaN(securityTypeId) ? securityTypeId : null,
      entity.sedol,
      entity.ticker,
      !isNaN(principalFactor) ? principalFactor : null,
      !isNaN(initialMargin) ? initialMargin : null,
      !isNaN(maintenanceMargin) ? maintenanceMargin : null,
      maturityMoment.isValid() ? maturityMoment.toDate() : null,
      entity.underlyingsecid ? +entity.underlyingsecid : null,
      +entity.sharesoutstanding,
      entity.pricingsourcename,
      entity.securitypricingid,
      +entity.securitymarketid,
      +entity.marketid,
      +entity.id
    );
  }

  private formatSecurityAttributeOption(
    entity: SecurityAttributeOptionFromApi
  ): SecurityAttributeOption {
    const id = parseInt(entity.id);
    return new SecurityAttributeOption(
      !isNaN(id) ? id : null,
      entity.optiondescription,
      entity.optionvalue
    );
  }

  private formatSecurityType(entity: SecurityTypeFromApi): SecurityType {
    const id = parseInt(entity.id, 10);
    const assetTypeId = parseInt(entity.assettypeid, 10);
    return new SecurityType(
      !isNaN(assetTypeId) ? assetTypeId : null,
      !isNaN(id) ? id : null,
      entity.sectype,
      entity.sectypedescription
    );
  }

  private getSecurityForServiceRequest(entity: Security): SecurityFromApi {
    const entityForApi = {} as SecurityFromApi,
      {
        currencyId,
        dataSourceId,
        description,
        id,
        manualPricingIndicator,
        multiplier,
        organizationId,
        privateIndicator,
        sandpRating,
        sector,
        securityTypeId
      } = entity;

    if (currencyId !== undefined) {
      entityForApi.currencyid = currencyId.toString();
    }
    if (dataSourceId !== undefined) {
      entityForApi.datasource = dataSourceId.toString();
    }
    if (description !== undefined) {
      entityForApi.description = description;
    }
    if (id !== undefined) {
      entityForApi.id = id.toString();
    }
    if (manualPricingIndicator !== undefined) {
      entityForApi.manualpricingindicator = manualPricingIndicator ? '1' : '0';
    }
    if (multiplier !== undefined) {
      entityForApi.multiplier = multiplier.toString();
    }
    if (organizationId !== undefined) {
      entityForApi['organizationid'] = organizationId.toString();
    }
    if (privateIndicator !== undefined) {
      entityForApi.privateindicator = privateIndicator ? '1' : '0';
    }
    if (sandpRating !== undefined) {
      entityForApi.sandprating = sandpRating;
    }
    if (sector !== undefined) {
      entityForApi.sector = sector;
    }
    if (securityTypeId !== undefined) {
      entityForApi.sectypeid = securityTypeId.toString();
    }
    return entityForApi;
  }

  private formatRestricedTradingListItem(entity: RestrictedSecurityFromApi): RestrictedSecurity {
    const id = parseInt(entity.id);
    return new RestrictedSecurity(
      id,
      +entity.securityId,
      entity.ticker,
      moment.utc(entity.startDate).toDate(),
      moment.utc(entity.endDate).toDate(),
      moment.utc(entity.timestamp).toDate(),
      entity.notes,
      entity.assetTypes,
      entity.countryOfIncorporation,
      entity.author,
      entity.userId,
      entity.accountMemberships,
      entity.enforcement
    );
  }
}

<highcharts-chart
  [Highcharts]="highcharts"
  [constructorType]="chartConstructor"
  [options]="chartOptions"
  [update]="updateFlag"
  style="width: 100%; height: 520px; display: block"
></highcharts-chart>

<div class="color-axis-container">
  <div class="mr-3">{{ minAxisLabel }}</div>
  <div
    class="color-axis-bar"
    style="background: #c80a0a"
  ></div>
  <div
    class="color-axis-bar"
    style="background: #ff3a2f"
  ></div>
  <div
    class="color-axis-bar"
    style="background: #ff7b50"
  ></div>
  <div
    class="color-axis-bar"
    style="background: #454d55"
  ></div>
  <div
    class="color-axis-bar"
    style="background: #64ec58"
  ></div>
  <div
    class="color-axis-bar"
    style="background: #0ad427"
  ></div>
  <div
    class="color-axis-bar"
    style="background: #00a235"
  ></div>
  <div class="ml-3">{{ maxAxisLabel }}</div>
</div>

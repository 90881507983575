import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  editPortfolioLatestPrice,
  loadPortfolioLatestPrices
} from './portfolio-latest-prices.actions';
import { LatestPrice, PortfolioLatestPricesState } from './portfolio-latest-prices.models';
import { PortfolioLatestPricesQuery } from './portfolio-latest-prices.selectors';

@Injectable()
export class PortfolioLatestPricesFacadeService {
  latestPrices$ = this.store.select(PortfolioLatestPricesQuery.getPortfolioLatestPrices);

  constructor(private readonly store: Store<PortfolioLatestPricesState>) {}

  getLatestPriceBySecurityMarketId(securityMarketId: number): Observable<LatestPrice | null> {
    return this.store.select(
      PortfolioLatestPricesQuery.getPortfolioLatestPriceBySecMarketId(securityMarketId)
    );
  }

  loadPortfolioLatestPrices(): void {
    this.store.dispatch(loadPortfolioLatestPrices());
  }

  updateLatestPriceBySecurityMarketId(securityMarketId: number, newLatestPrice: number): void {
    this.store.dispatch(
      editPortfolioLatestPrice({
        securityMarketId,
        newLatestPrice
      })
    );
  }
}

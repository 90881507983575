import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs';
import { TimeseriesPositionsApiService } from './timeseries-positions-api.service';
import { loadPositionsTimeSeries, positionsTimeSeriesLoaded } from './timeseries-positions.actions';

@Injectable()
export class TimeseriesPositionsEffects {
  loadPositionsTimeseries$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPositionsTimeSeries),
      concatMap(({ securityMarketId }) =>
        this.api.getTimeItemsBySecurityMarketId(securityMarketId).pipe(
          map(positions => ({
            securityMarketId,
            positions
          }))
        )
      ),
      map(positionsTimeItems => positionsTimeSeriesLoaded({ positionsTimeItems }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: TimeseriesPositionsApiService
  ) {}
}

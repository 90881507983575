import { InjectionToken } from '@angular/core';

export const HTTP_SERVICE_URL = new InjectionToken('tradingServiceUrl');
export const HTTP_SERVER_URL = new InjectionToken('serverHttpUrl');

export const USE_PMS_MOCK = new InjectionToken('Use Mock instaed of PMS endpoint response');
export const AG_GRID_KEY =
  'Using_this_{AG_Grid}_Enterprise_key_{AG-054749}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Pinnakl}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{pinnakl}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{pinnakl}_need_to_be_licensed___{pinnakl}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{7_February_2025}____[v3]_[01]_MTczODg4NjQwMDAwMA==2b7e7f298113f06f0e942281f6575836';

export const AG_GRID_KEY_TOKEN = new InjectionToken<string>('AG_GRID_KEY_TOKEN');
export const VDR_USER_SIDE = new InjectionToken('Use user side identify user type in vdr');
export const LOCATION = new InjectionToken('window.location');

export const V3_ENDPOINT = 'v3/entities/';
export const V4_ENDPOINT = 'v4/entities/';
export const PORTAL_USER_PERMISSION_SERVICE = 'Portal user permissions service';

import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { cloneDeep, includes, map, remove } from 'lodash';

import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { PopupSettings } from '@progress/kendo-angular-dropdowns/common/models/popup-settings';
import { Observable } from 'rxjs';

@Component({
  selector: 'pnkl-multiselect-dropdown',
  templateUrl: './pnkl-multiselect-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PnklMultiselectDropdownComponent),
      multi: true
    }
  ]
})
export class PnklMultiselectDropdownComponent implements ControlValueAccessor, OnChanges {
  private _dropdownSource: any[];
  @Input() popupSettings?: PopupSettings;
  @Input() controlName: string;
  @Input() disabled: boolean;
  @Input() inputClass: string;
  dropdownCollection: any[];
  dropdownLoading: boolean;
  comboBoxInstance: MultiSelectComponent;
  @Input()
  dropdownOptions: {
    allowCustom: boolean;
    disableSelectAll: boolean;
    isAsync: boolean;
    modelNormalizer: (textObservable: Observable<string>) => Observable<any>;
    modelProperty: string;
    objectModel: boolean;
    viewProperty: string;
  };
  @Output() dropdownFetchData = new EventEmitter<void>();
  @Output() onDropdownValueChange = new EventEmitter<number>();
  selectedValue: any[] = [];

  get dropdownSource(): any[] {
    return this._dropdownSource;
  }

  @Input()
  set dropdownSource(dropdownSource: any[]) {
    if (dropdownSource) {
      this.dropdownCollection = this._dropdownSource = [...dropdownSource];
    } else {
      this.dropdownCollection = this._dropdownSource = [];
    }

    if (!this.dropdownOptions || !this.dropdownOptions.isAsync) {
      return;
    }
    this.dropdownLoading = false;
    if (this.comboBoxInstance) {
      this.comboBoxInstance.toggle(true);
    }
  }

  propagateChange: any = () => {};

  dropdownOpen(event: any, comboBoxInstance: MultiSelectComponent): void {
    if (!this.comboBoxInstance) {
      this.comboBoxInstance = comboBoxInstance;
    }
    if (!this.dropdownOptions || !this.dropdownOptions.isAsync) {
      this.comboBoxInstance.toggle(true);
      return;
    }
    if (event) {
      event.preventDefault();
    }
    this.dropdownLoading = true;
    this.dropdownFetchData.emit();
  }

  filterDropdown(text: string): void {
    this.dropdownCollection = this._dropdownSource.filter(item => {
      const itemText: string =
        this.dropdownOptions && this.dropdownOptions.viewProperty
          ? (item[this.dropdownOptions.viewProperty]?.toString() ?? '')
          : item.toString();
      return itemText.toLowerCase().includes(text.toLowerCase());
    });
  }

  public onValueChange(value: any): void {
    if (this.dropdownOptions?.disableSelectAll) {
      this.selectedValue = value;
      this.propagateChange(value);
      this.onDropdownValueChange.emit(value);
      return;
    }
    this.onDropdownValueChange.emit(value);

    if (this.dropdownOptions?.modelProperty && this.dropdownOptions.modelProperty !== 'id') {
      if (this.dropdownOptions?.objectModel) {
        const allOption = value.find(
          (item: object) => item[this.dropdownOptions.viewProperty] === 'ALL'
        );
        if (allOption) {
          this.selectedValue = [allOption];
          const allValues = [
            ...this.dropdownCollection.filter(
              (item: object) => item[this.dropdownOptions.viewProperty] !== 'ALL'
            )
          ];
          this.propagateChange(allValues);
        } else {
          this.selectedValue = value;
          this.propagateChange(value);
        }
      } else {
        if (includes(value, 'ALL')) {
          this.selectedValue = ['ALL'];
          const allValues = map(
            this.dropdownCollection,
            item => item[this.dropdownOptions.viewProperty]
          );
          remove(allValues, item => {
            return item === 'ALL';
          });
          this.propagateChange(allValues);
        } else {
          this.selectedValue = value;
          this.propagateChange(value);
        }
      }
    } else if (
      this.dropdownOptions &&
      ((!this.dropdownOptions.objectModel && this.dropdownOptions.viewProperty) ||
        this.dropdownOptions.modelProperty === 'id')
    ) {
      if (includes(value, -1)) {
        this.selectedValue = [-1];
        const allValues = map(this.dropdownCollection, item => item.id);
        remove(allValues, item => {
          return item === -1;
        });
        this.propagateChange(allValues);
      } else {
        this.selectedValue = value;
        this.propagateChange(value);
      }
    } else if (this.dropdownOptions && this.dropdownOptions.objectModel) {
      let allObjectSelected = false;
      let allObject;
      value.forEach((item: any) => {
        if (item[this.dropdownOptions.viewProperty] === 'ALL') {
          allObjectSelected = true;
          allObject = item;
        }
      });
      if (allObjectSelected) {
        this.selectedValue = [allObject];
        const allValues = cloneDeep(this.dropdownCollection);
        remove(allValues, item => {
          return item[this.dropdownOptions.viewProperty] === 'ALL';
        });
        this.propagateChange(allValues);
      } else {
        this.selectedValue = value;
        this.propagateChange(value);
      }
    } else {
      if (value.includes('ALL')) {
        this.selectedValue = ['ALL'];
        this.propagateChange(this.dropdownCollection.filter(item => item !== 'ALL'));
      } else {
        this.selectedValue = value;
        this.propagateChange(value);
      }
    }
  }

  initializeSource(): void {
    if (this.dropdownOptions && !this.dropdownOptions.disableSelectAll && this.dropdownCollection) {
      if (this.dropdownOptions && this.dropdownOptions.viewProperty) {
        const allObject = {
          ...(this.dropdownOptions.modelProperty
            ? { [this.dropdownOptions.modelProperty]: -1 }
            : {}),
          id: -1,
          [this.dropdownOptions.viewProperty]: 'ALL'
        };
        if (this.dropdownCollection[0] && this.dropdownCollection[0].id !== -1) {
          this.dropdownCollection.unshift(allObject);
        }
      } else {
        if (this.dropdownCollection[0] !== 'ALL') {
          this.dropdownCollection.unshift('ALL');
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dropdownSource && changes.dropdownSource.currentValue) {
      this.initializeSource();
    }
  }

  writeValue(value: any): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    // this is the handler function we want to call whenever counterValue changes through the view.
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}
}

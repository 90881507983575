import { Injectable } from '@angular/core';
import { FileService, PresetsService, UserService } from '@pinnakl/core/data-providers';
import { PresetModel } from '@pinnakl/shared/types';
import { firstValueFrom } from 'rxjs';
import { AtdlDocumentModel } from './atdl-document.model';

export type AtdlCacheItem = {
  brokerCode: string;
  assetType: string;
  document: AtdlDocumentModel;
};

export type FixMessage = {
  fixTag: number;
  value: string;
};

export type AtdlStrategyFormResults = {
  messages: Array<FixMessage>;
  controls: { [name: string]: any };
};

export type AtdlStrategyFormState = {
  parameters: Record<string, string>;
  controlValues: Record<string, any>;
};

export type StrategyDefaults = {
  brokerCode: string;
  assetType: string;
  strategy: string;
  message: FixMessage[];
};

@Injectable()
export class AtdlService {
  private readonly CONFIG_NAME = 'ems_atdl_defaults';
  private readonly MODULE = 'EMS';

  private data: AtdlCacheItem[] = [];

  private defaults: PresetModel | null = null;

  private defaultsData: StrategyDefaults[] = [];

  constructor(
    private readonly fileService: FileService,
    private readonly presetsService: PresetsService,
    private readonly userService: UserService
  ) {}

  getDocument(brokerCode: string, assetType: string): Promise<AtdlDocumentModel> {
    return new Promise((resolve, reject) => {
      const cache = this.data.filter(
        entry => entry.assetType === assetType && entry.brokerCode === brokerCode
      );

      if (cache.length) {
        return resolve(cache[0].document);
      }

      firstValueFrom(this.fileService.getAtdlFile(brokerCode, assetType)).then(xml => {
        const document = new AtdlDocumentModel(xml);

        if (document.document === null) {
          return reject();
        }

        this.data.push({
          brokerCode,
          assetType,
          document
        });

        resolve(document);
      });
    });
  }

  setDefaults(defaults: StrategyDefaults) {
    const user = this.userService.getUser();

    if (user !== null) {
      this.defaultsData = this.defaultsData.filter(
        entry =>
          entry.strategy !== defaults.strategy &&
          entry.assetType !== defaults.assetType &&
          entry.brokerCode !== defaults.brokerCode
      );

      this.defaultsData.push(defaults);

      const preset: PresetModel = {
        configname: this.CONFIG_NAME,
        configvalue: JSON.stringify(this.defaultsData),
        userid: user.id.toString(),
        module: this.MODULE,
        id: this.defaults?.id
      };

      if (this.defaults === null) {
        this.presetsService.createPreset(preset);
      } else {
        this.presetsService.updatePreset(preset);
      }
    }
  }

  getDefaults(
    brokerCode: string,
    assetType: string,
    strategy: string
  ): Promise<StrategyDefaults | null> {
    return new Promise(resolve => {
      const user = this.userService.getUser();

      if (user === null) {
        return resolve(null);
      }

      if (this.defaults !== null) {
        const result = this.searchDefaults(brokerCode, assetType, strategy);

        if (result !== null) {
          return resolve(result);
        }

        resolve(null);
      }

      this.presetsService.getPresets(user?.id, this.CONFIG_NAME, this.MODULE).then(value => {
        this.defaults = value.length ? value[0] : null;

        if (this.defaults) {
          this.defaultsData = JSON.parse(this.defaults.configvalue);

          const result = this.searchDefaults(brokerCode, assetType, strategy);

          if (result !== null) {
            return resolve(result);
          }
        }

        resolve(null);
      });
    });
  }

  private searchDefaults(
    brokerCode: string,
    assetType: string,
    strategy: string
  ): StrategyDefaults | null {
    const results = this.defaultsData.filter(
      entry =>
        entry.strategy === strategy &&
        entry.assetType === assetType &&
        entry.brokerCode === brokerCode
    );

    return results.length ? results[0] : null;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pinnakl-big-toggle',
  templateUrl: './pinnakl-big-toggle.component.html',
  styleUrls: ['./pinnakl-big-toggle.component.scss']
})
export class PinnaklBigToggleComponent {
  uniqId = new Date().getTime();
  @Input() modelValue: any;
  @Output() changeValue = new EventEmitter();

  clickOnTheToggle() {
    this.modelValue = !this.modelValue;
    this.changeValue.emit(this.modelValue);
  }
}

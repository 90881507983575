import { createAction, props } from '@ngrx/store';
import { PmsGridColDef } from '@pinnakl/pms/domain';

export enum PmsGridColumnsActions {
  PrepareGridColumns = '[Pms Grid Columns] Prepare grid columns',
  SetGridColumns = '[Pms Grid Columns] Set grid columns',
  ResetGridColumns = '[Pms Grid Columns] Reset grid columns'
}

export const prepareGridColumns = createAction(PmsGridColumnsActions.PrepareGridColumns);
export const setGridColumns = createAction(
  PmsGridColumnsActions.SetGridColumns,
  props<{ colDefs: PmsGridColDef[] }>()
);
export const resetColumns = createAction(PmsGridColumnsActions.ResetGridColumns);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PmsPresetsApiService } from './presets-api.service';
import { PmsPresetsFacadeService } from './presets-facade.service';
import { PmsPresetsEffects } from './presets.effects';
import { initialState, presetsReducer } from './presets.reducer';
import { featureSelectorName } from './presets.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureSelectorName, presetsReducer, {
      initialState
    }),
    EffectsModule.forFeature([PmsPresetsEffects])
  ],
  providers: [PmsPresetsApiService, PmsPresetsFacadeService]
})
export class PmsPresetsDataAccessModule {}

<div class="container-fluid row">
  <div class="col-12 comparison-chart mt-2">
    <highcharts-chart
      [Highcharts]="highcharts"
      [constructorType]="chartConstructor"
      [options]="chartOptions"
      class="comparison-chart-container"
    ></highcharts-chart>
  </div>
</div>

<div>
  <div [ngClass]="inputClass">
    <kendo-multiselect
      #kendoMultiSelectInstance="kendoMultiSelect"
      (filterChange)="filterDropdown($event)"
      (open)="dropdownOpen($event, kendoMultiSelectInstance)"
      (valueChange)="onValueChange($event)"
      [attr.disabled]="disabled ? true : undefined"
      [clearButton]="false"
      [data]="dropdownCollection"
      [filterable]="true"
      [loading]="dropdownLoading"
      [popupSettings]="popupSettings"
      [textField]="dropdownOptions?.viewProperty ? dropdownOptions.viewProperty : undefined"
      [valueField]="
        dropdownOptions?.viewProperty
          ? dropdownOptions.modelProperty
            ? dropdownOptions.modelProperty
            : 'id'
          : undefined
      "
      [valuePrimitive]="dropdownOptions ? !dropdownOptions.objectModel : true"
      [value]="selectedValue"
    >
    </kendo-multiselect>
  </div>
</div>

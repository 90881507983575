export class StylesBuilderClass {
  private readonly classesArray: string[];

  get classesString(): string {
    return this.classesArray.join(' ');
  }

  constructor() {
    this.classesArray = [];
  }

  addClass(className: string, push = true): StylesBuilderClass {
    push && this.classesArray.push(className);
    return this;
  }
}

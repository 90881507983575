import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';
import { SecuritiesApiService } from './securities-api.service';
import { loadSecurities, securitiesLoaded } from './securities.actions';

@Injectable()
export class SecuritiesEffects {
  loadSecurities$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSecurities),
      switchMap(() => this.api.getSecurities()),
      map(securities => securitiesLoaded({ securities }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: SecuritiesApiService
  ) {}
}

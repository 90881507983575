import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OmsColDef } from '@pinnakl/shared/types';
import { includesStringCaseInsensitive } from '@pinnakl/shared/util-helpers';
import { GridColumnsManager } from '@pinnakl/shared/util-providers';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';

@Component({
  selector: 'pinnakl-table-columns-selector',
  templateUrl: './table-columns-selector.component.html',
  animations: [
    trigger('visibleChanged', [
      state('1', style({ transform: 'translateX(0px)', display: 'flex', opacity: 1 })),
      state('0', style({ transform: 'translateX(100%)' })),
      transition('* => *', animate('300ms'))
    ])
  ],
  styleUrls: ['./table-columns-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableColumnsSelectorComponent {
  private readonly displayField: keyof ColDef;
  @Input() visible = false;
  @Input() presetId?: string;
  @Input() unmovableColumnNames?: string[] = [];
  @Output() deletePreset = new EventEmitter();
  selectedColumns$: Observable<ColDef[]> = this._gridColumnsManager?.processedSelectedColumns$;
  unSelectedColumns$: Observable<ColDef[]> = this._gridColumnsManager?.processedUnselectedColumns$;
  public searchQuery = '';
  public confirmationVisible = false;

  constructor(private readonly _gridColumnsManager?: GridColumnsManager<ColDef>) {
    this.displayField = 'headerName';
    this._gridColumnsManager.selectedColumnsSortConfig = {
      sortField: this.displayField
    };
    this._gridColumnsManager.unselectedColumnsSortConfig = {
      sortField: this.displayField
    };
  }

  public addAllColumns(): void {
    this._gridColumnsManager.selectAllColumns();
  }

  public addColumn(column: OmsColDef): void {
    this._gridColumnsManager.selectColumn(column);
  }

  public removeColumn(column: OmsColDef): void {
    this._gridColumnsManager.deselectColumn(column);
  }

  // TODO extend logic in future
  removeImportantColumn(): void {
    this.confirmationVisible = false;
    this.deletePreset.emit();
  }

  // TODO extend logic in future
  canRemove(column: OmsColDef): boolean {
    if (this.unmovableColumnNames?.length) {
      return !this.unmovableColumnNames.some(name => name === column.field);
    }
    return true;
  }

  searchChanged(searchString: string): void {
    this._gridColumnsManager.unselectedColumnsFilters =
      searchString !== ''
        ? [
            ({ [this.displayField]: displayField }) =>
              includesStringCaseInsensitive(displayField, searchString)
          ]
        : [];
  }
}

import { createReducer, on } from '@ngrx/store';
import {
  currenciesLoaded,
  fxRatesLoaded,
  loadCurrencies,
  loadCurrenciesFailure,
  setFxRates
} from './common-data.actions';
import { CommonDataState } from './common-data.model';

export const initialState: CommonDataState = {
  fxRates: null,
  currencies: null,
  isCurrenciesLoading: false,
  isCurrenciesLoaded: false
};

export const commonDataReducer = createReducer(
  initialState,
  on(fxRatesLoaded, (state, { fxRates }) => ({
    ...state,
    fxRates
  })),
  on(setFxRates, (state, { fxRates }) => ({
    ...state,
    fxRates
  })),
  on(loadCurrencies, state => ({
    ...state,
    isCurrenciesLoading: true,
    isCurrenciesLoaded: false
  })),
  on(currenciesLoaded, (state, { currencies }) => ({
    ...state,
    currencies,
    isCurrenciesLoading: false,
    isCurrenciesLoaded: true
  })),
  on(loadCurrenciesFailure, (state, { error }) => ({
    ...state,
    isCurrenciesLoading: false,
    isCurrenciesLoaded: false
  }))
);

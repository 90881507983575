import { BehaviorSubject, combineLatest, debounceTime, map, Observable } from 'rxjs';

export type PrimeButtonSizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export const PrimeButtonSizeTypeDefault = 'md';

export interface ButtonConfig {
  label: string;
  size: PrimeButtonSizeType;
}

export class ButtonConfigBuilder {
  private readonly label$: BehaviorSubject<string>;
  private readonly size$: BehaviorSubject<PrimeButtonSizeType>;
  public readonly buttonConfig$: Observable<ButtonConfig>;

  get buttonConfig(): ButtonConfig {
    return {
      label: this.label,
      size: this.size
    };
  }

  set buttonConfig(config: ButtonConfig) {
    this.label = config.label;
    this.label$.next(this.label);
    this.size = config.size;
    this.size$.next(this.size);
  }

  constructor(
    private label = '',
    private size: PrimeButtonSizeType = PrimeButtonSizeTypeDefault
  ) {
    this.label$ = new BehaviorSubject<string>(this.label);
    this.size$ = new BehaviorSubject<PrimeButtonSizeType>(this.size);

    this.buttonConfig$ = combineLatest([this.label$, this.size$]).pipe(
      debounceTime(100),
      map(([label, size]) => ({
        label,
        size
      }))
    );
  }

  setLabel(label: string): ButtonConfigBuilder {
    this.label = label;
    this.label$.next(label);
    return this;
  }

  setSize(size: PrimeButtonSizeType): ButtonConfigBuilder {
    this.size = size;
    this.size$.next(size);
    return this;
  }
}

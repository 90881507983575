import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectButtonChangeEvent, SelectButtonOptionClickEvent } from 'primeng/selectbutton';

@Component({ template: '', standalone: true })
export class SelectButtonPublicApiComponent {
  /**
   * An array of selectitems to display as the available options.
   * @group Props
   */
  @Input() options: any[] | undefined;
  /**
   * Name of the label field of an option.
   * @group Props
   */
  @Input() optionLabel: string | undefined;
  /**
   * Name of the value field of an option.
   * @group Props
   */
  @Input() optionValue: string | undefined;
  /**
   * Name of the disabled field of an option.
   * @group Props
   */
  @Input() optionDisabled: string | undefined;
  /**
   * Whether selection can be cleared.
   * @group Props
   */
  @Input() unselectable = true;
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  @Input() tabindex = 0;
  /**
   * When specified, allows selecting multiple values.
   * @group Props
   */
  @Input() multiple: boolean | undefined;
  /**
   * Whether selection can not be cleared.
   * @group Props
   */
  @Input() allowEmpty = true;
  /**
   * Inline style of the component.
   * @group Props
   */
  @Input() style: any;
  /**
   * Style class of the component.
   * @group Props
   */
  @Input() styleClass: string | undefined;
  /**
   * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   * @group Props
   */
  @Input() ariaLabelledBy: string | undefined;
  /**
   * When present, it specifies that the element should be disabled.
   * @group Props
   */
  @Input() disabled: boolean | undefined;
  /**
   * A property to uniquely identify a value in options.
   * @group Props
   */
  @Input() dataKey: string | undefined;
  /**
   * Callback to invoke on input click.
   * @param {SelectButtonOptionClickEvent} event - Custom click event.
   * @group Emits
   */
  @Output() onOptionClick: EventEmitter<SelectButtonOptionClickEvent> =
    new EventEmitter<SelectButtonOptionClickEvent>();
  /**
   * Callback to invoke on selection change.
   * @param {SelectButtonChangeEvent} event - Custom change event.
   * @group Emits
   */
  @Output() onChange: EventEmitter<SelectButtonChangeEvent> =
    new EventEmitter<SelectButtonChangeEvent>();
}

<div class="row">
  <div class="col-md-12">
    <p>Securities</p>
    <form [formGroup]="securityForm">
      <security-selector
        [control]="getControl('security')"
        [disabled]="false"
        [required]="true"
      ></security-selector>
    </form>
  </div>
</div>

<kendo-chart>
  <kendo-chart-legend
    orientation="horizontal"
    position="top"
  ></kendo-chart-legend>
  <kendo-chart-value-axis>
    <kendo-chart-tooltip></kendo-chart-tooltip>
    <kendo-chart-value-axis-item
      [title]="{ text: 'Price' }"
      name="priceAxis"
    >
      <kendo-chart-value-axis-item-labels [content]="formatPrices">
      </kendo-chart-value-axis-item-labels>
    </kendo-chart-value-axis-item>
  </kendo-chart-value-axis>

  <kendo-chart-category-axis>
    <kendo-chart-category-axis-item
      [categories]="priceDates"
      name="priceCategory"
    >
      <kendo-chart-category-axis-item-labels [step]="priceStep">
      </kendo-chart-category-axis-item-labels>
    </kendo-chart-category-axis-item>
  </kendo-chart-category-axis>

  <kendo-chart-series>
    <kendo-chart-series-item
      *ngFor="let price of priceComparisonData"
      [data]="price.values"
      [markers]="{ visible: false }"
      [name]="price.key"
      categoryAxis="priceCategory"
      field="price"
      type="line"
    >
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>

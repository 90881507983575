import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Aum } from '@pinnakl/poems/aum/domain';
import { getLoadableEntities$, isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { combineLatest, filter, take } from 'rxjs';
import { aumLoaded, loadAum } from './aum.actions';
import { AumState } from './aum.models';
import { aumsQuery } from './aum.selectors';

@Injectable()
export class AumFacadeService {
  aums$ = this.store$.select(aumsQuery.getAums).pipe(filter(isNeitherNullNorUndefined));
  loading$ = this.store$.select(aumsQuery.getAumsLoading).pipe(filter(isNeitherNullNorUndefined));
  entities$ = getLoadableEntities$<Aum[]>(
    this.store$.select(aumsQuery.getAums).pipe(filter(isNeitherNullNorUndefined)),
    this.store$.select(aumsQuery.getAumsLoaded),
    this.store$.select(aumsQuery.getAumsLoading),
    this.loadAums.bind(this)
  );

  constructor(private readonly store$: Store<AumState>) {}

  loadAums(forceRefetch = false): void {
    combineLatest([
      this.store$.select(aumsQuery.getAumsLoaded),
      this.store$.select(aumsQuery.getAumsLoading)
    ])
      .pipe(take(1))
      .subscribe(([isLoaded, isLoading]) => {
        if (forceRefetch || (isLoaded === false && isLoading === false)) {
          this.store$.dispatch(loadAum());
        }
      });
  }

  setAums(aums: Aum[]): void {
    this.store$.dispatch(aumLoaded({ aums }));
  }
}

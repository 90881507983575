<ag-grid-angular
  (bodyScroll)="bodyScrollHandler()"
  (cellClicked)="onCellClicked($event)"
  (columnRowGroupChanged)="sortGroupsAndSetVisibility($event)"
  (dragStopped)="columnMovedHandler($event)"
  (firstDataRendered)="onFirstDataRendered($event)"
  (gridReady)="onGridReady($event)"
  (modelUpdated)="modelUpdateHandler($event)"
  (rowClicked)="onRowClicked($event)"
  (rowDataChanged)="onRowDataChange()"
  (selectionChanged)="selectionChanged()"
  (sortChanged)="sortHandler($event)"
  (viewportChanged)="viewPortChangedHandler()"
  [animateRows]="true"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [columnDefs]="columnDefs"
  [components]="frameworkComponents"
  [context]="context"
  [defaultColDef]="defaultColDef"
  [domLayout]="domLayout"
  [getRowId]="getRowId"
  [gridOptions]="gridOptions"
  [groupDefaultExpanded]="groupDefaultExpanded"
  [groupDisplayType]="groupDisplayType"
  [groupIncludeFooter]="groupIncludeFooter"
  [headerHeight]="headerHeight"
  [ngClass]="[themeClass, styleClass]"
  [pinnedBottomRowData]="pinnedBottomRowData"
  [quickFilterText]="searchText"
  [rowData]="rowData"
  [rowGroupPanelShow]="rowGroupPanelShow"
  [rowHeight]="rowHeight"
  [rowSelection]="rowSelection"
  [style.height]="gridHeight"
  [style.minHeight]="'150px'"
>
</ag-grid-angular>

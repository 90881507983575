<span
  [ngClass]="{
    disabled: controlDisabled,
    'label-required': controlRequired,
    'ng-invalid ng-dirty': controlInvalid
  }"
  class="label-text {{ styleClass }}"
>
  {{ label }}
</span>

@if (exclamationMessage) {
  <span
    class="flex align-items-center justify-content-center mb-2"
    [pTooltip]="exclamationMessage"
  >
    <i
      inlineSVG="sprites.svg#exclamation-mark"
      class="flex w-1rem h-1rem text-bluegray-600"
    ></i>
  </span>
}

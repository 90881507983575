<form
  [formGroup]="formGroup"
  class="row flex-column chart-date-options"
>
  <div
    *ngIf="hideSelected"
    class="col-6 mb-4"
  >
    <label> Selected </label>
    <kendo-multiselect
      [data]="selectionItems"
      formControlName="selectedItems"
    >
    </kendo-multiselect>
  </div>

  <div
    [ngClass]="{ 'col-6': hideSelected, 'col-12': !hideSelected }"
    class="d-flex align-items-center mb-4"
  >
    <ng-container *ngIf="!hideAllOptions">
      <div
        *ngFor="let option of viewOptions"
        class="toggle-radio-btn"
      >
        <input
          [id]="'frequency-' + option"
          [value]="option"
          formControlName="frequency"
          name="frequency"
          type="radio"
        />
        <label [for]="'frequency-' + option">{{ option }}</label>
      </div>
    </ng-container>
  </div>
</form>

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { GrpcMessage, RecursivePartial, ToProtobufJSONOptions } from '@ngx-grpc/common';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as googleProtobuf002 from '@ngx-grpc/well-known-types';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as ping003 from './ping.pb';

export enum PriceType {
  Mid = 0,
  Bid = 1,
  Ask = 2
}

/**
 * Message implementation for pricing.PriceResponse
 */
export class PriceResponse implements GrpcMessage {
  private _message?: Price;
  private _ping?: ping003.Ping;
  private _message: PriceResponse.MessageCase = PriceResponse.MessageCase.none;
  static id = 'pricing.PriceResponse';

  get ping(): ping003.Ping | undefined {
    return this._ping;
  }

  set ping(value: ping003.Ping | undefined) {
    if (value !== undefined && value !== null) {
      this._message = undefined;
      this._message = PriceResponse.MessageCase.ping;
    }
    this._ping = value;
  }

  get message(): Price | undefined {
    return this._message;
  }

  set message(value: Price | undefined) {
    if (value !== undefined && value !== null) {
      this._ping = undefined;
      this._message = PriceResponse.MessageCase.message;
    }
    this._message = value;
  }

  get message() {
    return this._message;
  }

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PriceResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<PriceResponse.AsObject>) {
    _value = _value || {};
    this.message = _value.message ? new Price(_value.message) : undefined;
    this.ping = _value.ping ? new ping003.Ping(_value.ping) : undefined;
    PriceResponse.refineValues(this);
  }

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PriceResponse();
    PriceResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PriceResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: PriceResponse, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.message = new Price();
          _reader.readMessage(_instance.message, Price.deserializeBinaryFromReader);
          break;
        case 2:
          _instance.ping = new ping003.Ping();
          _reader.readMessage(_instance.ping, ping003.Ping.deserializeBinaryFromReader);
          break;
        default:
          _reader.skipField();
      }
    }

    PriceResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: PriceResponse, _writer: BinaryWriter) {
    if (_instance.message) {
      _writer.writeMessage(1, _instance.message as any, Price.serializeBinaryToWriter);
    }
    if (_instance.ping) {
      _writer.writeMessage(2, _instance.ping as any, ping003.Ping.serializeBinaryToWriter);
    }
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PriceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PriceResponse.AsObject {
    return {
      message: this.message ? this.message.toObject() : undefined,
      ping: this.ping ? this.ping.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PriceResponse.AsProtobufJSON {
    return {
      message: this.message ? this.message.toProtobufJSON(options) : null,
      ping: this.ping ? this.ping.toProtobufJSON(options) : null
    };
  }
}

export module PriceResponse {
  /**
   * Standard JavaScript object representation for PriceResponse
   */
  export interface AsObject {
    message?: Price.AsObject;
    ping?: ping003.Ping.AsObject;
  }

  /**
   * Protobuf JSON representation for PriceResponse
   */
  export interface AsProtobufJSON {
    message: Price.AsProtobufJSON | null;
    ping: ping003.Ping.AsProtobufJSON | null;
  }

  export enum MessageCase {
    none = 0,
    message = 1,
    ping = 2
  }
}

/**
 * Message implementation for pricing.Price
 */
export class Price implements GrpcMessage {
  private _clientId: string;
  private _value?: googleProtobuf001.DoubleValue;
  private _securityMarketId: number;
  private _source: number;
  private _evalTime?: googleProtobuf002.Timestamp;
  private _priceType: PriceType;
  private _currency: string;
  static id = 'pricing.Price';

  get clientId(): string {
    return this._clientId;
  }

  set clientId(value: string) {
    this._clientId = value;
  }

  get value(): googleProtobuf001.DoubleValue | undefined {
    return this._value;
  }

  set value(value: googleProtobuf001.DoubleValue | undefined) {
    this._value = value;
  }

  get securityMarketId(): number {
    return this._securityMarketId;
  }

  set securityMarketId(value: number) {
    this._securityMarketId = value;
  }

  get source(): number {
    return this._source;
  }

  set source(value: number) {
    this._source = value;
  }

  get evalTime(): googleProtobuf002.Timestamp | undefined {
    return this._evalTime;
  }

  set evalTime(value: googleProtobuf002.Timestamp | undefined) {
    this._evalTime = value;
  }

  get priceType(): PriceType {
    return this._priceType;
  }

  set priceType(value: PriceType) {
    this._priceType = value;
  }

  get currency(): string {
    return this._currency;
  }

  set currency(value: string) {
    this._currency = value;
  }

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Price to deeply clone from
   */
  constructor(_value?: RecursivePartial<Price.AsObject>) {
    _value = _value || {};
    this.clientId = _value.clientId;
    this.value = _value.value ? new googleProtobuf001.DoubleValue(_value.value) : undefined;
    this.securityMarketId = _value.securityMarketId;
    this.source = _value.source;
    this.evalTime = _value.evalTime ? new googleProtobuf002.Timestamp(_value.evalTime) : undefined;
    this.priceType = _value.priceType;
    this.currency = _value.currency;
    Price.refineValues(this);
  }

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Price();
    Price.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Price) {
    _instance.clientId = _instance.clientId || '0';
    _instance.value = _instance.value || undefined;
    _instance.securityMarketId = _instance.securityMarketId || 0;
    _instance.source = _instance.source || 0;
    _instance.evalTime = _instance.evalTime || undefined;
    _instance.priceType = _instance.priceType || 0;
    _instance.currency = _instance.currency || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Price, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.clientId = _reader.readFixed64String();
          break;
        case 2:
          _instance.value = new googleProtobuf001.DoubleValue();
          _reader.readMessage(
            _instance.value,
            googleProtobuf001.DoubleValue.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.securityMarketId = _reader.readInt32();
          break;
        case 4:
          _instance.source = _reader.readInt32();
          break;
        case 5:
          _instance.evalTime = new googleProtobuf002.Timestamp();
          _reader.readMessage(
            _instance.evalTime,
            googleProtobuf002.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.priceType = _reader.readEnum();
          break;
        case 7:
          _instance.currency = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Price.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Price, _writer: BinaryWriter) {
    if (_instance.clientId) {
      _writer.writeFixed64String(1, _instance.clientId);
    }
    if (_instance.value) {
      _writer.writeMessage(
        2,
        _instance.value as any,
        googleProtobuf001.DoubleValue.serializeBinaryToWriter
      );
    }
    if (_instance.securityMarketId) {
      _writer.writeInt32(3, _instance.securityMarketId);
    }
    if (_instance.source) {
      _writer.writeInt32(4, _instance.source);
    }
    if (_instance.evalTime) {
      _writer.writeMessage(
        5,
        _instance.evalTime as any,
        googleProtobuf002.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.priceType) {
      _writer.writeEnum(6, _instance.priceType);
    }
    if (_instance.currency) {
      _writer.writeString(7, _instance.currency);
    }
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Price.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Price.AsObject {
    return {
      clientId: this.clientId,
      value: this.value ? this.value.toObject() : undefined,
      securityMarketId: this.securityMarketId,
      source: this.source,
      evalTime: this.evalTime ? this.evalTime.toObject() : undefined,
      priceType: this.priceType,
      currency: this.currency
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Price.AsProtobufJSON {
    return {
      clientId: this.clientId,
      value: this.value ? this.value.toProtobufJSON(options) : null,
      securityMarketId: this.securityMarketId,
      source: this.source,
      evalTime: this.evalTime ? this.evalTime.toProtobufJSON(options) : null,
      priceType:
        PriceType[this.priceType === null || this.priceType === undefined ? 0 : this.priceType],
      currency: this.currency
    };
  }
}

export module Price {
  /**
   * Standard JavaScript object representation for Price
   */
  export interface AsObject {
    clientId: string;
    value?: googleProtobuf001.DoubleValue.AsObject;
    securityMarketId: number;
    source: number;
    evalTime?: googleProtobuf002.Timestamp.AsObject;
    priceType: PriceType;
    currency: string;
  }

  /**
   * Protobuf JSON representation for Price
   */
  export interface AsProtobufJSON {
    clientId: string;
    value: googleProtobuf001.DoubleValue.AsProtobufJSON | null;
    securityMarketId: number;
    source: number;
    evalTime: googleProtobuf002.Timestamp.AsProtobufJSON | null;
    priceType: string;
    currency: string;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InvestorRelationsService } from './investor-relations.service';
import { InvestorService } from './investor.service';

@NgModule({
  imports: [CommonModule],
  providers: [InvestorService, InvestorRelationsService]
})
export class InvestorModule {}

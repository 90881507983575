import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BenchmarkIndex } from '@pinnakl/poems/pricing/domain';
import { DateHelpers, isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { Observable, distinctUntilChanged, filter, interval, map, take } from 'rxjs';
import {
  clearMainAccountId,
  loadBenchmarkIndexIntradayPrices,
  setMainAccountId,
  startBenchmarkIndexesPolling,
  stopBenchmarkIndexesPolling
} from './benchmark-index-intraday-prices.actions';
import { BenchmarkIndexIntradayPricesState } from './benchmark-index-intraday-prices.model';
import { benchmarkIndexIntradayPricesQuery } from './benchmark-index-intraday-prices.selectors';

@Injectable()
export class BenchmarkIndexIntradayPricesFacadeService {
  public readonly benchmarks$: Observable<BenchmarkIndex[]>;
  public readonly isMarketOpen$: Observable<boolean>;

  constructor(private readonly store: Store<BenchmarkIndexIntradayPricesState>) {
    this.benchmarks$ = this.store.select(
      benchmarkIndexIntradayPricesQuery.benchmarkIndexesSelector
    );

    // check every minute if market is open
    this.isMarketOpen$ = interval(60 * 1000).pipe(
      map(() => DateHelpers.isMarketOpen()),
      distinctUntilChanged()
    );
  }

  loadIntradayPerformance(): void {
    this.store
      .select(benchmarkIndexIntradayPricesQuery.mainAccountIdSelector)
      .pipe(filter(isNeitherNullNorUndefined), take(1))
      .subscribe(() => this.store.dispatch(loadBenchmarkIndexIntradayPrices()));
  }

  startPolling(): void {
    this.store.dispatch(startBenchmarkIndexesPolling());
  }

  stopPolling(): void {
    this.store.dispatch(stopBenchmarkIndexesPolling());
  }

  setMainAccountId(id: number): void {
    this.store.dispatch(setMainAccountId({ id }));
  }

  clearMainAccountId(): void {
    this.store.dispatch(clearMainAccountId());
  }
}

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { TradeWorkflowSpecFacadeService } from '@pinnakl/poems/trade-workflow-spec/data-access';
import { map } from 'rxjs/operators';

export type AccessFields =
  | 'pmsVersionsAccessLevel'
  | 'emsVersionsAccessLevel'
  | 'rebalancingAccessLevel';
export type AccessBaseUrls = 'pms' | 'ems' | 'rebalancing';
export type PageVersions = 'V1_ONLY' | 'V2_ONLY' | true;

export const checkPageVersionsAccess =
  (accessField: AccessFields, baseUrlToNavigate: AccessBaseUrls) => () => {
    const router = inject(Router);
    const tradesWorkflowSpecFacade = inject(TradeWorkflowSpecFacadeService);

    return tradesWorkflowSpecFacade.entities$.pipe(
      map(tradesWorkflowSpec => {
        if (
          tradesWorkflowSpec[accessField] === 'V1_AND_V2' ||
          tradesWorkflowSpec[accessField] === true
        ) {
          return true;
        }

        let urlToNavigate = `${baseUrlToNavigate}/v1`;
        if (tradesWorkflowSpec[accessField] === 'V2_ONLY') {
          urlToNavigate = `${baseUrlToNavigate}/v2`;
        }

        return router.parseUrl(urlToNavigate);
      })
    );
  };

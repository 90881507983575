<div class="container-round-checkbox">
  <input
    (ngModelChange)="inputModelChange.emit($event)"
    [(ngModel)]="value"
    [disabled]="disabled"
    [id]="'checkbox-round' + uniqId"
    class="checkbox-round"
    type="checkbox"
  />
  <label
    [for]="'checkbox-round' + uniqId"
    class="label"
  >
    {{ label }}
  </label>
</div>

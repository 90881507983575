import { createAction, props } from '@ngrx/store';
import { PriceEventData } from '@pinnakl/poems/streams/price/domain';

export enum PriceEventsStreamActions {
  SubscribeToStream = '[Price Events Stream] Subscribe to stream',
  PriceEventsReceived = '[Price Events Stream] Events from stream',
  SubscribeToStreamFailure = '[Price Events Stream] Subscribe stream failure',
  UnsubscribeFromStream = '[Price Events Stream] Unsubscribe from stream',
  UnsubscribeFromStreamFailure = '[Price Events Stream] Unsubscribe stream failure',
  EmitConnectionEstablished = '[Price Events Stream] Emit connection established',
  EmitConnectionError = '[Price Events Stream] Emit connection error'
}

export const subscribeToStream = createAction(
  PriceEventsStreamActions.SubscribeToStream,
  props<{ token: string }>()
);
export const subscribeToStreamFailure = createAction(
  PriceEventsStreamActions.SubscribeToStreamFailure,
  props<{ error: any }>()
);
export const priceEventsReceived = createAction(
  PriceEventsStreamActions.PriceEventsReceived,
  props<{ events: PriceEventData[] }>()
);
export const unsubscribeFromStream = createAction(PriceEventsStreamActions.UnsubscribeFromStream);
export const unsubscribeFromStreamFailure = createAction(
  PriceEventsStreamActions.UnsubscribeFromStreamFailure,
  props<{ error: any }>()
);
export const emitConnectionEstablished = createAction(
  PriceEventsStreamActions.EmitConnectionEstablished,
  props<{ disconnect?: boolean }>()
);
export const emitConnectionError = createAction(
  PriceEventsStreamActions.EmitConnectionError,
  props<{ isFixed?: boolean }>()
);

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class GoogleMapsAppService {
  // TODO: move API key to environment variables and import it from there
  private readonly GOOGLE_MAPS_API_KEY = 'AIzaSyAapQqQUfUgTp2YoEVDeJbvpzUEqDcaTpg';
  private readonly googleMapsApiLoaded = new BehaviorSubject(false);
  public readonly onGoogleMapsApiLoaded = this.googleMapsApiLoaded.pipe(
    filter(isLoaded => isLoaded)
  );

  constructor(
    private http: HttpClient,
    private readonly geocoder: MapGeocoder
  ) {
    this.loadGoogleMapsApi();
  }

  getCoordinatesForAddress(address: string): Observable<{ latitude: number; longitude: number }> {
    return this.geocoder.geocode({ address, language: 'en' }).pipe(
      map(response => {
        const { status, results } = response;
        if (status.toString() === 'OK') {
          const result = results[0];
          return {
            latitude: Math.round(result.geometry.location.lat() * 10000) / 10000,
            longitude: Math.round(result.geometry.location.lng() * 10000) / 10000
          };
        } else {
          throw response;
        }
      })
    );
  }

  private loadGoogleMapsApi(): void {
    this.http
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${this.GOOGLE_MAPS_API_KEY}&libraries=places&language=en`,
        'callback'
      )
      .subscribe({
        next: () => this.googleMapsApiLoaded.next(true),
        error: () => this.googleMapsApiLoaded.next(false)
      });
  }
}
